import {
  Assets,
  ModelAssetsFilterInput,
  PageSection,
  Pages,
  Project,
  UpdatePagesInput,
} from "@/API";
import { listAssets } from "@/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import { adminData } from "@/constants/staticData";
import { updateAssets, updatePages } from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import useS3Keys from "@/hooks/useS3Keys";
import { imageAddTemplate } from "@/templates/email-template";
import { getImageFromS3 } from "@/utils";
import { fetchFolderSize, sendEmail } from "@/utils/graphql";
import {
  Button,
  Card,
  Checkbox,
  Empty,
  Flex,
  Modal,
  Row,
  Tooltip,
  message,
  notification,
} from "antd";
import dayjs from "dayjs";
import { isEmpty, uniq } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { VscFileMedia } from "react-icons/vsc";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import NewAsset from "../RareComponents/NewAsset";
import Loading from "../common/Loading";
import PaginationComponent from "../common/PaginationComponent";
import QuickButton from "../common/QuickButton";
import { AssetWithStore } from "../sections/Assets";
import AllMediaModal, { ImageColMedia } from "./AllMediaModal";

const itemsPerPage = 4;

export const fetchAssets = async (assetIds: string[]) => {
  const ids = assetIds?.map((d) => ({
    id: {
      eq: d,
    },
  }));

  if (isEmpty(ids)) {
    return [];
  }
  const filter: ModelAssetsFilterInput = {
    or: ids,
  };

  const response: any = await API.graphql(
    graphqlOperation(listAssets, {
      limit: 500,
      filter,
    })
  );
  const items = response.data.listAssets.items ?? [];
  return items as Assets[];
};

const SectionMediaFiles = ({
  assetIds = [],
  pageId,
  sectionId,
  page,
  refetch,
  setSelectedSection,
  project,

  section,
}: {
  assetIds: string[];
  pageId: string;
  page: Pages;
  sectionId: string;
  project: Project;

  section: PageSection;
  refetch: () => void;
  setSelectedSection: (section: PageSection) => void;
}) => {
  const [localPageCopy, setLocalPageCopy] = useState({ ...page });

  const {
    data: assets,
    isLoading,
    isRefetching,
    refetch: refetchAssets,
  } = useQuery(`project-${pageId}-${sectionId}`, () => fetchAssets(assetIds), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !isEmpty(assetIds),
    // onSuccess(data) {
    //   // update assignedToInfo and isAssigned
    //   if (data && data.length > 0) {
    //     data.forEach(async (asset) => {
    //       if (
    //         isEmpty(asset?.assignedToInfo) ||
    //         (isEmpty(asset?.assignedToInfo.pageID) &&
    //           isEmpty(asset?.assignedToInfo.sectionID))
    //       ) {
    //         const input: UpdateAssetsInput = {
    //           id: asset.id,
    //           isAssigned: true,
    //           assignedDate: new Date().toISOString(),
    //           assignedToInfo: {
    //             sectionID: sectionId,
    //             pageID: pageId,
    //           },
    //         };
    //         const res: any = await API.graphql(
    //           graphqlOperation(updateAssets, {
    //             input,
    //           })
    //         );
    //         if (res.data.updateAssets) {
    //           console.log("Updated asset, ", section.section);
    //         }
    //       }
    //     });
    //   }
    // },
  });

  useEffect(() => {
    refetchAssets();
  }, [assetIds?.length]);

  const [allMediaModal, setAllMediaModal] = useState(false);

  const { email } = useUserData();

  const [notificationApi, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMessage] = message.useMessage();

  const onSendEmail = async (imgUrl: string, restrictByDay = true) => {
    const lastEmailSent = section?.lastEmailSent;

    // if last sent date is not null and is less than 24 hours

    if (
      restrictByDay &&
      lastEmailSent &&
      dayjs().diff(dayjs(lastEmailSent), "hour") < 24
    ) {
      messageApi.info("Email already sent in last 24 hours for this section");
      console.log("Email already sent in last 24 hours");

      return;
    }

    try {
      await sendEmail(
        adminData.email,
        "New image attached",
        imageAddTemplate(
          project.name,
          section.section,
          imgUrl,
          email!,
          `${location.origin}/project-view/${project.organizationID}/${project.id}?breadcrumbs=pages`
        ),
        false,
        "projects@zoiq.io"
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onImgClick = async (assetId: string, assetLink: string) => {
    if (localPageCopy.id !== pageId) return; // fix to prevent duplicate ids

    try {
      const updatedSections = localPageCopy.pageSections?.map((d) => {
        if (d === null) return null;
        if (d.id === sectionId) {
          return {
            ...d,
            media: uniq([...(assetIds || []), assetId]).filter(Boolean),
            lastEmailSent: new Date().toISOString(),
          };
        }
        return d;
      });

      const input: UpdatePagesInput = {
        id: pageId,
        pageSections: updatedSections,
      };

      const response: any = await API.graphql(
        graphqlOperation(updatePages, {
          input,
        })
      );
      await API.graphql(
        graphqlOperation(updateAssets, {
          input: {
            id: assetId,
            isAssigned: true,
            assignedDate: new Date().toISOString(),
          },
        })
      );

      if (response?.data?.updatePages) {
        const section = response.data.updatePages.pageSections?.find(
          (d: { id: string }) => d?.id === sectionId
        ) as PageSection;

        if (section) {
          notificationApi.success({
            message: <strong>Image attached</strong>,
            key: "image-attached",
            description:
              "Do you want ZOIQ to begin adding this media to your project (you can notify them later if you want to wait)?",
            btn: (
              <Flex gap={16}>
                <Button
                  onClick={() => {
                    notificationApi.destroy("image-attached");
                  }}
                >
                  No
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    onSendEmail(getImageFromS3(assetLink), false);
                    notificationApi.destroy("image-attached");
                  }}
                >
                  Yes
                </Button>
              </Flex>
            ),
          });
          setLocalPageCopy({ ...localPageCopy, pageSections: updatedSections });
          setSelectedSection(section);
          refetch();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onImgRemoveClick = async (assetId: string) => {
    if (localPageCopy.id !== pageId) return;

    try {
      const updatedSections = localPageCopy.pageSections?.map((d) => {
        if (d === null) return null;
        if (d.id === sectionId) {
          return {
            ...d,
            media: uniq(assetIds.filter((d) => d !== assetId)).filter(Boolean),
          };
        }
        return d;
      });

      const input: UpdatePagesInput = {
        id: pageId,
        pageSections: updatedSections,
      };

      const response: any = await API.graphql(
        graphqlOperation(updatePages, {
          input,
        })
      );

      await API.graphql(
        graphqlOperation(updateAssets, {
          input: {
            id: assetId,
            isAssigned: false,
            assignedDate: new Date().toISOString(),
            assignedToInfo: {
              sectionID: null,
              pageID: null,
            },
          },
        })
      );

      if (response?.data?.updatePages) {
        console.log("Removed media to section");
        refetch();
        const section = response.data.updatePages.pageSections?.find(
          (d: { id: string }) => d?.id === sectionId
        ) as PageSection;

        if (section) {
          setLocalPageCopy({ ...localPageCopy, pageSections: updatedSections });
          setSelectedSection(section);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const currentSection = localPageCopy.pageSections?.find(
    (d) => d?.id === sectionId
  ) as PageSection;

  const [noMedia, setNoMedia] = useState(Boolean(currentSection.noMedia));

  const onMediaFlagChange = async (value: boolean) => {
    const existingValue = noMedia;
    setNoMedia(value);
    try {
      if (localPageCopy.id !== pageId) return;

      const updatedSections = localPageCopy.pageSections?.map((d) => {
        if (d === null) return null;
        if (d.id === sectionId) {
          return {
            ...d,
            noMedia: value,
          };
        }
        return d;
      });

      const input: UpdatePagesInput = {
        id: pageId,
        pageSections: updatedSections,
      };
      setLocalPageCopy({ ...localPageCopy, pageSections: updatedSections });

      const response: any = await API.graphql(
        graphqlOperation(updatePages, {
          input,
        })
      );
      refetch();

      if (!response.data.updatePages) {
        console.error("Something went wrong");

        setNoMedia(existingValue);
      }
    } catch (error) {
      console.error(error);
      setNoMedia(existingValue);
    }
  };

  const [editAssetData, setEditAssetData] = useState<AssetWithStore>(
    {} as AssetWithStore
  );

  const [newAssetModal, setNewAssetModal] = useState(false);

  const onImgEdit = async (id: string) => {
    setNewAssetModal(true);
    const asset = assets?.find((asset) => asset.id === id) as Assets;
    if (!isEmpty(asset)) {
      setEditAssetData({
        ...asset,
        store: {} as any,
      });
    }
  };

  const onCancel = () => {
    setNewAssetModal(false);
    setEditAssetData({} as AssetWithStore);
  };

  const [sizeRemaingPercentage, setSizeRemaingPercentage] = useState<
    null | number
  >(null);

  const [usedSize, setUsedSize] = useState<null | number>(null);
  const bucketSize = project.organization?.maxBucketSize || 0;

  const calculatePercentage = useCallback(
    (folderUsedSize: number) => {
      // const MAX_BUCKET_SIZE = 10 // in mb
      const percentage = (folderUsedSize / bucketSize) * 100;
      return percentage;
    },
    [bucketSize]
  );

  useEffect(() => {
    if (sizeRemaingPercentage === null) {
      fetchFolderSize(projectFilesKey).then((res) => {
        if (typeof res === "number") {
          setSizeRemaingPercentage(calculatePercentage(res));
          setUsedSize(res);
        }
      });
    }
  }, [sizeRemaingPercentage, bucketSize]);

  const remainingSize = usedSize !== null ? bucketSize - usedSize : bucketSize;

  const { organisationId } = useParams<{ organisationId: string }>();
  const { projectFilesKey } = useS3Keys(project.id, organisationId!);

  return (
    <Card
      className="card"
      extra={
        <QuickButton
          disabled={noMedia}
          icon={<VscFileMedia />}
          onClick={() => setAllMediaModal(true)}
        >
          Update Media
        </QuickButton>
      }
      style={{
        width: "100%",
      }}
    >
      {contextHolderMessage}
      {contextHolder}
      {newAssetModal && (
        <Modal
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          title={isEmpty(editAssetData) ? "Edit File" : "Upload Files"}
          open={newAssetModal}
          width={1000}
          onCancel={onCancel}
        >
          <NewAsset
            onCancel={onCancel}
            remainingSize={remainingSize}
            editAssetData={editAssetData}
            organisationId={organisationId!}
            onSuccess={() => {
              onCancel();
              refetch?.();
            }}
            projectId={project.id}
            activeKey={editAssetData.type}
          />
        </Modal>
      )}

      {allMediaModal && (
        <AllMediaModal
          allMediaModal={allMediaModal}
          setAllMediaModal={setAllMediaModal}
          project={project}
          onSendEmail={onSendEmail}
          onImgClick={onImgClick}
          remainingSize={remainingSize}
          onImgRemoveClick={onImgRemoveClick}
          assetIds={assetIds}
        />
      )}
      {isLoading || isRefetching ? (
        <Loading useAppContainer={false} />
      ) : isEmpty(assets) ? (
        <Empty
          description={noMedia ? "No Media is flagged" : "No media files"}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <>
          <PaginationComponent items={assets!} itemsPerPage={itemsPerPage}>
            {(currentImages) => (
              <Row gutter={[16, 16]}>
                {currentImages.map((asset) => (
                  <ImageColMedia
                    onSendEmail={onSendEmail}
                    key={asset.id}
                    onlyRemove
                    mediaIds={assetIds}
                    onImgAttach={onImgClick}
                    onImgRemoveAttach={onImgRemoveClick}
                    // onImgDelete={onImgDelete}
                    onImgEdit={onImgEdit}
                    asset={asset}
                  />
                ))}
              </Row>
            )}
          </PaginationComponent>
        </>
      )}

      <Tooltip
        title={
          !isEmpty(assets)
            ? "Media already attached to this section"
            : "If Flagged, it will disable attaching media to this section"
        }
      >
        <Checkbox
          disabled={!isEmpty(assets)}
          checked={noMedia}
          onChange={(e) => {
            onMediaFlagChange(e.target.checked);
          }}
        >
          No Media
        </Checkbox>
      </Tooltip>
    </Card>
  );
};

export default SectionMediaFiles;
