import { NavLink } from "react-router-dom";
import { OUR_MISSION_VIDEO_URL } from "./assets";

export const HERO = {
  subtitle:
    "We provide start-ups, non-profits, and growing businesses access to emerging technologies that help them improve the impact on their bottom line.",
  subtitle2:
    "As emerging technologies reshape the human experience, let us show you how to harness what is happening to improve the impact on your bottom line.",
  title_p1: "Go Virtually Anywhere",
  title_p2: "Tech for",
  button: "Start Journey",

  backgroundImage:
    "https://images.unsplash.com/photo-1510906594845-bc082582c8cc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2044&q=80",

  words: [
    {
      id: 1,
      word: "Go ",
      highlight: false,
    },
    {
      id: 2,
      word: "Virtually",
      highlight: true,
    },
    {
      id: 3,
      word: "Anywhere",
      highlight: false,
    },
    {
      id: 4,
      word: "",
      highlight: false,
    },
  ],
};

export const CONTACT_US = {
  heading: "| Contact Us",
  title: (
    <>
      Have an Idea? <br /> Contact Us To Move it Forward
    </>
  ),
  text: "Click Here to Move Forward Today",
  formHeading: "Have an Idea? <br /> Contact Us To Move it Forward",
  formAddr: "+52 8 111 666 076 / idea@zoiq.io",
  mail: {
    // an initial body message placeholder from the client who is sending the email for website idea

    body: `Hi Zoiq Team,
    I have an idea for a website. I would like to discuss it with you.
    
    `,
    // also a preadded subject line
    subject: "Website Idea",
  },
};

const mailTo = () => {
  // @ts-ignore
  window.location = `mailto:info@zoiq.io?subject=${CONTACT_US.mail.subject}&body=${CONTACT_US.mail.body}`;
};

export const BASE_URL = "https://zoiq.io";
export const TASK_URL = `${BASE_URL}/sprints`;

export const OUR_MISSION = {
  heading: "| Follow The Data",
  heading2: "The sky's the limit.",
  text: (
    <>
      ZOIQ develops digital solutions that promotes innovation, creativity, and
      data-driven decision making. Our goal is to assist organizations gain a
      competitive advantage by utilizing emerging technologies to generate data
      points that lead to measurable outcomes and quantifiable impact
      assessments.
      <br /> <br /> Contact us at{" "}
      <span onClick={mailTo} className="highlight-link">
        info@zoiq.io
      </span>
      , or{" "}
      <NavLink to="/project-calculator">
        <span className={"highlight-link"}>click here</span>
      </NavLink>{" "}
      and let us help you see what you are doing and quantify the impact it is
      having to where you want to be.
    </>
  ),
  audio_prompt_text: "Add some audio with my visual, please",
  video_skip_text: "Skip",
  play_video_text: "Replay",
  video: {
    url: OUR_MISSION_VIDEO_URL,
    heading: "| For the visually inclined...",
  },
};

export const OUR_SERVICES = {
  heading: "| Our Services",
  title: (
    <>
      Services that <br className="hidden sm:block" />
      we provide to you
    </>
  ),
  helper_text: "Click On Services To Learn More",
  BUTTON: {
    front: "Learn More",
    back: "Learn More",
  },
  SERVICES: [
    {
      id: "1",
      img: "https://images.unsplash.com/photo-1523240795612-9a054b0db644?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
      name: "Digital Branding <br/>  ",
      heading: "Digital Development",
      showButton: true,
      url: "/services/startup",

      front: "Learn More",
      back: "Learn More",
      list: [
        { id: "3a", name: "Websites/PWAs" },
        { id: "3b", name: "Apps" },
        { id: "3c", name: "Social Networking" },
        { id: "3d", name: "Email Campaigns" },
        // { id: "3e", name: "Analytics Toolbox" },
      ],
    },

    {
      id: "2",
      img: "https://images.unsplash.com/photo-1661956602868-6ae368943878?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
      name: "Business<br/> Intelligence",
      heading: "Business Intelligence",
      showButton: true,
      url: "/services/business-intelligence",
      front: "Learn More",
      back: "Learn More",
      list: [
        { id: "2a", name: "Surveys" },
        { id: "2b", name: "LMS" },
        { id: "2c", name: "Research/Analytics" },
        { id: "3f", name: "Big Data" },
      ],
    },

    {
      id: "3",
      name: "Cloud<br/> Consultancy",
      heading: "Cloud Services",
      showButton: false,
      url: "/services/startup",
      front: "Learn More",
      back: "Learn More",
      img: "https://images.unsplash.com/photo-1561736778-92e52a7769ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
      list: [
        { id: "1a", name: "Cloud Architecture" },
        { id: "1b", name: "Data Management" },
        { id: "1c", name: "DNS Hosting" },
        { id: "1d", name: "Machine Learning" },
      ],
    },

    {
      id: "7",
      img: "https://images.unsplash.com/photo-1561736778-92e52a7769ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
      name: "ESG<br/> Opportunities",
      heading: "ESG",
      showButton: false,
      url: "/services/startup",
      front: "Learn More",
      back: "Learn More",
      list: [
        { id: "4a", name: "ESG Projects" },
        { id: "4b", name: "Partnerships" },
        { id: "4c", name: "Influencer Stories" },
        { id: "5c", name: "Press Releases" },
      ],
    },
  ],
};

export const OUR_CORE_TECHNOLOGIES = {
  overlay_text: "For those who are about to geek... We salute you",
  heading: "| Our Technology Stack",
  text: <>For Those Who are About to Geek, We Salute You</>,
  helper_text: "Click on icon to know more",
  helper_text2: "You can also use Keyboard keys ← → ↑ ↓ to navigate story",
  logos: [
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/figma.svg",
      title: "Figma",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "Figma is our wire-framing tool to confirm ideas before we begin a project",
    },

    // {
    //     logo: 'https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/github.svg',
    //     title: 'GitHub',
    //     bg: '#fff',
    //     glow: '#fff',
    //     color: '#000',
    //     text: 'We use Github to manage our code across a global team of developers',

    // },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/tailwind.png",
      title: "Tailwind",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "We use Github to manage our code across a global team of developers",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/chakra.png",
      title: "Chakra UI",
      bg: "#fff",
      glow: "#61CBCC",
      text: "Chakra keeps us centered by using reusable code on our projects",
    },

    // {
    //     logo: 'https://iconoclaststorage83444-uatenv.s3.amazonaws.com/public/zoiqsvgs/typescript.svg',
    //     title: 'Typescript',
    //     bg: '#fff',
    //     glow: '#0078CF',
    //     text: 'Typescript makes sure we keep to standards when coding',

    // },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/framer-motion-s3.png",
      title: "Framer Motion",
      bg: "#fff",
      glow: "#6D07FF",
      text: "Framer Motion is what gives animation and effects to your website",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/storybook.png",
      title: "StorybookJS",
      bg: "#fff",
      glow: "#FF4785",
      text: "Storybook let's us see play around with design ideas once we start to code",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/react.svg",
      title: "React | React Native",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "React creates everything you see",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/nextjs.png",
      title: "NextJs",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "NextJS took React and made it even better for developers",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/framework7.png",
      title: "Framework7",
      bg: "#fff",
      glow: "#0078CF",
      text: "Framework7 took React and made it even better for developers",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/threejs.png",
      title: "ThreeJS",
      bg: "#fff",
      glow: "#61B645",
      text: "ThreeJS is for visual effects that are sublime and look effortlessly cool.",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/greensock.svg",
      title: "GSAP",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "Greensock (GSAP) helps us create mind blowing animations and effects.",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/Cypress.svg",
      title: "Cypress",
      bg: "#fff",
      glow: "#fff",
      color: "#000",
      text: "Cypress helps us test our code to ensure it is ready for production",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/graphql.png",
      title: "GraphQL",
      bg: "#fff",
      glow: "#f0f",
      text: "We use GraphQL to connect your website to your data",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/postgres.png",
      title: "PostgreSQL",
      bg: "#fff",
      glow: "#FF4785",
      text: "Storybook let's us see play around with design ideas once we start to code",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/aws.svg",
      title: "AWS",
      bg: "#fff",
      glow: "#FF9900",
      text: "Amazon Web Services (AWS) is our go-to cloud service provider",
    },

    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/amazon-quicksight.png",
      title: "QuickSight",
      bg: "#fff",
      glow: "#61CBCC",
      text: "QuickSight is our go-to cloud service provider",
    },
    {
      logo: "https://raw.githubusercontent.com/BuilderIO/qwik/main/.github/assets/qwik-logo.svg",
      title: "Qwik",
      bg: "#fff",
      glow: "#61CBCC",

      text: "a lightweight and fast front-end JavaScript framework that utilizes web components",
    },
  ],
};

export const ZOIQ_CULTURE = {
  heading: "| Our Culture",
  list: [
    {
      id: 2,
      label: "Ambitious",
      iconUrl: "/target-board.svg",
      text: "We want to be the largest digital design agency for start ups, non-profits and growing companies in the next five years.  We know the only way to get there is to treat every project like it was our own.  If you succeed in your idea, we succeed along with you.",
    },
    {
      id: 1,
      label: "Adaptive",
      iconUrl: "/adaptive-design.svg",
      text: "We are committed to excellence and constantly seeking ways to enhance the processes, products, and services we deliver. Our team is dedicated to staying ahead of the curve and finding innovative solutions that will enable us to exceed your expectations every time.",
    },

    {
      id: 3,
      label: "Creative",
      iconUrl: "/creative-idea.svg",
      text: "We are deeply passionate about our craft and feel privileged to apply our skill sets in helping bring your ideas to life. We believe in the power of creativity to drive success, and we are fully committed to harnessing that power to help you achieve your goals.",
    },
    {
      id: 4,
      label: "Driven",
      iconUrl: "/creative-mind.svg",
      text: "Our creativity comes from a deep appreciation of industry design rules.  We embrace the timeless ways of building to create beautiful products. We find art in expressing these principles in new ways and thrive on structure to deliver innovative solutions. Our approach drives us to find solutions that work using the experience of the industry as a guide.",
    },
    {
      id: 5,
      label: "Systematic",
      iconUrl: "/system-configuration.svg",
      text: "We believe that if something isn't scalable, we haven't done our job properly. We ensure our results are sustainable and fit seamlessly into a greater design and useful framework. Our team is committed to delivering solutions that are scalable, efficient, and future-proof.  Creativity without a purpose is the worst possible design in our opinion.",
    },
  ],
};

export const TESTIMONIALS_PAGE = {
  heading: "| What Our Clients Say About Us",
  text: "",
  testimonials: [
    {
      id: "1",
      image: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/marlon.jpeg",
      title: "Case Study",
      description:
        "Our non-profit teaches Social and Emotional Learning (SEL) using hip-hop and modern artists to students in middle and high schools in Texas. Some of our students can't always be in classroom due to family, immigration, judicial, and pandemic reasons.   In response to this, ZOIQ built an online school so everyone can have access the tools and resources they need to make it life no matter where they are.",
      name: "Marlon Lizama",
      designation: "Director of Iconoclast Artists",
      website: "https://iconoclastartists.org/",
      logoUrl:
        "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/Iconoclast_Logo.png",
    },
    {
      id: "2",
      image:
        "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXZhdGFyc3xlbnwwfHwwfHw%3D&w=1000&q=80",
      title: "Case Study",
      description:
        "Over the last 25 years, I have built a very successful real estate management company in Central and Eastern Europe.  When we decided to expand our portfolio footprint across Europe, we wanted to increase our presence online so more investors could benefit from our expansion.  ZOIQ helped us achieve this.",
      name: "Jonathan Wilkinson",
      designation: "CEO, Fundsec",
      website: "https://www.discovery-group.cz/",
      logoUrl:
        "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/fundsec.svg",
    },
    {
      id: "3",
      image:
        "https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXZhdGFyc3xlbnwwfHwwfHw%3D&w=1000&q=80",
      title: "Case Study",
      description:
        "After the pandemic, I decided to start a new restaurant in Prague to celebrate the vibrance of the city and give both locals and tourists a reason to get out and explore the city and have a quality experience in the heart of the city.  I had my website set-up already but needed content and images.  ZOIQ was able to provide the content we needed that fit the feel and ideas of our new venture. ",
      name: "Marta",
      website: "https://tastrestaurant.cz/en/",
      designation: "Co-Founder and CEO, Tast",
      logoUrl: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/Tast.svg",
    },
  ],
};

export const OUR_CLIENTS = {
  heading: "| Our Clients",
  text: <> </>,
  partners: [
    {
      logo: "https://zoiqclients.s3.amazonaws.com/aldine-independent-school-district-squarelogo.png",

      website: "https://www.aldineisd.org/",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/sb.png",

      website: "https://www.springbranchisd.com/schools/high-schools",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/galveston.png",

      website: "https://www.gisd.org/",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/fundsec.svg",

      website: "https://www.fund-sec.com/",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/Iconoclast_Logo.png",

      website: "https://iconoclastartists.org/",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/ProjectCurateLogo-WhiteLetters.png",

      website: "https://tastrestaurant.cz/en/",
    },
    {
      logo: "https://zoiqclients.s3.amazonaws.com/zoiqclientlogos/Tast.svg",

      website: "https://tastrestaurant.cz/",
    },
  ],
};

export const dataNodes = {
  nodes: [
    {
      id: "Artificial Intelligence",
    },
    {
      id: "App",
    },
    {
      id: "Client Data Feeds",
    },
    {
      id: "Institutions",
    },
    {
      id: "Game Changers",
    },
    {
      id: "Feedback",
    },
    {
      id: "Private Journal",
    },
    {
      id: "Demographics",
    },
    {
      id: "Notebook",
    },
    {
      id: "Units",
    },
    {
      id: "Lessons",
    },
    {
      id: "Data Download",
    },
    {
      id: "Community",
    },
    {
      id: "Courses",
    },
    {
      id: "Classrooms",
    },
    {
      id: "Surveys",
    },
    {
      id: "Physical Data Upload",
    },
    {
      id: "Service Provider",
    },
    {
      id: "Learning Objectives",
    },
    {
      id: "Staff",
    },
    {
      id: "Schedule",
    },
    {
      id: "Attendance",
    },
  ],
  edges: [
    {
      source: "Artificial Intelligence",
      target: "App",
      value: 1,
    },
    {
      source: "App",
      target: "Game Changers",
      value: 8,
    },
    {
      source: "App",
      target: "Institutions",
      value: 10,
    },
    {
      source: "Game Changers",
      target: "Lessons",
      value: 6,
    },
    {
      source: "Lessons",
      target: "Units",
      value: 1,
    },
    {
      source: "Surveys",
      target: "Units",
      value: 1,
    },
    {
      source: "Lessons",
      target: "Notebook",
      value: 1,
    },
    {
      source: "Notebook",
      target: "Feedback",
      value: 1,
    },
    {
      source: "Notebook",
      target: "Private Journal",
      value: 1,
    },
    {
      source: "Feedback",
      target: "Private Journal",
      value: 2,
    },
    {
      source: "Lessons",
      target: "Learning Objectives",
      value: 1,
    },
    {
      source: "Units",
      target: "Physical Data Upload",
      value: 1,
    },
    {
      source: "Surveys",
      target: "Data Download",
      value: 2,
    },

    {
      source: "Units",
      target: "Courses",
      value: 1,
    },
    {
      source: "Courses",
      target: "Learning Objectives",
      value: 1,
    },
    {
      source: "Courses",
      target: "Demographics",
      value: 1,
    },
    {
      source: "Institutions",
      target: "Community",
      value: 2,
    },
    {
      source: "Institutions",
      target: "Courses",
      value: 2,
    },
    {
      source: "Institutions",
      target: "Classrooms",
      value: 2,
    },
    {
      source: "Institutions",
      target: "Staff",
      value: 2,
    },
    {
      source: "Staff",
      target: "Classrooms",
      value: 2,
    },
    {
      source: "Courses",
      target: "Classrooms",
      value: 2,
    },
    {
      source: "Classrooms",
      target: "Schedule",
      value: 2,
    },
    {
      source: "Schedule",
      target: "Attendance",
      value: 2,
    },
    {
      source: "Institutions",
      target: "Service Provider",
      value: 2,
    },
    {
      source: "Service Provider",
      target: "Client Data Feeds",
      value: 2,
    },
  ],
};

export const OUR_LOCATIONS = {
  heading: "| Our Locations",
  overlay_text: "We Are a Global Company That Works Your Hours",
  loc_title: "Locations In: ",
  loc_list: ["United States", "Latin America", "Europe", "India"],
  office_locations: [
    {
      name: "monterrey",
      lat: 25.67507,
      lng: -100.31847,
    },
    {
      name: "houston",
      lat: 29.749907,
      lng: -95.358421,
    },
    {
      name: "prague",
      lat: 50.073658,
      lng: 14.41854,
    },

    {
      name: "latvia",
      lat: 56.8796,
      lng: 24.6032,
    },

    {
      name: "ahmedabad",
      lat: 23.033863,
      lng: 72.585022,
    },
    {
      name: "riga",
      lat: 56.946285,
      lng: 24.105078,
    },
    {
      name: "mumbai",
      lat: 19.076,
      lng: 72.8777,
    },
  ],
};

export const MOVE_FORWARD = {
  heading: "Get a Quote",
  subheading:
    " Click on the project calculator and get a quote for your project. We will take your idea and help you launch it online.",
  button: "Project Calculator",
};

export const OUR_CULTURE = {
  heading: "What Makes Us Different",
};

export const LOGIN = {
  heading: "Client Login",
};
