import { Assets, Store } from "@/API";
import { listStores } from "@/customGraphql/customQueries";
import { AssetsProps } from "@/interface/Settings";
import { getImageFromS3, sortBySequence } from "@/utils";
import { updateSortSeqMutationOrg } from "@/utils/graphql";
import { SortAscendingOutlined } from "@ant-design/icons";
import StoreItem from "@components/StoreComponents/StoreItem";
import PaginationComponent from "@components/common/PaginationComponent";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Button, Empty, Modal, Row, Space, Spin } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import UploadJsonToS3 from "./RareComponents/UploadJsonToS3";
import SortTable from "./common/SortComponents/SortTable";
import { useParams } from "react-router-dom";

const StoreInventory = ({
  projectId,
  assets,
  users,
  onEditAsset,
  storeInventorySortSeq,
  onDeleteAsset,
  isAssetsRefetching,
}: {
  assets: Assets[];
  isAssetsRefetching: boolean;
  projectId: string;
  storeInventorySortSeq: string[];
} & AssetsProps) => {
  const [sortModal, setSortModal] = useState(false);

  const { organisationId } = useParams<{ organisationId: string }>();

  const {
    data: storeData,
    isLoading,
    isFetched,
    // isError,
    refetch,
  } = useQuery(
    `store-inventory-${organisationId}`,
    async () => {
      const filter = projectId
        ? {
            and: [
              { projectID: { eq: projectId } },
              {
                organizationID: {
                  eq: organisationId,
                },
              },
            ],
          }
        : {
            organizationID: {
              eq: organisationId,
            },
          };
      const response: any = await API.graphql(
        graphqlOperation(listStores, {
          limit: 1000,
          filter: filter,
        })
      );
      const items = response.data.listStores.items as Store[];
      return orderBy(items, "createdAt", ["desc"]);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!organisationId,
    }
  );
  const [localStoreList, setLocalStoreList] = useState([] as Store[]);

  const sorted =
    storeInventorySortSeq?.length > 0
      ? sortBySequence(storeData!, storeInventorySortSeq)
      : storeData ?? [];

  useEffect(() => {
    setLocalStoreList(sorted);
  }, [storeData?.length]);

  useEffect(() => {
    isAssetsRefetching && refetch();
  }, [isAssetsRefetching]);

  if (isLoading || !isFetched) {
    return (
      <center>
        <Spin>Fetching store inventory...</Spin>
      </center>
    );
  }

  const _storeData = sorted
    ?.map((d) => ({
      ...d,
      asset: assets.find((asset) => d.id === asset.storeID),
    }))
    .filter((d) => d.asset);

  const _storeDataForAPI = _storeData?.map((d) => {
    return {
      ...d,
      addedByUser: users.find((user) => user.authID === d.addedBy),

      mediaUrl: getImageFromS3(
        d.asset?.multipleLinks?.[0] ?? d.asset?.link!,
        true
      ),
    };
  });

  if (_storeData?.length === 0) {
    return (
      <Empty
        description="No store inventory found"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }

  const onEdit = async (assetId: string, storeId: string) => {
    const data = _storeData?.find((d) => d.id === storeId);
    onEditAsset(assetId, data);
    refetch();
  };

  const onDelete = (assetId: string, key: string, storeId: string) => {
    onDeleteAsset(assetId, key, storeId);
    refetch();
  };

  return (
    <>
      <Modal
        open={sortModal}
        width={1000}
        title="Sort Store Inventory"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setSortModal(false)}
      >
        <SortTable
          onDragEndSuccess={(updated) => {
            const newSeq: string[] = updated.map(
              (item: { id: UniqueIdentifier }) => item.id
            );
            updateSortSeqMutationOrg(
              organisationId!,
              newSeq,
              "storeInventorySortSeq"
            );
            setLocalStoreList(updated);
          }}
          dataSource={localStoreList}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
          ]}
        />
      </Modal>

      <Space>
        <Button
          onClick={() => setSortModal(true)}
          type="dashed"
          icon={<SortAscendingOutlined />}
        >
          Sorting
        </Button>
        <UploadJsonToS3
          type="storeInventory"
          data={_storeDataForAPI}
          projectId={projectId}
          organizationID={organisationId!}
        />
      </Space>
      <PaginationComponent shouldAffix items={_storeData} itemsPerPage={4}>
        {(storeItems) => (
          <>
            <Row
              style={{
                marginTop: "1rem",
              }}
              gutter={[16, 16]}
            >
              {storeItems.map((storeItem, idx) => {
                return (
                  <StoreItem
                    asset={storeItem.asset!}
                    key={`${storeItem.id}-${idx}`}
                    storeItem={storeItem}
                    users={users}
                    onEditStore={(assetId) => {
                      onEdit(assetId, storeItem.id);
                    }}
                    onDeleteStore={(assetId, key, storeId) => {
                      onDelete(assetId, key, storeId);
                    }}
                  />
                );
              })}
            </Row>
          </>
        )}
      </PaginationComponent>
    </>
  );
};

export default StoreInventory;
