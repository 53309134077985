import { Assets } from "@/API";
import {
  NO_FILE_ATTACHED,
  VIDEO_BACK,
  VIDEO_BACK_ERROR,
  VIDEO_LOADING,
  VIDEO_POSTER,
  audioAccept,
  videoAccept,
} from "@/constants/assets";
import { AssetsProps } from "@/interface/Settings";
import { getImageFromS3 } from "@/utils";
import { Flex } from "@aws-amplify/ui-react";
import {
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Input,
  Row,
  Space,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import Author from "./common/Author";
import PaginationComponent from "./common/PaginationComponent";
import {
  ActionDropDown,
  AdminOnlyTag,
  AssetWithStore,
  assetCols,
} from "./sections/Assets";
import AttachedComponentWrapper from "./ProjectFileComponents/AttachedComponentWrapper";
import { isEmpty } from "lodash";

const Asset = ({
  asset,
  onEditAsset,
  onDeleteAsset,
  onRemoveAsset,
  users = [],
  onAddToGallery,
  onRemoveGallery,
}: {
  asset: Assets;
  users: AssetsProps["users"];
  onRemoveGallery: AssetsProps["onRemoveGallery"];
  onEditAsset: AssetsProps["onEditAsset"];
  onDeleteAsset: AssetsProps["onDeleteAsset"];
  onRemoveAsset: AssetsProps["onRemoveAsset"];
  onAddToGallery: AssetsProps["onAddToGallery"];
}) => {
  const url = getImageFromS3(asset.link, true);

  const { token } = theme.useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const user = users.find((d) => d.authID === asset.userID);

  const isAttached = Boolean(
    asset.isAssigned &&
      !isEmpty(asset.assignedToInfo) &&
      !isEmpty(asset.assignedToInfo.pageID) &&
      !isEmpty(asset.assignedToInfo.sectionID)
  );

  return (
    <Col key={asset.id} {...assetCols}>
      <Card>
        <AttachedComponentWrapper
          isGallery={Boolean(asset.galleryId)}
          isAttached={isAttached}
        >
          {videoAccept
            .concat("video/mp4, video/quicktime")
            .includes(asset.mediaType ?? "none") ? (
            <VideoAsset src={url} />
          ) : audioAccept.includes(asset.mediaType ?? "none") ? (
            <AudioAsset src={url} />
          ) : (
            <Image
              onLoad={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = VIDEO_BACK;
              }}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = VIDEO_BACK_ERROR;
              }}
              loading="lazy"
              height={300}
              width={"100%"}
              style={{
                objectFit: "cover",
                borderRadius: token.borderRadius,
              }}
              src={asset.link ? url : NO_FILE_ATTACHED}
              alt={asset.note ?? "asset"}
            />
          )}

          <Flex
            marginTop={24}
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            justifyContent={"space-between"}
          >
            <Typography.Title
              style={{
                marginTop: "1rem",
              }}
              level={4}
            >
              {asset.note ?? "asset"}{" "}
              <AdminOnlyTag onlyAdmin={asset.onlyAdmin} />
            </Typography.Title>

            <ActionDropDown
              onRemoveAsset={onRemoveAsset}
              onRemoveGallery={onRemoveGallery}
              onEditAsset={onEditAsset}
              onDeleteAsset={onDeleteAsset}
              onAddToGallery={onAddToGallery}
              asset={asset}
              options={{
                delete: !isAttached,
                gallery: asset.galleryId ? false : true,
                removeGallery: asset.galleryId ? true : false,
                edit: true,
                download: true,
                remove: Boolean(isAttached),
              }}
              other={{
                pageId: asset.assignedToInfo?.pageID ?? "",
                sectionId: asset.assignedToInfo?.sectionID ?? "",
              }}
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  {React.cloneElement(menu as React.ReactElement, {
                    style: menuStyle,
                  })}
                  <Divider style={{ margin: 0 }} />

                  <div className="dropdown-footer">
                    <Typography.Text>
                      File type: {asset.mediaType}
                    </Typography.Text>
                    {/* {isAttached && (
                      <Button
                        onClick={() => goToSection(otherInfo)}
                        block
                        type="link"
                      >
                        Go to section
                      </Button>
                    )} */}
                  </div>
                </div>
              )}
            />
          </Flex>
        </AttachedComponentWrapper>

        <Author
          user={asset.isUpdated ? asset.lastUpdatedByUser! : user!}
          isUpdated={Boolean(asset.isUpdated)}
          updatedAt={asset.lastUpdatedOn!}
          createdAt={asset.createdAt}
        />
      </Card>
    </Col>
  );
};

export const VideoAsset = ({
  src,
  height = "300px",
  autoPlay,
  controls = true,
  hideBorderRadius,
}: {
  src: string;
  height?: string;
  autoPlay?: boolean;
  controls?: boolean;
  hideBorderRadius?: boolean;
}) => {
  const { token } = theme.useToken();
  return (
    <video
      autoPlay={autoPlay}
      poster={VIDEO_LOADING}
      // if loading show VIDEO_LOADING as a poster
      onLoadedData={(e) => {
        e.currentTarget.poster = VIDEO_POSTER;
      }}
      // if error show VIDEO_BACK_ERROR as a poster
      onError={(e) => {
        e.currentTarget.poster = VIDEO_BACK_ERROR;
      }}
      style={{
        height,
        width: "100%",
        borderRadius: hideBorderRadius ? 0 : `${token.borderRadius}px`,
      }}
      controls={controls}
    >
      <source src={src} type="video/mp4" />
      <source src={src} type="video/quicktime" />
    </video>
  );
};

export const AudioAsset = ({
  src,
  height = "300px",
}: {
  src: string;
  height?: string;
}) => {
  const { token } = theme.useToken();
  return (
    <audio
      style={{
        height,
        width: "100%",
        borderRadius: `${token.borderRadius}px`,
      }}
      controls
    >
      <source src={src} type="audio/mp3" />
    </audio>
  );
};

const MediaAssets = ({
  assets,
  users,
  onDeleteAsset,
  onEditAsset,
  onRemoveAsset,
  onAddToGallery,
  showAttachedOnlyBtn = null,
  mediaFilterBtn = null,
  setIsSearching,
  isSearching,
  onRemoveGallery,
}: AssetsProps) => {
  const [localCopyOfAssets, setLocalCopyOfAssets] = useState<AssetWithStore[]>(
    []
  );

  // update local copy of assets whenever assets changes using useEffect

  useEffect(() => {
    if (!isSearching) {
      setLocalCopyOfAssets([...assets]);
    }
  }, [assets, isSearching]);

  // whenever assets changes, update local copy

  const onSearch = (value: string) => {
    const _filtered = assets.filter((asset) =>
      asset.note?.toLowerCase()?.includes(value.toLowerCase())
    );
    setIsSearching?.(true);

    setLocalCopyOfAssets(_filtered);
  };

  return (
    <>
      <Space className="w-full">
        <Input.Search
          allowClear
          size="middle"
          style={{
            width: 400,
          }}
          placeholder="Search within assets"
          onSearch={onSearch}
        />

        {mediaFilterBtn !== null && mediaFilterBtn !== undefined && (
          <>{mediaFilterBtn}</>
        )}
        {showAttachedOnlyBtn !== null && showAttachedOnlyBtn !== undefined && (
          <>{showAttachedOnlyBtn}</>
        )}
      </Space>
      {localCopyOfAssets.length === 0 ? (
        <Empty description="No Media" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <PaginationComponent
          shouldAffix
          items={isSearching ? localCopyOfAssets : assets}
          itemsPerPage={4}
        >
          {(_assets) => (
            <>
              <Row
                style={{
                  marginTop: "1rem",
                }}
                gutter={[16, 16]}
              >
                {_assets.map((asset) => (
                  <Asset
                    onRemoveGallery={onRemoveGallery}
                    key={asset.id}
                    asset={asset}
                    users={users}
                    onAddToGallery={onAddToGallery}
                    onRemoveAsset={onRemoveAsset}
                    onEditAsset={onEditAsset}
                    onDeleteAsset={onDeleteAsset}
                  />
                ))}
              </Row>
            </>
          )}
        </PaginationComponent>
      )}
    </>
  );
};

export default MediaAssets;
