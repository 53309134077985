import { Button } from "antd";
import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button";
import { NavLink } from "react-router-dom";

interface QuickButtonProps extends ButtonProps {
  to?: string;
  children: React.ReactNode;
}

const QuickButton = ({ to, children, icon, ...rest }: QuickButtonProps) => {
  const Icon = icon ?? <PlusOutlined />;

  const btn = (
    <Button icon={Icon} type={rest.type ?? "primary"} {...rest}>
      {children}
    </Button>
  );

  if (to) {
    return <NavLink to={to}>{btn}</NavLink>;
  } else {
    return <>{btn}</>;
  }
};

export default QuickButton;
