const useS3Keys = (projectId: string, organizationID: string) => {
  const projectFilesKey = `company-assets/${organizationID}/${projectId}`;
  const galleryCoverKey = `GALLERY_COVER/${organizationID}/${projectId}`;
  const galleryFilesKey = `GALLERY_FILES/${organizationID}/${projectId}`;
  const projectDataKey = `PROJECT_DATA/${organizationID}/${projectId}`;
  const sourceFilesKey = `SOURCE_FILES/${organizationID}/${projectId}`;

  return {
    projectFilesKey,
    galleryCoverKey,
    galleryFilesKey,
    projectDataKey,
    sourceFilesKey,
  };
};

export default useS3Keys;
