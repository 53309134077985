import { MenuOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: props["data-row-key"],
  });

  const { children, ...rest } = props;

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,

    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...rest} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface SortTableProps extends TableProps<any> {
  dataSource: any[];
  columns: any[];
  shouldUpdateList?: boolean;
  disabled?: boolean;
  onDragEndSuccess: (fn: any) => void;
}

const SortTable = ({
  onDragEndSuccess,
  dataSource,
  columns,
  disabled,
  shouldUpdateList,
  pagination,
  ...rest
}: SortTableProps) => {
  // create a local copy of dataSource

  const [dataSourcesCopy, setDataSourcesCopy] = useState([...dataSource]);

  useEffect(() => {
    setDataSourcesCopy([...dataSource]);
  }, [dataSource.length, shouldUpdateList]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSourcesCopy.findIndex(
        (i: { id: UniqueIdentifier }) => i.id === active.id
      );
      const overIndex = dataSourcesCopy.findIndex(
        (i: { id: UniqueIdentifier | undefined }) => i.id === over?.id
      );

      const updatedArray = arrayMove(dataSourcesCopy, activeIndex, overIndex);

      setDataSourcesCopy(updatedArray);

      onDragEndSuccess(updatedArray);
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        disabled={disabled}
        items={dataSourcesCopy.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          pagination={pagination}
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="id"
          columns={[
            {
              key: "sort",
            },
            ...columns,
          ]}
          dataSource={dataSourcesCopy}
          {...rest}
        />
      </SortableContext>
    </DndContext>
  );
};

export default SortTable;
