import { Assets, StaffStatus } from "@/API";
import useUserData from "@/hooks/useData";
import useLocalStorage from "@/hooks/useLocalstorage";
import { AssetsProps } from "@/interface/Settings";
import { getImageFromS3, sortBySequence, wrapEmail, wrapPhone } from "@/utils";
import { updateSortSeqMutationOrg } from "@/utils/graphql";
import { SortAscendingOutlined } from "@ant-design/icons";
import { Flex } from "@aws-amplify/ui-react";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Image,
  Modal,
  Row,
} from "antd";
import { useState } from "react";
import UploadJsonToS3 from "./RareComponents/UploadJsonToS3";
import SortTable from "./common/SortComponents/SortTable";
import { ScrollableText } from "./common/StyledComponents";
import { ActionDropDown, AdminOnlyTag, assetCols } from "./sections/Assets";
import AttachedComponentWrapper from "./ProjectFileComponents/AttachedComponentWrapper";

const StaffAssets = ({
  assets,

  onDeleteAsset,
  onEditAsset,
  refetch,
  staffSortSeq,
  projectId,
  shouldUpload,
  showAttachedOnlyBtn,
}: AssetsProps & {
  staffSortSeq: string[];
  projectId: string;
  shouldUpload: boolean;
}) => {
  const { isAdmin, isZoiq } = useUserData();
  const filtered = assets.filter((asset) => {
    // if isZoiq or isAdmin is true. Show all assets
    // otherwise show only assets that have asset.profile.status === "ACTIVE"
    if (isZoiq || isAdmin) return true;
    return asset.profile?.status === StaffStatus.ACTIVE;
  });

  const sorted = sortBySequence(filtered, staffSortSeq);

  const [sortModal, setSortModal] = useState(false);

  const [selectedOrgId] = useLocalStorage("selectedOrgId", "");

  const staffDataForAPI = sorted.map((asset) => ({
    id: asset.id,
    image: getImageFromS3(asset.link),
    name: asset.profile?.name,
    description: asset.profile?.bio,
    title: asset.profile?.title,
    email: asset.profile?.email,
    phone: asset.profile?.phone,
  }));

  return (
    <>
      <Modal
        open={sortModal}
        width={1000}
        title="Sort Staff Profiles"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={() => setSortModal(false)}
      >
        <SortTable
          onDragEndSuccess={(updated) => {
            const newSeq: string[] = updated.map(
              (item: { id: UniqueIdentifier }) => item.id
            );
            updateSortSeqMutationOrg(selectedOrgId, newSeq, "staffSortSeq");
            refetch?.();
          }}
          dataSource={sorted as any[]}
          columns={[
            {
              title: "Name",
              dataIndex: "profile.name",
              key: "name",
              render: (_: string, data: Assets) => (
                <span>{data?.profile?.name ?? "-"}</span>
              ),
            },
            {
              title: "Title",
              dataIndex: "profile.title",
              key: "title",
              render: (_: string, data: Assets) => (
                <span>{data?.profile?.title ?? "-"}</span>
              ),
            },

            {
              title: "Email",
              dataIndex: "profile.email",
              key: "email",
              render: (_: string, data: Assets) => (
                <span>{data?.profile?.email ?? "-"}</span>
              ),
            },
            {
              title: "Phone",
              dataIndex: "profile.phone",
              key: "phone",
              render: (_: string, data: Assets) => (
                <span>{data?.profile?.phone ?? "-"}</span>
              ),
            },
          ]}
        />
      </Modal>
      <Flex
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={12}
      >
        <Button
          onClick={() => setSortModal(true)}
          type="dashed"
          icon={<SortAscendingOutlined />}
        >
          Sorting
        </Button>
        <UploadJsonToS3
          type="staffProfiles"
          data={staffDataForAPI}
          shouldUpload={shouldUpload}
          projectId={projectId}
          organizationID={selectedOrgId}
        />

        {showAttachedOnlyBtn !== null && showAttachedOnlyBtn !== undefined && (
          <>{showAttachedOnlyBtn}</>
        )}
      </Flex>
      {assets.length === 0 ? (
        <Empty
          description="No Staff Profiles"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          style={{
            marginTop: "1rem",
          }}
          gutter={[16, 16]}
        >
          {sorted.map((asset) => {
            console.log("asset", asset);
            return (
              <Col key={asset.id} {...assetCols}>
                <AttachedComponentWrapper isAttached={asset.isAttached}>
                  <Card
                    hoverable
                    style={{
                      height: "100%",
                    }}
                  >
                    <Card.Meta
                      avatar={
                        <Image
                          style={{
                            objectFit: "cover",
                            borderRadius: "50%",
                            background: "rgb(13, 17, 23)",
                          }}
                          height={64}
                          width={64}
                          src={getImageFromS3(asset.link)}
                        />
                      }
                      title={asset.profile?.name ?? "No Name"}
                      className={
                        asset.profile?.bio?.length! > 100 ? "overflow" : ""
                      }
                      description={
                        asset.profile?.bio ? (
                          <ScrollableText>{asset.profile?.bio}</ScrollableText>
                        ) : (
                          "No Bio"
                        )
                      }
                    />
                    <br />
                    <AdminOnlyTag onlyAdmin={asset.onlyAdmin} />

                    <Descriptions bordered>
                      <Descriptions.Item span={24} label="Title">
                        {asset.profile?.title ?? "No Title"}
                      </Descriptions.Item>
                      <Descriptions.Item span={24} label="Email">
                        {wrapEmail(asset.profile?.email!) ?? "No Email"}
                      </Descriptions.Item>
                      <Descriptions.Item span={24} label="Phone">
                        {wrapPhone(asset.profile?.phone!) ?? "No Phone"}
                      </Descriptions.Item>
                    </Descriptions>

                    <Flex
                      marginTop={24}
                      display={"flex"}
                      alignItems={"center"}
                      gap={"1rem"}
                      justifyContent={"flex-end"}
                    >
                      <ActionDropDown
                        asset={asset}
                        options={{
                          edit: true,
                          download: true,
                          delete: true,
                        }}
                        onEditAsset={onEditAsset}
                        onDeleteAsset={onDeleteAsset}
                      />
                    </Flex>
                  </Card>
                </AttachedComponentWrapper>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default StaffAssets;
