import { ActionItems, Meetings, Project, ProjectRole, User } from "@/API";
import { zoiqLogo } from "@/constants/assets";
import { meetingFrequencies, projectRoleList } from "@/constants/staticData";
import { formatTime, getDuration, parseProjectRole } from "@/utils";

const businessSponsor = (projectName: string) => `
    <ul>
        <li>Approve Business Case</li>
        <li>Approve Project Budget</li>
        <li>Chair the ${projectName} Steering Committee</li>
        <li>Attend Monthly Steering Committee Meeting</li>
        <li>Resolve any critical blockers on project, if needed</li>
    </ul>
      <p>Please click the button below to set your password and gain access to the ZOIQ Client Portal. For this role, we have given you access to the following:</p>
    <ul>
        <li>High-Level Project Plan</li>
        <li>Project Files</li>
    </ul>
`;

const decisionMaker = () => `
    <ul>
    <li>Attend weekly status meeting and monthly steering meeting for project</li>
    <li>Manage project deliverable scope for organization</li>
    <li>Ensure project team has representation from right attendees</li>
    <li>Set priorities for the product backlog</li>
    <li>Provide final decisions for functionality options during sprints, when applicable  </li></li>
    <li>Ensure action items are being completed by target dates by project team</li>
    </ul>
      <p>Please click the button below to set your password and gain access to the ZOIQ Client Portal. For this role, we have given you access to the following:</p>
    <ul>
        <li>High-Level Project Plan</li>
        <li>Style Guide</li>
        <li>Project Files</li>
    
    </ul>
`;

const SME = () => `
<ul>
    <li>Define deliverable scope</li>
    <li>Provide industry guidance on requirements</li>
    <li>Approve detailed calibration of deliverables</li>
    <li>Approve technical deliverables</li>
</ul>
 <p>Please click the button below to set your password and gain access to the ZOIQ Client Portal. For this role, we have given you access to the following:</p>
<ul>
    <li>High-Level Project Plan</li>
  
</ul>
`;

const technicalExpert = () => `
    <ul>
        <li>Provide technical overview of your organization's technology stack</li>
        <li>Ensure integration of project deliverables meet your organization's technical requirements</li>
    </ul>
     <p>Please click the button below to set your password and gain access to the ZOIQ Client Portal. For this role, we have given you access to the following:</p>
    <ul>
        <li>High-Level Project Plan</li>
       
    </ul>
`;

const emailStyles = `
/* CLIENT-SPECIFIC STYLES */
body,
table,
td,
a {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}



table,
td {
  mso-table-lspace: 0pt;
  mso-table-rspace: 0pt;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* RESET STYLES */
body {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse !important;
}

/* LAYOUT STYLES */
.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #0d1117;
  padding: 40px;
  font-family: Poppins, sans-serif;
  position: relative;
}
.pattern {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: -1;
  height: 100%;
  width: 100%;
}

h1,
p,
li {
  color: #fff;
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-left: 16px !important;
}

h1 {
  font-size: 26px;
  margin-top: 0;
}

ul {
  margin: 0;
  padding: 0;
  margin-left: 16px !important;
}

button {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #fcbf34;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  text-decoration: none;
}

a {
  color: #00a0dc;
  text-decoration: none;
}

.logo {
  width: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}


.meeting-details li {
  margin-bottom: 10px;
}
`;

const getName = (name: string) => {
  const splitted = name.split(" ");
  return splitted.length > 1 ? splitted[0] : name;
};

export const emailTemplate = (
  loginLink: string,
  userName: string,
  projectName: string,
  role: ProjectRole
): string => {
  // content by role
  const content =
    role === ProjectRole.BUSINESS_SPONSOR
      ? businessSponsor(projectName)
      : role === ProjectRole.DECISION_MAKER
      ? decisionMaker()
      : role === ProjectRole.SME
      ? SME()
      : technicalExpert();

  const name = getName(userName);

  return `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8" />
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

        <title>Welcome to the Team</title>
        <style type="text/css">
          ${emailStyles}

        </style>
      </head>
    
      <body>
        <table role="presentation" cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td>
              <div class="container">
                  <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
                <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
              <p>Hi ${name},</p>
              <p>Welcome to the ZOIQ Client Portal. We have granted you access as <strong>${parseProjectRole(
                role
              )}</strong> on the <strong>${projectName}</strong> project. In this role, we will ask you for the following:</p>
                
${content}
              <p>If you need any additional views, we can calibrate this to your preference during the project. Welcome to the team and we look forward to working with you.</p>
              <p> <br/> <br/>Kind regards, <br/>The ZOIQ Team</p>

              </div>
            </td>
          </tr>
        </table>
      </body>
    </html>`;
};

export const discussionEmailTemplate = (
  discussionDate: string,
  projectName: string,
  participantsList: string[],
  actionItems: ActionItems[],
  discussionLink: string,
  users: User[],
  meetingData?: Meetings,
  additionalNote?: string
) => {
  const getNames = (userIds: string[]) => {
    if (userIds === null || userIds.length === 0) return "None";

    const names = userIds.map((userId) => {
      const user = users.find((user) => user.authID === userId);
      return user?.name;
    });
    return names.join(", ");
  };

  const tableHtml = actionItems
    .map((item) => {
      return `<tr>
    <td>${item.actionItem}</td>
    <td>${getNames(item.owners as string[])}</td>
    <td>${item.targetDate}</td>
  </tr>`;
    })
    .join("");

  const participants = participantsList
    .map((participant) => `<strong>${participant}</strong>`)
    .join(", ");

  return `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="UTF-8" />
  <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">
  
      <title>Meeting</title>
      <style type="text/css">
      
        ${emailStyles}
       
        .discussion-items {
          width: 100%;
        }

        .discussion-items th {
          text-align: left;
          padding: 10px;
          background-color: #fcbf34;
          color:#fff;

        }
        
        .discussion-items tr {
          color:#fff;
        }

        .discussion-items td {
          padding: 10px;
          border-bottom: 1px solid #fcbf34;
          color:#fff;
        }

        p {
          color:#fff;
          margin-bottom: 20px;
          margin-top: 20px;
          margin-left: 0 !important;
          letter-spacing: 0.5px;
          line-height: 1.7;
        }
            
        .discussion-btn {
          margin-top: 20px;
        }
      </style>
    </head>
  
    <body>
      <table role="presentation" cellspacing="0" cellpadding="0" border="0">
        <tr>
          <td>
            <div class="container">
                <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
              <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
  
              <p>
              This email is a follow up to our discussion on ${discussionDate} which was attended by ${participants}. While talking, we came up with the following action items
              </p>

              ${additionalNote ? `<p>${additionalNote}</p>` : ``}

              <br/>
              <h1>
              Meeting Reference: ${meetingData?.name}
              </h1>
  
              <table class="discussion-items">

              <tr>
                <th>Action Item</th>
                <th>Action Owner</th>
                <th>Target Date</th>
              </tr>
              ${tableHtml}
              </table>

            <div class="discussion-btn">
            <a href="${discussionLink}">            
            <button>View Discussion</button>
            </a>
            </div>

              
            <p>  Let us know if we missed anything and we will get it on the list; otherwise, it was great to talk to everyone today and look forward to our continued progress on the ${projectName} project.
            <br/>
            <p> <br/> <br/>Kind regards, <br/>The ZOIQ Team</p>
            </div>
          </td>
        </tr>
      </table>
    </body>
  </html>`;
};

export const addedInProjectTemplate = (
  userName: string,
  role: ProjectRole,
  projectName: string,
  projectLink: string
) => {
  const content =
    role === ProjectRole.BUSINESS_SPONSOR
      ? businessSponsor(projectName)
      : role === ProjectRole.DECISION_MAKER
      ? decisionMaker()
      : role === ProjectRole.SME
      ? SME()
      : technicalExpert();

  const name = getName(userName);
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Welcome to the Team</title>
    <style type="text/css">
      ${emailStyles}

    </style>
  </head>

  <body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <td>
          <div class="container">
              <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
            <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
          <p>Hi ${name},</p>
          <p>Welcome to the ZOIQ Client Portal. We have added you as <strong>${parseProjectRole(
            role
          )}</strong> on the <strong>${projectName}</strong> project. In this role, we will ask you for the following:</p>
            
${content}
          <p>If you need any additional views, we can calibrate this to your preference during the project. Welcome to the team and we look forward to working with you.</p>
          <p> <br/> <br/>Kind regards, <br/> <br/>ZOIQ</p>

            <a href="${projectLink}">            
                <button>View Project Details</button>
            </a>

          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};

export const taskUpdateTemplate = (taskName: string, link: string) => {
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Welcome to the Team</title>
    <style type="text/css">
      ${emailStyles}

    </style>
  </head>

  <body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <td>
          <div class="container">
              <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
            <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
          

              <h1>
              Task Updated:
              </h1>

              <br/>

              <p>
              ${taskName}
              </p>

        
            <a href="${link}">            
                <button>View Task</button>
            </a>

          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};
export const taskAssignTemplate = (
  taskName: string,
  link: string,
  isUpdate = false
) => {
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Task Updates</title>
    <style type="text/css">
      ${emailStyles}

    </style>
  </head>

  <body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <td>
          <div class="container">
              <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
            <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
          

              <h1>
              ${
                isUpdate
                  ? "There has been a change in the task"
                  : "You have been assigned a new task"
              }:
              </h1>

              <br/>

              <p>
              ${taskName}
              </p>

        
            <a href="${link}">            
                <button>View Task</button>
            </a>

          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};

export const commentOnTaskTemplate = (
  taskName: string,
  comment: string,
  link: string
) => {
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Task Updates</title>
    <style type="text/css">
      ${emailStyles}

    </style>
  </head>

  <body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <td>
          <div class="container">
              <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
            <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
          

              <h1>
              There has been a comment on the task:
              </h1>
              <h1>Comment: </h1>
              <p>
              ${comment}
              </p>

              <hr/>

              <br/>

              <p>
              ${taskName}
              </p>


            
            <a href="${link}">            
                <button>View Task</button>
            </a>

          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};

export const meetingTemplate = (
  meetingData: Meetings,
  attendees: string[],
  projectName: Project["name"],
  userName: string,
  role: ProjectRole,
  additionalNote?: string
) => {
  const name = getName(userName);
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Welcome to the Team</title>
    <style type="text/css">
      ${emailStyles}

    </style>
  </head>

  <body>
    <table role="presentation" cellspacing="0" cellpadding="0" border="0">
      <tr>
        <td>
          <div class="container">
              <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
            <img class="logo" src=${zoiqLogo} alt="ZOIQ Logo" />
          
            <h1>Hi ${name}</h1>
    
            <p>You have been invited to the ${
              meetingData.name
            } for the ${projectName} project.  We have asked you to serve as the ${
    projectRoleList.find((d) => d.value === role)?.label
  } role on the project.</p> 
          
            <p>The objective of this meeting is to ensure the timely delivery of the ${projectName} project. The details of the meeting are the following (All times are CST):</p>

            ${additionalNote ? `<p>${additionalNote}</p>` : ``}

            <p>Meeting description: ${meetingData.description}</p>
            
            <br/>
            
              <ul class="meeting-details">
              <li>Start Date: ${meetingData.firstMeetingDate}</li>
              <li>End Date: ${meetingData.lastMeetingDate}</li>
              <li>Meeting Date/Day: ${
                meetingData.rolls?.Date || meetingData.rolls?.Day?.join(", ")
              }</li>
              <li>Time: ${formatTime(meetingData.startTime)} - ${formatTime(
    meetingData.endTime
  )}</li>
                <li>Duration: ${getDuration(
                  meetingData.startTime,
                  meetingData.endTime
                )}</li>
              <li>Frequency: ${
                meetingFrequencies.find(
                  (d) => d.value === meetingData.frequency
                )?.label
              }</li>
              <li>Attendees: ${attendees.join(", ")}</li>
            </ul>
          
            <p>Thank you in advance for making time in your schedule for this meeting and look forward to your input and guidance on the project.</p>
          

            <p> <br/> <br/>Kind regards, <br/>The ZOIQ Team</p>

           ${
             meetingData.link &&
             ` <a href="${meetingData.link}">            
                <button>Go to zoom</button>
              </a>`
           }

          </div>
        </td>
      </tr>
    </table>
  </body>
</html>`;
};

export const documentAddTemplate = (
  fileName: string,
  addedBy: string,
  organization: string,
  documentUrl: string,
  portalUrl: string
): string => {
  const emailTemplate = `
    <html>
    <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Document Added</title>
    <style type="text/css">
      
      ${emailStyles}
      
    

      .header {
        background-color: #fcbf34;
        color: #fff;
        text-align: center;
        padding: 10px;
      }

      a {
        color:#6895fd;

      }

      a:hover {
        text-decoration: underline;
      }

      .content {
        padding: 20px;
      }

      .button {
        display: inline-block;
        background-color: #007BFF;
        color: #fff;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 5px;
      }

    </style>
  </head>
    <body>
      <div class="container">
      <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
        <div class="header">
          <h1>New Document Added</h1>
        </div>
        <div class="content">
          <p>${fileName} was added by ${addedBy} for ${organization}.</p>
          <p>Click this link to review the document: <a href="${documentUrl}"></a></p>
          <p>Or go to the client portal: <a href="${portalUrl}">Client Portal</a></p>
        </div>
      </div>
    </body>
    </html>
  `;

  return emailTemplate;
};

export const contentAddTemplate = (
  pageName: string,
  sectionName: string,
  addedBy: string,
  portalUrl: string
): string => {
  const emailTemplate = `
    <html>
    <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>Document Added</title>
    <style type="text/css">
      
      ${emailStyles}
     

      .header {
        background-color: #fcbf34;
        color: #fff;
        text-align: center;
        padding: 10px;
      }

      a {
        color:#6895fd;

      }

      a:hover {
        text-decoration: underline;
      }

      .content {
        padding: 20px;
      }

      .button {
        display: inline-block;
        background-color: #007BFF;
        color: #fff;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 5px;
      }

    </style>
  </head>
    <body>
      <div class="container">
      <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
        <div class="header">
          <h1>New Document Added</h1>
        </div>
        <div class="content">
          <p>New site content was added by ${addedBy}.</p>

          <p>Page Name: ${pageName}</p>
          <p>Section Name: ${sectionName}</p>

          <p>go to client portal: <a href="${portalUrl}">Client Portal</a></p>
        </div>
      </div>
    </body>
    </html>
  `;

  return emailTemplate;
};

export const imageAddTemplate = (
  pageName: string,
  sectionName: string,
  imgUrl: string,
  addedBy: string,
  portalUrl: string,
  remove?: boolean
): string => {
  console.log(imgUrl, "imgUrl");

  const emailTemplate = `
    <html>
    <head>
    <meta charset="UTF-8" />
<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet">

    <title>
    ${remove ? "Image Removed" : "Image Added"}
    </title>
    <style type="text/css">
      
      ${emailStyles}
     

      .header {
        background-color: #fcbf34;
        color: #fff;
        text-align: center;
        padding: 10px;
      }

      a {
        color:#6895fd;

      }

      a:hover {
        text-decoration: underline;
      }

      .content {
        padding: 20px;
      }

      .button {
        display: inline-block;
        background-color: #007BFF;
        color: #fff;
        text-decoration: none;
        padding: 10px 20px;
        border-radius: 5px;
      }

      .image {
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: 20px;
      }

    </style>
  </head>
    <body>
      <div class="container">
      <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
        <div class="header">
          <h1>${remove ? "Image Removed" : "Image Added"}</h1>
        </div>
        <div class="content">
          <p>${
            remove ? "Content was removed" : "New site content was added"
          } by ${addedBy}.</p>

          <p>Page Name: ${pageName}</p>
          <p>Section Name: ${sectionName}</p>

          <img class="image" src="${imgUrl}" alt="none" />

          <p>go to client portal: <a href="${portalUrl}">Client Portal</a></p>
        </div>
      </div>
    </body>
    </html>
  `;

  return emailTemplate;
};

// from the above templates, create a new one called "newActivityTemplate" that will be used to send an email to the user when a new activity is created
// the template should include the following:
// - a header with the text "New Activity Created"
// - a paragraph with the text "A new activity has been created by [user name]"
// - a button with the text "View Activity" that links to the activity page
// - a footer with the text "Kind regards, The ZOIQ Team"
export const newActivityTemplate = (
  userName: string,
  activityName: string,
  activityLink: string
): string => {
  const emailTemplate = `
    <html>
    <head>
    <meta charset="UTF-8" />
    <title>New Activity Created</title>
    <style type="text/css">
      ${emailStyles}
    </style>
  </head>
    <body>
      <div class="container">
      <img src="https://zoiqclients.s3.amazonaws.com/assets/pattern1.png" class="pattern" alt="none" />
        <div class="header">
          <h1>New Activity Created</h1>
        </div>
        <div class="content">
          <p>A new activity has been created by ${userName}.</p>
          <p>Activity Name: ${activityName}</p>
          <a href="${activityLink}">            
            <button>View Activity</button>
          </a>
        </div>
        <div class="footer">
          <p>Kind regards, The ZOIQ Team</p>
        </div>
      </div>
    </body>
    </html>
  `;

  return emailTemplate;
};
