import { AssetType, Assets, ModelAssetsFilterInput, Project } from "@/API";
import {
  NO_FILE_ATTACHED,
  VIDEO_BACK,
  VIDEO_BACK_ERROR,
  audioAccept,
  docExtended,
  videoAccept,
} from "@/constants/assets";
import { ASSET_TYPE_OPTIONS, adminData } from "@/constants/staticData";
import { deleteAssets } from "@/graphql/mutations";
import { listAssets } from "@/graphql/queries";
import useUserData from "@/hooks/useData";
import useLocalStorage from "@/hooks/useLocalstorage";
import { deleteImageFromS3, formatDate, getImageFromS3 } from "@/utils";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Empty,
  Image,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { filter, isEmpty, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { IoDocumentsOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import { AudioAsset, VideoAsset } from "../MediaAssets";
import Author from "../common/Author";
import Loading from "../common/Loading";
import PaginationComponent from "../common/PaginationComponent";
import { ActionDropDown, AssetWithStore } from "../sections/Assets";
import InsideAssetModal from "./InsideAssetModal";

const fetchAssets = async (
  selectedTab: AssetType | null,
  selectedOrgId: string,
  project: Project,
  authID: string
) => {
  const filter: ModelAssetsFilterInput = {
    and: [
      {
        organizationID: {
          eq: project.organizationID ?? selectedOrgId,
        },
      },
      { multipleProjects: { contains: project.id } },
    ],
  };

  const generalFilter = {
    ...filter,
    or: [
      {
        isAssigned: {
          attributeExists: false,
        },
      },
      {
        isAssigned: {
          eq: false,
        },
      },
      {
        onlyAdmin: {
          eq: false,
        },
      },
      {
        onlyAdmin: {
          attributeExists: false,
        },
      },
    ],
    type: {
      eq: selectedTab,
    },
  };

  const response: any = await API.graphql(
    graphqlOperation(listAssets, {
      limit: 5000,
      filter:
        adminData.authID === authID
          ? generalFilter
          : selectedTab !== AssetType.PARTNERS_STOCK &&
            selectedTab !== AssetType.ORGANIZATION
          ? generalFilter
          : {
              ...filter,
              type: {
                eq: selectedTab,
              },
              or: [
                {
                  isAssigned: {
                    attributeExists: false,
                  },
                },
                {
                  isAssigned: {
                    eq: false,
                  },
                },
              ],
            },
    })
  );
  const items = response.data.listAssets.items ?? [];

  return orderBy(items, "createdAt", ["desc"]) as Assets[];
};

export const ImageColMedia = ({
  asset,
  onImgDelete,
  onImgEdit,
  onImgAttach,
  onImgRemoveAttach,
  mediaIds,
  onlyRemove,
  onSendEmail,
  showBadge,
}: {
  asset: Assets;
  onImgEdit?: (id: string) => void;
  onImgAttach: (id: string, link: string) => void;
  onImgRemoveAttach: (id: string) => void;
  onImgDelete?: (id: string) => void;
  mediaIds?: string[];
  showBadge?: boolean;
  onlyRemove?: boolean;
  onSendEmail: (id: string) => void;
}) => {
  const url = getImageFromS3(asset.link);

  const defaultAdded = mediaIds?.includes(asset.id) ?? false;
  const [isAdded, setIsAdded] = useState(defaultAdded);

  const { token } = theme.useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const [messageApi, contextHolder] = message.useMessage();

  const onDeleteAsset = async (id: string, linkKey?: string) => {
    try {
      linkKey && linkKey?.length > 0 && deleteImageFromS3(linkKey);
      id.length > 0 &&
        (await API.graphql(
          graphqlOperation(deleteAssets, {
            input: {
              id,
            },
          })
        ));
      messageApi.success("Successfully deleted");
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong deleting assets");
    }
  };

  const WrapperComponent = isAdded && showBadge ? Badge.Ribbon : React.Fragment;

  const props =
    isAdded && showBadge
      ? {
          color: "green",
          text: "Attached",
        }
      : {};

  return (
    <Col xs={24} lg={12}>
      {contextHolder}
      <WrapperComponent {...props}>
        {docExtended.includes(asset.mediaType ?? "none") ? (
          <Card
            extra={
              <Space>
                <Button
                  danger={isAdded}
                  onClick={() => {
                    if (isAdded) {
                      setIsAdded(false);
                      onImgRemoveAttach?.(asset.id);
                    } else {
                      setIsAdded(true);
                      onImgAttach?.(asset.id, asset.link);
                    }
                  }}
                  size="small"
                  shape="circle"
                >
                  {isAdded ? <MinusOutlined /> : <PlusOutlined />}
                </Button>
                {onImgDelete && onImgEdit && (
                  <ActionDropDown
                    onEditAsset={() => onImgEdit?.(asset.id)}
                    onDeleteAsset={() => onImgDelete?.(asset.id)}
                    asset={asset}
                  />
                )}
              </Space>
            }
          >
            <Card.Meta
              avatar={<IoDocumentsOutline />}
              title={asset.note ?? "No Name"}
            />
          </Card>
        ) : videoAccept
            .concat("video/mp4, video/quicktime")
            .includes(asset.mediaType ?? "none") ? (
          <VideoAsset src={url} />
        ) : audioAccept.includes(asset.mediaType ?? "none") ? (
          <AudioAsset src={url} />
        ) : (
          <Image
            onLoad={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = VIDEO_BACK;
            }}
            loading="lazy"
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = VIDEO_BACK_ERROR;
            }}
            height={300}
            width={"100%"}
            style={{
              objectFit: "contain",
              border: "var(--border)",
            }}
            src={asset.link ? url : NO_FILE_ATTACHED}
            alt={asset.note ?? "asset"}
          />
        )}
      </WrapperComponent>

      {docExtended.includes(asset.mediaType ?? "none") ? null : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 12,
              width: "100%",
            }}
          >
            <Typography.Title level={4}>{asset.note}</Typography.Title>

            {onlyRemove ? (
              <>
                <div className=""></div>
                <ActionDropDown
                  onEditAsset={() => onImgEdit?.(asset.id)}
                  onDeleteAsset={() => {
                    onDeleteAsset?.(asset.id, asset.link);
                  }}
                  asset={asset}
                  options={{
                    download: true,
                    delete: true,
                    edit: true,
                    remove: true,
                    sendEmail: true,
                  }}
                  onSendEmail={(id: string) => {
                    onSendEmail?.(id);
                  }}
                  onRemoveAsset={() => {
                    setIsAdded(false);
                    onImgRemoveAttach?.(asset.id);
                  }}
                  dropdownRender={(menu) => (
                    <div style={contentStyle}>
                      {React.cloneElement(menu as React.ReactElement, {
                        style: menuStyle,
                      })}
                      <Divider style={{ margin: 0 }} />

                      {onlyRemove && asset.assignedDate && (
                        <div className="dropdown-footer">
                          <Typography.Text type="secondary">
                            Assigned Date: {formatDate(asset.assignedDate)}
                          </Typography.Text>
                        </div>
                      )}
                    </div>
                  )}
                />
              </>
            ) : (
              <Space>
                <Tooltip
                  title={
                    isAdded
                      ? "Unattach this media from section"
                      : "Attach this media to section"
                  }
                >
                  <Button
                    danger={isAdded}
                    onClick={() => {
                      if (isAdded) {
                        setIsAdded(false);
                        onImgRemoveAttach?.(asset.id);
                      } else {
                        setIsAdded(true);
                        onImgAttach?.(asset.id, asset.link);
                      }
                    }}
                    size="small"
                    shape="circle"
                  >
                    {isAdded ? <MinusOutlined /> : <PlusOutlined />}
                  </Button>
                </Tooltip>
                {onImgDelete && onImgEdit && (
                  <ActionDropDown
                    onEditAsset={() => onImgEdit?.(asset.id)}
                    onDeleteAsset={() => onImgDelete?.(asset.id)}
                    asset={asset}
                    options={{
                      download: true,
                      delete: true,
                      edit: true,
                    }}
                    dropdownRender={(menu) => (
                      <div style={contentStyle}>
                        {React.cloneElement(menu as React.ReactElement, {
                          style: menuStyle,
                        })}
                        <Divider style={{ margin: 0 }} />

                        {onlyRemove && asset.assignedDate && (
                          <div className="dropdown-footer">
                            <Typography.Text type="secondary">
                              {formatDate(asset.assignedDate)}
                            </Typography.Text>
                          </div>
                        )}
                      </div>
                    )}
                  />
                )}
              </Space>
            )}
          </div>
          <Author
            user={asset.isUpdated ? asset.lastUpdatedByUser! : asset.user!}
            isUpdated={Boolean(asset.isUpdated)}
            updatedAt={asset.lastUpdatedOn!}
            createdAt={asset.createdAt}
          />
        </>
      )}
    </Col>
  );
};

const AllMediaModal = ({
  onImgClick,
  onImgRemoveClick,
  assetIds,
  remainingSize,
  project,
  allMediaModal,
  onSendEmail,
  setAllMediaModal,
}: {
  onImgRemoveClick: (id: string) => void;
  onImgClick: (id: string, link: string) => void;
  allMediaModal: boolean;
  setAllMediaModal: (value: boolean) => void;
  project: Project;
  assetIds?: string[];
  remainingSize: number;
  onSendEmail: (id: string) => void;
}) => {
  const [selectedOrgId] = useLocalStorage("selectedOrgId", "all");

  const isThisTabAllowed = (tab: AssetType) =>
    project.organization?.allowedFileTabs?.includes(tab);

  const options = ASSET_TYPE_OPTIONS.filter((d) => isThisTabAllowed(d.value));

  const [selectedTab, setSelectedTab] = useState<AssetType | null>(
    options.length > 0 ? AssetType.STOCK : null
  );

  const { authID, isAdmin, isZoiq } = useUserData();

  const filterIsZoiq = (data: Assets[]) => {
    if (selectedTab === AssetType.PARTNERS_STOCK) return data;
    if (isAdmin || isZoiq) return data;
    const filtered = filter(data, (asset) => {
      const user = asset.user;
      const isAdminOnly = Boolean(asset.onlyAdmin);
      if (isAdminOnly) {
        return false;
      }
      if (user) {
        // isZoiq could be null or undefined
        const isZoiq = user.isZoiq;
        if (isZoiq) {
          return false;
        }
      }
      return true;
    });

    // priority to assigned assets.
    // mediaIds?.includes(asset.id) <-. if true, then it is assigned
    // if false, then it is not assigned
    // if assigned. then it will be shown first
    // if not assigned, then it will be shown last

    const assigned = filtered.filter((d) => assetIds?.includes(d.id));
    const notAssigned = filtered.filter((d) => !assetIds?.includes(d.id));
    const sorted = [...assigned, ...notAssigned];

    return sorted;
  };

  const {
    data: assets,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    `all-assets-${selectedTab}`,
    () => fetchAssets(selectedTab, selectedOrgId, project, authID!),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess(data) {
        setImagesCopy(filterIsZoiq(data));
      },
    }
  );

  useEffect(() => {
    if (selectedTab !== null) {
      refetch();
    }
  }, [selectedTab]);

  const [imagesCopy, setImagesCopy] = useState<Assets[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const [editAssetData, setEditAssetData] = useState<AssetWithStore>(
    {} as AssetWithStore
  );

  const onImgDelete = async (id: string) => {
    const asset = assets?.find((d) => d.id === id);
    const linkKey = asset?.link;
    try {
      linkKey && linkKey?.length > 0 && deleteImageFromS3(linkKey);
      id.length > 0 &&
        (await API.graphql(
          graphqlOperation(deleteAssets, {
            input: {
              id,
            },
          })
        ));
      messageApi.success("Successfully deleted");

      refetch?.();
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong deleting assets");
    }
  };
  const [newAssetModal, setNewAssetModal] = useState(false);

  const onImgEdit = async (id: string) => {
    setNewAssetModal(true);
    const asset = assets?.find((asset) => asset.id === id) as Assets;
    if (!isEmpty(asset)) {
      setEditAssetData({
        ...asset,
        store: {} as any,
      });
    }
  };

  const onCancel = () => {
    setNewAssetModal(false);
    setEditAssetData({} as AssetWithStore);
  };

  const onSearch = (value: string) => {
    const filtered = assets?.filter((asset) =>
      asset.note?.toLowerCase()?.includes(value.toLowerCase())
    );

    if (!filtered) return;
    setImagesCopy(filterIsZoiq(filtered) as any[]);
  };

  const onAssetSuccess = () => {
    refetch();
  };

  return (
    <Drawer
      title={
        <>
          <Typography.Title
            style={{
              marginBottom: 0,
            }}
            level={4}
          >
            <Badge count={isLoading ? 0 : imagesCopy?.length}>
              {options.find((d) => d.value === selectedTab)?.label}
            </Badge>
          </Typography.Title>
        </>
      }
      placement="right"
      width={1000}
      onClose={() => setAllMediaModal(false)}
      open={allMediaModal}
      extra={
        <Row
          gutter={[16, 16]}
          style={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Col>
            <Select
              placeholder="Select Media Type"
              options={options}
              style={{
                width: 200,
              }}
              value={selectedTab}
              onChange={(value) => {
                setSelectedTab(value);
              }}
            />
          </Col>
          <Col>
            <Input.Search
              allowClear
              placeholder="Search within assets"
              onSearch={onSearch}
            />
          </Col>
          <Col>
            {selectedTab !== null && (
              <InsideAssetModal
                newAssetModal={newAssetModal}
                remainingSize={remainingSize}
                editAssetData={editAssetData}
                project={project}
                setNewAssetModal={setNewAssetModal}
                onSuccess={onAssetSuccess}
                onCancel={onCancel}
                activeKey={selectedTab}
              />
            )}
          </Col>
        </Row>
      }
    >
      <>
        {contextHolder}

        {isLoading || isRefetching ? (
          <Loading useAppContainer={false} />
        ) : isEmpty(imagesCopy) ? (
          <Empty
            description="No media files"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <PaginationComponent
            shouldAffix
            items={imagesCopy!}
            itemsPerPage={10}
          >
            {(currentImages) => (
              <Row gutter={[16, 16]}>
                {currentImages.map((asset) => (
                  <ImageColMedia
                    key={asset.id}
                    onSendEmail={onSendEmail}
                    mediaIds={assetIds}
                    onImgAttach={onImgClick}
                    showBadge
                    onImgRemoveAttach={onImgRemoveClick}
                    onImgDelete={onImgDelete}
                    onImgEdit={onImgEdit}
                    asset={asset}
                  />
                ))}
              </Row>
            )}
          </PaginationComponent>
        )}
      </>
    </Drawer>
  );
};

export default AllMediaModal;
