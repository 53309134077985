import {
  Card,
  Descriptions,
  Flex,
  List,
  Segmented,
  Space,
  Tooltip,
  Typography,
} from "antd";

import { Pages, Project } from "@/API";
import {
  audienceSizes,
  audienceTypes,
  languageTypes,
  projectStatusList,
  toneTypes,
} from "@/constants/staticData";
import { useEffect, useMemo, useState } from "react";
import { CiMap } from "react-icons/ci";
import { TbFrame } from "react-icons/tb";
import QuickButton from "../common/QuickButton";

import useUserData from "@/hooks/useData";
import { sortBySequence } from "@/utils";
import { IoMdLink } from "react-icons/io";
import { useQuery } from "react-query";
import BuildInfo from "../BuildInfo";
import { fetchAssets } from "./SectionMediaFiles";
import Wireframe from "./Wireframe";
import useBreakpoint from "@/hooks/useBreakpoint";
import { DescriptionsProps } from "antd/lib";
type LayoutType = "wireframe" | "site-info";

const SiteInfo = ({ project }: { project: Project }) => {
  const siteData = project?.siteData;
  const { isZoiq, isAdmin } = useUserData();

  const audience = useMemo(
    () =>
      siteData?.audience
        ?.map((d) => audienceTypes.find((e) => e.value === d)?.label)
        .join(", "),
    [siteData?.audience]
  );
  const tone = useMemo(
    () => toneTypes.find((d) => d.value === siteData?.tone)?.label,
    [siteData?.tone]
  );

  const language = useMemo(
    () => languageTypes.find((d) => d.value === siteData?.languages)?.label,
    [siteData?.languages]
  );

  const trafficCount = useMemo(
    () => audienceSizes.find((d) => d.value === siteData?.trafficCount)?.label,
    [siteData?.trafficCount]
  );
  const status = useMemo(
    () => projectStatusList.find((d) => d.value === project.status)?.label,
    [project.status]
  );

  const { isExtraSmall } = useBreakpoint();

  const descriptionProps: DescriptionsProps = isExtraSmall
    ? {
        layout: "vertical",
      }
    : {
        layout: "horizontal",
        bordered: true,
      };

  return (
    <Descriptions size="small" {...descriptionProps}>
      <Descriptions.Item span={24} label="Site Name">
        {project.name}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Audience">
        {audience}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Traffic Count">
        {trafficCount}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Traffic Location">
        {siteData?.trafficLocation?.join(", ")}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Tone">
        {tone}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Languages">
        {language}
      </Descriptions.Item>
      <Descriptions.Item span={24} label="Project Status">
        {status}
      </Descriptions.Item>
      {siteData?.websites && siteData?.websites?.length > 0 && (
        <Descriptions.Item span={24} label="Websites">
          <List
            bordered
            style={{
              width: "100%",
            }}
          >
            {siteData?.websites?.map((website) => {
              if (website === null) return null;
              return (
                <List.Item
                  key={website.link!}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="">
                    <Typography.Link href={website.link!} target="_blank">
                      {website.link}
                    </Typography.Link>
                  </div>
                  <div className="">
                    <Typography.Text>{website.reason}</Typography.Text>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </Descriptions.Item>
      )}

      {(isAdmin || isZoiq) && (
        <Descriptions.Item span={24} label="Build Status">
          <BuildInfo />
        </Descriptions.Item>
      )}
      {(isAdmin || isZoiq) && (
        <Descriptions.Item span={24} label="Zoiq">
          <List
            bordered
            dataSource={[
              {
                title: "Github Repo",
                link: project?.githubRepo,
              },
              {
                title: "Amplify App",
                link: project?.awsAccount,
              },
              {
                title: "Cognito",
                link: project?.cognitoUrl,
              },
              {
                title: "Dynamo",
                link: project?.dynamo,
              },

              {
                title: "Lambda Functions",
                link: project.lambdaFunction,
              },
              {
                title: "Live Url",
                link: project?.liveUrl,
              },
            ]}
            renderItem={(item) => (
              <List.Item key={item.title}>
                <Flex
                  className=""
                  align={
                    typeof item.link === "string" ? "center" : "flex-start"
                  }
                  vertical={Array.isArray(item.link)}
                >
                  <Typography.Text>{item.title}</Typography.Text>
                  {typeof item.link === "string" && (
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <IoMdLink />
                    </a>
                  )}

                  {item.title === "Dynamo" && (
                    // if this item interface is equals to Dynamo
                    <>
                      {Array.isArray(item.link) &&
                        item.link.map(
                          (link) =>
                            link !== null && (
                              <Flex>
                                <Typography.Text type="secondary">
                                  {/* @ts-ignore */}
                                  {link.key}
                                </Typography.Text>
                                {/* @ts-ignore */}
                                {link.value && (
                                  <a
                                    // @ts-ignore
                                    href={link.value}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <IoMdLink />
                                  </a>
                                )}
                              </Flex>
                            )
                        )}
                    </>
                  )}

                  {Array.isArray(item.link) &&
                    item.link.map(
                      (link) =>
                        link !== null && (
                          <Flex>
                            <Typography.Text type="secondary">
                              {/* @ts-ignore */}
                              {link.name}
                            </Typography.Text>
                            {/* @ts-ignore */}
                            {link.url && (
                              <a
                                // @ts-ignore
                                href={link.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <IoMdLink />
                              </a>
                            )}
                          </Flex>
                        )
                    )}
                </Flex>
              </List.Item>
            )}
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const SiteDataComponent = ({
  project,
  onEditClick,
}: {
  project: Project;
  onEditClick: () => void;
}) => {
  if (!project) return null;

  const [pages, setPages] = useState<Pages[]>([]);

  useEffect(() => {
    if (!project.siteData) return;
    // if (isProjectPagesLoading) return;

    const projectPagesData = project?.pages?.items as Pages[];

    if (!projectPagesData) return;
    if (projectPagesData?.length === 0) return;

    const existingPages: Pages[] = projectPagesData || [];
    const pageSortSeq = project.siteData?.pageSortSeq;
    const sectionSortSeq = project.siteData?.sectionSortSeq;

    const sortedSeq = existingPages.map((p) => {
      const seq = (
        sectionSortSeq?.find((s) => s?.pageId === p.id)?.seq || []
      ).filter(Boolean);

      return {
        ...p,
        pageSections: sortBySequence(p.pageSections as any[], seq as any[]),
      };
    });

    const sortedPages = sortBySequence(sortedSeq, pageSortSeq as any[]);

    setPages(sortedPages);
  }, [project]);

  const assetIds = useMemo(
    () =>
      pages
        ?.map((page) =>
          page?.pageSections?.map((section) => section?.media?.[0])
        )
        .flat()
        .filter(Boolean),
    [pages]
  );

  const { data: assetData } = useQuery(
    `asset-data-${project?.id}`,

    // @ts-ignore
    () => fetchAssets(assetIds!),
    {
      enabled: !!assetIds && assetIds.length > 0,
    }
  );

  const [layout, setLayout] = useState<LayoutType>("site-info");

  return (
    <Card
      extra={
        <Space>
          <Segmented
            onChange={(value) => {
              setLayout(value as LayoutType);
            }}
            value={layout.toString()}
            options={[
              {
                value: "site-info",
                icon: (
                  <Tooltip title="Site Info">
                    <CiMap />
                  </Tooltip>
                ),
              },
              {
                value: "wireframe",
                icon: (
                  <Tooltip title="Wireframe">
                    <TbFrame />
                  </Tooltip>
                ),
              },
            ]}
          />

          <QuickButton
            type="dashed"
            onClick={() => {
              onEditClick();
            }}
          >
            Edit Site Data
          </QuickButton>
        </Space>
      }
      title={layout === "site-info" ? "Site Data" : "Wireframe"}
      className="card no-shadow"
    >
      {layout === "site-info" ? (
        <SiteInfo project={project} />
      ) : (
        <Wireframe pages={pages} assetData={assetData!} />
      )}
    </Card>
  );
};

export default SiteDataComponent;
