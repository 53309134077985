import React from "react";

const HideOn = ({
  hide,
  children,
}: {
  hide: boolean;
  children: React.ReactNode;
}) => {
  if (hide) return null;
  return children as React.ReactElement;
};

export default HideOn;
