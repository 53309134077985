import {
  Button,
  Card,
  Dropdown,
  Empty,
  Flex,
  FormInstance,
  Input,
  Layout,
  List,
  Menu,
  Modal,
  Segmented,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  message,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import {
  AssetType,
  Assets,
  CreateAssetsInput,
  CreatePagesInput,
  PageSection,
  Pages,
  Project,
  SectionSort,
  UpdatePagesInput,
  UpdateProjectInput,
} from "@/API";
import { imgAccept, vidExtended } from "@/constants/assets";
import {
  createAssets,
  createPages,
  deletePages,
  updatePages,
  updateProject,
} from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import useS3Keys from "@/hooks/useS3Keys";
import { getBase64, getImageFromS3, sortBySequence } from "@/utils";
import { onUpload } from "@/utils/graphql";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RcFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { API, graphqlOperation } from "aws-amplify";
import { motion } from "framer-motion";
import { isEmpty, omit } from "lodash";
import { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CiLock, CiMap, CiUnlock } from "react-icons/ci";
import { useMutation, useQuery } from "react-query";
import PreviewImage from "../RareComponents/PreviewImage";
import Form from "../common/Form";
import { fetchAssets } from "./SectionMediaFiles";

import colors from "@/constants/colors";
import { getProjectInfo } from "@/customGraphql/customQueries";
import { TbFrame } from "react-icons/tb";
import { useParams } from "react-router-dom";
import Loading from "../common/Loading";
import SideLoader from "../common/SideLoader";
import Wireframe from "./Wireframe";
interface PageSectionWithFileList extends PageSection {
  fileList: UploadFile[];
}

type LayoutType = "wireframe" | "site-map";

type _UploadFile = UploadFile & {
  isNew: boolean;
};

const { Sider, Content } = Layout;

const UploadFilesComponent = ({
  file,
  form,
  setFile,
}: {
  form: FormInstance<any>;
  file: _UploadFile;
  setFile: React.Dispatch<React.SetStateAction<_UploadFile>>;
}) => {
  const handleFilesChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    // check if the file is new or not
    // if new then set the isNew flag to true
    // else set it to false
    setFile(newFileList[0] as _UploadFile);
    form.setFieldsValue({
      file: newFileList[0],
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadPropsObject: UploadProps = {
    listType: "picture-card",
    accept: imgAccept.concat(vidExtended),

    beforeUpload: () => false,
    onPreview: handlePreview,
    maxCount: 1,
    onChange: handleFilesChange,
    multiple: false,
    fileList: !isEmpty(file) ? [file] : [],
  };

  return (
    <Upload
      // also keep drag and drop for video

      {...uploadPropsObject}
    >
      <PreviewImage
        previewOpen={previewOpen}
        previewTitle={previewTitle}
        previewImage={previewImage}
        handleCancel={() => {
          setPreviewOpen(false);
        }}
      />
      <div id="upload-btn">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Upload>
  );
};

const SectionItem = (props: {
  page: Pages;
  section: PageSection;
  onDelete: (pageId: string, item: string) => void;
  onEditSection: (pageId: string, item: string) => void;
}) => {
  const { page, section, onDelete, onEditSection } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: section.id,
  });
  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    display: "flex",
  };

  return (
    <div key={section.id} ref={setNodeRef} style={style} {...attributes}>
      <MenuOutlined
        size={14}
        ref={setActivatorNodeRef}
        style={{ touchAction: "none", cursor: "move" }}
        {...listeners}
      />
      <List.Item
        style={{
          width: "100%",
          marginLeft: 12,
        }}
        extra={
          <Dropdown
            className="page-item-dropdown"
            menu={{
              items: [
                {
                  key: "Delete",
                  title: "Delete",
                  label: "Delete",
                  danger: true,
                  onClick: () => onDelete(page.id, section.id),
                },
                {
                  key: "Edit",
                  title: "Edit",
                  label: "Edit",
                  onClick: () => {
                    onEditSection(page.id, section.id);
                  },
                },
              ],
            }}
          >
            <BiDotsVerticalRounded />
          </Dropdown>
        }
      >
        {section.section}
      </List.Item>
    </div>
  );
};

const PageItem = (props: {
  page: Pages;
  selectedPage: string;
  pageSections: PageSection[];
  setSelectedPage: (page: string) => void;
  onDelete: (pageId: string, item: string) => void;
  onEditSection: (pageId: string, item: string) => void;

  onDeletePage: (pageId: string) => void;
  onDragEndSection: ({ active, over }: DragEndEvent) => void;
  onEditPage: (pageId: string) => void;
}) => {
  const {
    page,
    selectedPage,
    setSelectedPage,
    onDelete,
    onEditSection,
    pageSections,
    onDeletePage,
    onEditPage,
    onDragEndSection,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    setActivatorNodeRef,
    transition,
    isDragging,
  } = useSortable({
    id: page.id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    display: "flex",
    alignItems: "flex-start",
  };

  return (
    <div
      ref={setNodeRef}
      className={`page-item  ${
        selectedPage === page.id ? "selected-list-item" : ""
      }`}
      style={style}
      {...attributes}
      key={page.id}
    >
      <MenuOutlined
        size={10}
        ref={setActivatorNodeRef}
        style={{
          touchAction: "none",
          marginTop: ".2rem",
          cursor: "move",
          padding: ".75rem 0",
        }}
        {...listeners}
      />

      <List.Item
        style={{
          width: "100%",
          marginLeft: 12,
        }}
        onClick={() => setSelectedPage(page.id)}
        className={`reset-divider-list hoverable-list-item ${
          selectedPage === page.id ? "selected-list-item" : ""
        }`}
      >
        <Flex
          style={{
            width: "100%",
          }}
          vertical
        >
          <Flex
            style={{
              width: "100%",
            }}
            justify="space-between"
            align="center"
          >
            <Typography.Text className="title-list-item">
              {page.name}
            </Typography.Text>

            <Dropdown
              className="page-item-dropdown"
              menu={{
                items: [
                  {
                    key: "Delete",
                    title: "Delete",
                    label: "Delete",
                    danger: true,
                    onClick: () => onDeletePage(page.id),
                  },
                  {
                    key: "Edit",
                    title: "Edit",
                    label: "Edit",
                    onClick: () => {
                      onEditPage(page.id);
                    },
                  },
                ],
              }}
            >
              <BiDotsVerticalRounded />
            </Dropdown>
          </Flex>
          {pageSections.length > 0 && (
            <DndContext
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEndSection}
            >
              <SortableContext
                disabled={pageSections.length === 1 || selectedPage !== page.id}
                items={pageSections?.map((i) => i?.id ?? "") ?? []}
                strategy={verticalListSortingStrategy}
              >
                <List
                  size="small"
                  style={{
                    marginRight: 12,
                  }}
                  dataSource={pageSections}
                  renderItem={(item) => {
                    if (!item) return null;
                    return (
                      <SectionItem
                        page={page}
                        section={item}
                        onDelete={onDelete}
                        onEditSection={onEditSection}
                      />
                    );
                  }}
                />
              </SortableContext>
            </DndContext>
          )}
        </Flex>
      </List.Item>
    </div>
  );
};

const options = [
  {
    label: "Informational",
    children: [
      "Hero",
      "Dashboard",
      "Course Page",
      "Contact",
      "Resource/Links",
      "About Us",
      "Mission",
      "History",
      "Profile",
      "Partners",
      "Introduction/Welcome",
      "Summary",
      "Detailed Content",
      "List/Table",
      "Timeline",
      "Notebook",
      "Switchboard",
      "Persona",
      "Services",
      "Testimonial",
    ],
  },
  {
    label: "Interactive",
    children: [
      "Login",
      "Journal Entry",
      "Profile",
      "Store",
      "Scheduler",
      "Calendar",
      "Polls",
      "Email/Newsletter",
      "Surveys",
    ],
  },
  {
    label: "Updates",
    children: [
      "Blog",
      "News",
      "Documents",
      "Gallery",
      "Play List",
      "Staff Profiles",
      "Training/Lessons",
    ],
  },
  {
    label: "Navigation",
    children: ["Loader", "Navbar", "App Icons", "Footer"],
  },
];

const SiteMapComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSectionModalVisible, setIsSectionModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const { projectId } = useParams<{
    projectId: string;
  }>();

  const [pages, setPages] = useState<Pages[]>([]);

 
  const {
    data: projectData,
    isLoading: isProjectLoading,
    isFetched,refetch: refetchProjects
  } = useQuery(
    `project-view-${projectId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getProjectInfo, {
          id: projectId,
        })
      );
      return response.data.getProject as Project;
    },
    {
      enabled: !!projectId,
    }
  );

  useEffect(() => {
    if (!projectData) return;
    if (!projectData.siteData) return;

    const projectPagesData = projectData?.pages?.items as Pages[];

    if(!projectPagesData) return;
    if (projectPagesData?.length === 0) return;


    
    

    const existingPages: Pages[] = projectPagesData || [];

    const pageSortSeq = projectData.siteData?.pageSortSeq;

    const sectionSortSeq = projectData.siteData?.sectionSortSeq;

    const sortedSeq = existingPages.map((p) => {
      const seq = (
        sectionSortSeq?.find((s) => s?.pageId === p.id)?.seq || []
      ).filter(Boolean);

      return {
        ...p,
        pageSections: sortBySequence(p.pageSections as any[], seq as any[]),
      };
    });

    const sortedPages = sortBySequence(sortedSeq, pageSortSeq as any[]);

    setPages(sortedPages);
  }, [projectData, isProjectLoading]);

  const { mutate: mutateProject, isLoading: projectMutating } = useMutation({
    mutationFn: async (variables: UpdateProjectInput) => {
      return API.graphql(
        graphqlOperation(updateProject, {
          input: variables,
        })
      );
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [isLocked, setIsLocked] = useState(false);

  const [selectedPage, setSelectedPage] = useState("");

  const { organisationId } = useParams<{ organisationId: string }>();
  const { projectFilesKey } = useS3Keys(projectId!, organisationId!);
  const { authID, email } = useUserData();

  const [messageApi, contextHolder] = message.useMessage();

  const [sectionEditData, setSectionEditData] = useState<PageSection>(
    {} as PageSection
  );

  const assetIds = pages
    ?.map((page) => page?.pageSections?.map((section) => section?.media?.[0]))
    .flat()
    .filter(Boolean);

  const { data: assetData, refetch: refetchAssets } = useQuery(
    `asset-data-${projectId}`,

    // @ts-ignore
    () => fetchAssets(assetIds!),
    {
      enabled: !!assetIds && assetIds.length > 0,
    }
  );

  const [file, setFile] = useState<_UploadFile>({} as _UploadFile);

  const [layout, setLayout] = useState<LayoutType>("site-map");

  if (!projectId) {
    return <Empty description="No project id found" />;
  }

  if (!isProjectLoading && isFetched && isEmpty(projectData)) {
    return <Empty description="No project data found." />;
  }

  const handleAddPage = () => {
    setSectionEditData({} as PageSection);
    setSelectedPage("");
    setIsModalVisible(true);
  };

  const onPageSave = () => {
    form.validateFields().then((values) => {
      // @ts-ignore
      const newPage: Pages = {
        projectID: projectId!,
        description: "-",
        name: values.name,
        id: uuidv4(),
        pageSections: [],
        // @ts-ignore
        isNew: true,
      };

      let updatedPages: Pages[] = [];

      if (selectedPage === "") {
        updatedPages = [...pages, newPage];
        setPages(updatedPages);
      } else {
        updatedPages = pages.map((page) => {
          if (page.id !== selectedPage) return page;
          return {
            ...page,
            name: values.name,
          };
        });
        setPages(updatedPages);
      }

      form.resetFields();
      setIsModalVisible(false);
      onProjectAndPagesMutation(updatedPages);
      // onFinish(parentForm.getFieldsValue(), false, updatedPages);
    });
  };

  const onProjectAndPagesMutation = async (pages: Pages[]) => {
    try {
      setIsLoading(true);
      const pageSortSeq = pages.map((page) => page.id);

      // @ts-ignore
      const sectionSortSeq: SectionSort[] = pages.map((page) => ({
        pageId: page.id,
        seq: page.pageSections.map((section) => section?.id),
      }));

      const projectInput: UpdateProjectInput = {
        id: projectId as string,
        siteData: {
          pageSortSeq: pageSortSeq,
          sectionSortSeq: sectionSortSeq,
        },
      };

      const mappedResponse = pages.map((page) => {
        // @ts-ignore
        if (page.isNew) {
          // @ts-ignore
          const input: CreatePagesInput = omit(
            {
              ...page,
              projectID: projectId,
            },
            ["isNew"]
          );
          return API.graphql(
            graphqlOperation(createPages, {
              input,
            })
          );
        }

        const input: UpdatePagesInput = {
          ...page,
        };

        return API.graphql(
          graphqlOperation(updatePages, {
            input,
          })
        );
      });

      await Promise.all(mappedResponse);
      mutateProject(projectInput);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [isSectionLoading, setIsSectionLoading] = useState(false);

  const onSectionSave = () => {
    setIsSectionLoading(true);
    form2.validateFields().then(async (values) => {
      messageApi.loading("Uploading file");
      // @ts-ignore
      const sectionObj: PageSectionWithFileList = {
        id: sectionEditData.id ?? uuidv4(),
        section: values.section,
        notes: values.notes,
      };

      const url = await onUpload(
        file.originFileObj,
        `${projectFilesKey}/${uuidv4()}`
      );

      const input: CreateAssetsInput = {
        id: uuidv4(),
        note: file.name,
        link: url,
        projectID: projectId,
        userID: authID!,
        email: email!,
        type: AssetType.STOCK,
      };

      const response: any = await API.graphql(
        graphqlOperation(createAssets, {
          input,
        })
      );

      const { id } = response.data.createAssets ?? {};

      const updatedPages = pages.map((page) => {
        if (page.id !== selectedPage) return page;
        return {
          ...page,
          pageSections: page.pageSections.map((d) => {
            if (d?.id !== sectionEditData.id) return d;
            if (!id) {
              return {
                ...d,
                ...sectionObj,
              };
            }

            return {
              ...d,
              ...sectionObj,
              media: [id],
            };
          }),
        };
      });

      setPages(updatedPages);

      form2.resetFields();
      setIsSectionModalVisible(false);
      messageApi.success("File uploaded successfully");
      onProjectAndPagesMutation(updatedPages);
      // onFinish(parentForm.getFieldsValue(), false, updatedPages);
      refetchAssets();
      refetchProjects()
      setIsSectionLoading(false);
    });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleModalCancel2 = () => {
    form2.resetFields();
    setIsSectionModalVisible(false);
  };

  const handleSectionSelect = (section: any) => {
    // @ts-ignore
    const newSection: PageSection = {
      id: uuidv4(),
      section: section,
      notes: "",
    };
    const updatedPages = pages.map((page) => {
      if (page.id !== selectedPage) return page;
      return {
        ...page,
        pageSections: [...page.pageSections, newSection],
      };
    });
    setPages(updatedPages);
    onProjectAndPagesMutation(updatedPages);
    // onFinish(parentForm.getFieldsValue(), false, updatedPages);
  };

  const onDelete = (pageId: string, sectionId: string) => {
    const updatedPages = pages.map((d) => {
      if (d.id === pageId) {
        return {
          ...d,
          pageSections: d.pageSections.filter((e) => e?.id !== sectionId),
        };
      }
      return d;
    });
    setPages(updatedPages);
    onProjectAndPagesMutation(updatedPages);
    // onFinish(parentForm.getFieldsValue(), false, updatedPages);
  };

  const onDeletePage = async (pageId: string) => {
    messageApi.loading("Deleting page");
    const updatedPages = pages.filter((d) => d.id !== pageId);
    setPages(updatedPages);
    // onFinish(parentForm.getFieldsValue(), false, updatedPages);
    onProjectAndPagesMutation(updatedPages);
    await API.graphql(
      graphqlOperation(deletePages, {
        input: {
          id: pageId,
        },
      })
    );
    messageApi.success("Page deleted successfully");
  };

  const onEditSection = (pageId: string, sectionId: string) => {
    const section = pages
      ?.find((d) => d.id === pageId)
      ?.pageSections?.find((d) => d?.id === sectionId);

    if (!section) return;

    setSectionEditData(section);

    form2.setFieldsValue({
      section: section?.section,
      notes: section?.notes,
    });

    if (section.media?.[0]) {
      const asset = assetData?.find((d: any) => d?.id === section?.media?.[0]);

      const fileObject: _UploadFile = {
        uid: section.media?.[0] ?? "",
        name: section.notes ?? "",
        status: "done" as UploadFile["status"],
        url: getImageFromS3(asset?.link!) ?? "",

        originFileObj: new File([], "".concat("image/jpeg")) as RcFile,

        type: "image/jpeg",
        isNew: isEmpty(asset),
      };

      setFile(fileObject);
    } else {
      setFile({} as _UploadFile);
    }

    setIsSectionModalVisible(true);
  };

  const onEditPage = (pageId: string) => {
    const page = pages?.find((d) => d.id === pageId);
    form.setFieldsValue({
      name: page?.name,
    });
    setIsModalVisible(true);
    setSelectedPage(pageId);
  };

  const onDragEndPage = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = pages.findIndex(
        (i: { id: UniqueIdentifier }) => i.id === active.id
      );
      const overIndex = pages.findIndex(
        (i: { id: UniqueIdentifier | undefined }) => i.id === over?.id
      );

      const updatedArray = arrayMove(pages, activeIndex, overIndex);

      setPages(updatedArray);
      onProjectAndPagesMutation(updatedArray);
      // onFinish(parentForm.getFieldsValue(), false, updatedArray);
    }
  };

  // 33
  // e9
  const onDragEndSection = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const sections = (
        pages.find((d) => d.id === selectedPage)?.pageSections ?? []
      ).filter(Boolean);
      if (!sections) return;
      const activeIndex = sections?.findIndex(
        // @ts-ignore
        (i: { id: UniqueIdentifier }) => i?.id === active?.id
      );
      const overIndex = sections?.findIndex(
        // @ts-ignore
        (i: { id: UniqueIdentifier | undefined }) => i.id === over?.id
      );

      const updatedArray = arrayMove(sections, activeIndex, overIndex);
      const updatedPages = pages.map((page) => {
        if (page.id !== selectedPage) return page;
        return {
          ...page,
          pageSections: updatedArray,
        };
      });

      setPages(updatedPages);
      onProjectAndPagesMutation(updatedPages);
      // onFinish(parentForm.getFieldsValue(), false, updatedPages);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", borderRadius: "1rem" }}>
      {contextHolder}
      <Sider
        style={{
          borderRadius: "1rem",
        }}
        width={layout === "site-map" ? 250 : 0}
        theme="light"
      >
        <Menu
          style={{
            width: 250,

            backgroundColor: colors.lightGray,
            border: "1px solid rgba(253, 253, 253, 0.12)",
            borderRadius: "1rem",
            height: "100%",
          }}
          className="card no-shadow"
          defaultOpenKeys={["Informational", "Interactive", "Updates"]}
          mode="inline"
        >
          <Typography.Title
            style={{
              padding: "1rem 1rem 0 1rem",
            }}
            level={3}
          >
            Sections
          </Typography.Title>
          {options.map((d) => {
            return (
              <Menu.SubMenu key={d.label} title={d.label}>
                <Flex
                  style={{
                    padding: "1rem",
                  }}
                  gap={"middle"}
                  wrap="wrap"
                >
                  {d.children.map((e) => {
                    return (
                      <Tag
                        onClick={() => handleSectionSelect(e)}
                        style={{ cursor: "pointer" }}
                        color={"default"}
                      >
                        {e}
                      </Tag>
                    );
                  })}
                </Flex>
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        {isProjectLoading ? (
          <Loading useAppContainer={false} />
        ) : (
          <Content style={{ padding: "24px" }}>
            <Flex justify="space-between">
              <Typography.Title level={3}>
                {layout === "site-map" ? "Site Map" : "Wireframe"}
              </Typography.Title>
              <Space>
                <Segmented
                  onChange={(value) => {
                    setLayout(value as LayoutType);
                  }}
                  value={layout.toString()}
                  options={[
                    {
                      value: "site-map",
                      icon: (
                        <Tooltip title="Site Map">
                          <CiMap />
                        </Tooltip>
                      ),
                    },
                    {
                      value: "wireframe",
                      icon: (
                        <Tooltip title="Wireframe">
                          <TbFrame />
                        </Tooltip>
                      ),
                    },
                  ]}
                />

                <Tooltip
                  title={
                    isLocked
                      ? "Unlock to reorder"
                      : "Lock to prevent reordering"
                  }
                >
                  <Button
                    shape="circle"
                    loading={projectMutating}
                    onClick={() => {
                      mutateProject({
                        id: projectId,
                        siteData: {
                          ...projectData?.siteData,
                          isLocked: !isLocked,
                        },
                      });
                      setIsLocked(!isLocked);
                    }}
                    type="text"
                    icon={
                      isLocked ? <CiLock size={24} /> : <CiUnlock size={24} />
                    }
                  />
                </Tooltip>
              </Space>
            </Flex>

            {layout === "site-map" ? (
              <motion.div
                key={layout}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <List>
                  <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEndPage}
                  >
                    <SortableContext
                      disabled={isLocked}
                      items={pages.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {pages.map((page) => {
                        return (
                          <PageItem
                            page={page}
                            pageSections={
                              page?.pageSections?.filter(
                                Boolean
                              ) as PageSection[]
                            }
                            onDeletePage={onDeletePage}
                            onEditPage={onEditPage}
                            selectedPage={selectedPage}
                            onDragEndSection={onDragEndSection}
                            setSelectedPage={setSelectedPage}
                            onDelete={onDelete}
                            onEditSection={onEditSection}
                          />
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                </List>

                <Button
                  style={{
                    marginTop: 12,
                  }}
                  block
                  type="primary"
                  onClick={handleAddPage}
                >
                  Add Page
                </Button>
              </motion.div>
            ) : (
              assetData && (
                <motion.div
                  initial={{ opacity: 0 }}
                  key={layout}
                  animate={{ opacity: 1 }}
                >
                  <Wireframe pages={pages} assetData={assetData as Assets[]} />
                </motion.div>
              )
            )}
          </Content>
        )}
      </Layout>
      <Modal
        title={selectedPage === "" ? "Add Page" : "Edit Page"}
        open={isModalVisible}
        onOk={onPageSave}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Section"
        open={isSectionModalVisible}
        onOk={onSectionSave}
        okButtonProps={{
          loading: isSectionLoading,
        }}
        onCancel={handleModalCancel2}
      >
        <Form form={form2} layout="vertical">
          <Form.Item
            name="section"
            label="Section"
            required
            rules={[{ required: true, message: "Please enter a section name" }]}
          >
            <Input />
          </Form.Item>

          <Card className="card no-shadow" title="Upload files">
            <Form.Item name="file">
              <UploadFilesComponent
                file={file as _UploadFile}
                form={form2}
                setFile={setFile}
              />
            </Form.Item>
          </Card>

          <Form.Item name="notes" label="Notes">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
      <SideLoader isLoading={projectMutating || isLoading} />
    </Layout>
  );
};
export default SiteMapComponent;
