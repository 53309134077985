import { getStorageSize } from "@/utils";
import { Progress, theme } from "antd";

const BucketSizeBar = ({
  usedSize,
  maxBucketSize,
  sizeRemaingPercentage,
}: {
  usedSize: number;
  maxBucketSize: number;
  sizeRemaingPercentage: number;
}) => {
  const { token } = theme.useToken();
  return (
    <Progress
      type="line"
      size={"small"}
      trailColor={token.colorPrimaryBg}
      style={{
        minWidth: 300,
        maxWidth: 300,
        marginRight: 100,
      }}
      strokeColor={token.colorPrimary}
      percent={sizeRemaingPercentage}
      status={sizeRemaingPercentage > 95 ? "exception" : "normal"}
      format={() =>
        `${getStorageSize(usedSize!)}/${getStorageSize(maxBucketSize)}`
      }
    />
  );
};

export default BucketSizeBar;
