import { NAVBAR_SIZE } from "@/constants/assets";
import colors from "@/constants/colors";
import { Card, Col, Row, Typography } from "antd";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const gridStyles = css`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);

  // for mobile
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  gap: 1rem;
  label {
    width: 100%;
    col-span: 1;
  }

  .ant-card {
    transition: some 0.3s ease-in-out;
  }

  & .selected {
    border: 1px solid ${colors.primary} !important;
  }

  & .ant-card:hover {
    transition: some 0.3s ease-in-out;
    border: 1px solid ${colors.primaryDark} !important;
  }
  .ant-card-meta-avatar {
    display: flex;
    align-items: center;
  }
`;

export const AnimatedCard = styled(motion(Card))``;
export const AnimatedRow = styled(motion(Row))``;
export const AnimatedCol = styled(motion(Col))``;
export const MotionTitle = motion(Typography.Title);
export const MotionText = motion(Typography.Text);
export const MotionParagraph = motion(Typography.Paragraph);

export const AnimatedCalculatorItemCard = styled(motion(Card))`
  min-height: 100px;
`;

export const CardImg = styled.img`
  width: 26px;
  height: 26px;
  object-fit: cover;
  filter: invert(1);
  margin-left: 0.5rem;

  @media (max-width: 1024px) {
    width: 20px;
    height: 20px;
  }
`;

export const StyledCardPattern = styled.div<{ color?: string }>`
  @media (min-width: 768px) {
    color: ${({ color }) => (color ? color : colors.gray)};
    .card {
      transform: translateY(-20px) translateX(-20px);
    }
  }
`;

export const BackgroundPattern = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  z-index: 2;
  background-size: 100%;
  background-image: url("https://zoiqclients.s3.amazonaws.com/assets/bg-pattern.svg");
  background-repeat: no-repeat;
`;

export const StyledAuthWrapper = styled.div`
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  // center
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  width: 100%;

  & .pattern-dots-md {
    width: 100%;
  }

  min-height: calc(100vh - ${NAVBAR_SIZE}px - 10rem);
  gap: 2rem;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  border: 2px dashed ${colors.darkVariant};
  padding: 1rem;
  border-radius: 0.5rem;
`;

export const BottomBar = styled.div`
  position: fixed;
  margin: 2rem 4rem;
  bottom: 0;
  right: 0;
  height: ${NAVBAR_SIZE}px;
  z-index: 100;
  background-color: transparent;
  border: 1px solid ${colors.darkVariant};
  backdrop-filter: blur(10px);
  padding: 0 2rem;
  border-radius: 999px;
  z-index: 9999;
`;

export const ScrollableText = styled.div`
  overflow-y: auto;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const ScrollView = styled.div<{ height?: number }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: ${({ height }) => (height ? `${height}px` : `100%`)};
  position: relative;
  padding-right: 12px;

  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }

  /* Styles for the scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background: @dark; /* Adjust the color as desired */
  }
`;
