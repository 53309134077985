import { Assets, SubAssetType } from "@/API";
import { TypingText } from "@/components/common/Title";
import { Buffer } from "@/components/sections/StyleGuide";
import { getImageFromS3 } from "@/utils";
import { Col, Divider, Image, Row, Space, Typography } from "antd";
import { motion } from "framer-motion";

const Logos = ({ assetsData }: { assetsData?: Assets[] }) => {
  const mainLogo = assetsData?.find(
    (d) => d.subType === SubAssetType.MAIN_LOGO
  );
  const imgProps: {
    style: React.CSSProperties;
  } = {
    style: {
      maxWidth: "100%",
      minHeight: "200px",
      maxHeight: "200px",
      objectFit: "contain",
    },
  };

  const otherLogo = assetsData?.find((d) => d.subType === SubAssetType.OTHER);
  const favicon = assetsData?.find((d) => d.subType === SubAssetType.FAVICON);

  const data = [
    {
      id: "main",
      link: mainLogo?.link,
      title: "Main Logo",
      text: `The main logo is the primary logo that will be used on all
        marketing materials, including the website, social media, and
        printed materials. The main logo should be used in all cases
        unless the alternate logo is required.`,
    },
    {
      id: "favicon",
      link: favicon?.link,
      title: "Browser Tab Favicon",
      text: `The favicon is the small icon that appears in the browser tab
        for the website. It should be a simplified version of the main
        logo.`,
    },
    {
      id: "alternate",
      link: otherLogo?.link,
      title: "Alternate Logo",
      text: `The alternate logo is a simplified version of the main logo that can be used in cases where the main logo is too large or too detailed to be used. The alternate logo should only be used when the main logo is not suitable.`,
    },
  ];

  const MotionCol = motion(Col);

  return (
    <motion.div className="">
      <TypingText title={"Client Logos"} />
      <Divider />
      <Buffer />

      <Space size={"large"} direction="vertical">
        {data.map((d) => (
          <Row key={d.id}>
            <MotionCol xs={24} lg={12} className="center bordered">
              {d?.link && <Image src={getImageFromS3(d?.link)} {...imgProps} />}
            </MotionCol>
            <MotionCol
              xs={24}
              lg={10}
              style={{
                marginLeft: "1rem",
              }}
            >
              <Typography.Title level={3}>{d.title}</Typography.Title>
              <Typography.Text type="secondary">{d.text}</Typography.Text>
            </MotionCol>
          </Row>
        ))}
      </Space>
    </motion.div>
  );
};

export default Logos;
