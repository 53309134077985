import React from "react";
import zipIcon from "../assets/icons/zip.png";
import photoIcon from "../assets/icons/photo.png";
import wordIcon from "../assets/icons/word.png";
import pdfIcon from "../assets/icons/pdf.png";
import xlsxIcon from "../assets/icons/xlsx.png";
import svgIcon from "../assets/icons/svg.png";
import defaultIcon from "../assets/icons/documents.png";
import { Tooltip } from "antd";

interface FileIconProps {
  mediaType: string;
}

const iconStyle = { width: 30, height: 30 };

const FileIcon: React.FC<FileIconProps> = ({ mediaType }) => {
  let selectedIcon: React.ReactNode;
  let tooltip = "";

  switch (true) {
    case mediaType === "zip":
      selectedIcon = <img src={zipIcon} alt="ZIP" style={iconStyle} />;
      tooltip = "ZIP";
      break;
    case mediaType === "png" || mediaType === "jpg":
      selectedIcon = (
        <img src={photoIcon} alt={mediaType.toUpperCase()} style={iconStyle} />
      );
      tooltip = mediaType.toUpperCase();
      break;

    case mediaType.includes("pdf"):
      selectedIcon = <img src={pdfIcon} alt="PDF" style={iconStyle} />;
      tooltip = "PDF";
      break;
    case mediaType === "xlsx" ||
      mediaType === "docx" ||
      mediaType.includes("spreadsheet"):
      selectedIcon = <img src={xlsxIcon} alt="XLSX" style={iconStyle} />;
      tooltip = "XLSX";
      break;
    case mediaType.includes("word"):
      selectedIcon = <img src={wordIcon} alt="WORD" style={iconStyle} />;
      tooltip = "WORD";
      break;
    case mediaType === "svg":
      selectedIcon = <img src={svgIcon} alt="SVG" style={iconStyle} />;
      tooltip = "SVG";
      break;
    default:
      selectedIcon = (
        <img
          src={defaultIcon}
          alt={mediaType.toUpperCase()}
          style={{
            ...iconStyle,
            filter: "invert(1)",
          }}
        />
      );
      tooltip = mediaType.toUpperCase();
      break;
  }

  return (
    <Tooltip title={tooltip}>
      <div>{selectedIcon}</div>
    </Tooltip>
  );
};

export default FileIcon;
