import {
  AssetType,
  Assets,
  ModelAssetsFilterInput,
  ModelStyleGuideFilterInput,
  StyleGuide,
} from "@/API";
import { VideoBack } from "@/components/ZoiqVideo";
import { VIDEO_PATTERN_URL } from "@/constants/assets";
import { listAssets, listStyleGuides } from "@/graphql/queries";
import useUserData from "@/hooks/useData";
import SectionLayout from "@/layout/SectionLayout";
import { Modal, Typography } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Elements from "../RareComponents/Elements";
import Colors from "../RareComponents/StyleGuide/Colors";
import Logos from "../RareComponents/StyleGuide/Logos";
import TypographySection from "../RareComponents/StyleGuide/TypographyStyleGuide";
import TypographyPreview from "../RareComponents/TypographyPreview";
import { getImageFromS3 } from "@/utils";
import ImageStyleGuide from "../RareComponents/ImageStyleGuide";

export const fetchStyleGuide = async (
  orgId: string,
  filter?: ModelStyleGuideFilterInput
) => {
  const response: any = await API.graphql(
    graphqlOperation(listStyleGuides, {
      limit: 1000,
      filter: filter ?? {
        organizationID: {
          eq: orgId,
        },
      },
    })
  );
  const items = response.data.listStyleGuides.items as StyleGuide[];
  return items;
};
export const fetchStyleGuideVariables = async (orgId: string) => {
  const response: any = await API.graphql(
    graphqlOperation(listStyleGuides, {
      limit: 1000,
      filter: {
        and: [
          {
            organizationID: {
              eq: orgId,
            },
          },
          {
            name: {
              eq: "variables",
            },
          },
        ],
      },
    })
  );
  const items = response.data.listStyleGuides.items as StyleGuide[];
  return items;
};

export const Buffer = () => {
  return (
    <div
      className=""
      style={{
        marginTop: "4rem",
      }}
    ></div>
  );
};

const StyleGuideComponent = ({ selectedOrgId }: { selectedOrgId: string }) => {
  const { isAdmin, data: userData } = useUserData();

  const { data: assetsData } = useQuery(
    `website-overview-${selectedOrgId}`,
    async () => {
      const filter: ModelAssetsFilterInput = {
        organizationID: {
          eq: selectedOrgId,
        },
        type: {
          eq: AssetType.ORGANIZATION,
        },
      };
      const response: any = await API.graphql(
        graphqlOperation(listAssets, {
          limit: 1000,
          filter: filter,
        })
      );
      const items = response.data.listAssets.items as Assets[];
      return items;
    },
    {
      enabled: isAdmin
        ? selectedOrgId.length > 0 && selectedOrgId !== "all"
        : !!userData?.organizationID,
    }
  );

  const { data: styleGuideData } = useQuery(
    `style-guide-${selectedOrgId}`,
    () => fetchStyleGuide(selectedOrgId)
  );

  const [showCodeModal, setShowCodeModal] = useState(false);
  const [apiUrl, setApiUrl] = useState("");

  const onCodeModalCancel = () => {
    setShowCodeModal(false);
    setApiUrl("");
  };

  const [modalPreview, setModalPreview] = useState<{
    show: boolean;
    data: any;
  }>({
    show: false,
    data: null,
  });

  useEffect(() => {
    // append css file in App.less file
    const linkHref = document.createElement("link");
    linkHref.rel = "stylesheet";
    linkHref.href = getImageFromS3(`STYLES/${selectedOrgId}/parsed`);
    document.head.appendChild(linkHref);

    return () => {
      document.head.removeChild(linkHref);
    };
  }, []);

  return (
    <SectionLayout
      isLoading={!styleGuideData}
      innerStyle={{ minHeight: "100vh" }}
      backgroundElement={
        <VideoBack bottom>
          <img src={VIDEO_PATTERN_URL} alt="video-back-bottom" />
        </VideoBack>
      }
      useContainer={false}
    >
      <TypographyPreview
        modalPreview={modalPreview}
        setModalPreview={setModalPreview}
      />
      {showCodeModal && (
        <Modal
          onCancel={onCodeModalCancel}
          open={showCodeModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          title="API"
        >
          <Typography.Link>{apiUrl}</Typography.Link>
        </Modal>
      )}
      {styleGuideData && (
        <>
          <Logos assetsData={assetsData} />
          <Buffer />
          <TypographySection styleGuideData={styleGuideData} />
          <Buffer />

          <Colors styleGuideData={styleGuideData} />
          <Buffer />
          <Elements styleGuideData={styleGuideData} />
          {/* <Buffer /> */}
          {/* <ImageStyleGuide styleGuideData={styleGuideData} /> */}
        </>
      )}
    </SectionLayout>
  );
};

export default StyleGuideComponent;
