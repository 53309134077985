import colors from "@/constants/colors";
import useTabs from "@/hooks/useTabs";
import { scrollTo } from "@/utils";
import { Affix, Breadcrumb, theme } from "antd";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";

export interface BreadcrumbContextType {
  breadcrumbs: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
}

export const BlurredBackground = styled.div`
  z-index: 99999;
  background-color: transparent;
  border: 1px solid ${colors.darkVariant};
  backdrop-filter: blur(10px);
  border-radius: ${({ theme }) => theme.borderRadius * 0.5}px;
  margin-bottom: 16px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Breadcrumbs = (
  {
    breadcrumbs,
  }: {
    breadcrumbs?: BreadcrumbContextType["breadcrumbs"];
  } = { breadcrumbs: [] }
) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { token } = theme.useToken();

  const { activeKey, handleTabChange } = useTabs("breadcrumbs", "overview");

  const onClick = (key: string) => {
    handleTabChange(key);
    scrollTo(key, 20);
  };

  const rightBreadCrumbs: BreadcrumbContextType["breadcrumbs"] = [
    {
      key: "overview",
      title: "Overview",

      className: `breadcrumbs-item ${
        activeKey === "overview" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("overview"),
    },

    {
      className: `breadcrumbs-item ${
        activeKey === "team" ? "breadcrumbs-active" : ""
      }`,
      title: "Team",

      key: "team",
      onClick: () => onClick("team"),
    },
    {
      title: "MoSCoW",
      key: "moscow",

      className: `breadcrumbs-item ${
        activeKey === "moscow" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("moscow"),
    },
    {
      title: "Milestones",
      key: "milestones",

      className: `breadcrumbs-item ${
        activeKey === "milestones" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("milestones"),
    },

    {
      title: "Action Items",
      key: "discussion",

      className: `breadcrumbs-item ${
        activeKey === "discussion" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("discussion"),
    },
    {
      title: "Summaries",
      key: "summaries",

      className: `breadcrumbs-item ${
        activeKey === "summaries" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("summaries"),
    },
    {
      title: "Site Scope",
      key: "pages",

      className: `breadcrumbs-item ${
        activeKey === "pages" ? "breadcrumbs-active" : ""
      }`,
      onClick: () => onClick("pages"),
    },
  ];

  const showRightBreadcrumbs = location.pathname.includes("project-view");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // scroll to top when the page is loaded
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
      }}
      initial="hidden"
      animate={isLoading ? "hidden" : "visible"}
    >
      <div className="scrollable-container" ref={setContainer}>
        {container !== null && (
          <Affix
            style={{ marginBottom: "30px" }}
            offsetTop={10}
            className="affix"
            target={() => container}
          >
            <BlurredBackground theme={token}>
              <Breadcrumb items={breadcrumbs} />
              {showRightBreadcrumbs && (
                <Breadcrumb separator={" | "} items={rightBreadCrumbs} />
              )}
            </BlurredBackground>
          </Affix>
        )}
      </div>
    </motion.div>
  );
};

export default Breadcrumbs;
