import {
  Backlog,
  ContentCreation,
  DeleteContentCreationInput,
  DeleteSuccessCriteriaInput,
  PageSection,
  Pages,
  SuccessCriteria,
  UpdatePagesInput,
} from "@/API";
import useUserData from "@/hooks/useData";
import { Card, Col, Flex, Modal, Row, message } from "antd";
import { useQuery } from "react-query";
import QuickButton from "../common/QuickButton";

import {
  deleteContentCreation,
  deletePages,
  deleteSuccessCriteria,
  updatePages,
} from "@/graphql/mutations";

import EditSiteData from "@/components/RareComponents/EditSiteData";
import NewPage from "@/components/RareComponents/NewPage";
import SiteMapSidebar from "@/components/RareComponents/SiteMapSidebar";
import { getSiteData, listPages } from "@/customGraphql/customQueries";
import { SortAscendingOutlined } from "@ant-design/icons";
import { API, graphqlOperation } from "aws-amplify";

import useLocalStorage from "@/hooks/useLocalstorage";
import { sortBySequence } from "@/utils";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import TaskModal from "../RareComponents/TaskComponents/TaskModal";
import Loading from "../common/Loading";
import { fetchContentCreation } from "./ContentCreationTab";
import PageHeader from "./PageHeader";
import { fetchSuccessCriteriaData } from "./ProjectSC";
import SectionComponent from "./SectionComponent";
import SiteDataComponent from "./SiteDataComponent";
import SortPagesAndSection from "./SortPagesAndSection";
import SectionPopup from "./section-popup";

interface PagesProps {
  projectId: string;
}

export const fetchProjectsPages = async (projectId: string) => {
  const response: any = await API.graphql(
    graphqlOperation(listPages, {
      filter: {
        projectID: {
          eq: projectId,
        },
      },
    })
  );
  const items = (response.data.listPages.items ?? []).filter(Boolean);

  return items as Pages[];
};

export const fetchSiteData = async (projectId: string) => {
  const response: any = await API.graphql(
    graphqlOperation(getSiteData, {
      id: projectId,
    })
  );

  return response.data.getProject;
};

const ProjectPages = ({ projectId }: PagesProps) => {
  const { isAdmin: rawisAdmin, isZoiq } = useUserData();
  const isAdmin = rawisAdmin || isZoiq;

  const ref = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetched, refetch } = useQuery(
    `project-pages-${projectId}`,
    () => fetchProjectsPages(projectId),
    {
      enabled: !!projectId,
    }
  );

  const { data: project, refetch: refetchProject } = useQuery(
    `project-sitedata-${projectId}`,
    () => fetchSiteData(projectId),
    {
      enabled: !!projectId,
    }
  );

  const [editPageData, setEditPageData] = useState<Pages>({} as Pages);

  const [pageModal, setPageModal] = useState(false);

  const onModalCancel = () => {
    setPageModal(false);
    setEditPageData({} as Pages);
  };

  const onSuccessMutation = () => {
    onModalCancel();
    refetch();
  };

  const [messageApi, contextHolder] = message.useMessage();

  const [selectedSection, setSelectedSection] = useState<Omit<
    PageSection,
    "__typename"
  > | null>(null);

  const [selectedPage, setSelectedPage] = useState<Pages | null>(null);

  const [editSiteModal, setEditSiteModal] = useState(false);

  const { data: contentCreation, refetch: refetchContentCreation } = useQuery(
    `content-creation-${projectId}-${selectedPage?.id}-${selectedSection?.id}`,
    () =>
      fetchContentCreation(projectId, selectedPage?.id, selectedSection?.id),
    {
      enabled: !!projectId && !!selectedPage?.id && !!selectedSection?.id,
    }
  );

  const { data: successCriteria, refetch: refetchSuccessCriteria } = useQuery(
    `project-sc-${projectId}-${selectedPage?.id}-${selectedSection?.id}`,
    () =>
      fetchSuccessCriteriaData(
        project.id,
        selectedPage?.id,
        selectedSection?.id
      ),
    {
      enabled: !!projectId && !!selectedPage?.id && !!selectedSection?.id,
    }
  );

  const onSectionDelete = async (page: Pages, sectionId: string) => {
    try {
      if (page.pageSections?.length === 1) {
        messageApi.error("You cannot delete the last section of a page");
        return;
      }
      const updatedPage = page.pageSections?.filter(
        (section) => section && section.id !== sectionId
      );

      refetchContentCreation();
      refetchSuccessCriteria();

      const input: UpdatePagesInput = {
        id: page.id,
        pageSections: updatedPage,
      };

      const contentCreationData = contentCreation?.filter(
        (d) => d.sectionId === sectionId && d.pageId === page.id
      ) as ContentCreation[];

      const successCriteriaData = successCriteria as SuccessCriteria[];

      const listPromisesContent =
        contentCreationData?.map((content: ContentCreation) => {
          const input: DeleteContentCreationInput = {
            id: content.id,
          };
          return API.graphql(
            graphqlOperation(deleteContentCreation, { input })
          );
        }) ?? [];

      const listPromisesCriteria =
        successCriteriaData?.map((content: SuccessCriteria) => {
          const input: DeleteSuccessCriteriaInput = {
            id: content.id,
          };
          return API.graphql(
            graphqlOperation(deleteSuccessCriteria, { input })
          );
        }) ?? [];

      const [response]: any[] = await Promise.all([
        API.graphql(
          graphqlOperation(updatePages, {
            input,
          })
        ),
        ...listPromisesContent,
        ...listPromisesCriteria,
      ]);

      if (response.data.updatePages) {
        messageApi.success("Section deleted successfully");
        setSelectedSection(null);
        setSelectedPage(null);
        refetch();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong deleting section");
    }
  };

  const onPageDelete = async (page: Pages) => {
    try {
      // update sort sequence
      const response: any = await API.graphql(
        graphqlOperation(deletePages, {
          input: {
            id: page.id,
          },
        })
      );

      if (response.data.deletePages) {
        messageApi.success("Page deleted successfully");
        setSelectedSection(null);
        setSelectedPage(null);
        refetch();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong deleting page");
    }
  };

  const onPageEdit = (page: Pages) => {
    setEditPageData(page);
    setPageModal(true);
  };

  const [sortModal, setSortModal] = useState(false);

  const onSortCancel = () => {
    setSortModal(false);
  };

  const [sortedData, setSortedData] = useState<Pages[]>([]);

  useEffect(() => {
    const sorted = sortBySequence(
      data!,
      project?.siteData?.pageSortSeq as string[]
    ) as Pages[];
    const sortedSeq = sorted.map((d) => {
      const pageSections = sortBySequence(
        // @ts-ignore
        d.pageSections,
        project?.siteData?.sectionSortSeq?.find(
          (s: { pageId: string }) => s?.pageId === d.id
        )?.seq as string[]
      );

      return {
        ...d,
        pageSections,
      };
    }) as Pages[];

    setSortedData(sortedSeq);
  }, [data, project?.siteData?.pageSortSeq, project?.siteData?.sectionSortSeq]);

  const [newTaskModal, setNewTaskModal] = useState(false);
  const [selectedOrgId] = useLocalStorage("selectedOrgId", "");

  const [taskData, setTaskData] = useState<Backlog>({} as Backlog);

  const [onlySidebar, setOnlySidebar] = useState(false);

  return (
    <Card
      ref={ref}
      id="pages"
      title="Site Map"
      extra={
        !onlySidebar && (
          <Flex gap={"middle"} justify="flex-end">
            {/* <Button
              icon={<PlusOutlined />}
              onClick={() => setNewTaskModal(true)}
              type="link"
            >
              New Task
            </Button> */}
            {isAdmin && (
              <>
                <QuickButton
                  onClick={() => setSortModal(true)}
                  type="dashed"
                  icon={<SortAscendingOutlined />}
                >
                  Update sort
                </QuickButton>
                <QuickButton
                  type="primary"
                  onClick={() => {
                    setPageModal(true);
                    setEditPageData({} as Pages);
                  }}
                >
                  Add new page
                </QuickButton>
              </>
            )}
          </Flex>
        )
      }
      className="card"
    >
      {contextHolder}

      {isLoading || !isFetched ? (
        <Loading useAppContainer={false} />
      ) : (
        <>
          {pageModal && isAdmin && (
            <Modal
              title={isEmpty(editPageData) ? "Add new page" : "Edit page"}
              open={pageModal}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={onModalCancel}
            >
              <NewPage
                project={project}
                onModalCancel={onModalCancel}
                editPageData={editPageData}
                onSuccessMutation={onSuccessMutation}
              />
            </Modal>
          )}

          {sortModal && isAdmin && (
            <Modal
              title={"Sort Pages and Sections"}
              open={sortModal}
              width={800}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={onSortCancel}
            >
              <SortPagesAndSection
                project={project}
                pages={sortedData ?? []}
                refetch={() => {
                  refetchProject();
                  refetch();
                }}
              />
            </Modal>
          )}

          {/* {newTaskModal && (
            <Modal
              open={newTaskModal}
              width={800}
              onCancel={() => {
                setNewTaskModal(false);
              }}
              title={"New Task"}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
            >
              <TaskModal
                projects={[]}
                onCancel={() => {
                  setNewTaskModal(false);
                }}
                isInsidePages
                prepoluateData={{
                  projectID: project.id,
                  organizationID: selectedOrgId,
                  pageID: selectedPage?.id,
                  sectionID: selectedSection?.id,
                  pages: sortedData,
                }}
                sequence={[]}
                onSuccess={() => {
                  setNewTaskModal(false);
                }}
                taskData={taskData}
              />
            </Modal>
          )} */}

          {editSiteModal && (
            <Modal
              title={"Edit site data"}
              open={editSiteModal}
              width={800}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              onCancel={() => {
                setEditSiteModal(false);
              }}
            >
              <EditSiteData
                project={project}
                onCancel={() => {
                  setEditSiteModal(false);
                }}
                onSuccessMutation={() => {
                  setEditSiteModal(false);
                  refetchProject();
                }}
              />
            </Modal>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24} xs={0} lg={onlySidebar ? 24 : 6}>
              <SiteMapSidebar
                project={project}
                onlySidebar={onlySidebar}
                setOnlySidebar={setOnlySidebar}
                setSelectedPage={setSelectedPage}
                setSelectedSection={setSelectedSection}
                pages={sortedData ?? []}
              />
            </Col>
            <Col span={24} xs={24} lg={onlySidebar ? 0 : 18}>
              {!isEmpty(selectedPage) && (
                <div>
                  <PageHeader
                    projectId={projectId}
                    refetch={refetchProject}
                    onPageEdit={onPageEdit}
                    onSectionDelete={onSectionDelete}
                    page={selectedPage}
                    section={selectedSection as PageSection}
                    onPageDelete={onPageDelete}
                  />
                </div>
              )}

              {!isEmpty(selectedSection) ? (
                <>
                  {selectedSection.id === "flyer" ? (
                    <SectionPopup
                      // refetch={refetch}

                      projectID={project.id}
                      // popupID={section.popupID!}
                    />
                  ) : selectedSection.id === "site" ? (
                    <SiteDataComponent
                      onEditClick={() => {
                        setEditSiteModal(true);
                      }}
                      project={project}
                    />
                  ) : (
                    <SectionComponent
                      setSelectedSection={setSelectedSection}
                      project={project}
                      newTaskModal={newTaskModal}
                      setNewTaskModal={setNewTaskModal}
                      taskData={taskData}
                      setTaskData={setTaskData}
                      refetch={refetch}
                      page={selectedPage as Pages}
                      section={selectedSection as PageSection}
                    />
                  )}
                </>
              ) : (
                <SiteDataComponent
                  onEditClick={() => {
                    setEditSiteModal(true);
                  }}
                  project={project}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default ProjectPages;
