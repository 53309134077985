import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const customMutationCreateUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      authID
      organizationID
      name
      title
      email
      phone
      orgLevel
      status
      createdAt
      updatedAt
    }
  }
`;

export const customMutationCreateOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
    }
  }
`;

export const createDiscussion = /* GraphQL */ `
  mutation CreateDiscussion(
    $input: CreateDiscussionInput!
    $condition: ModelDiscussionConditionInput
  ) {
    createDiscussion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createActionItems = /* GraphQL */ `
  mutation CreateActionItems(
    $input: CreateActionItemsInput!
    $condition: ModelActionItemsConditionInput
  ) {
    createActionItems(input: $input, condition: $condition) {
      id
      discussionID
      actionItem
      owners
      isCompleted
      targetDate
      dateCompleted
      createdAt
      updatedAt
    }
  }
`;

export const updateDiscussion = /* GraphQL */ `
  mutation UpdateDiscussion(
    $input: UpdateDiscussionInput!
    $condition: ModelDiscussionConditionInput
  ) {
    updateDiscussion(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateActionItems = /* GraphQL */ `
  mutation UpdateActionItems(
    $input: UpdateActionItemsInput!
    $condition: ModelActionItemsConditionInput
  ) {
    updateActionItems(input: $input, condition: $condition) {
      id
      discussionID
    }
  }
`;

export const deleteActionItems = /* GraphQL */ `
  mutation DeleteActionItems(
    $input: DeleteActionItemsInput!
    $condition: ModelActionItemsConditionInput
  ) {
    deleteActionItems(input: $input, condition: $condition) {
      id
      discussionID
    }
  }
`;

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      organizationID
    }
  }
`;

export const createProjectPlan = /* GraphQL */ `
  mutation CreateProjectPlan(
    $input: CreateProjectPlanInput!
    $condition: ModelProjectPlanConditionInput
  ) {
    createProjectPlan(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateProjectPlan = /* GraphQL */ `
  mutation UpdateProjectPlan(
    $input: UpdateProjectPlanInput!
    $condition: ModelProjectPlanConditionInput
  ) {
    updateProjectPlan(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      organizationID
    }
  }
`;
export const updateProjectSeq = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      projectPlanSortSeq
      userSortSeq
      siteData {
        sectionSortSeq {
          pageId
          seq
        }
        pageSortSeq
      }
    }
  }
`;

export const createSummary = /* GraphQL */ `
  mutation CreateSummary(
    $input: CreateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    createSummary(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateSummary = /* GraphQL */ `
  mutation UpdateSummary(
    $input: UpdateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    updateSummary(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateBacklogSequence = /* GraphQL */ `
  mutation UpdateBacklogSequence(
    $input: UpdateBacklogSequenceInput!
    $condition: ModelBacklogSequenceConditionInput
  ) {
    updateBacklogSequence(input: $input, condition: $condition) {
      id
      sequence
    }
  }
`;

export const updateSubTaskModel = /* GraphQL */ `mutation UpdateSubTaskModel(
  $input: UpdateSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  updateSubTaskModel(input: $input, condition: $condition) {
    id
 
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubTaskModelMutationVariables,
  APITypes.UpdateSubTaskModelMutation
>;
export const deleteSubTaskModel = /* GraphQL */ `mutation DeleteSubTaskModel(
  $input: DeleteSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  deleteSubTaskModel(input: $input, condition: $condition) {
    id
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubTaskModelMutationVariables,
  APITypes.DeleteSubTaskModelMutation
>;

export const createBacklog = /* GraphQL */ `mutation CreateBacklog(
  $input: CreateBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  createBacklog(input: $input, condition: $condition) {
    id
   
 
   
  }
}
` as GeneratedMutation<
  APITypes.CreateBacklogMutationVariables,
  APITypes.CreateBacklogMutation
>;
export const updateBacklog = /* GraphQL */ `mutation UpdateBacklog(
  $input: UpdateBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  updateBacklog(input: $input, condition: $condition) {
    id
  }
}
` as GeneratedMutation<
  APITypes.UpdateBacklogMutationVariables,
  APITypes.UpdateBacklogMutation
>;
export const deleteBacklog = /* GraphQL */ `mutation DeleteBacklog(
  $input: DeleteBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  deleteBacklog(input: $input, condition: $condition) {
    id
    
  }
}
` as GeneratedMutation<
  APITypes.DeleteBacklogMutationVariables,
  APITypes.DeleteBacklogMutation
>;

export const createSubTaskModel = /* GraphQL */ `mutation CreateSubTaskModel(
  $input: CreateSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  createSubTaskModel(input: $input, condition: $condition) {
    id

   
  }
}
` as GeneratedMutation<
  APITypes.CreateSubTaskModelMutationVariables,
  APITypes.CreateSubTaskModelMutation
>;
