import { PageSection, Pages, UpdatePagesInput } from "@/API";
import { updatePages } from "@/graphql/mutations";
import { textVariant2 } from "@/utils/motions";
import { Segmented, Typography } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { motion } from "framer-motion";
import { useEffect } from "react";
// None- Client provides example of how section should look like and expects ZOIQ to implement their ideas
// Collaborative - Client will provide copy, assets and high level expectations but will want ZOIQ to come up with best design idea for section
// Full Service - Client knows they need a section but will expect ZOIQ to provide best industry solution
// something like this

export const designInstructionsOptions = [
  {
    label: "None",
    value: "0",
  },
  {
    label: "Collaborative",
    value: "1",
  },
  {
    label: "Full Service",
    value: "2",
  },
];

const instructions = [
  {
    title: "None",
    key: "0",
    description:
      "Client provides example of how section should look like and expects ZOIQ to implement their ideas",
  },
  {
    key: "1",
    title: "Collaborative",
    description:
      "Client will provide copy, assets and high level expectations but will want ZOIQ to come up with best design idea for section",
  },
  {
    title: "Full Service",
    key: "2",

    description:
      "Client knows they need a section but will expect ZOIQ to provide best industry solution",
  },
];

const DesignInstructions = ({
  page,
  section,
  sliderValue,
  setSliderValue,
}: {
  page: Pages;
  section: PageSection;
  sliderValue: number;
  setSliderValue: (value: number) => void;
}) => {
  useEffect(() => {
    const level = Number(section.instructionsLevel) ?? 0;
    setSliderValue(level);
  }, []);

  const onSliderChange = async (value: number) => {
    setSliderValue(value);
    try {
      const sections = page.pageSections?.map((item) => {
        if (item?.id === section.id) {
          return {
            ...item,
            instructionsLevel: value,
          };
        }
        return item;
      }) as PageSection[];

      const input: UpdatePagesInput = {
        id: page.id,
        pageSections: sections,
      };

      await API.graphql(
        graphqlOperation(updatePages, {
          input,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Segmented
        block
        onChange={(value) => {
          onSliderChange(Number(value));
        }}
        value={sliderValue.toString()}
        options={designInstructionsOptions}
      />

      <motion.div
        style={{ marginTop: 12, textAlign: "center" }}
        key={sliderValue}
        variants={textVariant2}
        initial="hidden"
        animate="show"
      >
        <Typography.Text type="secondary">
          {instructions[sliderValue].description}
        </Typography.Text>
      </motion.div>
    </div>
  );
};

export default DesignInstructions;
