import { PageSection, Pages, UpdatePagesInput } from "@/API";
import { updatePages } from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import { Flex } from "@aws-amplify/ui-react";
import { Button, Input, message } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import Form from "../common/Form";

interface NewSectionProps {
  editSectionData: PageSection;
  onSuccessMutation: () => void;
  onModalCancel: () => void;

  page: Pages;
}

interface NewSectionForm {
  section: string;
  link: string;
}

const NewSection = ({
  editSectionData,
  onSuccessMutation,
  onModalCancel,

  page,
}: NewSectionProps) => {
  const { isAdmin } = useUserData();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const onFinish = async (values: NewSectionForm) => {
    try {
      messageApi.loading("Adding new section to page...");
      const newSection = {
        section: values.section,
        id: uuidv4().substring(0, 8),
        link: values.link,
      };
      const pageSections = [...page.pageSections, newSection];

      const input: UpdatePagesInput = {
        id: page.id,
        pageSections,
      };
      const response: any = await API.graphql(
        graphqlOperation(updatePages, { input: input })
      );

      if (response.data.updatePages) {
        messageApi.success("Section added successfully");
        onSuccessMutation();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    }
  };
  const onFinishUpdate = async (values: NewSectionForm) => {
    try {
      messageApi.loading("Updating section...");

      const pageSections = page.pageSections.map((section) => {
        if (section?.id === editSectionData.id) {
          return {
            ...section,
            section: values.section,
            link: values.link,
          };
        }
        return section;
      });

      const input: UpdatePagesInput = {
        id: page.id,
        pageSections,
      };

      const response: any = await API.graphql(
        graphqlOperation(updatePages, { input: input })
      );

      if (response.data.updatePages) {
        messageApi.success("Section edited successfully");
        onSuccessMutation();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        form={form}
        initialValues={{
          section: editSectionData?.section,
          link: editSectionData?.link,
        }}
        onFinish={isEmpty(editSectionData) ? onFinish : onFinishUpdate}
      >
        <Form.Item
          label="Section Name"
          name={"section"}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Please input section name or delete this field.",
            },
          ]}
        >
          <Input placeholder="Enter section name" />
        </Form.Item>
        {isAdmin && (
          <Form.Item
            label="Section URL"
            name={"link"}
            rules={[
              {
                type: "url",
                message: "Please enter valid url",
              },
            ]}
          >
            <Input placeholder="Enter section link" />
          </Form.Item>
        )}

        <Form.Item>
          <Flex
            direction="row"
            gap={12}
            display={"flex"}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <Button
              type="default"
              onClick={() => {
                onModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewSection;
