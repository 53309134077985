import { PageSection, Pages } from "@/API";
import useUserData from "@/hooks/useData";
import { Flex } from "@aws-amplify/ui-react";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import NewSection from "../RareComponents/NewSection";

interface PageHeaderProps {
  onPageDelete: (page: Pages) => void;
  page: Pages;
  onPageEdit: (page: Pages) => void;
  onSectionDelete: (page: Pages, sectionId: string) => void;
  section: PageSection;
  refetch: () => void;
  projectId: string;
}

const PageHeader = ({
  onPageDelete,
  page,
  section,
  onPageEdit,
  onSectionDelete,
  refetch,
}: PageHeaderProps) => {
  const items: MenuProps["items"] = [
    {
      label: "Edit Page",
      key: "edit-page",
      onClick: () => {
        onPageEdit(page);
      },
    },
    {
      label: "Edit Section",
      key: "edit-section",
      onClick: () => {
        onSectionEdit();
      },
    },
    // {
    //   label: "Popups",
    //   key: "popups",
    //   onClick: () => {
    //     navigate(`/popups/${projectId}`);
    //   },
    // },
    {
      label: (
        <Popconfirm
          title="Are you sure you want to delete this page?"
          onConfirm={() => onPageDelete(page)}
        >
          Delete Page
        </Popconfirm>
      ),
      key: "delete-page",
    },
    {
      label: (
        <Popconfirm
          title="Are you sure you want to delete this section?"
          onConfirm={() => onSectionDelete(page, section?.id)}
        >
          Delete Section
        </Popconfirm>
      ),
      key: "delete-section",
    },
  ];

  const { isAdmin: rawisAdmin, isZoiq } = useUserData();
  const isAdmin = rawisAdmin || isZoiq;

  const onAddNewSectionClick = () => {
    setSectionModal(true);
    setEditSectionData({} as PageSection);
  };

  const [sectionModal, setSectionModal] = useState(false);

  const onModalCancel = () => {
    setSectionModal(false);
    setEditSectionData({} as PageSection);
  };

  const onSectionEdit = () => {
    setSectionModal(true);
    setEditSectionData(section);
  };

  const [editSectionData, setEditSectionData] = useState({} as PageSection);

  const onSuccessMutation = () => {
    onModalCancel();
    refetch();
  };

  return (
    <>
      <Modal
        title={isEmpty(editSectionData) ? "Add new section" : "Edit section"}
        open={sectionModal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={onModalCancel}
      >
        <NewSection
          page={page}
          editSectionData={editSectionData}
          onSuccessMutation={onSuccessMutation}
          onModalCancel={onModalCancel}
        />
      </Modal>
      <Flex
        className="page-header"
        width={"100%"}
        display={"flex"}
        marginBottom={12}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <div className="">
          <Typography.Title level={4}>
            {page.name ?? "Untitled Page"}
          </Typography.Title>
          <Typography.Text type="secondary">
            {section?.section ?? "Untitled Section"}
          </Typography.Text>
        </div>
        {isAdmin && (
          <Space>
            <Button onClick={() => onAddNewSectionClick()}>
              + Add new section
            </Button>
            <Dropdown placement="bottomRight" menu={{ items }}>
              <Button type="default" icon={<BiDotsVerticalRounded />} />
            </Dropdown>
          </Space>
        )}
      </Flex>
    </>
  );
};

export default PageHeader;
