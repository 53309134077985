import { PageSection } from "@/API";
import Form from "@/components/common/Form";
import { Button, Col, Empty, Input, Modal, Row, Upload } from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";

const SectionSignOff = ({ section }: { section: PageSection }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
        title="Sign Off"
      >
        <Form>
          <Form.Item label="Title">
            <Input name="title" placeholder="Enter title" />
          </Form.Item>

          <Form.Item label="Description">
            <Input.TextArea
              name="description"
              rows={2}
              placeholder="Enter description"
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Upload className="upload-list-inline">
                <Button
                  style={{
                    width: "100%",
                  }}
                  type="dashed"
                >
                  Upload Original Design
                </Button>
              </Upload>
            </Col>
            <Col span={12}>
              <Upload className="upload-list-inline">
                <Button
                  style={{
                    width: "100%",
                  }}
                  type="dashed"
                >
                  Upload Latest Design
                </Button>
              </Upload>
            </Col>
          </Row>
        </Form>
      </Modal>

      {isEmpty(section.signOff) ? (
        <div>
          <Empty
            description="No sign off information provided"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          >
            <Button onClick={handleOpenModal}>+ Add</Button>
          </Empty>
        </div>
      ) : (
        <div>there is a data</div>
      )}
    </div>
  );
};

export default SectionSignOff;
