import { MAX_BUCKET_SIZE } from "@/constants/staticData";
import { getOrganization } from "@/graphql/queries";
import { fetchFolderSize } from "@/utils/graphql";
import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "./useLocalstorage";

import { Organization } from "@/API";
import { useQuery } from "react-query";

const useBucketSize = (bucketKey: string, updateValues?: boolean) => {
  const [selectedOrgId] = useLocalStorage("selectedOrgId", "all");

  const [isLoading, setIsLoading] = useState(false);

  const { data: organisation, isFetched: isOrgFetched } = useQuery(
    `organisation-${selectedOrgId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getOrganization, {
          id: selectedOrgId,
        })
      );
      return response.data.getOrganization as Organization;
    },
    {
      enabled: !!selectedOrgId && selectedOrgId !== "all",
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const bucketSize = isOrgFetched
    ? organisation?.maxBucketSize ?? MAX_BUCKET_SIZE
    : MAX_BUCKET_SIZE;

  const [sizeRemaingPercentage, setSizeRemaingPercentage] = useState<
    null | number
  >(null);

  const [usedSize, setUsedSize] = useState<null | number>(null);

  const calculatePercentage = useCallback(
    (folderUsedSize: number) => {
      // const MAX_BUCKET_SIZE = 10 // in mb
      const percentage = (folderUsedSize / bucketSize) * 100;
      return percentage;
    },
    [bucketSize]
  );

  useEffect(() => {
    if (sizeRemaingPercentage === null || updateValues) {
      if (bucketKey !== "") {
        setIsLoading(true);
        fetchFolderSize(bucketKey)
          .then((res) => {
            if (typeof res === "number") {
              setSizeRemaingPercentage(calculatePercentage(res));
              setUsedSize(res);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [
    sizeRemaingPercentage,
    bucketKey,
    isOrgFetched,
    bucketSize,
    updateValues,
  ]);

  const remainingSize = usedSize !== null ? bucketSize - usedSize : bucketSize;

  return {
    remainingSize,
    sizeRemaingPercentage:
      sizeRemaingPercentage === null ? 0 : sizeRemaingPercentage,
    usedSize: usedSize === null ? 0 : usedSize,
    bucketSize,
    maxBucketSize: organisation?.maxBucketSize ?? MAX_BUCKET_SIZE,
    isBucketSizeLoading: isLoading,
  };
};

export default useBucketSize;
