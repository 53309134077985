// add jsDocs

export type Colors = {
  primary: string;
  primaryDark: string;
  primaryLight: string;
  secondary: string;
  dark: string;
  darker: string;
  darkVariant: string;
  gray: string;
  grayVariant: string;
  secondaryBlack: string;
  lightGray: string;
  secondaryWhite: string;
  graphs: {
    blue: string;
    green: string;
    red: string;
    yellow: string;
    purple: string;
    orange: string;
    pink: string;
    teal: string;
    cyan: string;
    lime: string;
    indigo: string;
    violet: string;
  };
};

export const colorInfo = {
  primary: "Used for actions and primary buttons",
  secondary: "Used for secondary buttons and actions",
  darkest: "Used for main background",
  dark: "Used for alternative background",
  medium: "Used for decorative borders. Forms, Inputs etc.",
  light: "Used for non decorative borders. Dividers etc.",
  lightest: "Used for secondary text",
  white: "Used for heading text",
};

/**
 * @description
 * This file contains all the colors used in the application.
 * The colors are divided into 3 categories:
 * 1. Primary colors
 * 2. Dark colors
 * 3. Gray colors
 *
 * @example
 * import colors from 'constants/colors';
 * styles:{
 *  backgroundColor: colors.primary,
 * }
 *
 
 */
const colors: Colors = {
  // primary colors
  primary: "#FCBF34",
  primaryDark: "#D89C26",
  primaryLight: "#FDD366",
  secondary: "#6895fd",

  // Dark colors
  dark: "#0d1117",
  darker: "#17141d",
  darkVariant: "#3c4449",

  // gray colors
  gray: "#717F92",
  grayVariant: "rgba(255, 255, 255, 0.48)",
  secondaryBlack: "#5d6680",
  lightGray: "rgb(22, 27, 34)",
  secondaryWhite: "#C4C4C5",
  graphs: {
    blue: "#6895fd",
    green: "#00d68f",
    red: "#ff3d71",
    yellow: "#fdcb6e",
    purple: "#8e44ad",
    orange: "#ff9f43",
    pink: "#f368e0",
    teal: "#1dd1a1",
    cyan: "#0abde3",
    lime: "#7bed9f",
    indigo: "#3c40c6",
    violet: "#7158e2",
  },
};

// 111928
export default colors;
