import { useEffect, useState } from "react";
import StyledProgress from "./StyledComponents/Progress";
import useBreakpoint from "@/hooks/useBreakpoint";

const Scroller = () => {
  // get current scroll height of body
  // if scroll height is greater than 0, show scroller
  // else, hide scroller

  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!document.body) return;
      if (screens.isExtraSmall) return;
      // get current scroll position of body

      // get height of footer element
      // get height of header element

      const footerHeight =
        document.getElementsByTagName("footer")?.[0].clientHeight || 0;

      const headerHeight =
        document.getElementsByTagName("header")?.[0]?.clientHeight || 0;

      const BUFFER = 320;
      const headerFooterHeight = headerHeight + footerHeight + BUFFER;

      const position =
        (window.pageYOffset /
          (document.body.scrollHeight - headerFooterHeight)) *
        100;

      // set scroll position to state
      setScrollHeight(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const screens = useBreakpoint();
  if (screens.isExtraSmall) return null;
  return <StyledProgress width={scrollHeight.toString()} />;
};

export default Scroller;
