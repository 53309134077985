import {
  CreatePagesInput,
  PageSection,
  Pages,
  Project,
  UpdatePagesInput,
} from "@/API";
import { createPages, updatePages } from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import { addActivity } from "@/utils/graphql";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Flex } from "@aws-amplify/ui-react";
import { Button, Divider, Input, message } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty, omit } from "lodash";
import { v4 as uuidv4 } from "uuid";
import Form from "../common/Form";

interface NewPageProps {
  editPageData: Pages;
  onSuccessMutation: () => void;
  onModalCancel: () => void;
  project: Project;
}

const NewPage = ({
  editPageData,
  onSuccessMutation,
  onModalCancel,
  project,
}: NewPageProps) => {
  const { isAdmin } = useUserData();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const onFinish = async (values: CreatePagesInput) => {
    try {
      messageApi.loading("Creating page...");
      const pageSections = values.pageSections.map((section: any) => {
        return {
          section: section.section,
          id: uuidv4().substring(0, 8),
          link: section.link,
        };
      });

      const input: CreatePagesInput = {
        name: values.name,
        description: values.description,
        projectID: project.id,
        pageSections,
        link: values.link,
      };
      const response: any = await API.graphql(
        graphqlOperation(createPages, { input: input })
      );

      if (response.data.createPages) {
        messageApi.success("Page created successfully");
        onSuccessMutation();
        await addActivity(
          "New page added",
          `/project-view/${project.organizationID}/${project.id}`
        );
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    }
  };

  const onFinishUpdate = async (values: UpdatePagesInput) => {
    try {
      messageApi.loading("Updating page...");
      const pageSections = values?.pageSections
        ?.map((section) => {
          if (section === null) return null;
          const existing = omit(
            editPageData?.pageSections?.find((s) => s && s.id === section.id),
            ["__typename"]
          );
          if (existing) {
            return {
              ...existing,
              section: section.section,
              id: section.id ?? uuidv4().substring(0, 8),
              link: section.link,
            };
          }
        })
        .filter(Boolean);

      const input: UpdatePagesInput = {
        id: editPageData.id,
        name: values.name,
        description: values.description,
        projectID: project.id,
        pageSections: pageSections as PageSection[],
        link: values.link,
      };
      const response: any = await API.graphql(
        graphqlOperation(updatePages, { input: input })
      );

      if (response.data.updatePages) {
        messageApi.success("Page updated successfully");
        onSuccessMutation();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        form={form}
        initialValues={{
          name: editPageData?.name,
          description: editPageData?.description,
          link: editPageData?.link,
          pageSections: editPageData?.pageSections,
        }}
        onFinish={!isEmpty(editPageData) ? onFinishUpdate : onFinish}
      >
        <Form.Item
          label="Page Name"
          name="name"
          required
          rules={[
            {
              required: true,
              message: "Please enter page name",
            },
            {
              min: 4,
              message: "Page name must be at least 4 characters",
            },

            {
              max: 20,
              message: "Page name must be less than 20 characters",
            },
          ]}
        >
          <Input placeholder="Enter page Name" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter page description",
            },
            {
              min: 4,
              message: "Page description must be at least 4 characters",
            },
          ]}
          label="Page Description"
          name="description"
        >
          <Input.TextArea rows={4} placeholder="Enter page description" />
        </Form.Item>
        <Form.Item tooltip="You can add it later" label="Page Link" name="link">
          <Input placeholder="Enter page link" />
        </Form.Item>
        <Divider>Sections</Divider>
        <Form.List
          name="pageSections"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error("At least 1 section"));
                } else if (names.length > 50) {
                  return Promise.reject(new Error("No more than 50 sections"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field) => {
                return (
                  <Flex key={field.name}>
                    <Form.Item
                      label="Section Name"
                      name={[field.name, "section"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            "Please input section name or delete this field.",
                        },
                      ]}
                    >
                      <Input placeholder="Enter section name" />
                    </Form.Item>
                    {isAdmin && (
                      <Form.Item
                        {...field}
                        label="Section URL"
                        name={[field.name, "link"]}
                        rules={[
                          {
                            type: "url",
                            message: "Please enter valid url",
                          },
                        ]}
                      >
                        <Input placeholder="Enter section link" />
                      </Form.Item>
                    )}

                    <Button
                      block
                      icon={<MinusCircleOutlined />}
                      type="text"
                      danger
                      onClick={() => remove(field.name)}
                    >
                      Remove
                    </Button>
                  </Flex>
                );
              })}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add section
                </Button>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>

        <Form.Item>
          <Flex
            direction="row"
            gap={12}
            display={"flex"}
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <Button
              type="default"
              onClick={() => {
                onModalCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewPage;
