import { Project } from "@/API";
import { getAwsInfo } from "@/customGraphql/customQueries";
import useLocalStorage from "@/hooks/useLocalstorage";
import { formatDate, formatTime } from "@/utils";
import { getBuildInfo } from "@/utils/graphql";
import { JobStatus, Job } from "@aws-sdk/client-amplify";
import {
  TagProps,
  Typography,
  Flex,
  Tag,
  Button,
  StepProps,
  Steps,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { IoRefresh } from "react-icons/io5";
import { useQuery } from "react-query";

const getColor = (status: JobStatus): TagProps["color"] => {
  switch (status) {
    case "CANCELLED":
      return "default";
    case "CANCELLING":
      return "default";
    case "FAILED":
      return "error";
    case "PENDING":
      return "default";
    case "PROVISIONING":
      return "default";
    case "RUNNING":
      return "processing";
    case "SUCCEED":
      return "success";

    default:
      break;
  }
};

const BuildInfo = () => {
  const [info, setInfo] = useState<Job>({} as Job);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId] = useLocalStorage("selectedProjectId", "none");
  const [_, setBuildStatus] = useLocalStorage<null | Job>("buildStatus", null);

  const { data: awsInfo, isLoading: isProjectLoading } = useQuery(
    `awsInfo-${selectedProjectId}`,
    async () => {
      const res: any = await API.graphql(
        graphqlOperation(getAwsInfo, {
          id: selectedProjectId,
        })
      );
      return res.data.getProject as Project;
    },
    {
      enabled: !isEmpty(selectedProjectId) && selectedProjectId !== "none",
    }
  );

  const appId = awsInfo?.siteData?.appId;
  const branchName = awsInfo?.siteData?.branchName;

  useEffect(() => {
    setBuildStatus(info);
  }, [info]);

  const refetch = async () => {
    if (
      isEmpty(appId) ||
      isEmpty(branchName) ||
      appId === undefined ||
      appId === null ||
      branchName === undefined ||
      branchName === null
    )
      return;
    setIsLoading(true);
    const fetchedInfo = await getBuildInfo(appId, branchName);

    if (isEmpty(fetchedInfo)) return;
    setInfo(fetchedInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(appId) || isEmpty(branchName)) return;
    if (!isEmpty(info)) return;
    refetch();
  }, [appId, branchName, info]);

  // if status is running, run the refetch function every 10 seconds
  useEffect(() => {
    if (info.summary?.status === "RUNNING") {
      const interval = setInterval(() => {
        refetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [info]);

  if (isProjectLoading) {
    return (
      <Typography.Text type="secondary">Loading project...</Typography.Text>
    );
  }

  if (isEmpty(appId) || isEmpty(branchName)) {
    return (
      <Typography.Text type="secondary">
        No Amplify App Id or Branch Name found
      </Typography.Text>
    );
  }

  if (isEmpty(info.summary)) {
    return (
      <Typography.Text type="secondary">
        Loading live build status ...
      </Typography.Text>
    );
  }

  const { startTime, status } = info.summary;

  const commonComponent = (
    <Flex justify="space-between">
      <div>
        <Tag color={getColor(status!)}>{status}</Tag>
        <Typography.Text type="secondary">
          Last upload to {branchName} ({awsInfo?.name}) ~{" "}
          {formatDate(startTime?.toString()!)}{" "}
          {formatTime(startTime?.toString()!)}
        </Typography.Text>
      </div>
      <Button
        size="small"
        title="Refresh Build Info"
        onClick={() => {
          refetch();
        }}
        loading={isLoading}
        disabled={isLoading}
        icon={<IoRefresh />}
      ></Button>
    </Flex>
  );

  const runningBuildSteps: StepProps[] =
    info.steps?.map((step) => {
      const status = step.status;
      return {
        title: step.stepName,
        description: step.statusReason,

        status:
          status === "SUCCEED"
            ? "finish"
            : status === "RUNNING"
            ? "process"
            : status === "FAILED" || status === "CANCELLED"
            ? "error"
            : status === "PENDING"
            ? "wait"
            : "finish",
      };
    }) || [];

  const runningBuild = (
    <Flex vertical gap={"middle"}>
      <Steps items={runningBuildSteps} size="small" />
      {commonComponent}
    </Flex>
  );

  return status !== JobStatus.RUNNING ? commonComponent : runningBuild;
};

export default BuildInfo;
