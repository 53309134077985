import { User } from "@/API";
import { formatDate } from "@/utils";
import { Typography } from "antd";
import { NavLink } from "react-router-dom";

const Author = ({
  user,
  isUpdated,
  createdAt,
  updatedAt,
}: {
  user: User;
  isUpdated?: boolean;
  updatedAt?: string;
  createdAt?: string;
}) => {
  return (
    <Typography.Text type="secondary">
      {isUpdated ? "Updated By " : "By "}
      {user?.isZoiq || user?.isAdmin ? (
        "ZOIQ"
      ) : (
        <NavLink to={`/profile/?id=${user?.authID}&email=${user?.email}`}>
          {user?.name}
        </NavLink>
      )}{" "}
      {isUpdated && <small> ~ {updatedAt ? formatDate(updatedAt) : ""}</small>}
      {!isUpdated && <small> ~ {createdAt ? formatDate(createdAt) : ""}</small>}
    </Typography.Text>
  );
};

export default Author;
