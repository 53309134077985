import FOOTER from "@/constants/footer.constants";
import useBreakpoint from "@/hooks/useBreakpoint";
import { SendOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  Layout,
  List,
  Row,
  Typography,
  theme,
} from "antd";
import { TitleProps } from "antd/es/typography/Title";
import styled from "styled-components";
const { Footer: AntdFooter } = Layout;

const Link = styled(Typography.Link)`
  // color: ${(props) => props.theme.token.colorWhite} !important;
  text-transform: capitalize;
  text-decoration: underline !important;
  &:hover {
    color: ${(props) => props.theme.token.colorPrimary} !important;
  }
`;

const StyledFooter = styled(AntdFooter)`
  text-align: center;
  position: relative;
  padding: 0;
`;

const MobileFooter = styled(AntdFooter)`
  padding-left: 1rem;
`;

let DynamicFooter = StyledFooter;

const Footer = () => {
  const { token } = theme.useToken();

  const footerHeaderConfig: TitleProps = {
    level: 4,
  };

  const screens = useBreakpoint();

  if (screens.isExtraSmall) {
    DynamicFooter = MobileFooter;
  }

  return (
    <>
      <DynamicFooter theme={{ token }}>
        <div className={screens.isExtraSmall ? "" : "container"}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <div style={{ textAlign: "left" }}>
                <Typography.Title {...footerHeaderConfig}>
                  {FOOTER.heading}
                </Typography.Title>
                <Typography.Paragraph type="secondary">
                  {FOOTER.subtitle}
                </Typography.Paragraph>
                <Typography.Paragraph type="secondary">
                  {FOOTER.subtitle2}
                </Typography.Paragraph>
              </div>
            </Col>

            {FOOTER.links.map((footer) => (
              <Col key={footer.id} xs={24} sm={12} md={6}>
                <div style={{ textAlign: "left" }}>
                  <Typography.Title {...footerHeaderConfig}>
                    {footer.heading}
                  </Typography.Title>
                  <List className="reset-divider-list">
                    {footer.links.map((link) => (
                      <List.Item key={link.id}>
                        <Link
                          type="secondary"
                          theme={{ token }}
                          href={link.href}
                        >
                          {link.name}
                        </Link>
                      </List.Item>
                    ))}
                  </List>
                </div>
              </Col>
            ))}

            <Col xs={24} sm={12} md={6} style={{ zIndex: 999 }}>
              <div style={{ textAlign: "left" }}>
                <Typography.Title {...footerHeaderConfig}>
                  {FOOTER.contactUs.heading}
                </Typography.Title>

                <Typography.Paragraph type="secondary">
                  {/* some informal text to contact us */}
                  {FOOTER.contactUs.subtitle}
                </Typography.Paragraph>

                <Input
                  suffix={<Button icon={<SendOutlined />} />}
                  placeholder="Enter your email"
                />
              </div>
            </Col>
          </Row>

          <Divider />

          <Typography.Text strong>{FOOTER.copyrights}</Typography.Text>
        </div>
        <div className="footer-gradient"></div>
      </DynamicFooter>
    </>
  );
};

export default Footer;
