import Loading from "@/components/common/Loading";
import { TaskProvider } from "@/contexts/TaskContexts";
import useUserData from "@/hooks/useData";
// import Popups from "@/pages/popups";
import SiteMap from "@/pages/SiteMap";
import UploadFonts from "@/pages/UploadFonts";
import { AnimatePresence } from "framer-motion";

import { Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const ForgotPassword = lazy(() => import("@/pages/ForgotPassword"));
const ProjectView = lazy(() => import("@/pages/ProjectView"));
const Profile = lazy(() => import("@/pages/Profile"));

const ClientPortal = lazy(() => import("@/pages/ClientPortal"));
const Home = lazy(() => import("@/pages/Home"));

const Login = lazy(() => import("@/pages/Login"));
const NotFoundPage = lazy(() => import("@/pages/NotFoundPage"));
const ProjectCalculator = lazy(() => import("@/pages/ProjectCalculator"));

const RegisterUser = lazy(() => import("@/pages/RegisterUser"));
const RegisterOrganization = lazy(() => import("@/pages/RegisterOrganisation"));
const OrganizationList = lazy(() => import("@/pages/OrganizationList"));
const Tasks = lazy(() => import("@/pages/TasksPage"));
const TaskView = lazy(() => import("@/pages/TaskView"));
const CreateProject = lazy(() => import("@/pages/CreateProject"));

const UserList = lazy(() => import("@/pages/UserList"));
const Solutions = lazy(() => import("@/pages/Solutions"));

const MainRouter = () => {
  const { isLoggedIn, isAdmin, isUserLoading, isZoiq, canAddProjects } =
    useUserData();

  const location = useLocation();

  if (!isLoggedIn) {
    // save current pathname as redirectUrl to localstorage
    console.log("location.pathname", location.pathname);

    localStorage.setItem("redirectUrl", location.pathname);
  }

  if (isUserLoading) return <Loading />;

  const dynamicCanAddProjects = isAdmin || isZoiq || canAddProjects;

  return (
    <Suspense fallback={<Loading />}>
      <AnimatePresence initial={false} mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route index path="/" element={<Home />} />

          <Route
            path="/profile"
            element={isLoggedIn ? <Profile /> : <Login />}
          />

          <Route path="/login" element={!isLoggedIn ? <Login /> : <Home />} />

          <Route
            path="/project/create"
            element={
              dynamicCanAddProjects ? <CreateProject /> : <NotFoundPage />
            }
          />
          <Route
            path="/project/site-map/:projectId"
            element={isAdmin || isZoiq ? <SiteMap /> : <NotFoundPage />}
          />

          <Route
            path="/sprints"
            element={
              dynamicCanAddProjects ? (
                <TaskProvider>
                  <Tasks />
                </TaskProvider>
              ) : (
                <NotFoundPage />
              )
            }
          />

          <Route
            path="/solutions"
            element={isAdmin || isZoiq ? <Solutions /> : <NotFoundPage />}
          />
          <Route
            path="/sprints/:taskId"
            element={
              dynamicCanAddProjects ? (
                <TaskProvider>
                  <TaskView />
                </TaskProvider>
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="/user-list"
            element={isAdmin ? <UserList /> : <NotFoundPage />}
          />

          <Route
            path="/project/edit/:projectId"
            element={
              dynamicCanAddProjects ? <CreateProject /> : <NotFoundPage />
            }
          />

          <Route
            path="/upload-fonts"
            element={isAdmin ? <UploadFonts /> : <NotFoundPage />}
          />
          <Route
            path="/upload-fonts/:fontId"
            element={isAdmin ? <UploadFonts /> : <NotFoundPage />}
          />

          <Route
            path="/project-view/:organisationId/:projectId"
            element={isLoggedIn ? <ProjectView /> : <Login />}
          />
          <Route
            path="/project-view/:projectId"
            element={isLoggedIn ? <ClientPortal /> : <Login />}
          />

          <Route path="/project-calculator" element={<ProjectCalculator />} />

          <Route
            path="/client-portal"
            element={isLoggedIn ? <ClientPortal /> : <Login />}
          />

          <Route
            path="/register/organization"
            element={
              dynamicCanAddProjects ? (
                <RegisterOrganization />
              ) : (
                <NotFoundPage />
              )
            }
          />
          <Route
            path="/organization-list"
            element={
              dynamicCanAddProjects ? <OrganizationList /> : <NotFoundPage />
            }
          />
          <Route
            path="/register/organization/edit"
            element={
              dynamicCanAddProjects ? (
                <RegisterOrganization />
              ) : (
                <NotFoundPage />
              )
            }
          />

          <Route
            path="/register/user"
            element={isAdmin ? <RegisterUser /> : <NotFoundPage />}
          />
          <Route
            path="/profile/edit"
            element={isLoggedIn ? <RegisterUser /> : <Login />}
          />

          <Route
            path="/forgot-password"
            element={!isLoggedIn ? <ForgotPassword /> : <Home />}
          />

          <Route
            path="*"
            element={isUserLoading ? <Loading /> : <NotFoundPage />}
          />
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
};

export default MainRouter;
