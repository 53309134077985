import {
  CreateGalleryInput,
  Gallery,
  GalleryItem,
  UpdateGalleryInput,
} from "@/API";
import { imgAccept, vidExtended } from "@/constants/assets";
import { createGallery, updateGallery } from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import { onUpload } from "@/utils/graphql";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Card,
  Input,
  Modal,
  Progress,
  Typography,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Form from "@/components/common/Form";
import SortableUploadList from "@/components/common/SortComponents/SortableUploadList";
import {
  convertToMB,
  deleteImageFromS3,
  getBase64,
  getImageFromS3,
  sortBySequence,
} from "@/utils";
import { UniqueIdentifier } from "@dnd-kit/core";
import { RcFile } from "antd/es/upload";
import { filter, isEmpty, map, omit } from "lodash";
import PreviewImage from "../PreviewImage";

type _UploadFile = UploadFile & {
  isNew: boolean;
};

type _GalleryItem = Omit<GalleryItem, "__typename">;

const GalleryModal = ({
  selectedOrdId: selectedOrgId,
  open,
  setOpen,
  refetch,
  editGalleryData,
  remainingSize,
  onSuccess,
  projectId,
}: {
  open: boolean;
  remainingSize: number;
  editGalleryData: Gallery;
  refetch: () => void;
  onSuccess: (galleryID: string) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: string;
  selectedOrdId: string;
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const isEdit = !isEmpty(editGalleryData);

  const [isMediaUpdated, setIsMediaUpdated] = useState(false);
  const [isCoverUpdated, setIsCoverUpdated] = useState(false);

  const [progress, setProgress] = useState<null | number>(null);

  useEffect(() => {
    if (isEdit) {
      //  create a fake file list with the editGalleryData
      //  set the fileList to the fake file list
      //  set the form values to the editGalleryData

      const coverFile: UploadFile = {
        uid: editGalleryData.id,
        name: editGalleryData.title ?? "",
        status: "done" as UploadFile["status"],
        url: getImageFromS3(editGalleryData.coverImage) ?? "",

        originFileObj: new File([], "".concat("image/jpeg")) as RcFile,

        type: "image/jpeg",
      };

      const galleryFiles: _UploadFile[] = [];
      const sorted =
        editGalleryData.gallery && editGalleryData.sortSeq
          ? sortBySequence(
              editGalleryData.gallery as any[],
              editGalleryData.sortSeq as string[]
            )
          : editGalleryData.gallery;
      sorted?.forEach((item) => {
        if (item === null) return;
        const file: _UploadFile = {
          uid: item.id,
          name: item.note ?? "",
          status: "done" as UploadFile["status"],
          url: getImageFromS3(item.link) ?? "",

          originFileObj: new File(
            [],
            "".concat(item.mediaType ?? "image/jpeg")
          ) as RcFile,

          type: item.mediaType ?? "image/jpeg",
          isNew: false,
        };

        galleryFiles.push(file);
      });

      setFileList(galleryFiles);
      setCoverFile(coverFile);

      form.setFieldsValue({
        title: editGalleryData.title,
      });
    }
  }, [isEdit]);

  const [fileList, setFileList] = useState<_UploadFile[]>([]);
  const [sortSeq, setSortSeq] = useState<string[]>([]);
  const [coverFile, setCoverFile] = useState<UploadFile>();

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const { authID, email, data: userData } = useUserData();

  const [error, setError] = useState("");

  const onFinish = async (values: any) => {
    try {
      if (coverFile === undefined) {
        setError("Please upload a cover image");
        return;
      }

      setIsLoading(true);

      const files = fileList.map((file) => file.originFileObj);

      const galleryUniqId = uuidv4();

      const key = `GALLERY_FILES/${selectedOrgId}/${projectId}/${galleryUniqId}`;

      const cover = coverFile?.originFileObj;
      const coverKey = `GALLERY_COVER/${selectedOrgId}/${projectId}/${galleryUniqId}`;
      let coverLink = "";

      if (cover) {
        const coverUrl = await onUpload(cover, coverKey, {
          progressCallback({ progress }) {
            setProgress(Number(progress.toFixed(0)));
          },
        });
        if (coverUrl) {
          coverLink = coverUrl;
        }
      }

      const galleryArray: _GalleryItem[] = [];

      const totalFileSize = fileList.reduce(
        (acc, file) => acc + (file.size ?? 0),
        0
      );
      const fileSize = convertToMB(totalFileSize);

      if (remainingSize !== null && fileSize > remainingSize) {
        setError(
          `File size is greater than bucket remaining size. File size: ${fileSize.toFixed(
            2
          )} mb, Remaining size: ${remainingSize} mb. ${
            fileList.length === 1 ? "Remove some files and try again." : ""
          }`
        );
        return;
      }

      const noLinksFiles: string[] = [];

      for (const file of files) {
        if (!file) return;
        const url = await onUpload(file, `${key}/${uuidv4()}`, {
          progressCallback({ progress }) {
            setProgress(Number(progress.toFixed(0)));
          },
          onError(error) {
            console.log(error);
          },
        });

        if (!url) {
          noLinksFiles.push(file.name);
          continue;
        }

        const input: _GalleryItem = {
          id: uuidv4(),
          link: url,

          mediaType: file.type,
          note: file.name,
        };

        galleryArray.push(input);
      }

      if (noLinksFiles.length > 0) {
        messageApi.error(`Error uploading ${noLinksFiles.join(", ")}`);
        return;
      }

      const input: CreateGalleryInput = {
        id: galleryUniqId,
        organizationID:
          selectedOrgId === null ? userData?.organizationID : selectedOrgId,
        projectId,
        coverImage: coverLink,
        userID: authID!,
        email: email!,
        title: values.title,
        sortSeq,
        gallery: galleryArray,
      };

      const response: any = await API.graphql(
        graphqlOperation(createGallery, {
          input,
        })
      );

      if (response.data.createGallery) {
        messageApi.success("Gallery created successfully");
        setFileList([]);
        setCoverFile(undefined);
        form.resetFields();
        setOpen(false);
        refetch();
        onSuccess(galleryUniqId);
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Error saving file");
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishUpdate = async (values: any) => {
    try {
      if (coverFile === undefined) {
        setError("Please upload a cover image");
        return;
      }
      setIsLoading(true);

      messageApi.loading("Uploading...");
      let link = editGalleryData.coverImage;
      const galleryArray: _GalleryItem[] = [];

      //   find the deleted files
      //   delete them from s3
      //   delete them from the database
      const deletedFiles = editGalleryData.gallery?.filter((item) => {
        if (item === null) return false;
        return !fileList.some((file) => file.uid === item.id);
      });

      if (isMediaUpdated) {
        console.log("media updated");

        // delete the old gallery images
        // check which one is new and which one is old
        // upload the new ones
        // update the link

        // only new files
        const newFiles = fileList
          .filter((file) => file.isNew)
          .map((file) => file.originFileObj);

        // upload the new files and get the links
        const coverKey = `GALLERY_FILES/${selectedOrgId}/${projectId}/${editGalleryData.id}`;

        const noLinksFiles: string[] = [];
        for (const file of newFiles) {
          if (!file) return;
          const url = await onUpload(file, `${coverKey}/${uuidv4()}`);
          if (!url) {
            noLinksFiles.push(file.name);
            continue;
          }
          const input: _GalleryItem = {
            id: uuidv4(),
            link: url,
            mediaType: file.type,
            note: file.name,
          };
          galleryArray.push(input);
        }

        if (noLinksFiles.length > 0) {
          messageApi.error(`Error uploading ${noLinksFiles.join(", ")}`);
          return;
        }
      }

      if (isCoverUpdated) {
        console.log("cover updated");

        await deleteImageFromS3(link);

        const cover = coverFile?.originFileObj;
        const coverKey = `GALLERY_COVER/${selectedOrgId}/${projectId}/${editGalleryData.id}`;

        if (cover) {
          const coverUrl = await onUpload(cover, coverKey);
          if (coverUrl) {
            link = coverUrl;
          }
        }
      }

      const updatedGallery = filter(
        map([...galleryArray, ...(editGalleryData.gallery ?? [])], (item) =>
          omit(item, ["__typename"])
        ),
        // remove the deleted files
        (item: any) =>
          item.id !== deletedFiles?.find((file) => file?.id === item.id)?.id
      );

      const input: UpdateGalleryInput = {
        id: editGalleryData.id,
        coverImage: link,
        title: values.title,
        userID: authID!,
        email: email!,
        sortSeq,
        // @ts-ignore
        gallery: updatedGallery,
      };

      const noLinksFiles: string[] = [];

      for (const file of updatedGallery ?? []) {
        if (!file) return;
        if (!file.link && file.note) {
          noLinksFiles.push(file.note);
          continue;
        }
      }
      if (noLinksFiles.length > 0) {
        noLinksFiles.forEach((note) => {
          messageApi.error(`Error uploading: ${note}`);
        });
        return;
      }

      const response: any = await API.graphql(
        graphqlOperation(updateGallery, {
          input,
        })
      );

      if (response.data.updateGallery) {
        messageApi.success("File uploaded successfully");
        setFileList([]);
        setCoverFile(undefined);
        form.resetFields();
        setOpen(false);
        refetch();
        onSuccess(editGalleryData.id);
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Error saving file");
    } finally {
      setIsMediaUpdated(false);
      setIsCoverUpdated(false);
      setIsLoading(false);
    }
  };

  const handleGalleryChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    // check if the file is new or not
    // if new then set the isNew flag to true
    // else set it to false
    const newFiles: _UploadFile[] = [];
    newFileList.forEach((file) => {
      if (fileList.some((item) => item.uid === file.uid)) {
        newFiles.push({
          ...file,
          isNew: false,
        });
      } else {
        newFiles.push({
          ...file,
          isNew: true,
        });
      }
    });
    setIsMediaUpdated(true);

    setFileList(newFiles as _UploadFile[]);
  };

  const handleCoverChange: UploadProps["onChange"] = ({ fileList }) => {
    setCoverFile(fileList[0]);
    setIsCoverUpdated(true);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const commonPropsObject: UploadProps = {
    listType: "picture-card",
    accept: imgAccept.concat(vidExtended),

    beforeUpload: () => false,
    onPreview: handlePreview,
  };

  const coverPropsObject: UploadProps = {
    ...commonPropsObject,
    maxCount: 1,
    onChange: handleCoverChange,
    accept: ".png, .jpg, .jpeg",
    multiple: false,
    fileList: coverFile ? [coverFile] : [],
  };

  const galleryPropsObject: UploadProps = {
    ...commonPropsObject,
    maxCount: 50,
    onChange: handleGalleryChange,
    multiple: true,
    fileList: fileList,
  };

  return (
    <Modal
      title="Upload Files"
      open={open}
      okText="Submit"
      okButtonProps={{
        loading: isLoading,
      }}
      width={800}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <div>
        <PreviewImage
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          previewImage={previewImage}
          handleCancel={() => {
            setPreviewOpen(false);
          }}
        />

        {contextHolder}

        <Form
          form={form}
          initialValues={{
            ...(editGalleryData ?? {}),
          }}
          onFinish={isEdit ? onFinishUpdate : onFinish}
        >
          <Form.Item
            name="title"
            label="Title"
            required
            rules={[
              {
                required: true,
                message: "Please input your title!",
              },
              {
                min: 6,
                message: "Title must be at least 6 characters long!",
              },
            ]}
          >
            <Input placeholder="Add gallery title" />
          </Form.Item>

          <Form.Item required name="files" label="Cover image">
            <Dragger
              className={`cover-upload ${!isEmpty(coverFile) ? "hidden" : ""}`}
              {...coverPropsObject}
            >
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Cover Image</p>
                <p className="ant-upload-hint">
                  Click or drag file to this area to upload
                </p>
              </>
            </Dragger>
          </Form.Item>

          {progress !== null && <Progress percent={progress} />}

          <Form.Item name="gallery">
            {error && (
              <Alert
                style={{ marginBottom: 16 }}
                type="error"
                message={error}
              />
            )}
            <Card
              className="card no-shadow"
              title="Gallery Content"
              extra={
                <Typography.Text type="secondary">
                  Drag and drop to reorder
                </Typography.Text>
              }
            >
              <SortableUploadList
                fileList={fileList}
                //   @ts-ignore
                setFileList={setFileList}
                onDragEndSuccess={(updated) => {
                  setFileList(updated);
                  const newSeq: string[] = updated.map(
                    (item: { uid: UniqueIdentifier }) => item.uid
                  );
                  setSortSeq(newSeq);
                }}
                // also keep drag and drop for video

                {...galleryPropsObject}
              >
                <div
                  id="upload-btn"
                  onClick={() => {
                    setIsMediaUpdated(true);
                  }}
                >
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </SortableUploadList>
            </Card>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default GalleryModal;
