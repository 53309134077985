import {
  AssetType,
  Assets,
  CreateAssetsInput,
  SubAssetType,
  UpdateAssetsInput,
} from "@/API";
import { createAssets, updateAssets } from "@/graphql/mutations";
import { listAssets } from "@/graphql/queries";
import useUserData from "@/hooks/useData";
import {
  deleteImageFromS3,
  getImageFromS3,
  jsonFileCreator,
  uploadFileToS3,
} from "@/utils";
import { Button, Tooltip, message } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";

const fetchAsset = async (key: string) => {
  const response: any = await API.graphql(
    graphqlOperation(listAssets, {
      limit: 1000,
      filter: {
        id: {
          eq: key,
        },
      },
    })
  );
  const items = response.data.listAssets.items as Assets[];

  if (items.length > 0) {
    return items[0];
  }
  return null;
};

export const uploadJsonFileToS3 = async (
  key: string,
  data: any,
  type: string,
  requiredInfo: {
    authID: string;
    email: string;
    projectId: string;
    organizationID?: string;
  }
) => {
  const { authID, email, projectId, organizationID } = requiredInfo;
  try {
    const jsonFile = jsonFileCreator(data, type);
    console.log("🚀 ~ data:", data);

    const asset = await fetchAsset(key);

    if (asset !== null) {
      await deleteImageFromS3(asset.link);
    }

    const response = await uploadFileToS3(jsonFile, key, "application/json");

    if (response === "") {
      console.error("Something went wrong");

      return;
    }

    if (response.key) {
      if (asset === null) {
        const input: CreateAssetsInput = {
          type: AssetType.DOCUMENTS,
          subType: SubAssetType.API,
          id: key,
          userID: authID!,
          email: email!,
          projectID: projectId,
          organizationID: organizationID,
          note: type,
          link: response.key,
          mediaType: "application/json",
          onlyAdmin: true,
        };
        await API.graphql(
          graphqlOperation(createAssets, {
            input,
          })
        );
      } else {
        const input: UpdateAssetsInput = {
          id: asset.id,
          link: response.key,
        };
        await API.graphql(
          graphqlOperation(updateAssets, {
            input,
          })
        );
      }

      // copy key to clipboard
      navigator.clipboard.writeText(response.key);

      const apiUrl = getImageFromS3(response.key);
      console.log("API URL: ", apiUrl);
      return apiUrl;
    }
  } catch (error) {
    console.error(error);
  }
};

const UploadJsonToS3 = ({
  data,
  projectId,
  organizationID,
  type,
  hideButton,
  onUploadSuccess,
  shouldUpload,
}: {
  type: "storeInventory" | "styleGuide" | "staffProfiles" | "popup";
  data: any;
  hideButton?: boolean;
  projectId?: string;
  organizationID: string;
  onUploadSuccess?: (apiUrl: string) => void;
  shouldUpload?: boolean;
}) => {
  const isStore = type === "storeInventory";
  const isStaff = type === "staffProfiles";
  const isPopup = type === "popup";

  const key = `PROJECT_DATA/${organizationID}/${projectId}/${
    isPopup
      ? "POPUP"
      : isStore
      ? "STORE_INVENTORY"
      : isStaff
      ? "STAFF_PROFILES"
      : "STYLE_GUIDE"
  }`;
  const { isAdmin, authID, email } = useUserData();
  const [messageApi, contextHolder] = message.useMessage();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const upload = async () => {
    await uploadJsonFileToS3(key, data, type, {
      authID: authID!,
      email: email!,
      projectId: projectId!,
      organizationID: organizationID,
    });
  };

  useEffect(() => {
    if (shouldUpload) {
      if (isUploaded || isUploading) return;
      upload();
    }
  }, [shouldUpload]);

  return (
    <>
      {contextHolder}
      {isAdmin && !hideButton && (
        <Tooltip
          title={`This will upload the ${
            isPopup
              ? "popup"
              : isStore
              ? "store inventory"
              : isStaff
              ? "staff profiles"
              : "style guide"
          } data to S3 in json format.`}
        >
          <Button
            type="dashed"
            disabled={isUploading || isUploaded}
            onClick={upload}
          >
            {isUploading
              ? "Uploading..."
              : isUploaded
              ? "Uploaded"
              : `Upload ${
                  isPopup
                    ? "Popup"
                    : isStore
                    ? "Store Inventory"
                    : isStaff
                    ? "Staff Profiles"
                    : "Style Guide"
                }`}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default UploadJsonToS3;
