import {
  Backlog,
  BacklogStatus,
  CreateBacklogInput,
  CreateSubTaskModelInput,
  Organization,
  SprintType,
  SubTaskModel,
  UpdateBacklogInput,
  UpdateSubTaskModelInput,
} from "@/API";
import DeleteSubtaskPopup from "@/components/modules/delete-subtask-popup";
import DeleteTaskPopup from "@/components/modules/delete-task-popup";
import EditSubtaskModule from "@/components/modules/edit-subtask-module";
import EditTaskModule from "@/components/modules/edit-task-module";
import SprintsSelectorModule from "@/components/modules/sprints-selector-module";
import {
  createBacklog,
  createSubTaskModel,
  deleteBacklog,
  deleteSubTaskModel,
  updateBacklog,
  updateSubTaskModel,
} from "@/customGraphql/customMutations";
import {
  listBacklogs,
  listOrgNamesWithProjects,
  listSubTaskModels,
} from "@/customGraphql/customQueries";

import useAuth from "@/hooks/useAuth";
import { deleteImageFromS3 } from "@/utils";
import { API, graphqlOperation } from "aws-amplify";
import { orderBy } from "lodash";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useQuery, UseQueryResult } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";

type LabelValue = {
  label: string;
  value: string;
};

type EditSubtaskModuleType = {
  backlog: Backlog | null;
  subTask: SubTaskModel | null;
  show: boolean;
  callback?: (subTask: SubTaskModel | null) => void;
};
type EditTaskModuleType = {
  backlog: Backlog | null;
  show: boolean;
  callback?: (subTask: SubTaskModel | null) => void;
};

type Actions = {
  addTask: (task: CreateBacklogInput) => Promise<Backlog | null>;
  updateTask: (task: UpdateBacklogInput) => Promise<Backlog | null>;
  deleteTask: (id: string) => Promise<void>;

  updatePriority: (subTask: SubTaskModel, priority: number) => void;

  changeStatus: (status: Status) => void;
  changeProject: (projectId: string) => void;
  changeOrganization: (organizationId: string) => void;
  getOrganizationsList: () => LabelValue[];
  subTaskActions: {
    handleCheck: (id: string, value: boolean) => void;
    handleDelete: (id: string) => void;
    moveToSprint: (id: string, backlogID: string) => void;
    moveToBacklog: (id: string) => void;
    selectImage: (id: string, image: string) => void;
    createSubTask: (subTask: CreateSubTaskModelInput) => void;
    editSubTask: (subTask: UpdateSubTaskModelInput) => void;
  };

  backlogQuery: UseQueryResult<SubTaskModel[], unknown>;
  taskQuery: UseQueryResult<Backlog[], unknown>;
  modules: {
    deleteTaskPopup: string | null;
    setDeleteTaskPopup: React.Dispatch<React.SetStateAction<string | null>>;
    deleteSubtaskPopup: string | null;
    setDeleteSubtaskPopup: React.Dispatch<React.SetStateAction<string | null>>;
    editTaskModule: EditTaskModuleType;
    setEditTaskModule: React.Dispatch<React.SetStateAction<EditTaskModuleType>>;
    editSubtaskModule: EditSubtaskModuleType;
    setEditSubtaskModule: React.Dispatch<
      React.SetStateAction<EditSubtaskModuleType>
    >;
    sprintsModule: string | null;
    setSprintsModule: React.Dispatch<React.SetStateAction<string | null>>;
  };
};

type Status = "backlog" | "inprogress" | "pending" | "completed";

type State = {
  tasks: Backlog[];
  status: Status;
  projectId: string;
  backlog: SubTaskModel[];
  organizationId: string;
  projects: LabelValue[];
};

type TaskContextValue = State & Actions;

// Create the context
export const TaskContext = createContext<TaskContextValue | undefined>(
  undefined
);

// Create the provider component
export const TaskProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tasks, setTasks] = useState<Backlog[]>([]);
  const [backlog, setBacklog] = useState<SubTaskModel[]>([]);
  const [originalData, setOriginalData] = useState<Backlog[]>([]);

  const searchParams = new URLSearchParams(window.location.search);

  const [status, setStatus] = useState<Status>(
    (searchParams.get("status") as Status) ?? "backlog"
  );

  const [projectId, setProjectId] = useState<string>("");
  const [organizationId, setOrganizationId] = useState<string>("all");
  const [projects, setProjects] = useState<LabelValue[]>([]);

  const { authID, email: ownerEmail } = useAuth();

  const [deleteTaskPopup, setDeleteTaskPopup] = useState<string | null>(null);
  const [sprintsModule, setSprintsModule] = useState<string | null>(null);
  const [deleteSubtaskPopup, setDeleteSubtaskPopup] = useState<string | null>(
    null
  );
  const [editTaskModule, setEditTaskModule] = useState<EditTaskModuleType>({
    backlog: null,
    show: false,
    callback: () => {},
  });
  const [editSubtaskModule, setEditSubtaskModule] =
    useState<EditSubtaskModuleType>({
      backlog: null,
      subTask: null,
      show: false,
    });

  const { data: organisations } = useQuery(
    `organisations-with-projects-${authID}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(listOrgNamesWithProjects, {
          limit: 1000,
        })
      );
      const items = response.data.listOrganizations.items as Organization[];
      return items;
    }
  );

  const taskQuery = useQuery(
    [`tasks-${authID}`],
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(listBacklogs, {
          limit: 1000,
          // filter: {
          //   status: {
          //     eq:
          //       status === "pending"
          //         ? BacklogStatus.TODO
          //         : status === "inprogress"
          //         ? BacklogStatus.IN_PROGRESS
          //         : BacklogStatus.DONE,
          //   },
          // },
        })
      );
      const items = response.data.listBacklogs.items as Backlog[];
      return items;
    },
    {
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setTasks(data);
        setOriginalData(data);
      },
    }
  );

  const getOrganizationsList = useCallback(() => {
    return orderBy(
      organisations?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      }) ?? [],
      ["label"]
    );
  }, [organisations]);

  const addTask = async (task: CreateBacklogInput) => {
    editTaskModule.callback?.(null);
    setEditTaskModule({
      backlog: null,
      show: false,
      callback: () => {},
    });

    setTasks((prev) => [task, ...prev] as Backlog[]);

    try {
      const response: any = await API.graphql(
        graphqlOperation(createBacklog, {
          input: task,
        })
      );
      return response.data.createBacklog as Backlog;
    } catch (error) {
      console.error("Error creating task:", error);
      return null;
    }
  };

  const updateTask = async (task: UpdateBacklogInput) => {
    editTaskModule.callback?.(null);
    setEditTaskModule({
      backlog: null,
      show: false,
      callback: () => {},
    });
    setTasks(
      (prev) =>
        prev.map((d) => {
          if (d.id === task.id) {
            return {
              ...d,
              ...task,
            };
          }
          return d;
        }) as Backlog[]
    );

    try {
      const response: any = await API.graphql(
        graphqlOperation(updateBacklog, {
          input: task,
        })
      );
      return response.data.updateBacklog as Backlog;
    } catch (error) {
      console.error("Error updating task:", error);
      return null;
    }
  };

  const deleteTask = async (taskId: string) => {
    const task = tasks.find((d) => d.id === taskId);
    setTasks((prev) => prev.filter((d) => d.id !== taskId));
    const media = task?.media ?? [];

    media &&
      media.length > 0 &&
      media.forEach(async (item) => {
        deleteImageFromS3(item?.mediaLink!);
      });

    try {
      const response: any = await API.graphql(
        graphqlOperation(deleteBacklog, {
          input: {
            id: taskId,
          },
        })
      );
      return response.data.deleteBacklog;
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const backlogQuery = useQuery(
    `backlog-${authID}`,
    async () => {
      const filter = {
        sprintType: {
          eq: SprintType.BACKLOG,
        },
      };

      const response: any = await API.graphql(
        graphqlOperation(listSubTaskModels, {
          limit: 2000,
          filter,
        })
      );
      const items = response.data.listSubTaskModels.items as SubTaskModel[];

      return items;
    },
    {
      enabled: status === "backlog",
      onSuccess(data) {
        const sorted = orderBy(data, ["updatedAt"]) as SubTaskModel[];
        setBacklog(sorted);
      },
    }
  );

  const everythingLoaded = !taskQuery.isLoading && taskQuery.isFetched;

  const addBacklog = (subTask: CreateSubTaskModelInput) => {
    // Implementation here
  };

  const updatePriority = (subTask: SubTaskModel, priority: number) => {
    // Implementation here
  };

  const navigate = useNavigate();

  const setupProjects = (organizationId: string) => {
    const projects = organisations?.find(
      (org) => org.id === organizationId
    )?.projects;
    const projectList = projects?.items?.map((d) => {
      return {
        label: d?.name,
        value: d?.id,
      };
    });

    const sorted = orderBy(projectList, ["label"]) as LabelValue[];
    return sorted;
  };

  const handleURLQuery = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const organizationId = searchParams.get("organizationId");

    const projectId = searchParams.get("projectId");
    const status = searchParams.get("status");

    if (organizationId) {
      setOrganizationId(organizationId);
    }

    if (status) {
      setStatus(status as Status);
    }

    if (projectId) {
      const sorted = setupProjects(organizationId ?? "all");
      setProjects(sorted);
      changeProject(projectId, status as Status);
    }
  };

  useEffect(() => {
    if (everythingLoaded) {
      handleURLQuery();
    }
  }, [everythingLoaded]);

  const updateQuery = (key: string, value: string) => {
    const newObj = {
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
      [key]: value,
    };

    const query = createSearchParams({ ...newObj });

    navigate({
      pathname: "/sprints",
      search: `?${query}`,
    });
  };

  const changeStatus = (status: Status) => {
    setStatus(status);
    updateQuery("status", status);
    let filteredTasks: Backlog[] = [];

    if (status === "pending") {
      filteredTasks = originalData.filter(
        (task) => task.status === BacklogStatus.TODO
      );
    } else if (status === "inprogress") {
      filteredTasks = originalData.filter(
        (task) => task.status === BacklogStatus.IN_PROGRESS
      );
    } else if (status === "completed") {
      filteredTasks = originalData.filter(
        (task) => task.status === BacklogStatus.DONE
      );
    } else {
      filteredTasks = originalData;
    }

    setTasks(filteredTasks);
  };

  const changeProject = (projectId: string, queryStatus?: Status) => {
    setProjectId(projectId);

    updateQuery("projectId", projectId);
    const _status = queryStatus ?? status;
    const backlogStatus =
      _status === "pending"
        ? BacklogStatus.TODO
        : _status === "completed"
        ? BacklogStatus.DONE
        : BacklogStatus.IN_PROGRESS;

    setTasks(originalData.filter((task) => task.status === backlogStatus));
  };

  const changeOrganization = (organizationId: string) => {
    setOrganizationId(organizationId);

    updateQuery("organizationId", organizationId);

    const sorted = setupProjects(organizationId);

    setProjects(sorted);
    const firstProjectId = sorted[0]?.value ?? "";
    if (firstProjectId) {
      changeProject(firstProjectId);
    } else {
      setProjectId("");
    }
  };

  const subTaskActions = {
    handleCheck: async (id: string, value: boolean) => {
      try {
        const response: any = await API.graphql(
          graphqlOperation(updateSubTaskModel, {
            input: {
              id,
              isCompleted: value,
              completedOn: value ? new Date().toISOString() : null,
              completedByEmail: value ? ownerEmail : null,
            },
          })
        );
        return response.data.updateSubTaskModel;
      } catch (error) {
        console.error("Error updating subtask:", error);
      }
    },

    handleDelete: async (id: string) => {
      try {
        setBacklog((prev) => prev.filter((d) => d.id !== id));

        const response: any = await API.graphql(
          graphqlOperation(deleteSubTaskModel, {
            input: {
              id,
            },
          })
        );
        return response.data.deleteSubTaskModel;
      } catch (error) {
        console.error("Error deleting subtask:", error);
      }
    },

    moveToSprint: async (id: string, backlogID: string) => {
      try {
        setSprintsModule(null);

        setBacklog((prev) => prev.filter((d) => d.id !== id));

        const response: any = await API.graphql(
          graphqlOperation(updateSubTaskModel, {
            input: {
              id,
              backlogID,
              sprintType: SprintType.ACTIVE,
            },
          })
        );
        return response.data.updateSubTaskModel;
      } catch (error) {
        console.error("Error moving subtask to sprint:", error);
      } finally {
      }
    },

    moveToBacklog: async (id: string) => {
      try {
        const response: any = await API.graphql(
          graphqlOperation(updateSubTaskModel, {
            input: {
              id,
              sprintType: SprintType.BACKLOG,
              backlogID: null,
            },
          })
        );
        return response.data.updateSubTaskModel;
      } catch (error) {
        console.error("Error moving subtask to backlog:", error);
      }
    },

    selectImage: async (id: string, image: string) => {},

    createSubTask: async (subTask: CreateSubTaskModelInput) => {
      try {
        setBacklog((prev) => [...prev, subTask] as SubTaskModel[]);

        editSubtaskModule.callback &&
          editSubtaskModule.callback(subTask as SubTaskModel);

        const response: any = await API.graphql(
          graphqlOperation(createSubTaskModel, {
            input: subTask,
          })
        );
        return response.data.createSubTaskModel;
      } catch (error) {
        console.error("Error creating subtask:", error);
      }
    },

    editSubTask: async (subTask: UpdateSubTaskModelInput) => {
      try {
        setBacklog(
          (prev) =>
            prev?.map((d) => {
              if (d.id === subTask.id) {
                return {
                  ...d,
                  ...subTask,
                };
              }
              return d;
            }) as SubTaskModel[]
        );
        editSubtaskModule.callback &&
          editSubtaskModule.callback(subTask as SubTaskModel);

        const response: any = await API.graphql(
          graphqlOperation(updateSubTaskModel, {
            input: subTask,
          })
        );
        return response.data.updateSubTaskModel;
      } catch (error) {
        console.error("Error updating subtask:", error);
      }
    },
  };

  const modules = {
    deleteTaskPopup,
    setDeleteTaskPopup,
    deleteSubtaskPopup,
    setDeleteSubtaskPopup,
    editTaskModule,
    setEditTaskModule,
    editSubtaskModule,
    setEditSubtaskModule,
    sprintsModule,
    setSprintsModule,
  };

  const value: TaskContextValue = {
    tasks,
    status,
    projectId,
    organizationId,
    addTask,
    updateTask,

    deleteTask,
    subTaskActions,
    backlog,

    projects,
    getOrganizationsList,
    updatePriority,

    changeStatus,

    changeProject,
    changeOrganization,
    taskQuery,
    backlogQuery,
    modules,
  };

  return (
    <TaskContext.Provider value={value}>
      {deleteTaskPopup && <DeleteTaskPopup />}
      {deleteSubtaskPopup && <DeleteSubtaskPopup />}
      {editTaskModule.show && <EditTaskModule />}
      {editSubtaskModule.show && <EditSubtaskModule />}
      {sprintsModule && <SprintsSelectorModule />}
      {children}
    </TaskContext.Provider>
  );
};

// custom hook
export const useTaskContext = () => {
  const context = React.useContext(TaskContext);
  if (context === undefined) {
    throw new Error("useTaskContext must be used within a TaskProvider");
  }
  return context;
};
