import "@/index.css";
import App from "@components/App";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ZoiqThemeProvider } from "zoiq-module";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

const API_KEY = "0af93b60-5b7f-4946-a7c5-94874d1d95f8";
// force push

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <ZoiqThemeProvider API_KEY={API_KEY}>
      <App />
    </ZoiqThemeProvider>
  </QueryClientProvider>
);
