/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOrgMeeting = /* GraphQL */ `query GetOrgMeeting($id: ID!) {
  getOrgMeeting(id: $id) {
    id
    organizationID
    name
    description
    startTime
    endTime
    attendees
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrgMeetingQueryVariables,
  APITypes.GetOrgMeetingQuery
>;
export const listOrgMeetings = /* GraphQL */ `query ListOrgMeetings(
  $filter: ModelOrgMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrgMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationID
      name
      description
      startTime
      endTime
      attendees
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrgMeetingsQueryVariables,
  APITypes.ListOrgMeetingsQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    authID
    email
    avatar
    user {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    name
    type
    address1
    address2
    city
    state
    country
    timezone {
      country
      timezone
      __typename
    }
    zipCode
    url
    maxBucketSize
    staffSortSeq
    storeInventorySortSeq
    partnersLogoSortSeq
    mediaFilesSortSeq
    gallerySortSeq
    allowedFileTabs
    orgSize
    industry
    mission
    software
    paper
    printer
    comments {
      comment
      date
      __typename
    }
    socialMedia {
      type
      link
      __typename
    }
    contact {
      name
      email
      phone
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getAssets = /* GraphQL */ `query GetAssets($id: ID!) {
  getAssets(id: $id) {
    id
    userID
    projectID
    organizationID
    email
    size
    profile {
      id
      name
      title
      bio
      email
      phone
      country
      department
      LI
      Twitter
      YouTube
      IG
      status
      __typename
    }
    mediaType
    onlyAdmin
    link
    multipleLinks
    type
    subType
    note
    isAssigned
    galleryId
    assignedDate
    storeID
    storeImagesOrder
    multipleProjects
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    lastUpdatedBy
    lastUpdatedByEmail
    lastUpdatedOn
    isUpdated
    lastUpdatedByUser {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    isRemoved
    assignedToInfo {
      pageID
      sectionID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAssetsQueryVariables, APITypes.GetAssetsQuery>;
export const listAssets = /* GraphQL */ `query ListAssets(
  $filter: ModelAssetsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      projectID
      organizationID
      email
      size
      mediaType
      onlyAdmin
      link
      multipleLinks
      type
      subType
      note
      isAssigned
      galleryId
      assignedDate
      storeID
      storeImagesOrder
      multipleProjects
      lastUpdatedBy
      lastUpdatedByEmail
      lastUpdatedOn
      isUpdated
      isRemoved
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssetsQueryVariables,
  APITypes.ListAssetsQuery
>;
export const getSourceFile = /* GraphQL */ `query GetSourceFile($id: ID!) {
  getSourceFile(id: $id) {
    id
    userID
    organizationID
    projectId
    email
    size
    mediaType
    link
    note
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSourceFileQueryVariables,
  APITypes.GetSourceFileQuery
>;
export const listSourceFiles = /* GraphQL */ `query ListSourceFiles(
  $filter: ModelSourceFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listSourceFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      organizationID
      projectId
      email
      size
      mediaType
      link
      note
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSourceFilesQueryVariables,
  APITypes.ListSourceFilesQuery
>;
export const getGallery = /* GraphQL */ `query GetGallery($id: ID!) {
  getGallery(id: $id) {
    id
    userID
    organizationID
    projectId
    email
    coverImage
    sortSeq
    gallery {
      id
      link
      mediaType
      note
      __typename
    }
    title
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    publishDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGalleryQueryVariables,
  APITypes.GetGalleryQuery
>;
export const listGalleries = /* GraphQL */ `query ListGalleries(
  $filter: ModelGalleryFilterInput
  $limit: Int
  $nextToken: String
) {
  listGalleries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      organizationID
      projectId
      email
      coverImage
      sortSeq
      title
      publishDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGalleriesQueryVariables,
  APITypes.ListGalleriesQuery
>;
export const getUserStat = /* GraphQL */ `query GetUserStat($id: ID!) {
  getUserStat(id: $id) {
    id
    userID
    taskID
    task {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserStatQueryVariables,
  APITypes.GetUserStatQuery
>;
export const listUserStats = /* GraphQL */ `query ListUserStats(
  $filter: ModelUserStatFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      taskID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserStatsQueryVariables,
  APITypes.ListUserStatsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($authID: ID!, $email: String!) {
  getUser(authID: $authID, email: $email) {
    id
    authID
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    timezone {
      country
      timezone
      __typename
    }
    title
    email
    phone
    isAdmin
    initialEmailSentOn
    otherProjects {
      projectID
      projectRole
      __typename
    }
    overrideOrganization
    orgLevel
    lastLogin
    status
    adminAccessTo
    liveVisiblity
    isZoiq
    completedTasks
    links
    socialMedia {
      type
      link
      __typename
    }
    canAddProjects
    stats {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $authID: ID
  $email: ModelStringKeyConditionInput
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    authID: $authID
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    description
    startDate
    endDate
    milestone
    ownerID
    ownerAuthID
    ownerEmail
    owner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    status
    otherUsers {
      userID
      projectRole
      __typename
    }
    risk
    githubRepo
    awsAccount
    cognitoUrl
    dynamo {
      key
      value
      __typename
    }
    solutionType
    scope
    estUsersInFirstYear
    userAgeGroups
    lambdaFunction {
      name
      url
      __typename
    }
    liveUrl
    siteData {
      audience
      trafficCount
      trafficLocation
      tone
      languages
      pageSortSeq
      organizationSummary
      purpose
      goals
      messaging
      keyDates
      suggestedSolution
      projectNotes
      contactPerson
      isLocked
      appId
      branchName
      __typename
    }
    isZoiq
    discussion {
      nextToken
      __typename
    }
    sourceFile {
      nextToken
      __typename
    }
    gallery {
      nextToken
      __typename
    }
    projectPlan {
      nextToken
      __typename
    }
    successCriteria {
      nextToken
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    summaries {
      nextToken
      __typename
    }
    assets {
      nextToken
      __typename
    }
    store {
      nextToken
      __typename
    }
    userSortSeq
    projectPlanSortSeq
    projectMeetingsSortSeq
    projectCriteriaSortSeq
    counts {
      ORGANIZATION
      TEAM
      STOCK
      DOCUMENTS
      PARTNERS_STOCK
      STORE_INVENTORY
      VECTORS
      GALLERY
      SOURCE_FILE
      __typename
    }
    closingMatrix {
      projectFunds
      relationshipWithLead
      projectRelation
      opportunityRelated
      additionalBuildRequirements
      estNewFeatures
      industryExpertOnTheirSide
      technicalExpertOnTheirSide
      perceivedReadiness
      notes
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const getProjectPlan = /* GraphQL */ `query GetProjectPlan($id: ID!) {
  getProjectPlan(id: $id) {
    id
    projectID
    milestone
    startDate
    endDate
    status
    risk
    fakeId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectPlanQueryVariables,
  APITypes.GetProjectPlanQuery
>;
export const listProjectPlans = /* GraphQL */ `query ListProjectPlans(
  $filter: ModelProjectPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjectPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectPlansQueryVariables,
  APITypes.ListProjectPlansQuery
>;
export const getDiscussion = /* GraphQL */ `query GetDiscussion($id: ID!) {
  getDiscussion(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    date
    actionItemsSortSeq
    actions {
      nextToken
      __typename
    }
    emailSent
    emailSentDate
    meetingID
    meeting {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscussionQueryVariables,
  APITypes.GetDiscussionQuery
>;
export const listDiscussions = /* GraphQL */ `query ListDiscussions(
  $filter: ModelDiscussionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscussions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      name
      date
      actionItemsSortSeq
      emailSent
      emailSentDate
      meetingID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscussionsQueryVariables,
  APITypes.ListDiscussionsQuery
>;
export const getStyleGuide = /* GraphQL */ `query GetStyleGuide($id: ID!) {
  getStyleGuide(id: $id) {
    id
    name
    type
    value
    organizationID
    projectID
    extraInfo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStyleGuideQueryVariables,
  APITypes.GetStyleGuideQuery
>;
export const listStyleGuides = /* GraphQL */ `query ListStyleGuides(
  $filter: ModelStyleGuideFilterInput
  $limit: Int
  $nextToken: String
) {
  listStyleGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      value
      organizationID
      projectID
      extraInfo
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStyleGuidesQueryVariables,
  APITypes.ListStyleGuidesQuery
>;
export const getActionItems = /* GraphQL */ `query GetActionItems($id: ID!) {
  getActionItems(id: $id) {
    id
    discussionID
    actionItem
    owners
    isCompleted
    targetDate
    dateAssigned
    emailSent
    dateCompleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActionItemsQueryVariables,
  APITypes.GetActionItemsQuery
>;
export const listActionItems = /* GraphQL */ `query ListActionItems(
  $filter: ModelActionItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      discussionID
      actionItem
      owners
      isCompleted
      targetDate
      dateAssigned
      emailSent
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActionItemsQueryVariables,
  APITypes.ListActionItemsQuery
>;
export const getSummary = /* GraphQL */ `query GetSummary($id: ID!) {
  getSummary(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    summary
    summaryDate
    summaryOwnerID
    summaryOwnerEmail
    summaryOwner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    summaryType
    discussionDate
    discussedWithID
    discussedWithEmail
    discussedWith {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSummaryQueryVariables,
  APITypes.GetSummaryQuery
>;
export const listSummaries = /* GraphQL */ `query ListSummaries(
  $filter: ModelSummaryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      summary
      summaryDate
      summaryOwnerID
      summaryOwnerEmail
      summaryType
      discussionDate
      discussedWithID
      discussedWithEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSummariesQueryVariables,
  APITypes.ListSummariesQuery
>;
export const getMeetings = /* GraphQL */ `query GetMeetings($id: ID!) {
  getMeetings(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    firstMeetingDate
    lastMeetingDate
    startTime
    endTime
    rolls {
      Date
      Day
      __typename
    }
    frequency
    link
    attendees
    mailSentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingsQueryVariables,
  APITypes.GetMeetingsQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $filter: ModelMeetingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const getSuccessCriteria = /* GraphQL */ `query GetSuccessCriteria($id: ID!) {
  getSuccessCriteria(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    description
    measurableCriteria
    type
    pageId
    sectionId
    page {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    pagesCriteriaId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSuccessCriteriaQueryVariables,
  APITypes.GetSuccessCriteriaQuery
>;
export const listSuccessCriteria = /* GraphQL */ `query ListSuccessCriteria(
  $filter: ModelSuccessCriteriaFilterInput
  $limit: Int
  $nextToken: String
) {
  listSuccessCriteria(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      description
      measurableCriteria
      type
      pageId
      sectionId
      createdAt
      updatedAt
      pagesCriteriaId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSuccessCriteriaQueryVariables,
  APITypes.ListSuccessCriteriaQuery
>;
export const getPages = /* GraphQL */ `query GetPages($id: ID!) {
  getPages(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    link
    pageSections {
      section
      noMedia
      id
      link
      meta
      media
      lastEmailSent
      notes
      illustration
      instructionsLevel
      __typename
    }
    criteria {
      nextToken
      __typename
    }
    successCriteriaSummary {
      mustHave
      shouldHave
      couldHave
      wontHave
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPagesQueryVariables, APITypes.GetPagesQuery>;
export const listPages = /* GraphQL */ `query ListPages(
  $filter: ModelPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPagesQueryVariables, APITypes.ListPagesQuery>;
export const getUserJourney = /* GraphQL */ `query GetUserJourney($id: ID!) {
  getUserJourney(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    userRole
    pageName
    sectionName
    access
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserJourneyQueryVariables,
  APITypes.GetUserJourneyQuery
>;
export const listUserJourneys = /* GraphQL */ `query ListUserJourneys(
  $filter: ModelUserJourneyFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      userRole
      pageName
      sectionName
      access
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserJourneysQueryVariables,
  APITypes.ListUserJourneysQuery
>;
export const getBacklogSequence = /* GraphQL */ `query GetBacklogSequence($id: ID!) {
  getBacklogSequence(id: $id) {
    id
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBacklogSequenceQueryVariables,
  APITypes.GetBacklogSequenceQuery
>;
export const listBacklogSequences = /* GraphQL */ `query ListBacklogSequences(
  $filter: ModelBacklogSequenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listBacklogSequences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sequence
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBacklogSequencesQueryVariables,
  APITypes.ListBacklogSequencesQuery
>;
export const getComments = /* GraphQL */ `query GetComments($id: ID!) {
  getComments(id: $id) {
    id
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    comment
    date
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    authID
    authEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentsQueryVariables,
  APITypes.GetCommentsQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      backlogID
      comment
      date
      authID
      authEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const getSubTaskModel = /* GraphQL */ `query GetSubTaskModel($id: ID!) {
  getSubTaskModel(id: $id) {
    id
    isCompleted
    task
    completedOn
    mediaId
    sprintType
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    statusUpdateDate
    priority
    completedByEmail
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    projectPlanID
    projectPlan {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    status
    moscow
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubTaskModelQueryVariables,
  APITypes.GetSubTaskModelQuery
>;
export const listSubTaskModels = /* GraphQL */ `query ListSubTaskModels(
  $filter: ModelSubTaskModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubTaskModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      isCompleted
      task
      completedOn
      mediaId
      sprintType
      backlogID
      statusUpdateDate
      priority
      completedByEmail
      organizationID
      projectID
      projectPlanID
      status
      moscow
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubTaskModelsQueryVariables,
  APITypes.ListSubTaskModelsQuery
>;
export const getBacklog = /* GraphQL */ `query GetBacklog($id: ID!) {
  getBacklog(id: $id) {
    id
    name
    title
    isCompleted
    dateCompleted
    createdBy
    createdByEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    media {
      id
      mediaType
      mediaLink
      __typename
    }
    status
    priority
    assignedTo
    assignedToUser {
      nextToken
      __typename
    }
    comments {
      nextToken
      __typename
    }
    targetDate
    sectionID
    pageID
    notifyAssignedMembers
    subtasks {
      nextToken
      __typename
    }
    referralLinks
    styles {
      bgStyle
      __typename
    }
    note
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBacklogQueryVariables,
  APITypes.GetBacklogQuery
>;
export const listBacklogs = /* GraphQL */ `query ListBacklogs(
  $filter: ModelBacklogFilterInput
  $limit: Int
  $nextToken: String
) {
  listBacklogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBacklogsQueryVariables,
  APITypes.ListBacklogsQuery
>;
export const getContentCreation = /* GraphQL */ `query GetContentCreation($id: ID!) {
  getContentCreation(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    content
    pageId
    sectionId
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    pageName
    sent
    sentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContentCreationQueryVariables,
  APITypes.GetContentCreationQuery
>;
export const listContentCreations = /* GraphQL */ `query ListContentCreations(
  $filter: ModelContentCreationFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentCreations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      content
      pageId
      sectionId
      authID
      authEmail
      pageName
      sent
      sentDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContentCreationsQueryVariables,
  APITypes.ListContentCreationsQuery
>;
export const getConversation = /* GraphQL */ `query GetConversation($id: ID!) {
  getConversation(id: $id) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    conversations {
      message
      isBot
      date
      id
      __typename
    }
    authID
    pageId
    sectionId
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConversationQueryVariables,
  APITypes.GetConversationQuery
>;
export const listConversations = /* GraphQL */ `query ListConversations(
  $filter: ModelConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      authID
      pageId
      sectionId
      authEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConversationsQueryVariables,
  APITypes.ListConversationsQuery
>;
export const getStore = /* GraphQL */ `query GetStore($id: ID!) {
  getStore(id: $id) {
    id
    projectID
    organizationID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    title
    description
    amount
    inStock
    addedBy
    mediaIds
    colorsAvailable
    sizesAvailable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStoreQueryVariables, APITypes.GetStoreQuery>;
export const listStores = /* GraphQL */ `query ListStores(
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      projectID
      organizationID
      title
      description
      amount
      inStock
      addedBy
      mediaIds
      colorsAvailable
      sizesAvailable
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStoresQueryVariables,
  APITypes.ListStoresQuery
>;
export const getFont = /* GraphQL */ `query GetFont($id: ID!) {
  getFont(id: $id) {
    id
    name
    link
    complimentsWithID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFontQueryVariables, APITypes.GetFontQuery>;
export const listFonts = /* GraphQL */ `query ListFonts(
  $filter: ModelFontFilterInput
  $limit: Int
  $nextToken: String
) {
  listFonts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      link
      complimentsWithID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFontsQueryVariables, APITypes.ListFontsQuery>;
export const getActivity = /* GraphQL */ `query GetActivity($id: ID!) {
  getActivity(id: $id) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    activity
    link
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityQueryVariables,
  APITypes.GetActivityQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities(
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authID
      authEmail
      activity
      link
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
export const getSolution = /* GraphQL */ `query GetSolution($id: ID!) {
  getSolution(id: $id) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    tags
    title
    description
    projectID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSolutionQueryVariables,
  APITypes.GetSolutionQuery
>;
export const listSolutions = /* GraphQL */ `query ListSolutions(
  $filter: ModelSolutionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authID
      authEmail
      tags
      title
      description
      projectID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSolutionsQueryVariables,
  APITypes.ListSolutionsQuery
>;
export const getPopups = /* GraphQL */ `query GetPopups($id: ID!) {
  getPopups(id: $id) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    title
    description
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    isActive
    expiryDate
    content {
      name
      value
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPopupsQueryVariables, APITypes.GetPopupsQuery>;
export const listPopups = /* GraphQL */ `query ListPopups(
  $filter: ModelPopupsFilterInput
  $limit: Int
  $nextToken: String
) {
  listPopups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authID
      authEmail
      title
      description
      projectID
      isActive
      expiryDate
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPopupsQueryVariables,
  APITypes.ListPopupsQuery
>;
export const orgMeetingsByOrganizationID = /* GraphQL */ `query OrgMeetingsByOrganizationID(
  $organizationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrgMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  orgMeetingsByOrganizationID(
    organizationID: $organizationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationID
      name
      description
      startTime
      endTime
      attendees
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrgMeetingsByOrganizationIDQueryVariables,
  APITypes.OrgMeetingsByOrganizationIDQuery
>;
export const assetsByProjectID = /* GraphQL */ `query AssetsByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAssetsFilterInput
  $limit: Int
  $nextToken: String
) {
  assetsByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      projectID
      organizationID
      email
      size
      mediaType
      onlyAdmin
      link
      multipleLinks
      type
      subType
      note
      isAssigned
      galleryId
      assignedDate
      storeID
      storeImagesOrder
      multipleProjects
      lastUpdatedBy
      lastUpdatedByEmail
      lastUpdatedOn
      isUpdated
      isRemoved
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssetsByProjectIDQueryVariables,
  APITypes.AssetsByProjectIDQuery
>;
export const assetsByStoreID = /* GraphQL */ `query AssetsByStoreID(
  $storeID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAssetsFilterInput
  $limit: Int
  $nextToken: String
) {
  assetsByStoreID(
    storeID: $storeID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      projectID
      organizationID
      email
      size
      mediaType
      onlyAdmin
      link
      multipleLinks
      type
      subType
      note
      isAssigned
      galleryId
      assignedDate
      storeID
      storeImagesOrder
      multipleProjects
      lastUpdatedBy
      lastUpdatedByEmail
      lastUpdatedOn
      isUpdated
      isRemoved
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssetsByStoreIDQueryVariables,
  APITypes.AssetsByStoreIDQuery
>;
export const sourceFilesByProjectId = /* GraphQL */ `query SourceFilesByProjectId(
  $projectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSourceFileFilterInput
  $limit: Int
  $nextToken: String
) {
  sourceFilesByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      organizationID
      projectId
      email
      size
      mediaType
      link
      note
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SourceFilesByProjectIdQueryVariables,
  APITypes.SourceFilesByProjectIdQuery
>;
export const galleriesByProjectId = /* GraphQL */ `query GalleriesByProjectId(
  $projectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelGalleryFilterInput
  $limit: Int
  $nextToken: String
) {
  galleriesByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      organizationID
      projectId
      email
      coverImage
      sortSeq
      title
      publishDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GalleriesByProjectIdQueryVariables,
  APITypes.GalleriesByProjectIdQuery
>;
export const userStatsByUserID = /* GraphQL */ `query UserStatsByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserStatFilterInput
  $limit: Int
  $nextToken: String
) {
  userStatsByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      taskID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserStatsByUserIDQueryVariables,
  APITypes.UserStatsByUserIDQuery
>;
export const usersByAuthID = /* GraphQL */ `query UsersByAuthID(
  $authID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByAuthID(
    authID: $authID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByAuthIDQueryVariables,
  APITypes.UsersByAuthIDQuery
>;
export const usersByOrganizationID = /* GraphQL */ `query UsersByOrganizationID(
  $organizationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  usersByOrganizationID(
    organizationID: $organizationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsersByOrganizationIDQueryVariables,
  APITypes.UsersByOrganizationIDQuery
>;
export const projectsByOrganizationID = /* GraphQL */ `query ProjectsByOrganizationID(
  $organizationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  projectsByOrganizationID(
    organizationID: $organizationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectsByOrganizationIDQueryVariables,
  APITypes.ProjectsByOrganizationIDQuery
>;
export const projectPlansByProjectID = /* GraphQL */ `query ProjectPlansByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelProjectPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  projectPlansByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ProjectPlansByProjectIDQueryVariables,
  APITypes.ProjectPlansByProjectIDQuery
>;
export const discussionsByProjectID = /* GraphQL */ `query DiscussionsByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDiscussionFilterInput
  $limit: Int
  $nextToken: String
) {
  discussionsByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      name
      date
      actionItemsSortSeq
      emailSent
      emailSentDate
      meetingID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DiscussionsByProjectIDQueryVariables,
  APITypes.DiscussionsByProjectIDQuery
>;
export const actionItemsByDiscussionID = /* GraphQL */ `query ActionItemsByDiscussionID(
  $discussionID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelActionItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  actionItemsByDiscussionID(
    discussionID: $discussionID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      discussionID
      actionItem
      owners
      isCompleted
      targetDate
      dateAssigned
      emailSent
      dateCompleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ActionItemsByDiscussionIDQueryVariables,
  APITypes.ActionItemsByDiscussionIDQuery
>;
export const summariesByProjectID = /* GraphQL */ `query SummariesByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSummaryFilterInput
  $limit: Int
  $nextToken: String
) {
  summariesByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      summary
      summaryDate
      summaryOwnerID
      summaryOwnerEmail
      summaryType
      discussionDate
      discussedWithID
      discussedWithEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SummariesByProjectIDQueryVariables,
  APITypes.SummariesByProjectIDQuery
>;
export const meetingsByProjectID = /* GraphQL */ `query MeetingsByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingsFilterInput
  $limit: Int
  $nextToken: String
) {
  meetingsByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MeetingsByProjectIDQueryVariables,
  APITypes.MeetingsByProjectIDQuery
>;
export const successCriteriaByProjectID = /* GraphQL */ `query SuccessCriteriaByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSuccessCriteriaFilterInput
  $limit: Int
  $nextToken: String
) {
  successCriteriaByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      description
      measurableCriteria
      type
      pageId
      sectionId
      createdAt
      updatedAt
      pagesCriteriaId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SuccessCriteriaByProjectIDQueryVariables,
  APITypes.SuccessCriteriaByProjectIDQuery
>;
export const pagesByProjectID = /* GraphQL */ `query PagesByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPagesFilterInput
  $limit: Int
  $nextToken: String
) {
  pagesByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PagesByProjectIDQueryVariables,
  APITypes.PagesByProjectIDQuery
>;
export const commentsByBacklogID = /* GraphQL */ `query CommentsByBacklogID(
  $backlogID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCommentsFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByBacklogID(
    backlogID: $backlogID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      backlogID
      comment
      date
      authID
      authEmail
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByBacklogIDQueryVariables,
  APITypes.CommentsByBacklogIDQuery
>;
export const subTaskModelsByBacklogID = /* GraphQL */ `query SubTaskModelsByBacklogID(
  $backlogID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSubTaskModelFilterInput
  $limit: Int
  $nextToken: String
) {
  subTaskModelsByBacklogID(
    backlogID: $backlogID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      isCompleted
      task
      completedOn
      mediaId
      sprintType
      backlogID
      statusUpdateDate
      priority
      completedByEmail
      organizationID
      projectID
      projectPlanID
      status
      moscow
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubTaskModelsByBacklogIDQueryVariables,
  APITypes.SubTaskModelsByBacklogIDQuery
>;
export const storesByProjectID = /* GraphQL */ `query StoresByProjectID(
  $projectID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelStoreFilterInput
  $limit: Int
  $nextToken: String
) {
  storesByProjectID(
    projectID: $projectID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      projectID
      organizationID
      title
      description
      amount
      inStock
      addedBy
      mediaIds
      colorsAvailable
      sizesAvailable
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StoresByProjectIDQueryVariables,
  APITypes.StoresByProjectIDQuery
>;
