import { StyleGuide } from "@/API";
import { getStyleGuideValue } from "@/utils";
import { Buffer } from "@components/sections/StyleGuide";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  theme,
} from "antd";
import { TypingText } from "../common/Title";

const ButtonsWrapper = ({
  styleGuideData,
}: {
  styleGuideData: StyleGuide[];
}) => {
  const getValue = (key: string) => getStyleGuideValue(styleGuideData, key);

  return (
    <ConfigProvider
      theme={{
        token: { fontFamily: getValue("fontFamilyPrimary") },
        components: {
          Button: {
            colorPrimary: getValue("primary"),
            colorPrimaryHover: getValue("primary"),
            colorLink: getValue("primary"),
            colorBgContainer: getValue("darkest"),
            colorBgBase: getValue("darkest"),
            colorBorder: getValue("dark"),
            colorText: getValue("white"),
          },
        },
      }}
    >
      <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
        <Button block type="primary">
          Primary
        </Button>
        <Button block type="default">
          Default
        </Button>
        <Button block type="dashed">
          Dashed
        </Button>
        <Button
          block
          style={{ maxWidth: "100%" }}
          className="styled-button"
          type="dashed"
        >
          CTA Button
        </Button>
      </Space>
    </ConfigProvider>
  );
};

const DropdownWrapper = ({
  styleGuideData,
}: {
  styleGuideData: StyleGuide[];
}) => {
  const getValue = (key: string) => getStyleGuideValue(styleGuideData, key);

  const options = [
    {
      label: "Option 1",
      value: "1",
    },
    {
      label: "Option 2",
      value: "2",
    },
    {
      label: "Option 3",
      value: "3",
    },
    {
      label: "Option 4",
      value: "4",
    },
    {
      label: "Option 5",
      value: "5",
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: getValue("primary"),
          colorBgContainer: getValue("darkest"),
          fontFamily: getValue("fontFamilyPrimary"),
        },
        components: {
          Select: {
            colorPrimary: getValue("primary"),
            colorBgBase: getValue("darkest"),
          },
          Input: {
            colorBgContainer: getValue("darkest"),

            colorBorder: getValue("dark"),
            colorTextPlaceholder: getValue("light"),
            colorText: getValue("white"),
            fontSize: 20,
            fontFamily: getValue("fontFamilyPrimary"),
            controlOutline: getValue("primary"),
          },
        },
      }}
    >
      <Space style={{ width: "100%" }} size={"large"} direction="vertical">
        <Select
          placeholder="Select an option"
          style={{
            width: "100%",
          }}
          options={options}
        />

        <InputNumber
          type="number"
          addonBefore="$"
          min={25}
          style={{
            width: "100%",
          }}
          step={50}
          max={10000}
          placeholder="number input"
        />
      </Space>
    </ConfigProvider>
  );
};

const Elements = ({ styleGuideData }: { styleGuideData: StyleGuide[] }) => {
  const { token } = theme.useToken();
  const borderStyle = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: token.colorBorder,
  };

  const rightBorderStyle = {
    ...borderStyle,
    borderLeftWidth: "0px",
    borderTopWidth: "0px",
    borderBottomWidth: "0px",
  };

  return (
    <>
      <TypingText title={"Site Elements"} />

      <Divider />
      <Buffer />

      <Row style={borderStyle}>
        <Col
          lg={4}
          style={{
            padding: 12,
            paddingTop: 24,

            ...rightBorderStyle,
          }}
        >
          {/* shadow */}
          <ButtonsWrapper styleGuideData={styleGuideData}></ButtonsWrapper>
        </Col>
        <Col
          lg={12}
          style={{
            overflow: "hidden",
            paddingTop: 24,
            padding: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...rightBorderStyle,
          }}
        >
          <div
            className=""
            style={{
              zIndex: 999,
              position: "relative",
            }}
          >
            <Typography.Title
              style={{
                position: "absolute",
                // center
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: getStyleGuideValue(styleGuideData, "white"),
                textShadow: "2px 2px 8px #000000",
              }}
              level={2}
            >
              Shadow
            </Typography.Title>
            <div
              className=""
              style={{
                height: 250,
                width: 250,
                filter: "blur(105px)",
                background: "var(--primary-gradient)",
              }}
            ></div>
          </div>
          {/* Button */}
        </Col>
        <Col
          lg={8}
          style={{
            padding: 12,
            paddingTop: 24,
          }}
        >
          {/* Drop Down */}
          <DropdownWrapper styleGuideData={styleGuideData} />
        </Col>
      </Row>
    </>
  );
};

export default Elements;
