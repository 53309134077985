import { Project, SiteData, UpdateProjectInput } from "@/API";
import {
  audienceSizes,
  audienceTypes,
  languageTypes,
  locationTypes,
  riskList,
  toneTypes,
} from "@/constants/staticData";
import { updateProject } from "@/customGraphql/customMutations";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import useUserData from "@/hooks/useData";
import { dayJsFix, isValidGithubRepo } from "@/utils";
import { Button, Col, Divider, Flex, Input, Row, Select, message } from "antd";
import { Rule } from "antd/es/form";
import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import Form from "../common/Form";
dayJsFix();

type InputProps = {
  name?: Project["name"];
  awsAccount?: Project["awsAccount"];
  githubRepo?: Project["githubRepo"];
  liveUrl?: Project["liveUrl"];
  startDate?: Project["startDate"];
  endDate?: Project["endDate"];
  audience?: SiteData["audience"];
  trafficCount?: SiteData["trafficCount"];
  trafficLocation?: SiteData["trafficLocation"];
  tone?: SiteData["tone"];
  languages?: SiteData["languages"];
  websites?: SiteData["websites"];

  cognitoUrl?: Project["cognitoUrl"];

  lambdaFunction?: Project["lambdaFunction"];
  appId?: SiteData["appId"];
  branchName?: SiteData["branchName"];
  dynamo?: Project["dynamo"];
};

const EditSiteData = ({
  project,
  onSuccessMutation,
}: {
  project: Project;
  onSuccessMutation: () => void;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const initialValues = {
    audience: project.siteData?.audience,
    name: project.name,
    trafficCount: project.siteData?.trafficCount,
    trafficLocation: project.siteData?.trafficLocation,
    tone: project.siteData?.tone,
    languages: project.siteData?.languages,
    websites: project.siteData?.websites,
    awsAccount: project.awsAccount,
    githubRepo: project.githubRepo,
    liveUrl: project.liveUrl,
    startDate: project.startDate ? dayjs(project.startDate) : dayjs(),
    endDate: project.endDate ? dayjs(project.endDate) : dayjs().add(1, "M"),
    // status: project.status,
    risk: project.risk,
    dynamo: project.dynamo,
    cognitoUrl: project.cognitoUrl,

    lambdaFunction: project.lambdaFunction,
    appId: project.siteData?.appId,
    branchName: project.siteData?.branchName,
  };

  const onFinish = async (values: InputProps) => {
    messageApi.loading("Updating site data...");

    try {
      const input: UpdateProjectInput = {
        id: project.id,
        awsAccount: values.awsAccount,
        name: values.name,
        githubRepo: values.githubRepo,
        liveUrl: values.liveUrl,
        startDate: values.startDate,
        endDate: values.endDate,
        // status: project.status,
        risk: project.risk,
        dynamo: values.dynamo,
        cognitoUrl: values.cognitoUrl,

        lambdaFunction: values.lambdaFunction,

        siteData: {
          audience: values.audience,
          trafficCount: values.trafficCount,
          trafficLocation: values.trafficLocation,
          tone: values.tone,
          languages: values.languages,
          websites: values.websites,
          appId: values.appId,
          branchName: values.branchName,
        },
      };

      const response: any = await API.graphql(
        graphqlOperation(updateProject, { input: input })
      );
      if (response.data.updateProject) {
        onSuccessMutation();

        messageApi.success("Site data updated successfully");
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    }
  };

  const { isAdmin } = useUserData();

  const urlRules: Rule[] = [
    {
      type: "url",
      message: "Please enter a valid URL",
    },
  ];

  return (
    <>
      {contextHolder}

      <Form form={form} initialValues={initialValues} onFinish={onFinish}>
        <Form.Item
          rules={[
            {
              type: "string",
              min: 3,
              max: 50,
              message: "Please enter a valid name",
            },
            { required: true, message: "Please enter a valid name" },
          ]}
          label="Project Name"
          name="name"
        >
          <Input name="name" placeholder="Enter project name" />
        </Form.Item>
        <Form.Item name={"audience"} label="Audience">
          <Select
            mode="multiple"
            placeholder="Select an option"
            options={audienceTypes}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item name={"trafficCount"} label="Traffic Count">
              <Select placeholder="Select an option" options={audienceSizes} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={"trafficLocation"} label="Traffic Location">
              <Select placeholder="Select an option" options={locationTypes} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item name={"tone"} label="Tone">
              <Select placeholder="Select an option" options={toneTypes} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name={"languages"} label="Languages">
              <Select placeholder="Select an option" options={languageTypes} />
            </Form.Item>
          </Col>
        </Row>
        {/* add dynamic websites sections here */}
        <Divider>Websites</Divider>
        <Form.List name="websites">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field) => (
                <Flex gap={"middle"} vertical key={field.key}>
                  <Form.Item
                    label="Link"
                    name={[field.name, "link"]}
                    rules={[
                      { required: true, message: "Missing link" },
                      {
                        type: "url",
                        message: "Please enter a valid URL",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    label="Reason"
                    name={[field.name, "reason"]}
                    rules={[{ required: false, message: "Missing reason" }]}
                  >
                    <Input.TextArea rows={2} />
                  </Form.Item>

                  <Button
                    block
                    icon={<MinusCircleOutlined />}
                    type="text"
                    danger
                    onClick={() => remove(field.name)}
                  >
                    Remove
                  </Button>
                </Flex>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add website
                </Button>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>

        {isAdmin && (
          <>
            <Form.Item
              rules={[
                {
                  validator: (_, value) => {
                    if (value === undefined || value === null || value === "") {
                      return Promise.resolve();
                    }
                    const isValid = isValidGithubRepo(value);
                    if (isValid) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                  message: "Please input valid github repo!",
                },
              ]}
              label="Github Repo"
              name="githubRepo"
            >
              <Input
                name="githubRepo"
                placeholder="https://github.com/username/repo.git"
              />
            </Form.Item>

            <Form.Item rules={urlRules} label="AWS" name="awsAccount">
              <Input name="awsAccount" placeholder="Aws Account Url" />
            </Form.Item>

            <Form.Item rules={urlRules} label="Live Site Url" name="liveUrl">
              <Input name="liveUrl" placeholder="Enter Url" />
            </Form.Item>
            <Form.Item rules={urlRules} label="Cognito Url" name="cognitoUrl">
              <Input name="cognitoUrl" placeholder="Enter cognito Url" />
            </Form.Item>
            <Form.List name="dynamo">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Flex gap={"middle"} vertical key={field.key}>
                      <Form.Item
                        label="Url"
                        name={[field.name, "value"]}
                        rules={[
                          { required: true, message: "Missing url" },
                          {
                            type: "url",
                            message: "Please enter a valid URL",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label="Key"
                        name={[field.name, "key"]}
                        rules={[{ required: false, message: "Missing name" }]}
                      >
                        <Input />
                      </Form.Item>

                      <Button
                        block
                        icon={<MinusCircleOutlined />}
                        type="text"
                        danger
                        onClick={() => remove(field.name)}
                      >
                        Remove
                      </Button>
                    </Flex>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add dynamo db
                    </Button>
                  </Form.Item>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>

            <Form.List name="lambdaFunction">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <Flex gap={"middle"} vertical key={field.key}>
                      <Form.Item
                        label="Url"
                        name={[field.name, "url"]}
                        rules={[
                          { required: true, message: "Missing url" },
                          {
                            type: "url",
                            message: "Please enter a valid URL",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[{ required: false, message: "Missing name" }]}
                      >
                        <Input />
                      </Form.Item>

                      <Button
                        block
                        icon={<MinusCircleOutlined />}
                        type="text"
                        danger
                        onClick={() => remove(field.name)}
                      >
                        Remove
                      </Button>
                    </Flex>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add lambda function
                    </Button>
                  </Form.Item>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
            <Form.Item label="Aws App Id" name="appId">
              <Input name="appId" placeholder="Enter app id" />
            </Form.Item>
            <Form.Item label="Branch Name" name="branchName">
              <Input name="branchName" placeholder="Enter branch name" />
            </Form.Item>
          </>
        )}

        <Form.Item label="Risk" name="risk">
          <Select id="risk" placeholder="Select risk" options={riskList} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Start Date" name="startDate">
              <Form.DatePicker
                name="startDate"
                onChange={() => {
                  form.setFieldsValue({
                    endDate: undefined,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="End Date" name="endDate">
              <Form.DatePicker
                name="endDate"
                disabledDate={
                  // disable date should be greater than or equal to start date
                  (current: any) => {
                    const startDate = form.getFieldValue("startDate");
                    if (startDate) {
                      return current && current < startDate.endOf("day");
                    }
                  }
                }
                // otherwise disable selector if start date is not selected
                disabled={!form.getFieldValue("startDate")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex justify="flex-end">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

export default EditSiteData;
