import useBreakpoint from "@/hooks/useBreakpoint";
import { useEffect } from "react";

const Cursor = () => {
  const screens = useBreakpoint();

  useEffect(() => {
    // dots is an array of Dot objects,
    // mouse is an object used to track the X and Y position
    // of the mouse, set with a mousemove event listener below
    var dots: any = [],
      mouse = {
        x: 0,
        y: 0,
      };

    const layout = document.querySelector(".layout");
    // The Dot object used to scaffold the dots
    var Dot: any = function (this: any) {
      this.x = 0;
      this.y = 0;
      this.node = (function () {
        var n = document.createElement("div");
        n.className = "trail";
        layout && layout.appendChild(n);

        return n;
      })();
    };
    // The Dot.prototype.draw() method sets the position of
    // the object's <div> node
    Dot.prototype.draw = function () {
      this.node.style.left = this.x + "px";
      this.node.style.top = this.y + "px";
    };

    // Creates the Dot objects, populates the dots array
    for (var i = 0; i < 12; i++) {
      var d = new Dot();
      dots.push(d);
    }

    // This is the screen redraw function
    function draw() {
      // Make sure the mouse position is set everytime
      // draw() is called.
      var x = mouse.x,
        y = mouse.y;

      // This loop is where all the 90s magic happens
      dots.forEach(function (
        dot: { x: number; y: number; draw: () => void },
        index: number
      ) {
        var nextDot = dots[index + 1] || dots[0];

        dot.x = x;
        dot.y = y;

        if (x && y) {
          dot.draw();
          x += (nextDot.x - dot.x) * 0.4;
          y += (nextDot.y - dot.y) * 0.4;
        }
      });
    }

    addEventListener(
      "mousemove",
      function (event) {
        //event.preventDefault();
        mouse.x = event.pageX;
        mouse.y = event.pageY;
      },
      { passive: true }
    );

    // animate() calls draw() then recursively calls itself
    // everytime the screen repaints via requestAnimationFrame().
    function animate() {
      draw();
      // find all trail class which doesnt have any styles
      const trails = document.querySelectorAll(".trail");
      trails.forEach((trail) => {
        const styles = trail.getAttribute("style");
        if (!styles) {
          trail.className = "trail hide";
        } else {
          trail.className = "trail";
        }
      });
      requestAnimationFrame(animate);
    }

    // And get it started by calling animate().

    if (screens.lg && layout) {
      animate();
    } else {
      // set cursor to default in body element
      document.body.style.cursor = "default";
    }
  }, [screens.lg]);

  return null;
};

export default Cursor;
