import { Assets, Store } from "@/API";
import { VIDEO_BACK } from "@/constants/assets";
import { availableColors } from "@/constants/staticData";
import { AssetsProps } from "@/interface/Settings";
import { deleteImageFromS3, getImageFromS3 } from "@/utils";
import { Flex } from "@aws-amplify/ui-react";
import Author from "@components/common/Author";
import RowFlex from "@components/common/RowFlex";
import {
  Card,
  Carousel,
  Col,
  Image,
  Popover,
  Space,
  Tag,
  Typography,
  theme,
} from "antd";
import { CustomArrow } from "../Testimonials";
import { ActionDropDown, assetCols } from "../sections/Assets";

const StoreItem = ({
  onEditStore,
  onDeleteStore,
  storeItem,
  users,
  asset,
}: {
  storeItem: Store;
  asset: Assets;
  users: AssetsProps["users"];
  onEditStore: AssetsProps["onEditAsset"];
  onDeleteStore: AssetsProps["onDeleteAsset"];
}) => {
  const { token } = theme.useToken();
  const user = users.find((d) => d.authID === storeItem.addedBy);
  const isColorsAvailable =
    storeItem?.colorsAvailable && storeItem?.colorsAvailable?.length > 0;
  // const isSizesAvailable =
  //   storeItem?.sizesAvailable && storeItem?.sizesAvailable?.length > 0;

  return (
    <Col key={storeItem.id} {...assetCols}>
      <Card>
        <Carousel
          autoplay
          arrows
          className="store-carousel"
          dotPosition="bottom"
          nextArrow={<CustomArrow inside direction="next" />}
          prevArrow={<CustomArrow inside direction="prev" />}
          autoplaySpeed={5000}
        >
          {storeItem?.mediaIds?.map((asset) => {
            const url = getImageFromS3(asset!, true);

            return (
              <Image
                fallback={VIDEO_BACK}
                loading="lazy"
                height={400}
                preview={false}
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: token.borderRadius,
                }}
                src={url}
                alt={"asset"}
              />
            );
          })}
        </Carousel>

        <RowFlex justifyContent={"space-between"}>
          <Typography.Title
            style={{
              marginTop: "1rem",
              color: token.colorPrimary,
            }}
            level={3}
          >
            ${storeItem?.amount ?? "No price"}
          </Typography.Title>
          <Space>
            {isColorsAvailable && (
              <Popover
                content={
                  <>
                    <Typography.Title level={5}>
                      Available colors
                    </Typography.Title>
                    <Flex>
                      {storeItem?.colorsAvailable
                        ?.map((size) => {
                          const colorName = availableColors.find(
                            (d) => d.value === size
                          )?.label;
                          return colorName;
                        })
                        .join(", ")}
                    </Flex>
                  </>
                }
              >
                <Flex>
                  {storeItem?.colorsAvailable?.map((color) => {
                    const colorName = availableColors.find(
                      (d) => d.value === color
                    )?.label;
                    return (
                      <Tag key={color} color={color?.toString()}>
                        {colorName}
                      </Tag>
                    );
                  })}
                </Flex>
              </Popover>
            )}
            {/* {isSizesAvailable && (
              <Popover
                content={
                  <>
                    <Typography.Title level={5}>
                      Available sizes
                    </Typography.Title>
                    <Flex>
                      {storeItem?.sizesAvailable
                        ?.map((size) => {
                          const sizeName = availableSizes.find(
                            (d) => d.value === size
                          )?.label;
                          return sizeName;
                        })
                        .join(", ")}
                    </Flex>
                  </>
                }
              >
                <Tag>{storeItem?.sizesAvailable?.join(", ")}</Tag>
              </Popover>
            )} */}

            <Tag color={storeItem?.inStock ? "green" : "red"}>
              {storeItem?.inStock ? "In stock" : "Out of stock"}
            </Tag>
            <ActionDropDown
              onEditAsset={onEditStore}
              onDeleteAsset={() => {
                onDeleteStore(asset.id, "store", storeItem.id);
                storeItem?.mediaIds?.forEach((mediaId) => {
                  deleteImageFromS3(mediaId!);
                });
              }}
              asset={asset}
              store={storeItem}
            />
          </Space>
        </RowFlex>
        <Card.Meta
          style={{
            marginBottom: "1rem",
          }}
          title={storeItem?.title ?? "No title"}
          description={storeItem?.description ?? "No description"}
          className="store-card-meta"
        />

        <Author
          user={asset.isUpdated ? asset.lastUpdatedByUser! : user!}
          isUpdated={Boolean(asset.isUpdated)}
          updatedAt={asset.lastUpdatedOn!}
          createdAt={storeItem.createdAt}
        />
      </Card>
    </Col>
  );
};

export default StoreItem;
