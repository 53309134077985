import { TESTIMONIALS_PAGE } from "@/constants/home.constants";
import useBreakpoint from "@/hooks/useBreakpoint";
import SectionLayout from "@/layout/SectionLayout";
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Image,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import styled from "styled-components";
import { TitleText, TypingText } from "./common/Title";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const StyledImage = styled(Image)`
  border-radius: 99999px;
  height: 200px !important;
  width: 200px !important;
  object-fit: cover !important;
  margin-right: 1rem;
`;

const StyledCard = styled(Card)``;

type ArrowProps = {
  direction: "prev" | "next";
  onClick?: () => void;
};

const StyledArrow = styled(Button)<{ next?: boolean; inside?: boolean }>`
  position: absolute;

  ${({ next, inside }) =>
    next
      ? `right: ${inside ? "0.5rem" : "-3rem"};`
      : `left: ${inside ? "0.5rem" : "-3rem"};`}

  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
`;

export const CustomArrow: React.FC<ArrowProps & { inside?: boolean }> = ({
  direction,
  inside,
  onClick,
}) => {
  const text = direction === "prev" ? "Previous" : "Next";
  return (
    <Tooltip title={text}>
      <StyledArrow
        inside={inside}
        next={direction === "next"}
        shape="circle"
        onClick={onClick}
      >
        {direction === "prev" ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
      </StyledArrow>
    </Tooltip>
  );
};

const Testimonials = () => {
  const screens = useBreakpoint();
  return (
    <SectionLayout>
      <TypingText title={TESTIMONIALS_PAGE.heading} />
      <TitleText title={TESTIMONIALS_PAGE.text} />
      <Carousel
        accessibility
        dotPosition={screens.isExtraSmall ? "bottom" : "bottom"}
        autoplaySpeed={10000}
        arrows
        nextArrow={<CustomArrow direction="next" />}
        prevArrow={<CustomArrow direction="prev" />}
        autoplay
        waitForAnimate
        dots={{
          className: `carousel-dots ${screens.isExtraSmall ? "hidden" : ""}`,
        }}
      >
        {TESTIMONIALS_PAGE.testimonials.map((testimonial) => (
          <StyledCard
            actions={
              screens.isExtraSmall
                ? [
                    <Button
                      type="link"
                      href={testimonial.website}
                      target="_blank"
                    >
                      Visit Website
                    </Button>,
                  ]
                : undefined
            }
            className="card"
            key={testimonial.id}
          >
            <Row gutter={[16, 16]} justify="space-between">
              <Space direction="horizontal" size={"small"}>
                <Col xs={0} lg={4}>
                  <StyledImage
                    src={testimonial.image}
                    alt={`${testimonial.name}'s profile image`}
                    preview={false}
                  />
                </Col>
                <Col xs={24} lg={20}>
                  {screens.isExtraSmall && (
                    <Card.Meta
                      style={{ marginBottom: "1rem" }}
                      title={testimonial.name}
                      description={testimonial.designation}
                      avatar={<Avatar src={testimonial.image} />}
                    />
                  )}

                  {!screens.isExtraSmall && (
                    <>
                      <Typography.Text type="secondary">
                        {testimonial.title}
                      </Typography.Text>
                      <Typography.Title className="mt-reset" level={3}>
                        {testimonial.name}
                      </Typography.Title>
                    </>
                  )}
                  <Typography.Paragraph
                    className="testimonial-description"
                    type="secondary"
                  >
                    {testimonial.description}
                  </Typography.Paragraph>

                  <Row justify={"space-between"}>
                    <Image
                      preview={false}
                      style={{
                        height: 20,
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={testimonial.logoUrl}
                    />

                    {!screens.isExtraSmall && (
                      <Button
                        type="link"
                        href={testimonial.website}
                        target="_blank"
                      >
                        Visit Website
                      </Button>
                    )}
                  </Row>
                </Col>
              </Space>
            </Row>
          </StyledCard>
        ))}
      </Carousel>
    </SectionLayout>
  );
};

export default Testimonials;
