// create  a hook to get user data from aws amplify.. current auth status, user data, and sign in and sign out functions

import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "./useLocalstorage";

// add interface for user data
interface AuthState {
  isLoggedIn: boolean;
  authID: string | null;
  email: string | null;
  logout?: () => void;
  isLoading?: boolean;
}

const useAuth = (): AuthState => {
  const initialAuthState = {
    isLoggedIn: false,
    authID: "",
    email: "",
    logout: () => {},
    loading: true,
  };
  const navigate = useNavigate();

  const [authState, setAuthState] = useState(initialAuthState);

  const [_1, setSelectedOrgId] = useLocalStorage<string>(
    "selectedOrgId",
    "all"
  );
  const [_2, setSelectedOrgName] = useLocalStorage<string>(
    "selectedOrgName",
    "all"
  );

  const onLogout = () => {
    Auth.signOut();
    setAuthState(initialAuthState);
    setSelectedOrgId("all");
    setSelectedOrgName("all");
    navigate("/login");
  };

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        setAuthState({
          isLoggedIn: true,
          loading: false,
          authID: payload.data?.attributes?.sub,
          email: payload.data?.attributes?.email,
          logout: onLogout,
        });
      } else if (payload.event === "signOut") {
        setAuthState(initialAuthState);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthState({
          isLoggedIn: true,
          authID: user?.attributes?.sub,
          email: user?.attributes?.email,
          loading: false,
          logout: onLogout,
        });
      })
      .catch(() => {
        setAuthState(initialAuthState);
      });
  }, []);

  return authState;
};

export default useAuth;
