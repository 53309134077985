import { CreateFontInput, Font } from "@/API";
import transition from "@/components/Animations/transition";
import { HeroPatternWrapper } from "@/components/Hero";
import { VideoBack } from "@/components/ZoiqVideo";
import Form from "@/components/common/Form";
import { TitleText } from "@/components/common/Title";
import UploadedFonts from "@/components/sections/UploadedFonts";
import { HERO_PATTERN_URL, VIDEO_PATTERN_URL } from "@/constants/assets";
import { FONT_KEY } from "@/constants/staticData";
import { createFont } from "@/graphql/mutations";
import { listFonts } from "@/graphql/queries";
import AppLayout from "@/layout/AppLayout";
import SectionLayout from "@/layout/SectionLayout";
import {
  addSlug,
  cssFileCreator,
  fontNameToNumber,
  fontWeightToNumberConverter,
  getImageFromS3,
  removeExtension,
  removeWhiteSpace,
  uploadFileToS3,
} from "@/utils";
import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
  Upload,
  message,
} from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import { API, graphqlOperation } from "aws-amplify";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
const { Dragger } = Upload;

const UploadSection = ({
  fontOptions,
  fileList,
  setFileList,
}: {
  fontOptions: any[];
  fileList?: UploadFile[];
  setFileList?: React.Dispatch<React.SetStateAction<RcFile[]>>;
}) => {
  const [error, setError] = useState("");
  return (
    <Card>
      <Form.Item
        name={"name"}
        rules={[
          {
            required: true,
            message: "Please enter name of font",
          },
          {
            // regex to remove space at start and end

            pattern: /^[\S]+.*[\S]+$/,
            message: "Remove space at start and end",
          },
          {
            // only alphabets and numbers
            pattern: /^[a-zA-Z0-9 ]*$/,
            message: "Only alphabets and numbers are allowed",
          },
        ]}
        label="Name"
      >
        <Input placeholder="Enter name of font" />
      </Form.Item>
      <Form.Item
        name={`link`}
        rules={[
          {
            required: fileList?.length === 0,
            message: "Please enter a valid URL",
          },
          {
            type: "url",
            message: "Please enter a valid URL",
          },
          // link has to be like https://fonts.googleapis.com/css2?family=Montaga&display=swap
          {
            pattern:
              /^https:\/\/(fonts\.googleapis\.com\/css2\?family=.*&display=swap|db\.onlinewebfonts\.com\/c\/.+family=.*)$/,
            message:
              "Please enter a valid URL. example: https://fonts.googleapis.com/css2?family=Montaga&display=swap",
          },
          //
        ]}
        label="Link"
      >
        <Input placeholder="https://fonts.googleapis.com/css2?family=Montaga&display=swap" />
      </Form.Item>

      <Divider>Or</Divider>

      <Form.Item name={`fileList`}>
        <Dragger
          fileList={fileList ?? []}
          beforeUpload={(file, fileList) => {
            if (
              !fileList.find(
                (file) =>
                  file.name?.toLowerCase()?.includes("regular") ||
                  file.name?.toLowerCase()?.includes("400")
              )
            ) {
              setError(
                "Regular font file is required. Rename any file to regular"
              );
              throw new Error("Regular font file is required");
            }
            setFileList?.(uniqBy([...fileList, file], "name"));
            return false;
          }}
          onRemove={(file) => {
            // @ts-ignore
            setFileList?.(fileList?.filter((f) => f.uid !== file.uid));
          }}
          accept=".woff,.woff2 ,.otf,.ttf"
          multiple={true}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            use{" "}
            <a
              href="https://anyconv.com/otf-to-woff-converter/#google_vignette"
              target="_blank"
            >
              converter
            </a>{" "}
            to convert files to woff format
          </p>
        </Dragger>
      </Form.Item>
      <Form.ErrorList errors={[error]} />

      <Form.Item label="Compliments With" name={"complimentsWithID"}>
        <Select
          mode="multiple"
          placeholder="Select a font that compliments this font"
          options={fontOptions}
        />
      </Form.Item>
    </Card>
  );
};

interface RcFileWithUrl extends RcFile {
  url: string;
}

function findMissingFonts(existingFonts: string[]) {
  let missingFonts = [];

  for (let key in fontNameToNumber) {
    if (!existingFonts.find((d) => d.includes(key))) {
      missingFonts.push(key);
    }
  }

  return missingFonts;
}

const createFontFile = async (fileList: UploadFile[], fontName: string) => {
  try {
    let fileListWithUrl: RcFileWithUrl[] = [...fileList] as RcFileWithUrl[];

    // one regular file is required
    if (
      !fileListWithUrl.find(
        (file) =>
          file.name?.toLowerCase()?.includes("regular") ||
          file.name?.toLowerCase()?.includes("400")
      )
    ) {
      throw new Error("Regular font file is required");
    }

    // upload file to s3 using for const loop
    for (const file of fileList) {
      if (file.originFileObj) {
        const result = await uploadFileToS3(
          file.originFileObj!,
          `${FONT_KEY}/${addSlug(file.name)}`,
          file.type!
        );
        if (result) {
          const url = getImageFromS3(result.key, false);

          //  @ts-ignore
          fileListWithUrl[fileList.indexOf(file)] = {
            ...file,
            url,
          };
        }
      }
    }

    // create css file.
    let cssFile = "";
    fileListWithUrl = fileListWithUrl.filter(
      (file) => file.name !== undefined && file.url !== undefined
    );

    const filesWithoutItalic = fileListWithUrl
      .filter(
        // remove italic files
        (file) => !file.name.toLowerCase()?.includes("italic")
      )
      .map((file) => file.name.toLowerCase());
    const missingFonts = findMissingFonts(filesWithoutItalic);

    for (const file of fileListWithUrl) {
      if (file.name) {
        const withoutExtension = removeExtension(file.name);
        const format = file.type?.split("/")[1];
        cssFile += `
        @font-face {
          font-family: '${fontName}';
          font-display: swap;
          font-style: ${file?.name?.includes("Italic") ? "italic" : "normal"};
          font-weight: ${fontWeightToNumberConverter(withoutExtension)};
          src: url(${file.url}) format('${
          format === "otf"
            ? "opentype"
            : format === "ttf"
            ? "truetype"
            : format === "woff"
            ? "woff"
            : "woff2"
        }');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          `;
      }
    }

    if (missingFonts.length > 0) {
      // find url of fileListWithUrl of weigth 400

      const regularFile = fileListWithUrl.find(
        (file) =>
          (file.name.toLowerCase()?.includes("regular") ||
            file.name.toLowerCase()?.includes("400")) &&
          file.url
      );
      if (regularFile) {
        const format = regularFile.type?.split("/")[1];

        // use regular font for missing files
        missingFonts.forEach((key) => {
          cssFile += `
        @font-face {
          font-family: '${fontName}';
          font-display: swap;
          font-style: normal;
          font-weight: ${fontNameToNumber[key]};
          
          src: url(${regularFile.url}) format('${
            format === "otf"
              ? "opentype"
              : format === "ttf"
              ? "truetype"
              : "woff"
          }');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          `;
        });
      }
    }

    return cssFile;
  } catch (error) {
    console.error(error);
  }
};

const uploadCssToS3 = async (css: string, key: string) => {
  try {
    const file = cssFileCreator(css);
    const result = await uploadFileToS3(file, key, "text/css");
    if (result) {
      return getImageFromS3(result.key, false);
    } else {
      throw new Error("Something went wrong while uploading css file to s3");
    }
  } catch (error) {
    console.error(error);
  }
};

const UploadFonts = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [loadingText, setLoadingText] = useState("");

  const [links, setLinks] = useState({
    primary: "",
  });

  const onFinish = async (values: any) => {
    setIsLoading(true);
    setShowModal(true);
    try {
      let link1 = values.link;

      if (link1 === undefined) {
        setLoadingText("Uploading font to s3");
        const css = await createFontFile(
          values["fileList"].fileList,
          values["name"]
        );

        link1 = await uploadCssToS3(
          css!,
          `${FONT_KEY}/LINKS/${values["name"]}`
        );

        if (link1 === "" || link1 === undefined) {
          messageApi.error("Something went wrong while uploading font to s3");
          throw new Error("Something went wrong while uploading font to s3");
        }

        setLoadingText("Font Uploaded");
      }

      setLoadingText("Generating link...");

      const input1: CreateFontInput = {
        name: values["name"],
        link: link1,
        complimentsWithID: values["complimentsWithID"],
      };

      const [response1]: any[] = await Promise.all([
        API.graphql(graphqlOperation(createFont, { input: input1 })),
      ]);

      setLinks({
        primary: response1.data.createFont.link,
      });

      setLoadingText("Font uploaded to database");
      form.resetFields();
      setFileList([]);
      messageApi.success("Fonts uploaded successfully");
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const { data: fontsFromDB, refetch } = useQuery("fonts", async () => {
    const response: any = await API.graphql(
      graphqlOperation(listFonts, {
        limit: 50,
      })
    );
    return response.data.listFonts.items as Font[];
  });

  const fontOptions = fontsFromDB?.map((font) => ({
    label: font.name,
    value: font.id,
  }));

  return (
    <AppLayout
      seo={{
        title: "Upload Fonts",
      }}
    >
      {contextHolder}

      <SectionLayout
        backgroundElement={
          <>
            <HeroPatternWrapper>
              <img src={HERO_PATTERN_URL} alt="none" />
            </HeroPatternWrapper>
            <VideoBack bottom>
              <img src={VIDEO_PATTERN_URL} alt="video-back-bottom" />
            </VideoBack>
          </>
        }
        innerStyle={{
          minHeight: "100vh",
        }}
      >
        <TitleText title="Upload Fonts" />

        <Tabs
          items={[
            {
              label: "Upload New Fonts",
              key: "upload",
              children: (
                <Form form={form} onFinish={onFinish}>
                  <Modal
                    title="Font Links"
                    open={showModal}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    onCancel={() => {
                      setLinks({
                        primary: "",
                      });
                      setShowModal(false);
                    }}
                  >
                    {isLoading && (
                      <Spin>
                        <Typography.Title level={4}>
                          Uploading Fonts...
                        </Typography.Title>
                      </Spin>
                    )}
                    <Result
                      status={isLoading ? "info" : "success"}
                      title={
                        isLoading ? loadingText : "Fonts uploaded successfully"
                      }
                      subTitle={
                        isLoading
                          ? "Please wait while we upload your fonts to our server"
                          : "You can copy the links below to use in your project. Paste the link in your project's css file."
                      }
                    />
                    {links.primary && (
                      <>
                        <Divider>Links</Divider>

                        <Space
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography.Text
                            code
                            copyable={{
                              tooltips: false,
                              text: removeWhiteSpace(links.primary),
                              onCopy: () => {
                                messageApi.success("Primary font link copied");
                              },
                            }}
                          >
                            Primary Font Link
                          </Typography.Text>
                        </Space>
                      </>
                    )}
                  </Modal>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <UploadSection
                        fileList={fileList}
                        setFileList={setFileList}
                        fontOptions={fontOptions!}
                      />
                    </Col>
                  </Row>

                  <Form.Item>
                    <Space
                      style={{
                        marginTop: 24,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        onClick={() => {
                          form.resetFields();
                        }}
                        type="default"
                      >
                        Reset
                      </Button>
                      <Button
                        loading={isLoading}
                        htmlType="submit"
                        type="primary"
                      >
                        Submit
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              ),
            },
            {
              label: "Uploaded Fonts",
              key: "uploaded",
              children: (
                <div className="">
                  <UploadedFonts fontsFromDB={fontsFromDB!} refetch={refetch} />
                </div>
              ),
            },
          ]}
        />
      </SectionLayout>
    </AppLayout>
  );
};

export default transition(UploadFonts);
