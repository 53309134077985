import { BacklogStatus } from "@/API";
import { useTaskContext } from "@/contexts/TaskContexts";
import { Button, Empty, List, Modal, Tabs } from "antd";
import { useMemo } from "react";

const ListModule = ({ status }: { status: BacklogStatus }) => {
  const {
    modules,
    taskQuery,

    subTaskActions,
    projectId,
    organizationId,
  } = useTaskContext();
  const { sprintsModule } = modules;

  const tasks = useMemo(
    () =>
      taskQuery.data?.filter(
        (task) =>
          task.status === status &&
          (organizationId === "all" || task.projectID === projectId)
      ),
    [taskQuery.data, status, organizationId, projectId]
  );

  if (!tasks || tasks.length === 0)
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No tasks" />
    );

  return (
    <List
      renderItem={(task) => (
        <List.Item>
          <List.Item.Meta
            title={task.title}
            description={
              <span dangerouslySetInnerHTML={{ __html: task.name }} />
            }
          />
          <div>
            <Button
              onClick={() => {
                if (!sprintsModule) return;
                subTaskActions.moveToSprint(sprintsModule, task.id);
              }}
            >
              Move to sprint
            </Button>
          </div>
        </List.Item>
      )}
      dataSource={tasks}
    />
  );
};

const SprintsSelectorModule = () => {
  const { modules } = useTaskContext();

  return (
    <Modal
      title="Select Sprint"
      open
      width={700}
      onCancel={() => {
        modules.setSprintsModule(null);
      }}
      okButtonProps={{ hidden: true }}
    >
      <Tabs
        items={[
          {
            label: "Pending",
            key: "pending",
            children: <ListModule status={BacklogStatus.TODO} />,
          },
          {
            label: "In Progress",
            key: "inprogress",
            children: <ListModule status={BacklogStatus.IN_PROGRESS} />,
          },
          {
            label: "Completed",
            key: "completed",
            children: <ListModule status={BacklogStatus.DONE} />,
          },
        ]}
      />
    </Modal>
  );
};

export default SprintsSelectorModule;
