import { StyleGuide } from "@/API";
import useLocalStorage from "@/hooks/useLocalstorage";
import { FormSettings } from "@/interface/Settings";
import { getStyleGuideValue } from "@/utils";
import {
  Form as AntdForm,
  FormItemProps as AntdFormItemProps,
  Col,
  DatePicker,
  DatePickerProps,
  FormProps,
  Row,
} from "antd";
import React from "react";

interface FormInterface extends FormProps {
  children: React.ReactNode;
  previewValues?: FormSettings;
}

const Form = (props: FormInterface) => {
  const [assets] = useLocalStorage<StyleGuide[]>("assets", []);
  const getValue = (value: string): any => getStyleGuideValue(assets, value);

  const inputsPerRow =
    Number(props.previewValues?.inputPerRow ?? getValue("inputPerRow")) ?? 1;

  return (
    <AntdForm
      scrollToFirstError={{ behavior: "smooth", block: "center" }}
      size={props?.previewValues?.size ?? (getValue("size") || "middle")}
      layout={
        props?.previewValues?.layout ?? (getValue("layout") || "vertical")
      }
      {...props}
    >
      {inputsPerRow === 2 ? (
        // Wrap Form.Items in rows and cols with two columns per row
        <Row gutter={[16, 0]}>
          {React.Children.map(props.children, (child, key) => {
            // @ts-ignore
            const span = child?.props?.span ?? 12;

            return (
              // if last index.. span 12.
              // if children has props span.. use that span
              // else span 24 for last item in row
              // or span 12 for all other items
              <Col
                key={key}
                span={
                  key === React.Children.count(props.children) - 1 ? 24 : span
                }
              >
                {child}
              </Col>
            );
          })}
        </Row>
      ) : (
        // Use default behavior when inputsPerRow is not 2
        props.children
      )}
    </AntdForm>
  );
};

// create sub componet Form.Item

// add new type span to Form.Item
type FormItemProps = {
  span?: number;
} & AntdFormItemProps;

Form.Item = (props: FormItemProps) => {
  return (
    // @ts-ignore
    <AntdForm.Item {...props} />
  );
};

Form.useForm = AntdForm.useForm;
Form.useWatch = AntdForm.useWatch;
// add default styles to datepicker
Form.DatePicker = (props: DatePickerProps) => (
  <DatePicker style={{ width: "100%" }} {...props} />
);

Form.List = AntdForm.List;
Form.ErrorList = AntdForm.ErrorList;
export default Form;
