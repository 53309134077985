import { Backlog } from "@/API";
import { useTaskContext } from "@/contexts/TaskContexts";
import { Form, Modal } from "antd";

import TaskModal from "../RareComponents/TaskComponents/TaskModal";

const EditTaskModule = () => {
  const { modules, tasks, projectId, organizationId } = useTaskContext();
  const { setEditTaskModule, editTaskModule } = modules;

  const [form] = Form.useForm();

  const isEdit = editTaskModule.backlog?.id;

  const editTaskData = isEdit
    ? (tasks.find((task) => task.id === editTaskModule.backlog?.id) as Backlog)
    : null;

  return (
    <Modal
      title={isEdit ? "Edit Sprint" : "Add Sprint"}
      open
      okText={isEdit ? "Save" : "Add"}
      onOk={form.submit}
      onCancel={() => setEditTaskModule({ show: false, backlog: null })}
    >
      <TaskModal
        form={form}
        prepoluateData={{
          projectID: projectId,
          organizationID: organizationId,
        }}
        taskData={editTaskData}
      />
    </Modal>
  );
};

export default EditTaskModule;
