import { ContentCreation, Project } from "@/API";
import { listContentCreations } from "@/graphql/queries";
import { Button, Card, Col, Empty, Row, Typography } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import Loading from "../common/Loading";

interface ContentCreationProps {
  project: Project;
}

export const fetchContentCreation = async (
  projectId: string,
  sectionId?: string,
  pageId?: string
) => {
  const filter = Boolean(sectionId && pageId)
    ? {
        projectID: {
          eq: projectId,
        },
        sectionId: {
          eq: sectionId,
        },
        pageId: {
          eq: pageId,
        },
      }
    : {
        projectID: {
          eq: projectId,
        },
      };

  const response: any = await API.graphql(
    graphqlOperation(listContentCreations, {
      filter: filter,
    })
  );
  const contentCreations = response.data.listContentCreations
    .items as ContentCreation[];
  return contentCreations;
};

const ContentCreationTab = ({ project }: ContentCreationProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetched } = useQuery(
    `content-creation-${project.id}`,
    () => fetchContentCreation(project.id),
    {
      enabled: !!project.id,
    }
  );

  return (
    <>
      <Card
        ref={ref}
        className="card"
        title={`Content Creation`}
        id="content-creator"
        extra={
          <NavLink to={`/content-create/${project.id}`}>
            <Button>Create Content</Button>
          </NavLink>
        }
      >
        {isLoading || !isFetched ? (
          <Loading useAppContainer={false} />
        ) : isEmpty(data) ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={"No content created yet."}
          />
        ) : (
          <Row gutter={[16, 16]}>
            {data?.map((contentCreation: ContentCreation) => (
              <Col key={contentCreation.id} xs={24} sm={24} md={12} lg={8}>
                <NavLink to={`/content-create/${project.id}`}>
                  <Card hoverable>
                    <Typography.Text ellipsis={true}>
                      {contentCreation.content}
                    </Typography.Text>
                  </Card>
                </NavLink>
              </Col>
            ))}
          </Row>
        )}
      </Card>
    </>
  );
};

export default ContentCreationTab;
