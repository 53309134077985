import { Backlog, PageSection, Pages, Project } from "@/API";
import GenerateContent, {
  fetchConversationData,
} from "@/components/RareComponents/GenerateContent";
import { section_collapsable } from "@/constants/portal.constants";
import { Collapse, Typography } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import DesignInstructions, {
  designInstructionsOptions,
} from "./ProjectPages/DesignInstructions";
import SectionSignOff from "./ProjectPages/SectionSignOff";
import ProjectSC, {
  TitleWithCount,
  fetchSuccessCriteriaData,
} from "./ProjectSC";
import SectionMediaFiles, { fetchAssets } from "./SectionMediaFiles";

const BottomText = ({ text }: { text: string }) => {
  return (
    <Typography.Text
      style={{
        fontWeight: "400",
      }}
      type="secondary"
    >
      {text}
    </Typography.Text>
  );
};

const SectionComponent = ({
  page,
  section,
  setSelectedSection,
  project,
  refetch,
}: {
  page: Pages;
  project: Project;
  section: PageSection;
  setSelectedSection: (section: PageSection) => void;
  refetch: () => void;
  newTaskModal: boolean;
  setNewTaskModal: (value: boolean) => void;
  taskData: Backlog | null;
  setTaskData: React.Dispatch<React.SetStateAction<Backlog>>;
}) => {
  // const { authID } = useUserData();

  // const {
  //   data: tasks,

  //   refetch: refetchTasks,
  // } = useQuery(
  //   `tasks-${authID}-${page.id}-${section.id}`,
  //   async () => {
  //     const response: any = await API.graphql(
  //       graphqlOperation(listBacklogs, {
  //         limit: 1000,
  //         filter: {
  //           pageID: { eq: page.id },
  //           sectionID: { eq: section.id },
  //         },
  //       })
  //     );
  //     const items = response.data.listBacklogs.items as Backlog[];

  //     return items;
  //   },
  //   {
  //     refetchOnReconnect: false,
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const [selectedOrgId] = useLocalStorage("selectedOrgId", "");

  const { data: successCriteriaData } = useQuery(
    `project-sc-${project?.id}-${page?.id}-${section?.id}`,
    () => fetchSuccessCriteriaData(project, page.id, section.id),
    {
      enabled: !!project.id && !!page.id && !!section.id,
    }
  );

  const [isTextSent, setIsTextSent] = useState(false);

  const [sliderValue, setSliderValue] = useState(0);

  const {
    data: conversationData,
    isLoading: isLoadingData,
    isFetched: isFetchedData,
    refetch: refetchConversationData,
  } = useQuery(
    ["content-creation", project.id, page.id, section.id],
    () => fetchConversationData(project.id, page.id, section.id),
    {
      enabled: !!project.id,
    }
  );

  const {
    data: assets,
    isLoading: isAssetsLoading,
    isFetched: isAssetsFetched,
  } = useQuery(
    `project-${page.id}-${section.id}`,
    () => fetchAssets(section.media as any[]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !isEmpty(section.media),
    }
  );

  useEffect(() => {
    if (isFetchedData && !isLoadingData) {
      // one of the item from data has to be isSent = true
      // @ts-ignore
      const isSent = conversationData?.some((d) => d.sent);
      setIsTextSent(isSent as boolean);
    }
  }, [isLoadingData, section.id, isFetchedData]);

  return (
    <>
      <Collapse
        className="site-collapse-custom-collapse"
        key={section.id}
        items={
          [
            {
              // extra: <BottomText text={section_collapsable.site_content} />,
              label: (
                <div>
                  <Typography.Title level={4}>
                    1. Provide Section Copy (
                    {isLoadingData && !isFetchedData ? (
                      "..."
                    ) : (
                      <span
                        style={{
                          color: isTextSent ? "var(--primary)" : "red",
                        }}
                      >
                        {isTextSent ? "complete" : "incomplete"}
                      </span>
                    )}
                    )
                  </Typography.Title>
                  <BottomText text={section_collapsable.site_content} />
                </div>
              ),
              key: "text",

              children: (
                <>
                  <GenerateContent
                    data={conversationData!}
                    isLoadingData={isLoadingData}
                    isFetchedData={isFetchedData}
                    refetchContentCreation={refetchConversationData}
                    page={page}
                    project={project}
                    section={section}
                  />
                </>
              ),
            },
            {
              // extra: <BottomText text={section_collapsable.media_files} />,
              label: (
                <div>
                  <TitleWithCount
                    title={"2.  Attach Media Files"}
                    redOnZero={!Boolean(section.noMedia)}
                    count={
                      isEmpty(section.media)
                        ? 0
                        : isAssetsLoading || !isAssetsFetched
                        ? `...`
                        : assets?.length ?? 0
                    }
                  />
                  <BottomText text={section_collapsable.media_files} />
                </div>
              ),

              key: "media",
              children: (
                <>
                  <SectionMediaFiles
                    section={section}
                    setSelectedSection={setSelectedSection}
                    sectionId={section.id}
                    project={project}
                    refetch={refetch}
                    pageId={page.id}
                    page={page}
                    assetIds={section.media as string[]}
                  />
                </>
              ),
            },

            {
              label: (
                <div>
                  <TitleWithCount
                    title={"3. Define Success Criteria"}
                    redOnZero
                    // @ts-ignore
                    count={successCriteriaData?.length ?? 0}
                  />
                  <BottomText text={section_collapsable.success_criteria} />
                </div>
              ),

              // extra: <BottomText text={section_collapsable.success_criteria} />,
              key: "success-criteria",
              children: (
                <>
                  <ProjectSC
                    section={section}
                    pageId={page.id}
                    project={project}
                  />
                </>
              ),
            },
            {
              // extra: <BottomText text={section_collapsable.involvement} />,
              label: (
                <div>
                  <TitleWithCount
                    redOnZero
                    title="4.  Select ZOIQ Design Involvement"
                    count={designInstructionsOptions[sliderValue].label}
                  />
                  <BottomText text={section_collapsable.involvement} />
                </div>
              ),

              key: "zoiq-design-involvement",
              children: (
                <>
                  <DesignInstructions
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    page={page}
                    section={section}
                  />
                </>
              ),
            },
            // {
            //   key: "tasks",
            //   label: (
            //     <Flex justify="space-between">
            //       <TitleWithCount
            //         style={{
            //           marginBottom: 0,
            //         }}
            //         title="5. Tasks"
            //         redOnZero
            //         count={tasks?.length ?? 0}
            //       />
            //       <Button
            //         icon={<PlusOutlined />}
            //         onClick={() => setNewTaskModal(true)}
            //         type="link"
            //       >
            //         New Task
            //       </Button>
            //     </Flex>
            //   ),

            //   children: (
            //     <div
            //       style={{
            //         marginLeft: 8,
            //       }}
            //     >
            //       <TaskList
            //         refetch={refetchTasks}
            //         data={tasks!}
            //         taskData={taskData as any}
            //         isInsidePages
            //         setTaskData={setTaskData}
            //         prepoluateData={{
            //           projectID: project.id,
            //           organizationID: selectedOrgId,
            //           pageID: page.id,
            //           sectionID: section.id,
            //         }}
            //         newTaskModal={newTaskModal}
            //         setNewTaskModal={setNewTaskModal}
            //       />
            //     </div>
            //   ),
            // },
            {
              key: "sign-off",
              label: (
                <div>
                  <Typography.Title level={4}>6. Sign Off</Typography.Title>
                </div>
              ),

              children: (
                <div
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <SectionSignOff section={section} />
                </div>
              ),
            },
          ].filter(Boolean) as any
        }
      />
    </>
  );
};

export default SectionComponent;
