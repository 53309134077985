import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import Cursor from "@/components/common/Cursor";
import ScrollUpButton from "@/components/common/ScrollUpButton";
import Scroller from "@/components/common/Scroller";
import { NAVBAR_SIZE } from "@/constants/assets";
import { Layout, theme } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import styled from "styled-components";

const { Content } = Layout;

interface AppLayoutProps {
  children: React.ReactNode;
  config?: {
    footer?: boolean;
    scroller?: boolean;
    scrollUpButton?: boolean;
    navbar?: boolean;
    cursor?: boolean;
  };
  seo?: {
    title?: string;
    description?: string;
    keywords?: string;
    name?: string;
    type?: string;
    image?: string;
  };
}

export const StyledContent = styled(Content)`
  padding-top: ${NAVBAR_SIZE}px;
  position: relative;
`;

const AppLayout = ({ children, config, seo }: AppLayoutProps) => {
  const { token } = theme.useToken();

  const {
    footer = true,
    scrollUpButton = false,
    scroller = true,
    navbar = true,
    cursor = false,
  } = config || {};

  const { title = "ZOIQ", image, description, name, type } = seo || {};

  // if cursor is false. then go to body element and set cursor to auto in useEffect

  useEffect(() => {
    if (!cursor) {
      document.body.style.cursor = "auto";
    }
  }, [cursor]);

  return (
    <Layout
      style={{
        backgroundColor: token.colorBgLayout,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
      className="layout"
    >
      <Helmet>
        {/* Standard metadata tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* End Facebook tags */}
        {/* Twitter tags */}
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="twitter:image" content={image} />
        {/* End Twitter tags */}
      </Helmet>
      {scroller && <Scroller />}
      {navbar && <Navbar />}
      <StyledContent>
        {cursor && <Cursor />}
        {children}
        {scrollUpButton && <ScrollUpButton />}
      </StyledContent>
      {footer && <Footer />}
    </Layout>
  );
};

export default AppLayout;
