import { Tooltip, UploadFile } from "antd";
import React from "react";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { VideoAsset } from "./MediaAssets";

const CustomUploadItem = ({
  originNode,
  file,
}: {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}) => {
  return (
    <div>
      {/* hide error tooltip when dragging */}
      {file.status === "error" ? (
        originNode.props.children
      ) : (file.type?.includes("video") ||
          file.originFileObj?.name?.includes("video")) &&
        file.url ? (
        <Tooltip title={file.name}>
          <div
            style={{
              position: "relative",
            }}
            className="ant-upload-list-item ant-upload-list-item-done"
          >
            <div
              style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
              }}
              className=""
            >
              <MdOutlineOndemandVideo />
            </div>

            <VideoAsset
              controls={false}
              hideBorderRadius
              autoPlay={false}
              height="100%"
              src={file.url}
            />
          </div>
        </Tooltip>
      ) : (
        originNode
      )}
    </div>
  );
};
export default CustomUploadItem;
