import { StyleGuide } from "@/API";
import { TypingText } from "@/components/common/Title";
import { Buffer } from "@/components/sections/StyleGuide";
import { fontsToInclude } from "@/constants/staticData";
import { getStyleGuideValue } from "@/utils";
import { Col, Divider, Row, Skeleton, theme } from "antd";
import { useEffect, useState } from "react";



const TypoCard = ({
  styleGuideData,
  type = "primary",
  fontLoading,
}: {
  type: "primary" | "secondary";
  styleGuideData: StyleGuide[];
  fontLoading: boolean;
}) => {
  const getValue = (name: string) => getStyleGuideValue(styleGuideData!, name);

  const { token } = theme.useToken();

  const conditional =
    type === "primary" ? "fontFamilyPrimary" : "fontFamilySecondary";

  const text =
    type === "primary"
      ? `${getValue(conditional)} is used for headings and titles. It is a
    bold, condensed font that is easy to read and works well for titles and
    headings.`
      : `${getValue(
          conditional
        )} is used for body text. It is a simple, easy to read font that works well for body text.`;

  // skeleton loading from antd if fontLoading
  if (fontLoading) {
    return (
      <Skeleton active paragraph={{ rows: 4 }}>
        <Skeleton.Image style={{ width: 200, height: 200 }} />
      </Skeleton>
    );
  }

  return (
    <div className="typography-display">
      <p
        style={{
          fontFamily: getValue(conditional),
          fontSize: `${getValue("fontSizeBanner")}px`,
          color: getValue("primary"),
          marginBottom: "1rem",
          fontWeight: getValue("fontWeightBanner"),
          letterSpacing: getValue("letterSpacingBanner"),
        }}
        className=""
      >
        Aa
      </p>
      <p
        style={{
          color: token.colorTextSecondary,
          fontSize: `${getValue("fontSizeSubtitle")}px`,
          fontFamily: getValue(conditional),
          fontWeight: getValue("fontWeightSubtitle"),
          letterSpacing: getValue("letterSpacingSubtitle"),
        }}
      >
        <strong
          style={{
            color: token.colorWhite,
          }}
        >
          {getValue(conditional)}
        </strong>
        <br />

        <span
          style={{
            color: token.colorTextSecondary,
          }}
        >
          {text}
        </span>
      </p>

      <p
        style={{
          fontSize: `${getValue("fontSizeBody")}px`,

          color: token.colorWhite,
          fontFamily: getValue(conditional),
        }}
        className="example"
      >
        0 1 2 3 4 5 6 7 8 9 ? ! @ # $ % ^ & *
        <br />
        <br />
        A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
        <br />
        <br />a b c d e f g h i j k l m n o p q r s t u v w x y z
      </p>
    </div>
  );
};

const Typography = ({ styleGuideData }: { styleGuideData: StyleGuide[] }) => {
  const getValue = (name: string) => getStyleGuideValue(styleGuideData!, name);

  const [primaryFontLoading, setPrimaryFontLoading] = useState(true);
  const [secondaryFontLoading, setSecondaryFontLoading] = useState(true);

  const loadFonts = () => {
    const primaryFont = getValue("fontFamilyPrimary");
    const secondaryFont = getValue("fontFamilySecondary");

    const head = document.getElementsByTagName("head")[0];

    const filteredFonts = fontsToInclude.filter(
      (font) =>
        font.includes(primaryFont.replaceAll(" ", "%20")) ||
        font.includes(secondaryFont.replaceAll(" ", "%20"))
    );

    if (filteredFonts.length === 0) {
      return;
    }

    filteredFonts.forEach((font) => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = font;
      head.appendChild(link);
      link.onload = () => {
        if (font.includes(primaryFont.replaceAll(" ", "%20"))) {
          setPrimaryFontLoading(false);
        } else {
          setSecondaryFontLoading(false);
        }
      };
    });
  };

  useEffect(() => {
    loadFonts();
  }, []);

  return (
    <div className="">
      <TypingText title={"Font Families"} />
      <Divider />
      <Buffer />

      <Row>
        <Col
          xs={24}
          lg={12}
          style={{
            paddingRight: 24,
          }}
        >
          <TypoCard
            type="primary"
            fontLoading={primaryFontLoading}
            styleGuideData={styleGuideData}
          />
        </Col>

        <Col xs={24} lg={12}>
          <TypoCard
            type="secondary"
            fontLoading={secondaryFontLoading}
            styleGuideData={styleGuideData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Typography;
