import { useTaskContext } from "@/contexts/TaskContexts";
import { Modal } from "antd";

const DeleteTaskPopup = () => {
  const { deleteTask, modules } = useTaskContext();
  const handleDelete = () => {
    deleteTask(modules.deleteTaskPopup!);
    modules.setDeleteTaskPopup(null);
  };
  return (
    <Modal
      title="Delete Task"
      open
      okButtonProps={{ danger: true }}
      onOk={handleDelete}
      destroyOnClose
      onCancel={() => {
        modules.setDeleteTaskPopup(null);
      }}
    >
      <p>Are you sure you want to delete this task?</p>
    </Modal>
  );
};

export default DeleteTaskPopup;
