import {
  OrganizationType,
  Risk,
  ProjectRole,
  ProjectStatus,
  Status,
  SummaryType,
  StyleGuideType,
  BacklogPriority,
  BacklogStatus,
  FrequencyType,
  SuccessCriteriaType,
  AssetType,
  AudienceType,
  ToneType,
  LanguageType,
  AudienceSize,
  User,
  SuggestedSolutions,
  OrgSize,
  PaperOptions,
  PrinterOptions,
  SocialLinkType,
  SubtaskStatus,
} from "@/API";
import { v4 as uuidv4 } from "uuid";

export const organizationTypeList = [
  { value: OrganizationType.PROFIT, label: "Profit" },
  { value: OrganizationType.NONPROFIT, label: "Nonprofit" },
  { value: OrganizationType.GOVERNMENT, label: "Government" },
  { value: OrganizationType.NGO, label: "NGO" },
];

export const organizationSizeList = [
  { value: OrgSize.ONE_TO_FIVE, label: "1-5" },
  { value: OrgSize.FIVE_TO_THIRTY, label: "5-30" },
  { value: OrgSize.THIRTY_TO_HUNDRED, label: "30-100" },
  { value: OrgSize.HUNDRED_TO_THOUSAND, label: "100-1000" },
  { value: OrgSize.THOUSAND_PLUS, label: "1000+" },
];

export const paperOptions = [
  { value: PaperOptions.PAPER_DRIVEN, label: "Paper Driven" },
  { value: PaperOptions.ONLINE, label: "Online" },
  { value: PaperOptions.AUTOMATED, label: "Automated" },
];

export const printerOptions = [
  { value: PrinterOptions.ALL_THE_TIME, label: "All the time" },
  { value: PrinterOptions.ONCE_A_DAY, label: "Once a day" },
  { value: PrinterOptions.ONCE_A_WEEK, label: "Once a week" },
  { value: PrinterOptions.NOT_OFTEN, label: "Not often" },
  { value: PrinterOptions.IF_EVER, label: "If ever" },
];

export const socialMediaTypes = [
  { value: SocialLinkType.LINKEDIN, label: "LinkedIn" },
  { value: SocialLinkType.TWITTER, label: "Twitter" },
  { value: SocialLinkType.INSTAGRAM, label: "Instagram" },
  { value: SocialLinkType.WEBSITE, label: "Website" },
  { value: SocialLinkType.FACEBOOK, label: "Facebook" },
  { value: SocialLinkType.OTHER, label: "Other" },
];

export const statusList = [
  { value: Status.ACTIVE, label: "Active" },
  { value: Status.INACTIVE, label: "Inactive" },
  { value: Status.TRAINING, label: "Training" },
];

export const projectRoleList = [
  { value: ProjectRole.BUSINESS_SPONSOR, label: "Business Sponsor" },
  { value: ProjectRole.DECISION_MAKER, label: "Decision Maker" },
  { value: ProjectRole.SME, label: "Subject Matter Expert" },
  { value: ProjectRole.TECHNICAL_EXPERT, label: "Technical Expert" },
  { value: ProjectRole.PROJECT_OWNER, label: "Project Owner" },

  { value: ProjectRole.SALES, label: "Sales" },
];

export const projectStatusList = [
  { value: ProjectStatus.ACTIVE, label: "In Progress" },
  { value: ProjectStatus.INACTIVE, label: "Dead" },
  { value: ProjectStatus.COMPLETED, label: "Completed" },
  { value: ProjectStatus.CANCELLED, label: "Closed" },
  { value: ProjectStatus.NOT_STARTED, label: "Potential" },
  { value: ProjectStatus.PENDING, label: "PENDING" },
];

export const summaryOptionsList = [
  { value: SummaryType.INFORMATION, label: "Information" },
  { value: SummaryType.DECISION, label: "Decision" },
  { value: SummaryType.ASSUMPTION, label: "Assumption" },
  { value: SummaryType.AGREEMENT, label: "Agreement" },
];

export const riskList = [
  { value: Risk.RED, label: "Red" },
  { value: Risk.YELLOW, label: "Yellow" },
  { value: Risk.GREEN, label: "Green" },
  { value: Risk.BLUE, label: "Blue" },
  { value: Risk.BLACK, label: "Black" },
];

export const rolesResponsibilites: {
  role: ProjectRole;
  responsibilities: string[];
}[] = [
  {
    role: ProjectRole.BUSINESS_SPONSOR,
    responsibilities: [
      "Approve Business Case",
      "Approve Project Budget",
      "Chair the Steering Committee",
      "Attend Monthly Steering Committee Meeting",
      "Resolve any critical blockers on project, if needed",
    ],
  },
  {
    role: ProjectRole.DECISION_MAKER,
    responsibilities: [
      "Attend weekly status meeting and monthly steering meeting for project",
      "Manage project deliverable scope for organization",
      "Ensure project team has representation from right attendees",
      "Set priorities for the product backlog",
      "Provide final decisions for functionality options during sprints, when applicable",
      "Ensure action items are being completed by target dates by project team",
    ],
  },
  {
    role: ProjectRole.SME,
    responsibilities: [
      "Define deliverable scope",
      "Provide industry guidance on requirements",
      "Approve detailed calibration of deliverables",
      "Approve technical deliverables",
    ],
  },
  {
    role: ProjectRole.TECHNICAL_EXPERT,
    responsibilities: [
      "Provide technical overview of your organization's technology stack",
      "Ensure integration of project deliverables meet your organization's technical requirements",
    ],
  },
  {
    role: ProjectRole.PROJECT_OWNER,
    responsibilities: [
      "Will define and approve MoSCoW success criteria for project",
      "Will attend status meetings for client",
      "Will make final decisions for client",
      "Will set development priorities for client",
      "Will ensure functionality meets user expectations for organization",
      "Will ensure client stakeholders are aware of how project is progressing",
    ],
  },
  {
    role: ProjectRole.ADMIN,
    responsibilities: [
      "Define cloud architecture for project",
      "Manage project management tools",
      "Represent ZOIQ on client calls",
      "Scope requirements, define success criteria and define digital solutions with client",
      "Conduct initial deliverable testing, work with client on feedback and ensure solutions meet client expectations",
    ],
  },
];

export const fontsToInclude = [
  "https://fonts.googleapis.com/css2?family=Montaga&display=swap",
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/FONTS/LINKS/Soin%20Sans%20Pro",
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/FONTS/LINKS/Rollerscript%20Smooth",
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/FONTS/LINKS/Perpetua%20Titling%20MT",
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/FONTS/LINKS/Bebas%20Neue",
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/FONTS/LINKS/Proxima%20Nova",
];

export const styledGuides = [
  {
    name: "fontFamilyPrimary",
    value: "Poppins, sans-serif",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontFamilySecondary",
    value: "Architects Daughter, cursive",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontFamilyTertiary",
    value: "Architects Daughter, cursive",
    type: StyleGuideType.TYPOGRAPHY,
  },

  // ----- FONT SIZES ------

  {
    name: "fontSizeBanner",
    value: "44",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontSizeTitle",
    value: "36",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontSizeSubtitle",
    value: "28",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontSizeBody",
    value: "22",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontSizeSmall",
    value: "15",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontSizeLabel",
    value: "15",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontSizeNavbar",
    value: "15",
    type: StyleGuideType.TYPOGRAPHY,
  },
  // ----- FONT WEIGHT ------
  {
    name: "fontWeightBanner",
    value: "600",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontWeightTitle",
    value: "500",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontWeightSubtitle",
    value: "500",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "fontWeightBody",
    value: "400",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontWeightSmall",
    value: "400",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontWeightLabel",
    value: "400",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "fontWeightNavbar",
    value: "400",
    type: StyleGuideType.TYPOGRAPHY,
  },
  // ----- LINE HEIGHT ------
  {
    name: "lineHeightBanner",
    value: "1.2",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "lineHeightTitle",
    value: "1.4",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "lineHeightSubtitle",
    value: "1.4",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "lineHeightBody",
    value: "1.6",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "lineHeightSmall",
    value: "1.6",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "lineHeightLabel",
    value: "1.6",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "lineHeightNavbar",
    value: "1.6",
    type: StyleGuideType.TYPOGRAPHY,
  },
  // ----- LETTER SPACING ------
  {
    name: "letterSpacingBanner",
    value: 0.05,
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "letterSpacingTitle",
    value: 0.03,
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "letterSpacingSubtitle",
    value: 0.03,
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "letterSpacingBody",
    value: 0.03,
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "letterSpacingSmall",
    value: 0.02,
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "letterSpacingLabel",
    value: 0.03,
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "letterSpacingNavbar",
    value: 0.02,
    type: StyleGuideType.TYPOGRAPHY,
  },

  // ----- TEXT TRANSFORMS ------

  {
    name: "textTransformBanner",
    value: "uppercase",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "textTransformTitle",
    value: "uppercase",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "textTransformSubtitle",
    value: "none",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "textTransformBody",
    value: "none",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "textTransformSmall",
    value: "none",
    type: StyleGuideType.TYPOGRAPHY,
  },
  {
    name: "textTransformLabel",
    value: "none",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "textTransformNavbar",
    value: "none",
    type: StyleGuideType.TYPOGRAPHY,
  },

  {
    name: "primary",
    value: "#FCBF34",
    type: StyleGuideType.COLORS,
  },
  {
    name: "secondary",
    value: "#c62368",
    type: StyleGuideType.COLORS,
  },

  {
    name: "darkest",
    value: "#171717",
    type: StyleGuideType.COLORS,
  },
  {
    name: "dark",
    value: "#414141",
    type: StyleGuideType.COLORS,
  },
  {
    name: "medium",
    value: "#808080",
    type: StyleGuideType.COLORS,
  },
  {
    name: "light",
    value: "#E0E0E0",
    type: StyleGuideType.COLORS,
  },
  {
    name: "lightest",
    value: "#F5F5F5",
    type: StyleGuideType.COLORS,
  },
  {
    name: "white",
    value: "#FFFFFF",
    type: StyleGuideType.COLORS,
  },
  {
    name: "tabsTop",
    value: "true",
    type: StyleGuideType.FORMS,
  },
  {
    name: "inputPerRow",
    value: "1",
    type: StyleGuideType.FORMS,
  },
  {
    name: "size",
    value: "middle",
    type: StyleGuideType.FORMS,
  },
  {
    name: "labelAlign",
    value: "left",
    type: StyleGuideType.FORMS,
  },
  {
    name: "layout",
    value: "vertical",
    type: StyleGuideType.FORMS,
  },
];

export const backlogPriorities = [
  { label: "Low 👇", value: BacklogPriority.LOW },
  { label: "Medium ✋", value: BacklogPriority.MEDIUM },
  { label: "High 🚀", value: BacklogPriority.HIGH },
  { label: "Critical ⚠️", value: BacklogPriority.CRITICAL },
];
export const backlogStatuses = [
  { label: "To Do", value: BacklogStatus.TODO },
  { label: "In Progress", value: BacklogStatus.IN_PROGRESS },
  { label: "In Review", value: BacklogStatus.IN_REVIEW },
  { label: "Done", value: BacklogStatus.DONE },
];

export const subTaskStatuses = [
  { label: "Pending", value: SubtaskStatus.Pending },
  { label: "In Progress", value: SubtaskStatus.InProgress },
  { label: "Partially Completed", value: SubtaskStatus.PartiallyCompleted },
  { label: "Killed", value: SubtaskStatus.Killed },
  { label: "Completed", value: SubtaskStatus.Completed },
];

export const meetingFrequencies = [
  { label: "Weekly", value: FrequencyType.WEEKLY },
  { label: "Biweekly", value: FrequencyType.BIWEEKLY },
  { label: "Monthly", value: FrequencyType.MONTHLY },
  { label: "Quarterly", value: FrequencyType.QUARTERLY },
];

export const successCriteriaTypes = [
  { label: "Must Have", value: SuccessCriteriaType.MUST_HAVE },
  { label: "Could Have", value: SuccessCriteriaType.COULD_HAVE },
  { label: "Should Have", value: SuccessCriteriaType.SHOULD_HAVE },
  { label: "Won't Have", value: SuccessCriteriaType.WONT_HAVE },
];

export const weekDays = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

export const monthDates = [
  { label: "1st", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", value: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21st", value: "21" },
  { label: "22nd", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
  { label: "29th", value: "29" },
  { label: "30th", value: "30" },
  { label: "31st", value: "31" },
];

export const ASSET_TYPE_OPTIONS = [
  { label: "Branding", value: AssetType.ORGANIZATION },
  { label: "Staff Profiles", value: AssetType.TEAM },
  { label: "Media Files", value: AssetType.STOCK },
  { label: "Documents", value: AssetType.DOCUMENTS },
  { label: "Partner Logos", value: AssetType.PARTNERS_STOCK },
  { label: "Vectors", value: AssetType.VECTORS },
  { label: "Store Inventory", value: AssetType.STORE_INVENTORY },
];

// single constants
export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MAX_BUCKET_SIZE = 50000;

export const openAiHeaders = {
  organization: import.meta.env.VITE_OPENAI_ORG_KEY,
  Authorization: `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
  "Content-Type": "application/json",
};

// prompt tags for openai image generation
export const promptTags = [
  "Modern",
  "Vintage",
  "Funky",
  "Minimal",
  "Sophisticated",
  "Luxury",
  "Geometric",
  "Wordmark",
  "Pictorial",
  "Emblem",
  "Mascot",
  "Abstract",
  "Illustrative",
  "Symbolic",
  "Letterform",
  "Symmetrical",
];

export const audienceTypes = [
  { label: "B2B", value: AudienceType.B2B },
  { label: "Community", value: AudienceType.COMMUNITY },
  { label: "Non Profits", value: AudienceType.NON_PROFIT },
  { label: "Users", value: AudienceType.USERS },
  { label: "Donors", value: AudienceType.DONORS },
  { label: "Foundations", value: AudienceType.FOUNDATIONS },
];

export const solutionType = [
  { label: "Closed", value: "closed" },
  { label: "Open", value: "open" },
];

export const scope = [
  { label: "Organizational", value: "organizational" },
  { label: "Neighborhood", value: "neighborhood" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Region", value: "region" },
  { label: "National", value: "national" },
  { label: "International", value: "international" },
];

export const estUsersInFirstYear = [
  { label: "< 100", value: "< 100" },
  { label: "< 1000", value: "< 1000" },
  { label: "1000+", value: "1000+" },
];

export const userAgeGroups = [
  { label: "All Ages", value: "all" },
  { label: "Teens", value: "teens" },
  { label: "Children", value: "children" },
];

export const toneTypes = [
  { label: "Informative", value: ToneType.INFORMATIVE },
];

export const languageTypes = [
  { label: "Single", value: LanguageType.SINGLE },
  { label: "Multilingual", value: LanguageType.MULTILINGUAL },
];

enum LocationType {
  NORTH_AMERICA = "North America",
  LATIN_AMERICA = "Latin America",
  EUROPE = "Europe",
  ASIA = "Asia",
  OCEANIA = "Oceania",
}

export const locationTypes = [
  { label: "North America", value: LocationType.NORTH_AMERICA },
  { label: "Latin America", value: LocationType.LATIN_AMERICA },
  { label: "Europe", value: LocationType.EUROPE },
  { label: "Asia", value: LocationType.ASIA },
  { label: "Oceania", value: LocationType.OCEANIA },
];

export const audienceSizes = [
  { label: "Low 100 viewers a month", value: AudienceSize.LOW },
  { label: "Medium 1,000 viewers a month", value: AudienceSize.MEDIUM },
  { label: "High 1000-100,000 viewers a month", value: AudienceSize.HIGH },
  {
    label: "Very High 100,000+ viewers a month",
    value: AudienceSize.VERY_HIGH,
  },
];

export const suggestedSolutions = [
  { label: "New Website", value: SuggestedSolutions.NEW_WEBSITE },
  { label: "Website Redesign", value: SuggestedSolutions.WEBSITE_REDESIGN },
  { label: "App", value: SuggestedSolutions.APP },
  { label: "Other", value: SuggestedSolutions.OTHER },
];

export const projectFunds = [
  { label: "None", value: "None" },

  { label: "Raising Grants", value: "Raising Grants" },

  { label: "Small Donations", value: "Small Donations" },

  {
    label: "Medium to Large Organization",
    value: "Medium to Large Organization",
  },

  { label: "For-Profit Organization", value: "For-Profit Organization" },
];

export const relationshipWithLead = [
  { label: "< month", value: "< month" },

  { label: "< year", value: "< year" },

  { label: "year +", value: "year +" },
];

export const additionalBuildRequirements = [
  { label: "None", value: "None" },

  { label: "New Features", value: "New Features" },

  { label: "New Build", value: "New Build" },
];

export const estimatedNewFeatures = [
  { label: "< 5", value: "< 5" },

  { label: "< 10", value: "< 10" },

  { label: "< 15", value: "< 15" },
];

export const expertOnTheirSide = [
  { label: "None", value: "None" },

  { label: "Some", value: "Some" },

  { label: "Expert", value: "Expert" },
];

export const perceivedReadiness = [
  { label: "None", value: "None" },

  { label: "Less than 20%", value: "Less than 20%" },

  { label: "50/50", value: "50/50" },

  { label: "Greater than 80%", value: "Greater than 80%" },
];

export const availableColors = [
  {
    label: "Red",
    value: "#FF0000",
  },
  {
    label: "Orange",
    value: "#FFA500",
  },
  {
    label: "Yellow",
    value: "#FFFF00",
  },
  {
    label: "Green",
    value: "#008000",
  },
  {
    label: "Blue",
    value: "#0000FF",
  },
  {
    label: "Purple",
    value: "#800080",
  },
  {
    label: "Pink",
    value: "#FFC0CB",
  },
  {
    label: "Brown",
    value: "#A52A2A",
  },
  {
    label: "Black",
    value: "#000000",
  },
  {
    label: "White",
    value: "#FFFFFF",
  },
  {
    label: "Gray",
    value: "#808080",
  },
  {
    label: "Silver",
    value: "#C0C0C0",
  },
];

export const availableSizes = [
  {
    label: "Small",
    value: "S",
  },
  {
    label: "Medium",
    value: "M",
  },
  {
    label: "Large",
    value: "L",
  },
  {
    label: "Extra Large",
    value: "XL",
  },
];

export const brandingSubTypes = [
  {
    label: "Mobile",
    value: "MOBILE",
  },
  {
    label: "Desktop",
    value: "DESKTOP",
  },
  {
    label: "Main Logo",
    value: "MAIN_LOGO",
  },
  {
    label: "Favicon",
    value: "FAVICON",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

// @ts-ignore
export const adminData: User = {
  authID: "11ee8d52-0a6f-42c8-a976-66ff21854b5f",
  id: "c42fbd77-5510-49e0-aded-56db61a3bb7a",
  name: "Michael Russell",
  email: "michael.russell@zoiq.io",
};
// @ts-ignore
export const devData: User = {
  id: "489f5dd6-6720-4c35-85e5-31ac15c54a9d",
};

export const ZOIQ_ORG_ID = "0af93b60-5b7f-4946-a7c5-94874d1d95f8";

// KEYS

// FONT KEYS
export const FONT_KEY = "FONTS";

export const statusOptions = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Inactive",
    value: "INACTIVE",
  },
];

export const taskSortOptions = [
  {
    label: "Priority",
    value: "priority",
  },
  {
    label: "Target Date",
    value: "targetDate",
  },
  {
    label: "Created Date",
    value: "createdAt",
  },
];

export const listOfTemplates = [
  {
    name: "Clone LMS 🚀",
    id: uuidv4(),
    description:
      "For cloning of LMS - set up project on client portal, get image for login, get logo, define hex color, add project to npm run push",
    data: {
      title: "Project name",
      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
      description: `Clone the LMS project for a new client`,
      status: BacklogStatus.TODO,
      assignedTo: [adminData.id, devData.id],
      priority: BacklogPriority.MEDIUM,
      referralLinks: [
        "https://us-east-1.console.aws.amazon.com/lambda/home?region=us-east-1#/functions/createUser-t4p?tab=code",
        "https://us-east-1.console.aws.amazon.com/lambda/home?region=us-east-1#/functions/requestResetPassword-t4p?tab=code",
      ],

      subTasks: [
        {
          id: uuidv4(),
          task: "Clone a new repository -> https://github.com/ZOIQ/IconoclastArtistsReact_Base",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add login photo url assets.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add logo url to to assets.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add favicon url to assets.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update hex color of client to _variables.scss",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update organization name on strings.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update private policy name on login screen on strings.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update privacy policy for client on strings.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update banner urls (optional) on assets.ts",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update loader url on file assets.ts",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),

          task: "Run Amplify Init on client repository",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Run Amplify API, Amplify Auth, Amplify Storage",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Copy Lambdas from Base to add new user and reset password (requestResetPassword & createUser)",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Update client details on url.ts file on base repository",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Manually add admin to Cognito and DynamoDB (see Project Summary below on how to manually confirm user in Cognito)",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add test user from frontend and log-in as user",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add this in Rewrites and redirects section  </^[^.]+$|.(?!(css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$)([^.]+$)/>",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),

          task: "Create cognito user",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Create dynamo admin user",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Reset admin password by going localhost/forgot-password",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "After first getUser fetch. get the authID from graphql network and update it in dynamo ",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Create institution with random ids",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Create staff",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Add staff in institution",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),

          task: "Amplify add storage",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Copy ACL settings",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Copy the code for CORS",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),

          task: "Copy all settings from IA from permission settings",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),

          task: "Update auth id in allowedAuthIds in GlobalState.tsx",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),
          task: "Create a lambda function",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Give Auth permission to it",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Copy file code from IA",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "push code",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Go to console and create API Gateway",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Create a new API with name and description",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "add POST route",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "make sure integration has Payload format version as 2.0  if not.. delete and recreate",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Go to lambda console and add trigger API Gateway",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Select API name and stage",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: `Get the API endpoint (Also make sure CORS are correct. first three should be marked as "*")`,
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: `Force confirm admin user -> aws cognito-idp admin-set-user-password --user-pool-id us-east-1_y3VoIcpZC (replace from Cognito) --username john@limb.co (replace email) --password "Admin.123" --permanent  `,
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Add npm run push:[projectName] to Base code",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },

        {
          id: uuidv4(),

          task: `Before first build in aws,

          Go to general settings in app, 
          
          find service role AmplifyConsoleServiceRole-AmplifyRole and add it
          
          
          refer this link https://stackoverflow.com/a/76297103/11256338`,
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
        {
          id: uuidv4(),
          task: "Confirm the app built on Amplify",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
      ],
    },
  },

  {
    name: "New Build - Web 🖥️",
    id: uuidv4(),
    description:
      "For new build -  setup new mantine vite react app, pull src from warehouse, set up amplify project, set up style guide, define wireframe",
    data: {
      title: "Project name",
      status: BacklogStatus.TODO,
      assignedTo: [adminData.id, devData.id],
      priority: BacklogPriority.MEDIUM,

      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
      description: "Clone the LMS project for a new client",
      subTasks: [
        {
          id: uuidv4(),
          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.SHOULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
      ],
    },
  },
  {
    name: "New Build - App 📱",
    id: uuidv4(),
    description:
      "For new build -  setup new mantine vite react app, pull src from warehouse, set up amplify project, set up style guide, define wireframe",
    data: {
      title: "Project name",
      status: BacklogStatus.TODO,
      assignedTo: [adminData.id, devData.id],
      priority: BacklogPriority.MEDIUM,

      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
      description: "Clone the LMS project for a new client",
      subTasks: [
        {
          id: uuidv4(),
          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.SHOULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
      ],
    },
  },
  {
    name: "Project Completion - Web 🏁",
    id: uuidv4(),
    description: "For project completion",
    data: {
      title: "Project name",
      status: BacklogStatus.TODO,
      assignedTo: [adminData.id, devData.id],
      priority: BacklogPriority.MEDIUM,

      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
      description: "Clone the LMS project for a new client",
      subTasks: [
        {
          id: uuidv4(),
          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.SHOULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
      ],
    },
  },
  {
    name: "Project Completion - App 🏁",
    id: uuidv4(),
    description: "For project completion",
    data: {
      status: BacklogStatus.TODO,
      assignedTo: [adminData.id, devData.id],
      priority: BacklogPriority.MEDIUM,

      title: "Project name",
      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
      description: "Clone the LMS project for a new client",
      subTasks: [
        {
          id: uuidv4(),
          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.SHOULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.COULD_HAVE,
        },
        {
          id: uuidv4(),

          task: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio.",
          moscow: SuccessCriteriaType.MUST_HAVE,
        },
      ],
    },
  },
];

export const listStyleTypeOptions = [
  "armenian",
  "circle",
  "cjk-ideographic",
  "decimal",
  "decimal-leading-zero",
  "disc",
  "georgian",
  "hebrew",
  "hiragana",
  "hiragana-iroha",
  "katakana",
  "katakana-iroha",
  "lower-alpha",
  "lower-greek",
  "lower-latin",
  "lower-roman",
  "none",
  "square",
  "upper-alpha",
  "upper-latin",
  "upper-roman",
];

export const listStylePositionOptions = ["inside", "outside"];
export const borderRadiusOptions = ["xs", "sm", "md", "lg", "xl"];

export const previewList = [
  "Donec non ipsum in ligula aliquam mollis sit amet ac orci.",
  "Integer fermentum erat sed risus convallis scelerisque.",
  "Aliquam ac libero blandit, fermentum eros vitae, ornare lorem.",
  "Mauris tincidunt libero ut bibendum tristique.",
  "Nulla quis nunc ac ante sodales scelerisque.",
  "Phasellus et leo eget justo maximus pretium sit amet non arcu.",
];
