import { Activity, ModelActivityFilterInput } from "@/API";
import { listActivities } from "@/graphql/queries";
import useAuth from "@/hooks/useAuth";
import useUserData from "@/hooks/useData";
import { formatDate, formatTime } from "@/utils";
import { navVariants } from "@/utils/motions";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { Flex } from "@aws-amplify/ui-react";
import { IoMdAdd } from "react-icons/io";
let MobileMenu:
  | string
  | number
  | boolean
  | Iterable<React.ReactNode>
  | JSX.Element
  | null
  | undefined;

import {
  Badge,
  Button,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Layout,
  List,
  Menu,
  MenuProps,
  Popover,
  Space,
  Typography,
  theme,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { motion } from "framer-motion";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import { CiShare1 } from "react-icons/ci";
import { FaTasks } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import BuildInfo from "./BuildInfo";
import useLocalStorage from "@/hooks/useLocalstorage";
import { Job } from "@aws-sdk/client-amplify";
import useBreakpoint from "@/hooks/useBreakpoint";
import { JSX } from "react/jsx-runtime";
import { zoiqLogo } from "@/constants/assets";
const { Header } = Layout;

export const ActivityComponent = () => {
  const { data, isLoading, refetch } = useQuery("activity", async () => {
    const filter: ModelActivityFilterInput = {
      // date should be less last 7 days
      date: {
        gt: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
      },
    };
    const res: any = await API.graphql(
      graphqlOperation(listActivities, {
        limit: 50,
        filter,
      })
    );
    const items = res.data.listActivities.items as Activity[];
    return orderBy(items, "date", ["desc"]);
  });

  const menuStyle = {
    boxShadow: "none",
  };

  const [buildStatus] = useLocalStorage<null | Job>("buildStatus", null);

  return (
    <Popover
      overlayStyle={menuStyle}
      onOpenChange={(open) => {
        if (open) {
          refetch();
        }
      }}
      content={
        <>
          <BuildInfo />
          <Divider />

          <List
            style={{
              maxHeight: "25rem",
              overflowY: "auto",
            }}
            header={
              <Typography.Text strong>Past 7 days activity</Typography.Text>
            }
            loading={isLoading}
            size="small"
          >
            {data && data.length > 0 ? (
              data.map((activity) => (
                <List.Item
                  key={activity.id}
                  extra={
                    <>
                      {activity?.link && (
                        <Button
                          type="link"
                          onClick={() => {
                            window.open(activity?.link?.toString(), "_blank");
                          }}
                        >
                          View <CiShare1 />
                        </Button>
                      )}
                    </>
                  }
                >
                  <Space>
                    <Typography.Text>{activity.activity} by </Typography.Text>
                    <Typography.Text strong>
                      {activity.user?.name}
                    </Typography.Text>

                    <Typography.Text>
                      on {formatDate(activity.date)} {formatTime(activity.date)}
                    </Typography.Text>
                  </Space>
                </List.Item>
              ))
            ) : (
              <Empty
                description="No past 7 days activity found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </List>
        </>
      }
      placement="bottomRight"
    >
      <Badge
        color={buildStatus?.summary?.status === "RUNNING" ? "green" : "red"}
        count={data?.length}
        size="small"
      >
        <div
          className=""
          style={{
            cursor: "pointer",
          }}
        >
          Activity
        </div>
      </Badge>
    </Popover>
  );
};

export const UserDropdown = ({
  logout,
  refetchUser,
  name = "unknown",
  isZoiq = false,
  isAdmin,
  canAddProjects,
}: {
  logout?: () => void;
  refetchUser?: () => void;
  name?: string;
  isZoiq?: boolean;
  isAdmin?: boolean;
  canAddProjects?: boolean;
}) => {
  const profileUrl = `/profile`;

  const items = [
    {
      key: "view-profile",
      icon: <AiOutlineUser />,
      label: <NavLink to={profileUrl}>View Profile</NavLink>,
    },
    canAddProjects && {
      key: "register-org",
      icon: <IoMdAdd />,
      label: (
        <NavLink to={"/register/organization"}>Register Organization</NavLink>
      ),
    },
    canAddProjects && {
      key: "org-list",
      icon: <GoOrganization />,
      label: <NavLink to={"/organization-list"}>Organization List</NavLink>,
    },
    (isZoiq || isAdmin || canAddProjects) && {
      key: "tasks-for-zoiq",
      icon: <FaTasks />,
      label: <NavLink to={"/sprints"}>Sprints</NavLink>,
    },
    (isZoiq || isAdmin) && {
      key: "solutions",
      icon: <FaTasks />,
      label: <NavLink to={"/solutions"}>Solutions</NavLink>,
    },

    {
      key: "logout",
      danger: true,
      icon: <AiOutlineLogout />,
      label: (
        <div
          onClick={() => {
            logout?.();
            refetchUser?.();
          }}
        >
          Logout
        </div>
      ),
    },
  ].filter(Boolean) as MenuProps["items"];

  const { token } = theme.useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };
  return (
    <Dropdown
      dropdownRender={(menu) => (
        <div style={contentStyle} className="user-dropdown">
          <div
            className="dropdown-render "
            style={{
              padding: "0.5rem 1rem",
            }}
          >
            <Typography.Text>
              Hey <strong>{name}</strong>!
            </Typography.Text>
          </div>
          <Divider style={{ margin: 0 }} />
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
        </div>
      )}
      placement="bottomRight"
      menu={{ items }}
    >
      <Flex
        style={{ cursor: "pointer", color: "#fff" }}
        display={"flex"}
        gap={6}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <AiOutlineUser />
        <DownOutlined size={12} />
      </Flex>
    </Dropdown>
  );
};

const InnerNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  // hide logo on mobile
  @media (max-width: 768px) {
    .logo {
      display: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  background-color: inherit;
  border: none;
  width: 20rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  @media (min-width: 800px) {
    width: 30rem;
  }
  @media (min-width: 1200px) {
    width: 40rem;
  }
`;

const MobileStyledMenu = styled(Menu)`
  background-color: inherit;
  border: none;
`;

const AnimatedHeader = styled(motion(Header))`
  z-index: 30;
  background-color: transparent;
  border-bottom: var(--border);
  backdrop-filter: blur(10px);
  position: fixed;
  width: 100%;
  top: 0;
`;

const Navbar = () => {
  const { isLoggedIn, isAdmin, refetchUser, data: userData } = useUserData();

  const { logout } = useAuth();

  const items1: MenuProps["items"] = [
    {
      key: "1",
      label: "Home",
      url: "/",
    },

    {
      key: "3",
      label: "Project Calculator",
      url: "/project-calculator",
    },

    isLoggedIn && {
      key: "5",
      label: "Client Portal",
      url: "/client-portal",
    },
    !isLoggedIn && {
      key: "4",
      label: "Login",
      url: "/login",
    },

    isAdmin && {
      key: "admin-links",
      label: "Admin",

      children: [
        {
          key: "register-user",
          label: "Register User",
          url: "/register/user",
        },
        {
          key: "upload-font",
          label: "Upload Fonts",
          url: "/upload-fonts",
        },
        {
          key: "register-org",
          label: "Register Organization",
          url: "/register/organization",
        },
        {
          key: "user-list",
          label: "User List",
          url: "/user-list",
        },
        {
          key: "org-list",
          label: "Organization List",
          url: "/organization-list",
        },
      ],
    },
  ]
    .map((item) => {
      if (!item) return null;
      return {
        key: item.key,

        label: item.url ? (
          <NavLink
            onClick={() => {
              // scroll to top of page
              window.scrollTo(0, 0);
            }}
            to={item.url}
          >
            {item.label}
          </NavLink>
        ) : (
          item.label
        ),
        children: item?.children?.map((child) => ({
          key: child.key,
          label: (
            <NavLink
              onClick={() => {
                // scroll to top of page
                window.scrollTo(0, 0);
              }}
              to={child.url}
            >
              {child.label}
            </NavLink>
          ),
        })),
      };
    })
    .filter(Boolean);

  const [activeKey, setActiveKey] = useState("1");

  // find active key from url and set to active key state

  const clienPortals = ["client-portal", "project"];
  const adminLinks = [
    "register-user",
    "upload-font",
    "register-org",
    "user-list",
    "org-list",
  ];

  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/") {
      setActiveKey("1");
    } else if (path === "/project-calculator") {
      setActiveKey("3");
    } else if (path === "/login") {
      setActiveKey("4");
    } else if (
      clienPortals.some((portal) => path.includes(portal)) &&
      isLoggedIn
    ) {
      setActiveKey("5");
    } else if (adminLinks.some((link) => path.includes(link)) && isAdmin) {
      setActiveKey("admin-links");
    }
  }, [isLoggedIn, isAdmin]);

  useEffect(() => {
    if (activeKey) {
      if (activeKey === "2") {
        // find element with id our-mission. scroll to it
        const element = document.getElementById("our-mission");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else if (activeKey === "3") {
        const element = document.getElementById("contact");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        // navigate to that page
      }
    }
  }, [activeKey]);

  const { isExtraSmall } = useBreakpoint();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const openDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  if (isExtraSmall) {
    MobileMenu = (
      <Button type="primary" onClick={openDrawer} style={{ margin: "1rem" }}>
        <MenuOutlined />
      </Button>
    );
  }

  return (
    <AnimatedHeader
      viewport={{ once: true }}
      variants={navVariants}
      initial="hidden"
      whileInView="show"
    >
      <InnerNavbar style={{ paddingTop: 0, paddingBottom: 0 }}>
        <NavLink
          to="/"
          style={{
            flex: 1,
          }}
        >
          <img className="logo" src={zoiqLogo} alt="Zoiq Logo" width={100} />
        </NavLink>

        <Flex
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          flex={1}
        >
          {isExtraSmall ? (
            MobileMenu
          ) : (
            <StyledMenu
              onClick={(e: any) => setActiveKey(e.key.toString())}
              mode="horizontal"
              className="navbar"
              // defaultSelectedKeys={["1"]}
              items={items1}
              selectedKeys={[activeKey]}
            />
          )}
          <Flex
            gap={"medium"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {(Boolean(userData?.isZoiq) || Boolean(userData?.isAdmin)) && (
              <div
                style={{
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
                className=""
              >
                <ActivityComponent />
              </div>
            )}

            {isLoggedIn && (
              <UserDropdown
                canAddProjects={Boolean(userData?.canAddProjects)}
                name={userData?.name}
                isAdmin={Boolean(userData?.isAdmin)}
                isZoiq={Boolean(userData?.isZoiq)}
                logout={logout}
                refetchUser={refetchUser}
              />
            )}
          </Flex>
        </Flex>

        {isExtraSmall && (
          <Drawer
            title="Navigation"
            placement="right"
            onClose={closeDrawer}
            open={drawerVisible}
          >
            <MobileStyledMenu
              onClick={(e: any) => setActiveKey(e.key.toString())}
              mode="inline"
              className="navbar"
              items={items1}
              selectedKeys={[activeKey]}
            />
            <Flex
              gap={"medium"}
              display={"flex"}
              alignItems={"center"}
              padding={24}
              justifyContent={"space-between"}
            >
              {(Boolean(userData?.isZoiq) || Boolean(userData?.isAdmin)) && (
                <ActivityComponent />
              )}
              {isLoggedIn && (
                <UserDropdown
                  canAddProjects={Boolean(userData?.canAddProjects)}
                  name={userData?.name}
                  isAdmin={Boolean(userData?.isAdmin)}
                  isZoiq={Boolean(userData?.isZoiq)}
                  logout={logout}
                  refetchUser={refetchUser}
                />
              )}
            </Flex>
          </Drawer>
        )}
      </InnerNavbar>
    </AnimatedHeader>
  );
};

export default Navbar;
