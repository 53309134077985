import { Project } from "@/API";
import { HeroPatternWrapper } from "@/components/Hero";
import SiteMapComponent from "@/components/ProjectComponents/SiteMapComponent";
import { VideoBack } from "@/components/ZoiqVideo";
import { HERO_PATTERN_URL, VIDEO_PATTERN_URL } from "@/constants/assets";
import { getProjectName } from "@/customGraphql/customQueries";
import useUserData from "@/hooks/useData";
import AppLayout from "@/layout/AppLayout";
import SectionLayout from "@/layout/SectionLayout";
import { Button, Card, Dropdown } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const SiteMap = () => {
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const { isAdmin, isZoiq } = useUserData();

  const {
    data: projectData,
    isLoading: isProjectLoading,
    isFetched,
  } = useQuery(
    `project-view-name-${projectId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getProjectName, {
          id: projectId,
        })
      );
      return response.data.getProject as Project;
    },
    {
      enabled: !!projectId,
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const navigate = useNavigate();

  return (
    <AppLayout
      seo={{
        title: !isEmpty(projectData)
          ? `${projectData.name} - Site Map`
          : "Site Map",
      }}
    >
      <SectionLayout
        isLoading={isProjectLoading || !isFetched}
        backgroundElement={
          <>
            <HeroPatternWrapper>
              <img src={HERO_PATTERN_URL} alt="none" />
            </HeroPatternWrapper>
            <VideoBack bottom>
              <img src={VIDEO_PATTERN_URL} alt="video-back-bottom" />
            </VideoBack>
          </>
        }
        innerStyle={{
          minHeight: "100vh",
        }}
      >
        <Card
          extra={
            (isAdmin || isZoiq) && (
              <Dropdown
                menu={{
                  items: [
                    {
                      label: "Project List",
                      onClick: () => {
                        navigate("/client-portal");
                      },

                      key: "project-list",
                    },
                    {
                      label: "Project Brief",
                      onClick: () => {
                        navigate(`/project/edit/${projectId}`);
                      },
                      key: "project-brief",
                    },
                    {
                      label: "Project Page",
                      onClick: () => {
                        navigate(
                          `/project-view/${projectData?.organizationID}/${projectId}`
                        );
                      },
                      key: "project-page",
                    },
                  ],
                }}
              >
                <Button
                  shape="circle"
                  type="text"
                  icon={<BiDotsVerticalRounded />}
                  size="small"
                />
              </Dropdown>
            )
          }
          title="Site Map"
          className="card"
        >
          <SiteMapComponent />
        </Card>
      </SectionLayout>
    </AppLayout>
  );
};

export default SiteMap;
