import useBreakpoint from "@/hooks/useBreakpoint";
import { Affix, Pagination, PaginationProps } from "antd";
import { useEffect, useMemo, useState } from "react";

type CustomPaginationProps<T> = {
  items: T[];
  itemsPerPage?: number;
  itemsPerPageXXL?: number;
  children: (items: T[]) => JSX.Element;
  showSizeChanger?: PaginationProps["showSizeChanger"];
  showQuickJumper?: PaginationProps["showQuickJumper"];
  shouldAffix?: boolean;
  centered?: boolean;
};

function PaginationComponent<T>({
  items = [],
  itemsPerPage = 4,
  itemsPerPageXXL = 6,
  children,
  showSizeChanger = true,
  showQuickJumper = true,
  shouldAffix = false,
  centered = false,
}: CustomPaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState(1);

  const { isXXLarge, isLoading } = useBreakpoint();

  useEffect(() => {
    const pageSize = isLoading ? 4 : isXXLarge ? itemsPerPageXXL : itemsPerPage;
    setPageSize(pageSize);
  }, [isXXLarge, isLoading]);

  const [pageSize, setPageSize] = useState(itemsPerPage);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const currentItems = useMemo(
    () => items.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [currentPage, pageSize, items]
  );

  const [isAffixed, setIsAffixed] = useState(false);

  const component = (
    <div
      style={{
        marginTop: 16,
      }}
      className={`${isAffixed ? "blur" : ""}`}
    >
      <Pagination
        className={`${centered ? "center" : ""}`}
        total={items?.length}
        current={currentPage}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        pageSize={pageSize}
        onChange={handlePageChange}
      />
    </div>
  );

  return (
    <div
      style={{
        marginBottom: 16,
      }}
    >
      {children(currentItems.length > 0 ? currentItems : items)}
      {items?.length > pageSize &&
        (shouldAffix ? (
          <Affix
            offsetBottom={30}
            onChange={(affixed) => {
              shouldAffix && setIsAffixed(Boolean(affixed));
            }}
          >
            {component}
          </Affix>
        ) : (
          component
        ))}
    </div>
  );
}

export default PaginationComponent;
