const sidetab_tooltips = {
  media_files: "",
  documents: "",
  staff_profiles: "",
  partners_logos: "",
  branding: "",
  vector: "Upload background images, vectors, textures",
  store_inventory: "",
};

export const code_for_style_guide = {
  interface: `

  export interface StyleGuideValues {
    // Type - Typography
    fontFamilyPrimary: string;
    fontFamilySecondary: string;
    fontSizeBanner: number;
    fontSizeTitle: number;
    fontSizeSubtitle: number;
    fontSizeBody: number;
    fontWeightBanner: number;
    fontWeightTitle: number;
    fontWeightSubtitle: number;
    fontWeightBody: number;
    lineHeightBanner: number;
    lineHeightTitle: number;
    lineHeightSubtitle: number;
    lineHeightBody: number;
  
    // Type - Colors
    primary: string;
    primaryLight: string;
    primaryDark: string;
    secondary: string;
    dark: string;
    darker: string;
    lightGray: string;
    darkVariant: string;
    gray: string;
    secondaryBlack: string;
    grayVariant: string;
    secondaryWhite: string;
  
    // Type - Forms
    tabsTop: boolean;
    inputPerRow: 1 | 2;
    size: FormProps["size"];
    labelAlign: FormProps["labelAlign"];
    layout: FormProps["layout"];
  }

  export enum StyleGuideType {
    COLORS = "COLORS",
    TYPOGRAPHY = "TYPOGRAPHY",
    FORMS = "FORMS",
  }

  export type StyleGuide = {
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  };
  
  `,
  get_theme: `  const getTheme = (styleGuideData: StyleGuide[]) => {
    const styleGuide: any = {};
  
    styleGuideData.forEach((d) => {
      styleGuide[d.name] = d.value;
    });
  
    const styleGuideValues: StyleGuideValues = styleGuide;
  
    const token: ThemeConfig["token"] = {
      colorPrimary: styleGuideValues.primary,
      colorBgLayout: styleGuideValues.dark ?? colors.dark,
      borderRadius: 16,
      fontFamily: styleGuideValues?.fontFamilyPrimary ?? "Poppins, sans-serif",
      fontSize: Number(styleGuideValues?.fontSizeBody ?? 16),
      fontSizeHeading1: Number(styleGuideValues?.fontSizeBanner ?? 48),
      fontSizeHeading2: Number(styleGuideValues?.fontSizeTitle ?? 32),
      fontSizeHeading3: Number(styleGuideValues?.fontSizeSubtitle ?? 24),
      fontSizeHeading4: Number(styleGuideValues?.fontSizeBody ?? 16),
      lineHeightHeading1: Number(styleGuideValues?.lineHeightBanner ?? 1.2),
      lineHeightHeading2: Number(styleGuideValues?.lineHeightTitle ?? 1.2),
      lineHeightHeading3: Number(styleGuideValues?.lineHeightSubtitle ?? 1.2),
      lineHeightHeading4: Number(styleGuideValues?.lineHeightBody ?? 1.2),
    };
  
    document.documentElement.style.setProperty(
      "--font-primary",
      styleGuideValues.fontFamilyPrimary ?? "Poppins, sans-serif"
    );
    document.documentElement.style.setProperty(
      "--font-secondary",
      styleGuideValues.fontFamilySecondary ?? "Architects Daughter, cursive"
    );
  
    return {
      token,
    };
  };`,

  fetch_theme: (apiUrl: string) => `
  
  const [isFetchingTheme, setIsFetchingTheme] = useState(false)

  const [styleGuideData, setStyleGuideData] = useState({})

  const fetchTheme = async () => {
    setIsFetchingTheme(true)
    try {
      const response: any = await fetch(
        "${apiUrl}"
      );
      const data = await response.json();
      
      setStyleGuideData(data)

    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingTheme(false)
    }
  };

  useEffect(() => {
    fetchTheme();
  }, []);

  const { token } = getTheme(styleGuideData ?? []);
  `,
};

export const section_collapsable = {
  success_criteria:
    "Include the expectations, priorities and measurable criteria you have for this section.",
  site_content:
    "Add your copy and notes here that will help us meet your expectations regarding your message.",
  media_files:
    "Attach the images or videos you want us to include in this section.",
  tasks: "Lorem ipsum dolor sit amet",
  involvement:
    "Choose the involvement you need from our team so this section works for you.",
};

export const invalid_file_error = `you can not use social media links that have your embedded content.  Please upload the actual file or the link of the where the file is located.`;

export const organization_form_tooltips = {
  name: "The name of your organization",
  type: "The type of organization you are",
  organization_size: "The size of your organization",
  industry: "The industry your organization is in",
  mission: "Your organization's mission",
  address_line_1: "Your organization's address",
  address_line_2: "Your organization's address",
  city: "Your organization's city",
  state: "Your organization's state",
  zip: "Your organization's zip code",
  country: "Your organization's country",
  software: "Your organization's software",
  paper:
    "Provide estimate of how automated the organization is at the current time",
  printer:
    "To confirm automation, ask how many times the contact person uses their printer a week",
  contactName: "Your organization's contact name",
  contactPhone: "Your organization's contact phone number",
  contactEmail: "Your organization's contact email",
  socialMediaType: "The social media type",
  socialMediaLink: "The social media link",
};

export { sidetab_tooltips };
