import { Assets, Pages } from "@/API";
import { VIDEO_BACK, VIDEO_BACK_ERROR, videoAccept } from "@/constants/assets";
import { getImageFromS3 } from "@/utils";
import { Button, Divider, Empty, Flex, Image, Typography } from "antd";

import { useRef } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const ImageModule = ({ link, alt }: { link: string; alt: string }) => {
  return (
    <Image
      onLoad={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = VIDEO_BACK;
      }}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = VIDEO_BACK_ERROR;
      }}
      loading="lazy"
      style={{
        height: "25rem",
        width: "20rem",
        objectFit: "contain",
      }}
      src={getImageFromS3(link)}
      alt={alt}
    />
  );
};

const Wireframe = ({
  pages,
  assetData,
}: {
  pages: Pages[];
  assetData: Assets[];
}) => {
  const mapImages = () => {
    if (!assetData) return [];
    if (assetData.length === 0) return [];
    // map images in object with page name and their section media -> {name:"Test page", images: [...]}
    const images: any = [];
    pages.forEach((page) => {
      page.pageSections.forEach((section) => {
        const asset = assetData?.find(
          (d: any) => d?.id === section?.media?.[0]
        );
        if (!asset) return null;
        if (!asset.link) return null;
        if (
          videoAccept
            .concat("video/mp4, video/quicktime")
            .includes(asset.mediaType ?? "none")
        )
          return null;

        const index = images.findIndex((d: any) => d.pageName === page.name);
        if (index !== -1) {
          images[index].images.push({
            image: asset.link,
            name: section?.section,
            pageName: page.name,
          });
        } else {
          images.push({
            pageName: page.name,
            images: [
              {
                image: asset.link,
                name: section?.section,
                pageName: page.name,
              },
            ],
          });
        }
      });
    });
    return images;
  };

  const images = mapImages();

  const ref = useRef(null);

  const onMove = (offset: number) => {
    // move screen to right
    if (!ref.current) return;
    // @ts-ignore
    ref.current.scrollLeft += offset;
  };

  if (images.length === 0) {
    return <Empty description="No wireframe images found" />;
  }

  return (
    // show only images with title {section.section}
    <>
      <Divider />
      <div ref={ref} className="scrollport relative">
        {/* navigation buttons absolute on left and right */}
        {images.length > 3 && (
          <>
            <div className="left-btn">
              <Button
                shape="circle"
                type="primary"
                onClick={() => onMove(-150)}
                icon={<MdKeyboardArrowLeft />}
              />
            </div>
            <div className="right-btn">
              <Button
                shape="circle"
                type="primary"
                onClick={() => onMove(150)}
                icon={<MdKeyboardArrowRight />}
              />
            </div>
          </>
        )}

        {images.map(
          (
            img: {
              pageName: string;
              name?: string;
              images: { image: string; name: string; pageName: string }[];
              image?: string;
            },
            parentIdx: number
          ) =>
            img.images?.length > 0 ? (
              <Flex
                vertical
                style={{
                  height: "100%",
                  // if not last. add right border
                  borderRight:
                    parentIdx !== images.length - 1 ? "var(--border)" : "",
                  paddingRight: 12,
                }}
                gap={"large"}
              >
                {img.images.map((imgChild, idx) => (
                  <div className="img-container">
                    {idx === 0 && (
                      <div
                        className=""
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Typography.Title level={3}>
                          {parentIdx + 1}. {imgChild.pageName}
                        </Typography.Title>
                      </div>
                    )}
                    <div className="img">
                      <ImageModule
                        link={imgChild.image}
                        alt={imgChild.pageName}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: 12,
                      }}
                    >
                      <Typography.Text type="secondary">
                        {imgChild.name}
                      </Typography.Text>
                    </div>
                    {idx !== img?.images?.length - 1 && <Divider />}
                  </div>
                ))}
              </Flex>
            ) : (
              img?.image && (
                <div className="img-container">
                  <Typography.Title level={3}>{img.pageName}</Typography.Title>
                  <div className="img">
                    <ImageModule link={img.image} alt={img.pageName} />
                  </div>
                </div>
              )
            )
        )}
      </div>
    </>
  );
};

export default Wireframe;
