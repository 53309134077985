import styled from "styled-components";

interface ScrollerProps {
  width?: string;
  position?: string;
}

const StyledProgress = styled.div<ScrollerProps>`
  position: ${(props) => props.position || "fixed"};
  bottom: 0;
  left: 0;
  height: 2px;
  width: ${(props) => props.width}%;
  background: var(--color-gradient);
  z-index: 9999;

  //   hide for mobile
  @media (max-width: 768px) {
    display: none;
  }
`;

export default StyledProgress;
