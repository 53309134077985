import { GetPopupsQuery, Popups } from "@/API";

import { GraphQLResult } from "@aws-amplify/api-graphql";

import { getPopups } from "@/customGraphql/customQueries";
import { deletePopups } from "@/graphql/mutations";
import { getImageFromS3 } from "@/utils";
import {
  Button,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  MenuProps,
  Popconfirm,
  Spin,
  Typography,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useQuery } from "react-query";
import ManageSectionPopup from "./manage-section-popups";

const SectionPopup = ({
  projectID,
}: // refetch,

{
  projectID: string;
  // refetch: () => void;
}) => {
  const {
    data,
    isLoading,
    isFetched,
    refetch: refetchPopup,
  } = useQuery(
    ["getPopup", projectID],
    async () => {
      const response = (await API.graphql(
        graphqlOperation(getPopups, { id: projectID })
      )) as GraphQLResult<GetPopupsQuery>;
      return response?.data?.getPopups as Popups;
    },
    {
      enabled: !!projectID,
    }
  );

  const [isOpen, setIsOpen] = useState(false);

  const deletePopup = async () => {
    try {
      await API.graphql(
        graphqlOperation(deletePopups, {
          input: {
            id: projectID,
          },
        })
      );

      // const sections = page.pageSections?.map((item) => {
      //   if (item?.id === section.id) {
      //     return {
      //       ...item,
      //       popupID: null,
      //     };
      //   }
      //   return item;
      // }) as PageSection[];

      // setSelectedSection({
      //   ...section,
      //   popupID: null,
      // });

      // const input: UpdatePagesInput = {
      //   id: page.id,
      //   pageSections: sections,
      // };

      // await API.graphql(
      //   graphqlOperation(updatePages, {
      //     input,
      //   })
      // );

      // refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const dropdownItems: MenuProps["items"] = [
    {
      label: "Edit",
      key: "edit-popup",
      onClick: () => {
        setIsOpen(true);
      },
    },
    {
      label: (
        <Popconfirm
          title="Are you sure you want to delete this popup?"
          onConfirm={deletePopup}
        >
          Delete
        </Popconfirm>
      ),
      danger: true,
      key: "delete-popup",
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isOpen && (
        <ManageSectionPopup
          // setSelectedSection={setSelectedSection}
          // page={page}
          projectID={projectID}
          isOpen={isOpen}
          key={projectID}
          // refetch={() => {
          //   refetchPopup();
          //   refetch();
          // }}
          setIsOpen={setIsOpen}
          data={data}
          // section={section}
        />
      )}

      {isLoading || !isFetched ? (
        <Spin />
      ) : !data ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No flyer attached to this section"
        >
          <Button onClick={() => setIsOpen(true)} type="primary">
            Attach Flyer
          </Button>
        </Empty>
      ) : (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <Dropdown placement="bottomRight" menu={{ items: dropdownItems }}>
              <Button type="default" icon={<BiDotsVerticalRounded />} />
            </Dropdown>
          </div>
          <h1>{data.title}</h1>
          <p>{data.description}</p>

          {data.image && (
            <img
              src={getImageFromS3(data.image)}
              alt={data.title}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "8px",
                marginBottom: "1rem",
              }}
            />
          )}

          {data.expiryDate && (
            <p>Expires on: {new Date(data.expiryDate).toDateString()}</p>
          )}

          <Divider />

          <Descriptions bordered size="small" style={{ marginBottom: "1rem" }}>
            {data.content &&
              data.content?.map((item, index) => (
                <Descriptions.Item span={24} label={item?.name} key={index}>
                  {item?.value}
                </Descriptions.Item>
              ))}
          </Descriptions>
          <Divider />
          <Typography.Text
            copyable={{
              text: `https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/PROJECT_DATA/POPUP/${projectID}.json`,
            }}
          >
            Copy API URL
          </Typography.Text>
        </>
      )}
    </div>
  );
};

export default SectionPopup;
