import {
  ModelSuccessCriteriaFilterInput,
  PageSection,
  Project,
  SuccessCriteria,
  SuccessCriteriaType,
} from "@/API";
import useUserData from "@/hooks/useData";
import { Card, Empty, Modal, Spin, Tabs, Typography, message } from "antd";
import { useQuery } from "react-query";
import QuickButton from "../common/QuickButton";

import { deleteSuccessCriteria } from "@/graphql/mutations";

import { listSuccessCriteria } from "@/customGraphql/customQueries";
import { formatDate, sortBySequence } from "@/utils";
import { updateSortSeqMutation } from "@/utils/graphql";
import { Flex } from "@aws-amplify/ui-react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { TitleProps } from "antd/es/typography/Title";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import NewCriteria from "../RareComponents/NewCriteria";
import NotFoundInside from "../common/NotFoundInside";
import SortTable from "../common/SortComponents/SortTable";

export const TitleWithCount = ({
  title,
  count,
  style,
  level = 4,
  redOnZero = false,
}: {
  title: string | React.ReactNode;
  count: number | string;
  level?: TitleProps["level"];
  style?: React.CSSProperties;
  redOnZero?: boolean;
}) => {
  return (
    <Typography.Title style={style} level={level}>
      {title}{" "}
      <span
        style={{
          color: count === 0 && redOnZero ? "red" : "var(--primary)",
        }}
      >
        ({count})
      </span>
    </Typography.Title>
  );
};

interface ProjectSCProps {
  project: Project;
  pageId?: string;

  section?: PageSection;
}

const TabTable = ({
  projectId,
  isAdmin,
  isRefetching,
  data,
  columns,
}: {
  projectId: string;
  isAdmin: boolean;
  isRefetching: boolean;
  data: SuccessCriteria[];
  columns: any[];
}) => {
  return (
    <SortTable
      scroll={{ x: 1000 }}
      disabled={!isAdmin}
      shouldUpdateList={isRefetching}
      onDragEndSuccess={(updated) => {
        const newSeq: string[] = updated.map(
          (item: { id: UniqueIdentifier }) => item.id
        );
        updateSortSeqMutation(projectId, newSeq, "projectCriteriaSortSeq");
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

export const fetchSuccessCriteriaData = async (
  project: Project,
  pageId?: string,
  sectionId?: string
) => {
  const filter: ModelSuccessCriteriaFilterInput =
    !isEmpty(pageId) && !isEmpty(sectionId)
      ? {
          projectID: {
            eq: project.id,
          },
          pageId: {
            eq: pageId,
          },
          sectionId: {
            eq: sectionId,
          },
        }
      : {
          projectID: {
            eq: project.id,
          },
        };

  const response: any = await API.graphql(
    graphqlOperation(listSuccessCriteria, {
      filter,
    })
  );
  const items = (response.data.listSuccessCriteria.items ?? []).filter(Boolean);

  const sortedCriteria =
    project.projectCriteriaSortSeq && project.projectCriteriaSortSeq?.length > 0
      ? sortBySequence(items, project.projectCriteriaSortSeq as string[])
      : items;

  return sortedCriteria as SuccessCriteria[];
};

const ProjectSC = ({ project, pageId, section }: ProjectSCProps) => {
  const { isAdmin: rawisAdmin, isDecisionMaker } = useUserData();
  const isAdmin = rawisAdmin || isDecisionMaker(project.id);
  const sectionId = section?.id;
  const ref = useRef<HTMLDivElement>(null);

  const {
    data: successCriteriaData,
    isLoading,
    isError,
    isFetched,
    error,
    isRefetching,
    refetch,
  } = useQuery(
    `project-sc-${project.id}-${pageId}-${sectionId}`,
    () => fetchSuccessCriteriaData(project, pageId, sectionId),
    {
      enabled: !!project.id,
    }
  );

  const [editSuccessCriteriaData, setEditSuccessCriteriaData] =
    useState<SuccessCriteria>({} as SuccessCriteria);

  const [successCriteriaModal, setSuccessCriteriaModal] = useState(false);

  const onModalCancel = () => {
    setSuccessCriteriaModal(false);
    setEditSuccessCriteriaData({} as SuccessCriteria);
  };

  const onSuccessMutation = () => {
    onModalCancel();
    refetch();
  };

  const [messageApi, contextHolder] = message.useMessage();

  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onDeleteSuccessCriteria = async (id: string) => {
    try {
      const sortKeys = project.projectCriteriaSortSeq?.filter(
        (item) => item !== id
      ) as string[];
      updateSortSeqMutation(project.id, sortKeys, "projectCriteriaSortSeq");
      await API.graphql(
        graphqlOperation(deleteSuccessCriteria, {
          input: {
            id,
          },
        })
      );
      messageApi.success("Criteria deleted successfully");
      refetch();
    } catch (error) {
      console.error(error);

      messageApi.error("Something went wrong deleting criteria");
    }
  };

  const criteriaColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Measurable Criteria",
      dataIndex: "measurableCriteria",
      key: "measurableCriteria",
      // render it as plain raw html inside span element
      render: (measurableCriteria: string) => (
        <Typography.Text>
          <span dangerouslySetInnerHTML={{ __html: measurableCriteria }} />
        </Typography.Text>
      ),
    },

    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (createdAt: string) => formatDate(createdAt),
    },
    isAdmin && {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: SuccessCriteria) => (
        <Flex
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={12}
        >
          <QuickButton
            type="link"
            icon={<AiOutlineEdit />}
            onMouseDown={() => {
              setEditSuccessCriteriaData(record);
              setSuccessCriteriaModal(true);
            }}
          >
            Edit
          </QuickButton>

          <QuickButton
            onMouseDown={() => {
              setDeleteId(record.id);
              setDeleteModal(true);
            }}
            type="link"
            danger
            icon={<AiOutlineDelete />}
          >
            Delete
          </QuickButton>
        </Flex>
      ),
    },
  ].filter(Boolean) as any[];

  const tableProps = {
    projectId: project.id,
    isAdmin,
    isRefetching,

    columns: criteriaColumns,
  };

  const getData = (type: SuccessCriteriaType) =>
    successCriteriaData?.filter((item) => item.type === type);

  const mustHaveData = useMemo(
    () => getData(SuccessCriteriaType.MUST_HAVE),
    [successCriteriaData]
  );
  const shouldHaveData = useMemo(
    () => getData(SuccessCriteriaType.SHOULD_HAVE),
    [successCriteriaData]
  );
  const couldHaveData = useMemo(
    () => getData(SuccessCriteriaType.COULD_HAVE),
    [successCriteriaData]
  );
  const wontHaveData = useMemo(
    () => getData(SuccessCriteriaType.WONT_HAVE),
    [successCriteriaData]
  );

  return (
    <Card
      ref={ref}
      style={{ width: "100%" }}
      id="moscow"
      title={
        !isEmpty(pageId) && !isEmpty(sectionId)
          ? ``
          : "Project Success Criteria"
      }
      extra={
        isAdmin && (
          <QuickButton
            type="primary"
            onClick={() => {
              setSuccessCriteriaModal(true);
            }}
          >
            Add new criteria
          </QuickButton>
        )
      }
      className="card"
    >
      {contextHolder}
      <Modal
        width={800}
        title={
          isEmpty(editSuccessCriteriaData)
            ? "Add new criteria"
            : "Edit criteria"
        }
        open={successCriteriaModal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        onCancel={onModalCancel}
      >
        <NewCriteria
          pageId={pageId}
          sectionId={sectionId}
          project={project}
          onModalCancel={onModalCancel}
          editCriteriaData={editSuccessCriteriaData}
          onSuccessMutation={onSuccessMutation}
        />
      </Modal>
      <Modal
        title={"Delete criteria"}
        open={deleteModal}
        onOk={() => {
          onDeleteSuccessCriteria(deleteId);
          setDeleteModal(false);
        }}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteId("");
        }}
      >
        <p>Are you sure you want to delete this criteria?</p>
      </Modal>

      {isLoading && !isFetched ? (
        <Spin>Loading criterias..</Spin>
      ) : isError ? (
        <NotFoundInside
          extra={""}
          subTitle={`Error: ${JSON.stringify(error)}`}
        />
      ) : isEmpty(successCriteriaData) ? (
        <Empty
          description={`No criterias found. ${
            isAdmin ? "Add a new criteria to get started." : ""
          }`}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Tabs
          items={[
            {
              label: (
                <TitleWithCount
                  title={`Must Have`}
                  count={mustHaveData?.length ?? 0}
                />
              ),
              key: "must-have",
              children: <TabTable data={mustHaveData ?? []} {...tableProps} />,
            },
            {
              label: (
                <TitleWithCount
                  title={`Should Have`}
                  count={shouldHaveData?.length ?? 0}
                />
              ),
              key: "should-have",
              children: (
                <TabTable data={shouldHaveData ?? []} {...tableProps} />
              ),
            },
            {
              label: (
                <TitleWithCount
                  title={`Could Have`}
                  count={couldHaveData?.length ?? 0}
                />
              ),

              key: "could-have",
              children: <TabTable data={couldHaveData ?? []} {...tableProps} />,
            },

            {
              label: (
                <TitleWithCount
                  title={`Won't Have`}
                  count={wontHaveData?.length ?? 0}
                />
              ),
              key: "wont-have",
              children: <TabTable data={wontHaveData ?? []} {...tableProps} />,
            },
          ]}
        />
      )}
    </Card>
  );
};

export default ProjectSC;
