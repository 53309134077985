import { Grid } from "antd";
import { isEmpty } from "lodash";

const useBreakpoint = () => {
  const screens = Grid.useBreakpoint();

  const isExtraSmall = Boolean(screens.xs);
  const isSmall = Boolean(screens.sm);
  const isMedium = Boolean(screens.md);
  const isLarge = Boolean(screens.lg);
  const isXLarge = Boolean(screens.xl);
  const isXXLarge = Boolean(screens.xxl);

  return {
    isLoading: isEmpty(screens),
    ...screens,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    isXXLarge,
  };
};

export default useBreakpoint;
