import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

import { Tooltip, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { VideoAsset } from "@/components/MediaAssets";
import { MdOutlineOndemandVideo } from "react-icons/md";

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging ? (
        originNode.props.children
      ) : file.type?.includes("video") && file.url ? (
        <Tooltip title={file.name}>
          <div
            style={{
              position: "relative",
            }}
            className="ant-upload-list-item ant-upload-list-item-done"
          >
            <div
              style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "2rem",
              }}
              className=""
            >
              <MdOutlineOndemandVideo />
            </div>

            <VideoAsset
              controls={false}
              hideBorderRadius
              autoPlay={false}
              height="100%"
              src={file.url}
            />
          </div>
        </Tooltip>
      ) : (
        originNode
      )}
    </div>
  );
};

const SortableUploadList = ({
  children,
  fileList,
  setFileList,
  onDragEndSuccess,
  ...restProps
}: UploadProps & {
  fileList: UploadFile<any>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  onDragEndSuccess: (fn: any) => void;
}) => {
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = fileList.findIndex((i) => i?.uid === active.id);
      const overIndex = fileList.findIndex((i) => i?.uid === over?.id);

      const result = arrayMove(fileList, activeIndex, overIndex);
      onDragEndSuccess(result);
    }
  };

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext
        items={fileList.map((i) => i?.uid)}
        strategy={horizontalListSortingStrategy}
      >
        <Upload
          itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
          )}
          fileList={fileList}
          {...restProps}
        >
          {children}
        </Upload>
      </SortableContext>
    </DndContext>
  );
};

export default SortableUploadList;
