import { Spin } from "antd";
import { useEffect } from "react";

const SideLoader = ({ isLoading }: { isLoading: boolean }) => {
  useEffect(() => {
    if (!isLoading) {
      return;
    }
    // prevent user from navigating away
    window.onbeforeunload = () => true;
    return () => {
      window.onbeforeunload = null;
    };
  }, [isLoading]);
  if (!isLoading) {
    return null;
  }

  return (
    <div className="side-loader">
      <Spin />
    </div>
  );
};

export default SideLoader;
