import {
  Organization,
  ProjectPlan,
  SprintType,
  SubtaskStatus,
  SuccessCriteriaType,
} from "@/API";
import {
  backlogPriorities,
  subTaskStatuses,
  successCriteriaTypes,
} from "@/constants/staticData";
import { useTaskContext } from "@/contexts/TaskContexts";
import { listOrganizations } from "@/customGraphql/customQueries";
import { listProjectPlans } from "@/graphql/queries";
import { v4 as uuidv4 } from "uuid";
import useUserData from "@/hooks/useData";
import { getImageFromS3 } from "@/utils";
import { Empty, Form, Input, Modal, Select, Tooltip, UploadFile } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import ImageUpload from "../Upload/image-upload";
import { onUpload } from "@/utils/graphql";

const EditSubtaskModule = () => {
  const { modules, subTaskActions } = useTaskContext();

  const { editSubtaskModule, setEditSubtaskModule } = modules;
  const { isAdmin, isZoiq, authID, canAddProjects } = useUserData();

  const { data: organizations } = useQuery(
    "organizations-modal-task-modal",
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(listOrganizations, {
          limit: 1000,
        })
      );
      const items = response.data.listOrganizations.items as Organization[];

      const mapped =
        isAdmin || isZoiq
          ? items
          : items.filter((org) => {
              const isCreator = org?.authID === authID;
              const isMember = org?.user?.items?.some(
                (member) => member?.authID === authID
              );
              return isCreator || isMember;
            });
      return mapped;
    },
    {
      enabled: !!authID,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const organizationsMapped = useMemo(
    () =>
      organizations?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [organizations]
  );

  const allProjects = organizations
    ?.map((item) => item?.projects?.items.map((d) => d))
    .flat();

  const [form] = Form.useForm();

  Form.useWatch("organizationID", form);
  Form.useWatch("projectID", form);

  const projectId = form.getFieldValue("projectID");
  const organizationId = form.getFieldValue("organizationID");

  const getProjectsList = useCallback(() => {
    if (organizationId && allProjects) {
      const filteredList = allProjects?.filter(
        (item) => item?.organizationID === organizationId
      );
      return filteredList?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      });
    }
    return (
      allProjects?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      }) || []
    );
  }, [organizationId, allProjects, organizations]);

  const milestonesQuery = useQuery(
    ["milestones", projectId],
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(listProjectPlans, {
          filter: {
            projectID: { eq: projectId },
          },
        })
      );
      return response.data.listProjectPlans.items as ProjectPlan[];
    },
    {
      enabled: !!projectId,
    }
  );

  const milestonesMapped = useMemo(() => {
    return milestonesQuery.data?.map((item) => ({
      label: item.milestone,
      value: item.id,
    }));
  }, [milestonesQuery.data, milestonesQuery.isLoading]);

  const onSubmit = () => {
    form.submit();
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (editSubtaskModule.subTask?.mediaId) {
      setFileList([
        {
          uid: editSubtaskModule.subTask?.mediaId,
          name: "Image",
          status: "done",
          url: getImageFromS3(editSubtaskModule.subTask?.mediaId),
        },
      ]);
    }
  }, [editSubtaskModule.subTask?.mediaId]);

  const [hasImageChanged, setHasImageChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const isEdit = !!editSubtaskModule.subTask?.id;

  const onFinish = async (values: {
    task: string;
    moscow: string;
    mediaId?: string;
  }) => {
    let modifiedValues = values;
    setIsLoading(true);
    if (hasImageChanged && fileList.length > 0) {
      const id = editSubtaskModule.subTask?.id ?? uuidv4();
      const key = `sprints/${id}`;

      const link = await onUpload(fileList[0].originFileObj, key);

      modifiedValues.mediaId = link;
    }

    const input = {
      ...modifiedValues,
      sprintType: editSubtaskModule.backlog?.id
        ? SprintType.ACTIVE
        : SprintType.BACKLOG,
      backlogID: editSubtaskModule.backlog?.id,
      mediaId: modifiedValues.mediaId,
    };
    if (isEdit) {
      if (!editSubtaskModule.subTask?.id) return;
      subTaskActions.editSubTask({
        id: editSubtaskModule.subTask?.id,
        ...input,
      });
    } else {
      subTaskActions.createSubTask({
        ...input,
      });
    }

    setIsLoading(false);
    setEditSubtaskModule({
      backlog: null,
      subTask: null,
      show: false,
    });
  };

  const imagesOptions = editSubtaskModule.backlog
    ? editSubtaskModule.backlog?.media?.map((media) => ({
        label: (
          <img
            src={getImageFromS3(media?.mediaLink!)}
            alt="media"
            className="h-8 w-8 rounded-lg  bg-gray-700"
          />
        ),

        value: media?.id,
      }))
    : [];

  return (
    <Modal
      title={isEdit ? "Edit Deliverable" : "Add Deliverable"}
      open
      okText={isEdit ? "Update" : "Add"}
      onOk={onSubmit}
      okButtonProps={{
        loading: isLoading,
      }}
      destroyOnClose
      onCancel={() => {
        setEditSubtaskModule({
          backlog: null,
          subTask: null,
          show: false,
        });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={
          isEdit
            ? {
                ...editSubtaskModule.subTask,
              }
            : {
                task: "",
                moscow: SuccessCriteriaType.COULD_HAVE,
                organizationID: organizationId,
                projectID: projectId,
              }
        }
        onFinish={onFinish}
      >
        <Tooltip
          title={
            form.getFieldValue("status") === SubtaskStatus.Completed
              ? "Cannot upload files for completed deliverables. Please change the status to upload files"
              : ""
          }
        >
          <Form.Item>
            <ImageUpload
              disabled={
                form.getFieldValue("status") === SubtaskStatus.Completed
              }
              fileList={fileList}
              setFileList={(files) => {
                setFileList(files);
                setHasImageChanged(true);
              }}
            />
          </Form.Item>
        </Tooltip>

        <Form.Item label="Summary" name={"task"} rules={[{ required: true }]}>
          <Input.TextArea rows={2} placeholder="Enter task details" />
        </Form.Item>

        <Form.Item
          name={"organizationID"}
          rules={[
            {
              required: true,
              message: "Please select an organization",
            },
          ]}
          label={"Organization"}
        >
          <Select
            placeholder={"Select organization"}
            allowClear
            onChange={() => {
              form.setFieldsValue({
                projectID: undefined,
                projectPlanID: undefined,
                pageID: undefined,
                sectionID: undefined,
              });
            }}
            options={organizationsMapped}
          />
        </Form.Item>

        <Form.Item
          rules={
            canAddProjects
              ? [
                  {
                    required: true,
                    message: "Please select a project",
                  },
                ]
              : []
          }
          name={"projectID"}
          label={"Project"}
        >
          <Select
            allowClear
            onChange={() => {
              form.setFieldsValue({
                projectPlanID: undefined,
                pageID: undefined,
                sectionID: undefined,
              });
            }}
            disabled={!organizationId}
            placeholder={"Select project"}
            options={getProjectsList()}
          />
        </Form.Item>

        <Form.Item name={"projectPlanID"} label={"Milestone"}>
          <Select
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  projectId
                    ? "No milestones found for this project"
                    : "No milestones found"
                }
              />
            }
            allowClear
            disabled={!projectId}
            loading={milestonesQuery.isLoading}
            placeholder={"Select milestone"}
            options={milestonesMapped}
          />
        </Form.Item>

        <Form.Item label="Success Criteria" name={"moscow"}>
          <Select
            allowClear
            placeholder={"Select success criteria"}
            options={successCriteriaTypes}
          />
        </Form.Item>

        {editSubtaskModule.backlog && (
          <Form.Item name={"mediaId"}>
            <Select
              allowClear
              options={imagesOptions}
              placeholder="Select image"
              defaultValue={[]}
            />
          </Form.Item>
        )}
        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please enter a priority",
            },
          ]}
        >
          <Select placeholder={"Select priority"} options={backlogPriorities} />
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please enter a status",
            },
          ]}
        >
          <Select options={subTaskStatuses} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSubtaskModule;
