import {
  BacklogPriority,
  BacklogStatus,
  ProjectRole,
  ProjectStatus,
  Risk,
  Status,
  StyleGuide,
  SuccessCriteriaType,
  SummaryType,
} from "@/API";
import awsconfig from "@/aws-exports";
import { NAVBAR_SIZE } from "@/constants/assets";
import {
  DATE_FORMAT,
  TIME_FORMAT,
  backlogStatuses,
} from "@/constants/staticData";
import { TagProps } from "antd";
import { RcFile } from "antd/es/upload";
import { Storage } from "aws-amplify";
import Compressor from "compressorjs";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import timezonePlugin from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { capitalize, orderBy } from "lodash";
dayjs.extend(utc);
dayjs.extend(timezonePlugin);

const dayJsFix = () => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);
};

const scrollTo = (id: string, offset: number = 0) => {
  const element = document.getElementById(id);
  // check if ourMission is null
  if (!element) {
    throw new Error(`Element with id ${id} is not found`);
  }
  const BUFFER = 20;
  const offsetTop = element.offsetTop - NAVBAR_SIZE - BUFFER - offset;

  window.scrollTo({
    top: offsetTop,
    behavior: "smooth",
  });
};

// make a function to remove px from the end of a string
const removePx = (str: string) => {
  return str ? str.slice(0, str.length - 2) : "16";
};

function splitStringOnCaps(str: string) {
  if (!str) return str;
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

function getGithubRepoName(url: string) {
  const regex = /https:\/\/github\.com\/\S+\/(\S+)\.git/;
  const match = regex.exec(url);
  if (match !== null) {
    return match[1].replace(/[A-Z]/g, " $&").trim();
  } else {
    url = url.split("/").pop() ?? "";
    return url;
  }
}

const wrapEmail = (email: string, name?: string) => (
  <a href={`mailto:${email}?subject=Hello ${name ?? ""}`}>{email}</a>
);

const wrapPhone = (phone: string) => <a href={`tel:${phone}`}>{phone}</a>;

function isValidGithubRepo(url: string) {
  const regex =
    /^https:\/\/github\.com\/[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+(\/)?(\.git)?$/;
  return regex.test(url);
}

// parse ProjectRole items
const parseProjectRole = (role: ProjectRole) => {
  switch (role) {
    case ProjectRole.BUSINESS_SPONSOR:
      return "Business Sponsor";
    case ProjectRole.DECISION_MAKER:
      return "Decision Maker";
    case ProjectRole.SME:
      return "Subject Matter Expert";
    case ProjectRole.TECHNICAL_EXPERT:
      return "Technical Expert";
    case ProjectRole.PROJECT_OWNER:
      return "Project Owner";
    case ProjectRole.ADMIN:
      return "Admin";
    default:
      return "Unknown Role";
  }
};
// parse Status items
const parseUserStatus = (status: Status) => {
  switch (status) {
    case Status.ACTIVE:
      return "Active";
    case Status.INACTIVE:
      return "Inactive";
    case Status.TRAINING:
      return "Training";

    default:
      return "Inactive";
  }
};
const generateProjectStatusColor = (
  status: ProjectStatus,
  risk: Risk
): TagProps["color"] => {
  const _risk = risk?.toLowerCase();
  switch (status) {
    case ProjectStatus.ACTIVE:
      return _risk || "green";

    case ProjectStatus.INACTIVE:
    case ProjectStatus.NOT_STARTED:
      return _risk || "purple";

    case ProjectStatus.COMPLETED:
      return "black";
    case ProjectStatus.PENDING:
      return "blue";

    case ProjectStatus.CANCELLED:
      return "red-inverse";

    default:
      return "default";
  }
};

const parseProjectStatus = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.NOT_STARTED:
      return "NOT STARTED";

    default:
      return status;
  }
};

const generateUserStatusColor = (status: string) => {
  switch (status) {
    case Status.ACTIVE:
      return "green";

    case Status.INACTIVE:
      return "blue";

    case Status.TRAINING:
      return "yellow";

    default:
      return "blue";
  }
};

const generateSummaryTypeColor = (type: SummaryType): TagProps["color"] => {
  switch (type) {
    case SummaryType.INFORMATION:
      return "blue";
    case SummaryType.ASSUMPTION:
      return "yellow";
    case SummaryType.DECISION:
      return "green";
    case SummaryType.AGREEMENT:
      return "purple";
    default:
      return "blue";
  }
};

// format dayJs date
const formatDate = (date: string) => {
  return date
    ? dayjs(date).isValid()
      ? dayjs(date).format(DATE_FORMAT)
      : "TBD"
    : "TBD";
};
// format dayJs time
const formatTime = (time: string) => {
  return time
    ? dayjs(time).isValid()
      ? dayjs(time).format(TIME_FORMAT)
      : "TBD"
    : "TBD";
};

const sortBySequence = <T extends { id: string }>(
  items: T[],
  seqArray: string[]
) =>
  orderBy(items, (item) =>
    seqArray && seqArray.length > 0 ? seqArray.indexOf(item?.id) : []
  );

export interface S3UploadOptions {
  onSuccess?: (result: Object) => void;
  onError?: (error: Error) => void;
  progressCallback?: ({
    loaded,
    total,
    progress,
  }: {
    loaded: number;
    total: number;
    progress: number;
  }) => void;
}

const getStyleGuideValue = (styleGuides: StyleGuide[], value: string) =>
  styleGuides.find((guide) => guide.name === value)?.value ?? "";

const getStyleGuideEnvValue = (styleGuides: StyleGuide[], key: string) => {
  const variables = getStyleGuideValue(styleGuides, "variables");

  if (!variables) return "";
  const parse = JSON.parse(variables);
  return parse[key];
};

const uploadFileToS3 = async (
  file: File,
  key: string,
  type: string,
  options?: S3UploadOptions
) => {
  try {
    const result = await Storage.put(key, file, {
      contentType: type,
      level: "public",
      acl: "public-read-write",
      contentEncoding: type.includes("font") ? undefined : "base64",
      cacheControl: "max-age=31536000",

      progressCallback: ({ loaded, total }: any) => {
        const progress = (loaded * 100) / total;
        options?.progressCallback?.({ progress, loaded, total });
      },
    });

    if (options?.onSuccess && typeof options?.onSuccess === "function") {
      options.onSuccess(result);
    }

    return result;
  } catch (error) {
    if (options?.onError && typeof options?.onError === "function") {
      // if there is a error callback, call the onError function
      options.onError(error as Error);
    } else {
      // otherwise throw the error to console
    }
    console.error(error);

    return "";
  }
};

const uploadFileToS3Optimised = async (
  file: File,
  key: string,
  type: string,
  options?: S3UploadOptions
) => {
  try {
    const result: File | Blob = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        async success(result) {
          resolve(result);
        },
        error(error) {
          reject(error.message);
        },
      });
    });

    const resultFile = new File([result], result.name, {
      type: result.type,
    });

    // Upload original file
    const resultOriginal = await Storage.put(key, resultFile, {
      contentType: type,
      level: "public",
      acl: "public-read-write",
      contentEncoding: "base64",
      cacheControl: "max-age=31536000",
      progressCallback: ({ loaded, total }: any) => {
        const progress = (loaded * 100) / total;
        options?.progressCallback?.({ progress, loaded, total });
      },
    });

    // console original size and compressed size
    console.log(
      "Original File Size:",
      convertToMB(file.size),
      "MB",
      "Compressed File Size:",
      convertToMB(resultFile.size),
      "MB"
    );

    if (options?.onSuccess && typeof options?.onSuccess === "function") {
      options.onSuccess(resultOriginal);
    }
    return resultOriginal;
  } catch (error) {
    if (options?.onError && typeof options?.onError === "function") {
      // if there is a error callback, call the onError function
      options.onError(error as Error);
    } else {
      // otherwise throw the error to console
      console.error(error);
    }

    return "";
  }
};

const getImageFromS3 = (
  key: string,
  useThumbnail = true,
  isPrivate?: boolean,
  strict?: boolean
): any => {
  try {
    if (!key) return "";

    if (key.startsWith("http")) return key;
    if (
      !key.includes("company-assets") &&
      !key.includes("sprints") &&
      !key.includes("tasks") &&
      !key.includes("FONTS") &&
      !key.includes("PROJECT_DATA") &&
      !key.includes("STYLES") &&
      !key.includes("SOURCE_FILES") &&
      !key.includes("GALLERY_COVER") &&
      !key.includes("GALLERY_FILES") &&
      !key.includes("example") &&
      !key.includes("solutions") &&
      !key.includes("popup") &&
      !strict
    )
      return key;

    if (key) {
      const updatedKey = key;
      // Needs to fetch full URL to support image editing without refresh.

      if (!isPrivate) {
        const bucketname = awsconfig.aws_user_files_s3_bucket;
        const bucketRegion = awsconfig.aws_user_files_s3_bucket_region;

        if (bucketname) {
          return `https://${bucketname}.s3.${bucketRegion}.amazonaws.com/public/${updatedKey}${
            useThumbnail ? `?${Date.now()}` : ""
          }`;
        }
        return "";
      }

      return new Promise((resolve, reject) => {
        Storage.get(updatedKey)
          .then((result: string) => {
            resolve(result);
          })
          .catch((err) => {
            console.error("Error in fetching file to s3", err);
            reject(err);
          });
      });
    }
  } catch (error) {
    console.error(error);
    return "";
  }
};

const deleteImageFromS3 = async (key: string) => {
  try {
    if (key) {
      const result = await Storage.remove(key);
      await Storage.remove(`thumbnail-${key}`);

      console.log("Deleted file from s3", result);
    }
  } catch (error) {
    console.error("Error in deleting file from s3", error);
  }
};

const getBacklogStatusColor = (status: BacklogStatus) => {
  switch (status) {
    case BacklogStatus.TODO:
      return "blue";

    case BacklogStatus.IN_PROGRESS:
      return "yellow";

    case BacklogStatus.DONE:
      return "green";
    case BacklogStatus.IN_REVIEW:
      return "purple";

    default:
      return "blue";
  }
};

const getBacklogPriorityColor = (priority: BacklogPriority) => {
  switch (priority) {
    case BacklogPriority.LOW:
      return "green";

    case BacklogPriority.MEDIUM:
      return "yellow";

    case BacklogPriority.HIGH:
      return "red";

    default:
      return "black";
  }
};

function generateColorPalette(primaryColor: string): ColorPalette {
  const primaryDark = shadeColor(primaryColor, -50);
  const primaryLight = shadeColor(primaryColor, 50);

  return {
    primary: primaryColor,
    primaryDark: primaryDark,
    primaryLight: primaryLight,
  };
}

function shadeColor(color: string, percent: number): string {
  const num = parseInt(color.slice(1), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  const newColor = `#${(0x1000000 + (R << 16) + (G << 8) + B)
    .toString(16)
    .slice(1)}`;

  return newColor;
}

function extractLinksFromString(str: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );
}
function extractLinksFromStringRaw(str: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  // only return url if it is valid
  const url = str.match(urlRegex);
  if (url) return url[0];
  return "";
}

function calculateTimeDifference(
  date1: string | number | Date,
  date2: string | number | Date
) {
  const diff = dayjs(date1).diff(dayjs(date2), "hours");
  return diff;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface ColorPalette {
  primary: string;
  primaryDark: string;
  primaryLight: string;
}

const getDuration = (startTime: any, endTime: any) => {
  const diff = dayjs(endTime).diff(dayjs(startTime), "minutes");
  if (diff < 15) {
    return "15 min";
  } else if (diff < 40) {
    return "30 min";
  } else if (diff < 60) {
    return "45 min";
  } else if (diff >= 60) {
    return dayjs(endTime).diff(dayjs(startTime), "hours") + " hr";
  }

  const duration = diff + " min";
  return duration;
};

const getTimezone = (offset: string) => {
  const numberOffset = parseFloat(offset);

  const utc = dayjs().utc();
  const myTime = dayjs(utc).add(numberOffset, "hour").format("hh:mm A");
  return myTime;
};

const convertToMB = (bytes: number) => bytes / (1024 * 1024);
const convertMbToGb = (mb: number) => (mb / 1024).toFixed(0);

const getLineBrParsed = (value: string) => {
  if (!value) return "--";
  return value.replace(/\n/g, "<br/>");
};

const reverseLineBrParsed = (value: string) => value.replace(/<br\/>/g, "\n");

function copyTextToDummyFile(text: string, filename: string): File {
  const data = new Blob([text], { type: "text/plain" });
  const file = new File([data], filename, { type: data.type });

  // Use the created File object as desired
  return file;
}

async function urlToFile(
  url: RequestInfo | URL,
  filename: string,
  mimeType: any
) {
  const res = await fetch(url);
  const blob = await res.blob();

  return new File([blob], filename, { type: mimeType });
}

function convertFontToBase64(
  fileObject: File,
  fontFormat: string,
  fontName: string
): void {
  const reader = new FileReader();

  reader.onloadend = function () {
    // @ts-ignore
    const base64String = reader?.result?.replace(/^data:.+;base64,/, "");

    const cssContent = `
      @font-face {
          font-family: '${fontName}';
          src: url(data:font/${fontFormat};base64,${base64String}) format('${fontFormat}');
      }`;

    console.log(cssContent);
  };

  reader.readAsDataURL(fileObject);
}

function ellipsis(str: string) {
  if (str.length > 100) {
    return str.substring(0, 100) + "...";
  } else {
    return str;
  }
}

const jsonFileCreator = (jsonObj: any, filename?: string) => {
  // Convert JSON Object to JSON String
  const jsonString = JSON.stringify(jsonObj);

  return new File([jsonString], filename ?? "zoiq-project.json", {
    type: "application/json",
  });
};
const cssFileCreator = (cssContent: any, filename?: string) => {
  const uuid = Math.random().toString(36).substring(2, 15);
  return new File([cssContent], filename ?? `zoiq-fonts-${uuid}.css`, {
    type: "text/css",
  });
};

function removeAllHtmlTags(str: string) {
  return str.replace(/<[^>]*>/g, "");
}

export const fontNameToNumber: { [key: string]: number } = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const fontNumberToWeightConverter = (fontWeight: number) => {
  const fontName = Object.keys(fontNameToNumber).find(
    (key) => fontNameToNumber[key] === fontWeight
  ); // if font weight is not found return 400
  if (!fontName) return "regular";
  return capitalize(fontName);
};

const fontWeightToNumberConverter = (fontName: string) => {
  // remove - and _ from font name
  fontName = fontName.replace(/-|_/g, "").toLowerCase();

  // find the font weight number
  // fontName could poppinsblackitalic
  // find black from name and then find italic from object and return the value
  const fontWeight = Object.keys(fontNameToNumber).find((key) =>
    fontName.includes(key)
  ); // if font weight is not found return 400
  if (!fontWeight) return 400;
  return fontNameToNumber[fontWeight];
};
// function to remove extension from file name
const removeExtension = (fileName: string) => {
  return fileName.replace(/\.[^/.]+$/, "");
};

const removeWhiteSpace = (str: string) => str.replace(/\s/g, "%20");

const parseTaskStatus = (status: BacklogStatus) =>
  backlogStatuses.find((s) => s.value === status)?.label;

const addSlug = (str: string) => str.replace(/\s/g, "-");

const parseS3Url = (url: string) => url.replace(/\?.*/, "");

const getStorageSize = (size: number) => {
  // unit of size will be in mb. if size is less than 1mb then it will be in kb
  // if size is more than 1000mb then it will be in gb
  if (size < 1000) {
    return `${size.toFixed(2)} MB`;
  } else if (size > 1000) {
    return `${(size / 1000).toFixed(2)} GB`;
  }
};

function clearCookies() {
  // Clear Cookies
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  location.reload();
}

function parseJavaScript(text: string) {
  const regex = /\[([\s\S]+)\]/; // Regular expression to match the array part
  const match = text.match(regex);
  console.log("🚀 ~ file: index.tsx:665 ~ parseJavaScript ~ match:", match);

  if (match) {
    try {
      const jsonArray = JSON.parse(match[0]);
      return jsonArray;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  } else {
    console.error("No JavaScript array found in the text.");
    return null;
  }
}

function calculateComplementaryColor(hexColor: string) {
  if (hexColor.indexOf("#") === 0) {
    hexColor = hexColor.slice(1);
  }

  let r = 255 - parseInt(hexColor.substring(0, 2), 16);
  let g = 255 - parseInt(hexColor.substring(2, 4), 16);
  let b = 255 - parseInt(hexColor.substring(4, 6), 16);

  // @ts-ignore
  r = ("0" + r.toString(16)).slice(-2);
  // @ts-ignore
  g = ("0" + g.toString(16)).slice(-2);
  // @ts-ignore
  b = ("0" + b.toString(16)).slice(-2);

  return "#" + r + g + b;
}

function generateMonochromaticColors(hexColor: string, numberOfColors: number) {
  if (hexColor.indexOf("#") === 0) {
    hexColor = hexColor.slice(1);
  }

  let r = parseInt(hexColor.substring(0, 2), 16);
  let g = parseInt(hexColor.substring(2, 4), 16);
  let b = parseInt(hexColor.substring(4, 6), 16);

  let colorList = [];
  for (let i = 0; i < numberOfColors; ++i) {
    let newR = Math.floor(r * (i / numberOfColors)).toString(16);
    let newG = Math.floor(g * (i / numberOfColors)).toString(16);
    let newB = Math.floor(b * (i / numberOfColors)).toString(16);

    newR = ("0" + newR).slice(-2);
    newG = ("0" + newG).slice(-2);
    newB = ("0" + newB).slice(-2);

    colorList.push("#" + newR + newG + newB);
  }

  return colorList;
}

function getAnalogousColors(primaryColorHex: string) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (primaryColorHex.length === 4) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[1];
    // @ts-ignore
    g = "0x" + primaryColorHex[2] + primaryColorHex[2];
    // @ts-ignore
    b = "0x" + primaryColorHex[3] + primaryColorHex[3];
  } else if (primaryColorHex.length === 7) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[2];
    // @ts-ignore
    g = "0x" + primaryColorHex[3] + primaryColorHex[4];
    // @ts-ignore
    b = "0x" + primaryColorHex[5] + primaryColorHex[6];
  }
  // Then convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;
  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // calculate analogous colors by rotating hue
  let h1 = (h - 30 + 360) % 360;
  let h2 = (h + 30) % 360;

  return [hslToHex(h, s, l), hslToHex(h1, s, l), hslToHex(h2, s, l)];
}

function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
function getTriadicColors(primaryColorHex: string | any[]) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;

  if (primaryColorHex.length == 4) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[1];
    // @ts-ignore
    g = "0x" + primaryColorHex[2] + primaryColorHex[2];
    // @ts-ignore
    b = "0x" + primaryColorHex[3] + primaryColorHex[3];
    // @ts-ignore
  } else if (primaryColorHex.length == 7) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[2];
    // @ts-ignore
    g = "0x" + primaryColorHex[3] + primaryColorHex[4];
    // @ts-ignore
    b = "0x" + primaryColorHex[5] + primaryColorHex[6];
    // @ts-ignore
  }
  // Then convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // rotate the hue by ±120 degrees to get the triadic colors
  let h1 = (h + 120) % 360;
  let h2 = (h - 120 + 360) % 360;

  return [hslToHex(h, s, l), hslToHex(h1, s, l), hslToHex(h2, s, l)];
}

// Four colors that are evenly spaced on the color wheel. Tetradic color schemes are bold and work best if you let one color be dominant, and use the others as accents. The more colors you have in your palette, the more difficult it is to balance,

// // using this logic can you make a js function to get the hex code of Tetradic color scheme if the primary color is #e1b21e

const getTetradicColors = (primaryColorHex: string | any[]) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;

  if (primaryColorHex.length == 4) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[1];
    // @ts-ignore
    g = "0x" + primaryColorHex[2] + primaryColorHex[2];
    // @ts-ignore
    b = "0x" + primaryColorHex[3] + primaryColorHex[3];
    // @ts-ignore
  } else if (primaryColorHex.length == 7) {
    // @ts-ignore
    r = "0x" + primaryColorHex[1] + primaryColorHex[2];
    // @ts-ignore
    g = "0x" + primaryColorHex[3] + primaryColorHex[4];
    // @ts-ignore
    b = "0x" + primaryColorHex[5] + primaryColorHex[6];
    // @ts-ignore
  }
  // Then convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;
  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // rotate the hue to get four colors (tetradic)
  let h1 = (h + 90) % 360;
  let h2 = (h + 180) % 360;
  let h3 = (h + 270) % 360;

  return [
    hslToHex(h, s, l),
    hslToHex(h1, s, l),
    hslToHex(h2, s, l),
    hslToHex(h3, s, l),
  ];
};

const lightenColor = (hex: string, percentage: number): string => {
  // remove the hash from the color value

  hex = hex.startsWith("#") ? hex.slice(1) : hex;

  // convert hexadecimal to rgb
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // get the new color values, ensuring they are within 0-255
  const newR = Math.min(255, r + Math.floor((255 - r) * percentage));
  const newG = Math.min(255, g + Math.floor((255 - g) * percentage));
  const newB = Math.min(255, b + Math.floor((255 - b) * percentage));

  return `#${newR.toString(16).padStart(2, "0")}${newG
    .toString(16)
    .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
};

export type SizeValues = "xs" | "sm" | "md" | "lg" | "xl";

const convertRadiusToPx = (radius: SizeValues) => {
  switch (radius) {
    case "xs":
      return "0.125rem";
    case "sm":
      return "0.25rem";
    case "md":
      return "0.5rem";
    case "lg":
      return "1rem";
    case "xl":
      return "2rem";

    default:
      return "0.125rem";
  }
};

const getColorFromMoscow = (moscow: SuccessCriteriaType) => {
  switch (moscow) {
    case SuccessCriteriaType.MUST_HAVE:
      return "red";
    case SuccessCriteriaType.SHOULD_HAVE:
      return "orange";
    case SuccessCriteriaType.COULD_HAVE:
      return "green";
    case SuccessCriteriaType.WONT_HAVE:
      return "blue";
    default:
      return "red";
  }
};

const parseMoscow = (moscow: SuccessCriteriaType) => {
  switch (moscow) {
    case SuccessCriteriaType.MUST_HAVE:
      return "Must Have";
    case SuccessCriteriaType.SHOULD_HAVE:
      return "Should Have";
    case SuccessCriteriaType.COULD_HAVE:
      return "Could Have";
    case SuccessCriteriaType.WONT_HAVE:
      return "Won't Have";
    default:
      return "Must Have";
  }
};

export {
  calculateTimeDifference,
  clearCookies,
  parseMoscow,
  convertRadiusToPx,
  getColorFromMoscow,
  getTriadicColors,
  lightenColor,
  getTetradicColors,
  calculateComplementaryColor,
  getAnalogousColors,
  generateMonochromaticColors,
  getStorageSize,
  parseJavaScript,
  convertFontToBase64,
  parseS3Url,
  addSlug,
  convertMbToGb,
  convertToMB,
  copyTextToDummyFile,
  cssFileCreator,
  dayJsFix,
  deleteImageFromS3,
  ellipsis,
  extractLinksFromString,
  fontNumberToWeightConverter,
  fontWeightToNumberConverter,
  formatDate,
  formatTime,
  generateColorPalette,
  generateProjectStatusColor,
  generateSummaryTypeColor,
  generateUserStatusColor,
  getBacklogPriorityColor,
  getBacklogStatusColor,
  getBase64,
  getDuration,
  getGithubRepoName,
  getImageFromS3,
  getLineBrParsed,
  getStyleGuideValue,
  getTimezone,
  isValidGithubRepo,
  jsonFileCreator,
  parseProjectRole,
  parseProjectStatus,
  parseTaskStatus,
  parseUserStatus,
  removeAllHtmlTags,
  removeExtension,
  removePx,
  getStyleGuideEnvValue,
  removeWhiteSpace,
  reverseLineBrParsed,
  scrollTo,
  sortBySequence,
  splitStringOnCaps,
  uploadFileToS3,
  uploadFileToS3Optimised,
  urlToFile,
  wrapEmail,
  wrapPhone,
  extractLinksFromStringRaw,
};
