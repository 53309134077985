import {
  ContentCreation,
  ConversationInput,
  CreateContentCreationInput,
  ModelContentCreationFilterInput,
  PageSection,
  Pages,
  Project,
  UpdateContentCreationInput,
} from "@/API";
import { adminData } from "@/constants/staticData";
import {
  createContentCreation,
  deleteContentCreation,
  updateContentCreation,
} from "@/graphql/mutations";
import { listContentCreations } from "@/graphql/queries";
import useUserData from "@/hooks/useData";
import { contentAddTemplate } from "@/templates/email-template";
import { formatDate, getLineBrParsed, reverseLineBrParsed } from "@/utils";
import { sendEmail } from "@/utils/graphql";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Empty,
  Input,
  MenuProps,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { motion } from "framer-motion";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Form from "../common/Form";
import PaginationComponent from "../common/PaginationComponent";

const contentHeight = 400;
const MotionCol = motion(Col);

const SingleContent = ({
  contentCreation,
  refetchContentCreation,
  onEditContentCreation,
  onSent,
}: {
  contentCreation: ContentCreation;
  refetchContentCreation: () => void;
  onEditContentCreation: (id: string) => void;

  onSent: (contentCreationId: string, value: boolean, cb: () => void) => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [isSent, setIsSent] = useState(false);

  const onDelete = async (deleteId: string) => {
    try {
      const res: any = await API.graphql(
        graphqlOperation(deleteContentCreation, {
          input: { id: deleteId },
        })
      );

      if (res.data.deleteContentCreation) {
        messageApi.success("Content deleted successfully!");
        refetchContentCreation();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (contentCreation.sent && contentCreation.sentDate) {
      setIsSent(true);
    }
  }, [contentCreation.sent, contentCreation.sentDate]);

  const items: MenuProps["items"] = [
    {
      label: "Edit",
      icon: <EditOutlined />,
      key: "edit",
      onClick: () => {
        onEditContentCreation(contentCreation.id);
      },
    },
    {
      label: "Delete",
      onClick: () => {
        onDelete(contentCreation.id);
      },
      danger: true,
      icon: <DeleteOutlined />,

      key: "delete",
    },
  ];

  return (
    <MotionCol
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      key={contentCreation.id}
      style={{ height: contentHeight }}
      span={24}
    >
      {contextHolder}
      <Card
        className="conv-wrapper card no-shadow"
        extra={
          <Space
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              {contentCreation.sent && contentCreation.sentDate && (
                <Typography.Text type="secondary">
                  Sent at {formatDate(contentCreation.sentDate)}
                </Typography.Text>
              )}
            </div>

            <div className="actions">
              <Tooltip
                title={
                  isSent
                    ? ""
                    : "Clicking this will lock your edits and have ZOIQ start to move this production"
                }
              >
                <Button type="text">
                  <Checkbox
                    onClick={() => {
                      onSent(contentCreation.id, !isSent, () => {
                        setIsSent(!isSent);
                      });
                    }}
                    checked={isSent}
                  >
                    {isSent ? "Sent" : "Send"}
                  </Checkbox>
                </Button>
              </Tooltip>

              {!isSent && (
                <Dropdown menu={{ items }}>
                  <Button
                    shape="circle"
                    type="text"
                    icon={<BiDotsVerticalRounded />}
                    size="small"
                  />
                </Dropdown>
              )}
            </div>
          </Space>
        }
        style={{
          width: "100%",
          maxHeight: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: contentCreation.content,
          }}
        />
      </Card>
    </MotionCol>
  );
};

export const fetchConversationData = async (
  projectId: string,
  pageId?: string,
  sectionId?: string
) => {
  const filter: ModelContentCreationFilterInput = {
    content: {
      notContains: "http",
    },
    projectID: {
      eq: projectId,
    },
    pageId: {
      eq: pageId,
    },
    sectionId: {
      eq: sectionId,
    },
  };
  const response: any = await API.graphql(
    graphqlOperation(listContentCreations, {
      filter,
    })
  );
  const contentCreations = response.data.listContentCreations
    .items as ContentCreation[];
  // ordetBy createdAt
  return orderBy(contentCreations, ["createdAt"], ["desc"]);
};

export type ConversationInputProps = {
  images?: { url: string }[];
} & Omit<ConversationInput, "__typename">;

const GenerateText = ({
  project,
  page,
  section,
  data,
  refetchContentCreation,
  isLoadingData,
  isFetchedData,
}: {
  project: Project;
  page: Pages;
  section: PageSection;
  data: ContentCreation[];
  refetchContentCreation: () => void;
  isLoadingData: boolean;
  isFetchedData: boolean;
}) => {
  const { authID, email } = useUserData();

  const [form] = Form.useForm();
  const [isTextSent, setIsTextSent] = useState(false);

  useEffect(() => {
    if (isFetchedData && !isLoadingData) {
      // one of the item from data has to be isSent = true
      // @ts-ignore
      const isSent = data?.some((d) => d.sent);
      setIsTextSent(isSent as boolean);
    }
  }, [isLoadingData, isFetchedData]);

  const initialValues = {
    message: "",
  };
  const [isLoading, setIsLoading] = useState(false);

  const onSent = async (
    contentCreationId: string,
    value: boolean,
    cb: () => void
  ) => {
    try {
      messageApi.loading("Sending...");

      const res: any = await API.graphql(
        graphqlOperation(updateContentCreation, {
          input: {
            id: contentCreationId,
            sent: value,
            sentDate: new Date().toISOString(),
          },
        })
      );

      if (res.data.updateContentCreation) {
        refetchContentCreation();
        onSendEmail();

        messageApi.success("Content sent successfully!");
        cb();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong!");
    }
  };

  const [tempResponseId, setTempResponseId] = useState(null);
  const [saveLatestContent, setSaveLatestContent] = useState(false);

  useEffect(() => {
    if (tempResponseId && saveLatestContent) {
      onSent(tempResponseId, true, () => {
        setTempResponseId(null);
        setSaveLatestContent(false);
      });
    }
  }, [tempResponseId, saveLatestContent]);

  const onContentFinish = async (values: { message: string }) => {
    setIsLoading(true);
    try {
      messageApi.loading("Saving...");
      let response = null;
      if (editMode) {
        const input: UpdateContentCreationInput = {
          id: contentEditId,
          content: getLineBrParsed(values.message),
        };
        response = await API.graphql(
          graphqlOperation(updateContentCreation, { input })
        );
      } else {
        const input: CreateContentCreationInput = {
          projectID: project.id,
          pageId: page.id,
          sectionId: section.id,
          authID: authID!,
          authEmail: email!,
          content: getLineBrParsed(values.message),
        };

        response = await API.graphql(
          graphqlOperation(createContentCreation, {
            input: input,
          })
        );
      }

      if (response !== null) {
        messageApi.success("Saved successfully!");
        form.resetFields();
        refetchContentCreation();
        const id = editMode
          ? // @ts-ignore
            response.data.updateContentCreation.id
          : // @ts-ignore
            response.data.createContentCreation.id;
        setTempResponseId(id);
      }
    } catch (error) {
      messageApi.error("Something went wrong!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const [editMode, setEditMode] = useState(false);
  const [contentEditId, setContentEditId] = useState("");

  const onEditContentCreation = (contentCreationId: string) => {
    const item = data?.find((d) => d.id === contentCreationId);
    if (item) {
      setContentEditId(contentCreationId);
      setEditMode(true);
      form.setFieldsValue({
        message: reverseLineBrParsed(item.content),
      });
    }
  };

  const onSendEmail = async () => {
    try {
      await sendEmail(
        adminData.email,
        "New site content added",
        contentAddTemplate(
          project.name,
          section.section,
          email!,
          `${location.origin}/project-view/${project.organizationID}/${project.id}?breadcrumbs=pages`
        ),
        false,
        "projects@zoiq.io"
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card style={{ width: "100%" }} className="card">
      {contextHolder}
      <>
        <Row gutter={[16, 16]}>
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Form
              style={{ width: "100%" }}
              form={form}
              initialValues={initialValues}
              onFinish={onContentFinish}
            >
              <Form.Item
                style={{ flex: 1 }}
                span={24}
                name={"message"}
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: contentHeight }}
                  id="content-editor"
                  placeholder="Write your content here..."
                  rows={4}
                />
              </Form.Item>
              <Form.Item>
                <Popconfirm
                  okText="Yes"
                  onConfirm={async () => {
                    form.submit();
                    setSaveLatestContent(true);
                  }}
                  cancelText="Later"
                  onCancel={() => {
                    form.submit();
                    setSaveLatestContent(false);
                  }}
                  title="Do you want to send this to us right now?"
                >
                  <Button
                    block
                    // htmlType="submit"
                    type="primary"
                    loading={isLoading}
                    icon={<AiOutlineSave />}
                  >
                    Save
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            {isLoadingData && !isFetchedData ? (
              <Spin>Loading texts...</Spin>
            ) : data && data.length === 0 ? (
              <Empty
                description="No text added yet"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <PaginationComponent
                showQuickJumper={false}
                showSizeChanger={false}
                items={data as ContentCreation[]}
                itemsPerPage={1}
                itemsPerPageXXL={1}
                centered
              >
                {(currentContentItems) => (
                  <Row
                    style={{
                      marginBottom: 16,
                    }}
                    gutter={[16, 16]}
                  >
                    {currentContentItems?.map(
                      (contentCreation: ContentCreation) => {
                        if (contentCreation.content.length === 0) return null;

                        return (
                          <SingleContent
                            onSent={onSent}
                            key={contentCreation.id}
                            contentCreation={contentCreation}
                            refetchContentCreation={refetchContentCreation}
                            onEditContentCreation={onEditContentCreation}
                          />
                        );
                      }
                    )}
                  </Row>
                )}
              </PaginationComponent>
            )}
          </Col>
        </Row>
      </>

      {!isTextSent && data && data.length > 0 && (
        <div
          className=""
          style={{
            textAlign: "right",
            marginTop: 12,
          }}
        >
          <Typography.Text type="secondary">
            Click send to complete the process
          </Typography.Text>
        </div>
      )}
    </Card>
  );
};

export default GenerateText;
