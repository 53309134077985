import { Font } from "@/API";
import { FONT_KEY } from "@/constants/staticData";
import { deleteFont, updateFont } from "@/graphql/mutations";
import { deleteImageFromS3, formatDate, removeWhiteSpace } from "@/utils";
import { Button, Popconfirm, Select, Table, Typography, message } from "antd";
import { API, graphqlOperation } from "aws-amplify";

const UploadedFonts = ({
  fontsFromDB,
  refetch,
}: {
  fontsFromDB: Font[];
  refetch: () => void;
}) => {
  const onDeleteFont = async (fontData: Font) => {
    try {
      await API.graphql(
        graphqlOperation(deleteFont, { input: { id: fontData.id } })
      );

      await deleteImageFromS3(`${FONT_KEY}/LINKS/${fontData.name}`);

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const fontOptions = fontsFromDB.map((font) => ({
    label: font.name,
    value: font.id,
  }));

  const [messageApi, contextHolder] = message.useMessage();
  const onComplimentaryChange = async (value: string[], id: string) => {
    try {
      messageApi.loading("Updating Complimentary Font");
      await API.graphql(
        graphqlOperation(updateFont, {
          input: { id: id, complimentsWithID: value },
        })
      );
      messageApi.success("Updated Complimentary Font");
      refetch();
    } catch (error) {
      messageApi.error("Error Updating Complimentary Font");
      console.error(error);
    }
  };

  return (
    <>
      {contextHolder}
      <Table
        dataSource={fontsFromDB}
        columns={[
          {
            title: "Font Name",

            dataIndex: "name",
            key: "name",
          },
          {
            title: "Font URL",
            dataIndex: "link",
            key: "link",
            render: (link: string) => (
              <Typography.Text
                copyable={{
                  onCopy: () =>
                    messageApi.success(
                      "Copied Link. Paste it in the CSS file."
                    ),
                  text: removeWhiteSpace(link),
                }}
              >
                Link
              </Typography.Text>
            ),
          },
          {
            title: "Compliments With",
            dataIndex: "complimentsWith",
            key: "complimentsWith",
            render: (_: any, record: Font) => (
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                placeholder="Compliments With"
                onChange={(value) =>
                  onComplimentaryChange(value as string[], record.id)
                }
                value={record.complimentsWithID?.filter(Boolean)}
                options={fontOptions.filter((font) => font.value !== record.id)}
              />
            ),
          },
          {
            title: "Date Added",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => formatDate(date),
          },
          {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: Font) => (
              <Popconfirm
                onConfirm={() => onDeleteFont(record)}
                title="Are you sure you want to delete this font?"
              >
                <Button danger type="text">
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ]}
      />
    </>
  );
};

export default UploadedFonts;
