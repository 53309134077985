import {
  Backlog,
  BacklogPriority,
  BacklogStatus,
  CreateBacklogInput,
  Organization,
  Pages,
  Project,
  ProjectPlan,
  UpdateBacklogInput,
  User,
} from "@/API";
import { TASK_URL } from "@/constants/home.constants";
import {
  DATE_FORMAT,
  backlogPriorities,
  backlogStatuses,
} from "@/constants/staticData";
import { getUserNames, listOrganizations } from "@/customGraphql/customQueries";
import { taskAssignTemplate } from "@/templates/email-template";
import {
  deleteImageFromS3,
  getBase64,
  getImageFromS3,
  getLineBrParsed,
  reverseLineBrParsed,
  uploadFileToS3,
} from "@/utils";
import { addActivity, sendEmail } from "@/utils/graphql";
import { PlusOutlined } from "@ant-design/icons";

import { useTaskContext } from "@/contexts/TaskContexts";
import useUserData from "@/hooks/useData";
import {
  Checkbox,
  Col,
  Empty,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { RcFile } from "antd/es/upload";
import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import { map, omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import Form from "../../common/Form";
import PreviewImage from "../PreviewImage";

interface BacklogValues {
  name: string;
  title: string;
  status: BacklogStatus;
  priority: BacklogPriority;
  organizationID: string;
  assignedTo: string[];
  targetDate?: string;
  projectID?: string;
  projectPlanID?: string;
  moscow?: string;
  referralLinks?: {
    link: string;
  }[];

  pageID?: string;
  sectionID?: string;
}

export interface TaskModalProps {
  taskData: Backlog | null;
  onSuccess?: (id: string, status: BacklogStatus) => void;
  onCancel?: () => void;
  projects?: Project[];
  form: FormInstance;
  isInsidePages?: boolean;
  prepoluateData?: {
    organizationID: string;
    pageID?: string;
    sectionID?: string;
    projectID?: string;
    pages?: Pages[];
  };
}

const TaskModal = ({
  taskData,
  onSuccess,
  projects,
  isInsidePages,
  prepoluateData,
  form,
}: TaskModalProps) => {
  const { modules, addTask, updateTask, status } = useTaskContext();

  Form.useWatch("organizationID", form);
  Form.useWatch("status", form);
  Form.useWatch("projectID", form);
  Form.useWatch("pageID", form);

  const { data: userData, canAddProjects } = useUserData();

  const projectId =
    prepoluateData && prepoluateData?.projectID
      ? prepoluateData?.projectID
      : form.getFieldValue("projectID");
  const organizationId =
    prepoluateData && prepoluateData?.organizationID
      ? prepoluateData?.organizationID
      : isInsidePages
      ? userData?.organizationID
      : form.getFieldValue("organizationID");

  const { isAdmin, isZoiq, authID, email } = useUserData();

  const onReset = () => {
    setFileList([]);
    form.resetFields();
  };

  const [dontNotifyAtAll, setDontNotifyAtAll] = useState(true);

  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    isFetched: isOrgFetched,
  } = useQuery(
    "organizations-modal-task-modal",
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(listOrganizations, {
          limit: 1000,
        })
      );
      const items = response.data.listOrganizations.items as Organization[];

      const mapped =
        isAdmin || isZoiq
          ? items
          : items.filter((org) => {
              const isCreator = org?.authID === authID;
              const isMember = org?.user?.items?.some(
                (member) => member?.authID === authID
              );
              return isCreator || isMember;
            });
      return mapped;
    },
    {
      enabled: !!authID && (!isInsidePages || canAddProjects),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: userItems, isLoading: isUsersLoading } = useQuery(
    `list-zoiq-users-modal-task-modal`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getUserNames, {
          limit: 1000,
          filter: {
            or: [
              {
                isZoiq: {
                  eq: true,
                },
              },
              {
                isAdmin: {
                  eq: true,
                },
              },
            ],
          },
        })
      );
      const items = response.data.listUsers.items as User[];
      return items;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const users = userItems?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const initialValues: BacklogValues = {
    name: "",
    status:
      status === "completed"
        ? BacklogStatus.DONE
        : status === "inprogress"
        ? BacklogStatus.IN_PROGRESS
        : BacklogStatus.TODO,
    priority: BacklogPriority.MEDIUM,
    organizationID: prepoluateData?.organizationID ?? "",
    assignedTo: [],
    title: "",
    projectID: prepoluateData?.projectID ?? "",
  };

  const isEdit = taskData?.id !== undefined;

  useEffect(() => {
    if (isEdit) {
      const input = {
        name: reverseLineBrParsed(taskData.name),
        title: taskData.title,
        // moscow: taskData.moscow,
        status: taskData.status,
        // organizationID: taskData.organizationID,
        priority: taskData.priority,
        assignedTo: taskData.assignedTo ?? [],
        targetDate: dayjs(taskData.targetDate),
        // projectID: taskData.projectID,
        // projectPlanID: taskData.projectPlanID,
        referralLinks: taskData.referralLinks?.map((d) => ({
          link: d,
        })),
      };

      form.setFieldsValue(input);
      const fileList =
        taskData?.media && taskData?.media?.length > 0
          ? (taskData?.media?.map((item) => ({
              uid: item?.id,
              name: item?.mediaType,
              status: "done",
              url: getImageFromS3(item?.mediaLink!),
            })) as UploadFile[])
          : [];

      setFileList(fileList);
    } else {
      onReset();
    }
  }, [isEdit, taskData]);

  const [messageApi, contextHolder] = message.useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onAssign = async (value: string[], task: Backlog, isUpdate = false) => {
    if (!value) return [];
    if (value.length === 0) return;

    try {
      for (const userId of value) {
        const user = userItems?.find((item) => item.id === userId) as User;
        if (!user.email) continue;
        await sendEmail(
          user.email,
          isUpdate ? `Task updates` : `New Task Assigned to you`,
          taskAssignTemplate(task.name, `${TASK_URL}/${task.id}`, isUpdate),
          [],
          "tasks@zoiq.io"
        );
        console.log("email sent to -> ", user.email);
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Could not send email to assignees, please try again!");
    }
  };

  const onFinish = async (values: BacklogValues) => {
    try {
      const toUploadList = fileList.filter((item) => !item.url);

      setIsLoading(true);

      const links: CreateBacklogInput["media"] = await Promise.all(
        toUploadList.map(async (file) => {
          return {
            id: file.uid,
            mediaType: file.name.split(".")[1],
            mediaLink: await onUpload(file.originFileObj!),
          };
        })
      );

      const input: CreateBacklogInput = {
        name: getLineBrParsed(values?.name),
        title: values?.title,
        status: values.status,
        priority: isInsidePages ? BacklogPriority.MEDIUM : values.priority,
        // moscow: values.moscow,
        media: links,
        createdBy: authID!,
        createdByEmail: email!,
        assignedTo: values.assignedTo,
        targetDate: dayjs(values.targetDate!).toString() ?? "",
        // projectPlanID: isInsidePages ? "999" : values.projectPlanID,
        // organizationID: isInsidePages ? organizationId! : values.organizationID,
        // projectID: isInsidePages ? projectId : values.projectID,
        pageID: prepoluateData?.pageID ?? values.pageID,
        sectionID: prepoluateData?.sectionID ?? values.sectionID,
      };

      const response = await addTask(input);

      if (response) {
        const id = response.id;
        messageApi.success("Task created successfully!");
        onReset();
        await onAssign(values.assignedTo, response);
        onSuccess?.(id, values.status);

        if (!email) return;

        const project = projects?.find((item) => item.id === projectId);

        await sendEmail(
          email,
          "New Task Created",
          taskAssignTemplate(input.name, `${TASK_URL}/${input.id}`, false),
          [project?.ownerEmail ?? ""],
          "tasks@zoiq.io"
        );

        await addActivity("New task added", `${TASK_URL}/${id}`);
      } else {
        messageApi.error("Could not create task, please try again!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinishUpdate = async (values: BacklogValues) => {
    try {
      setIsLoading(true);
      const toUploadList = fileList.filter((item) => !item.url);

      const deletedFiles = taskData?.media?.filter(
        (item) => !fileList.find((file) => file.uid === item?.id)
      );

      deletedFiles?.forEach(async (file) => {
        deleteImageFromS3(file?.mediaLink!);
      });

      setIsLoading(true);
      const links: UpdateBacklogInput["media"] = await Promise.all(
        toUploadList.map(async (file) => {
          return {
            id: file.uid,
            mediaType: file.name.split(".")[1],
            mediaLink: await onUpload(file.originFileObj!),
          };
        })
      );
      const linkRes = links.filter((item) => item && item.mediaLink !== "");

      const media = map([...(taskData?.media ?? []), ...linkRes], (item) =>
        omit(item, ["__typename"])
      );

      const filteredMedia = media.filter(
        (item) => !deletedFiles?.find((file) => file?.id === item?.id)
      );

      const input: UpdateBacklogInput = {
        id: modules.editTaskModule.backlog?.id!,
        name: getLineBrParsed(values.name),
        title: values?.title ?? "",
        status: values.status,
        priority: isInsidePages ? BacklogPriority.MEDIUM : values.priority,
        // moscow: values.moscow,
        media: filteredMedia as any,
        assignedTo: values.assignedTo,
        targetDate: dayjs(values.targetDate).toString() ?? "",
        // organizationID: isInsidePages ? organizationId : values.organizationID,
        // projectPlanID: isInsidePages ? "999" : values.projectPlanID,
        // projectID: isInsidePages ? projectId : values.projectID,
        pageID: prepoluateData?.pageID ?? values.pageID,
        sectionID: prepoluateData?.sectionID ?? values.sectionID,
        isCompleted: values.status === BacklogStatus.DONE,
      };

      const response = await updateTask(input);

      if (response) {
        const id = response.id;
        messageApi.success("Task updated successfully!");
        !dontNotifyAtAll && (await onAssign(values.assignedTo, response, true));
        onSuccess?.(id, values.status);
        onReset();
      } else {
        messageApi.error("Could not update task, please try again!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpload = async (
    file: any,
    existingLink?: string
  ): Promise<string> => {
    // upload files to s3
    if (!fileList.length) return "";
    const id = uuidv4();
    const fileLink = await uploadFileToS3(
      file,
      existingLink ?? `tasks/${id}`,
      file?.type
    );

    if (!fileLink) return "";
    return fileLink.key;
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        {isInsidePages ? "Upload screenshot" : "Upload"}
      </div>
    </div>
  );

  const organizationsMapped = useMemo(
    () =>
      organizations?.map((item) => ({
        label: item.name,
        value: item.id,
      })) || [],
    [organizations]
  );

  const allProjects = organizations
    ?.map((item) => item?.projects?.items.map((d) => d))
    .flat();

  const handleCancel = () => setPreviewOpen(false);

  const projectsMapped = useMemo(() => {
    const orgId = form.getFieldValue("organizationID");

    if (orgId && allProjects) {
      const filteredList = allProjects?.filter(
        (item) => item?.organizationID === orgId
      );
      return filteredList?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      });
    }
    return (
      allProjects?.map((d) => {
        return {
          label: d?.name,
          value: d?.id,
        };
      }) || []
    );
  }, [form.getFieldValue("organizationID"), allProjects, organizations]);

  const project = projects?.find((item) => item.id === projectId);

  const fetchedMilestones = project?.projectPlan?.items as ProjectPlan[];

  const milestones = useMemo(() => {
    if (isInsidePages && projectId) {
      return fetchedMilestones?.map((item) => ({
        label: item?.milestone,
        value: item?.id,
      }));
    } else if (projectId) {
      const project = projects?.find((item) => item.id === projectId);

      return project?.projectPlan?.items?.map((item) => ({
        label: item?.milestone,
        value: item?.id,
      }));
    } else {
      // return all milestones
      const milestones: any[] = [];
      projects?.forEach((project) => {
        project?.projectPlan?.items?.forEach((item) => {
          milestones.push({
            label: item?.milestone,
            value: item?.id,
          });
        });
      });
      return milestones;
    }
  }, [
    projects,

    form.getFieldValue("projectID"),
    form.getFieldValue("organizationID"),
  ]);

  const pagesData = project?.pages?.items as Pages[];

  const pagesOptions =
    !isInsidePages || !canAddProjects
      ? []
      : useMemo(() => {
          const pages = pagesData ?? prepoluateData?.pages ?? [];
          return pages.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
        }, [prepoluateData?.pages, pagesData, isInsidePages]);
  const sectionOptions =
    !isInsidePages || !canAddProjects
      ? []
      : useMemo(() => {
          const pages = pagesData ?? prepoluateData?.pages ?? [];
          const pageID = form.getFieldValue("pageID");
          const page = pages.find((item) => item.id === pageID);
          return page?.pageSections?.map((item) => ({
            label: item?.section,
            value: item?.id,
          }));
        }, [prepoluateData?.pages, pagesData, form.getFieldValue("pageID")]);

  const showForAdmins = isAdmin || isZoiq;

  return (
    <>
      {contextHolder}

      <Form
        form={form}
        initialValues={initialValues}
        onFinish={isEdit ? onFinishUpdate : onFinish}
      >
        <>
          <Tooltip
            title={
              form.getFieldValue("status") === BacklogStatus.DONE
                ? "Cannot upload files for completed tasks. Please change the status to upload files"
                : ""
            }
          >
            <Form.Item>
              <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                disabled={form.getFieldValue("status") === BacklogStatus.DONE}
                onChange={handleChange}
                maxCount={20}
                multiple
                beforeUpload={() => false}
              >
                {fileList.length >= 20 ? null : uploadButton}
              </Upload>

              <PreviewImage
                previewOpen={previewOpen}
                previewTitle={previewTitle}
                previewImage={previewImage}
                handleCancel={handleCancel}
              />
            </Form.Item>
          </Tooltip>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              {(canAddProjects || !isInsidePages) && (
                <Form.Item
                  name={"organizationID"}
                  rules={[
                    {
                      required: true,
                      message: "Please select an organization",
                    },
                  ]}
                  label={"Organization"}
                >
                  <Select
                    placeholder={"Select organization"}
                    loading={isOrganizationsLoading || !isOrgFetched}
                    allowClear
                    options={organizationsMapped}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={12}>
              {(canAddProjects || !isInsidePages) && (
                <Form.Item
                  rules={
                    canAddProjects
                      ? [
                          {
                            required: true,
                            message: "Please select a project",
                          },
                        ]
                      : []
                  }
                  name={"projectID"}
                  label={"Project"}
                >
                  <Select
                    allowClear
                    onChange={() => {
                      form.setFieldsValue({
                        projectPlanID: undefined,
                        pageID: undefined,
                        sectionID: undefined,
                      });
                    }}
                    placeholder={"Select project"}
                    options={projectsMapped}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          {!isInsidePages && (
            <Form.Item name={"projectPlanID"} label={"Milestone"}>
              <Select
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      form.getFieldValue("projectID")
                        ? "No milestones found for this project"
                        : "No milestones found"
                    }
                  />
                }
                allowClear
                placeholder={"Select milestone"}
                options={milestones}
              />
            </Form.Item>
          )}

          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter a title",
              },
            ]}
            label={"Title"}
          >
            <Input placeholder="Enter task title here..." />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter description",
              },
              {
                min: 20,
                message: "Please enter atleast 20 characters",
              },
            ]}
            label={"Description"}
          >
            <Input.TextArea
              rows={2}
              placeholder="Enter task description here..."
            />
          </Form.Item>

          <Space
            style={{
              width: "100%",
            }}
            styles={{
              item: {
                width: "100%",
              },
            }}
          >
            {showForAdmins || canAddProjects ? (
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Please enter a status",
                  },
                ]}
              >
                <Select options={backlogStatuses} />
              </Form.Item>
            ) : null}

            {isInsidePages ? null : (
              <Form.Item
                name="priority"
                label="Priority"
                rules={[
                  {
                    required: true,
                    message: "Please enter a priority",
                  },
                ]}
              >
                <Select
                  placeholder={"Select priority"}
                  options={backlogPriorities}
                />
              </Form.Item>
            )}
          </Space>
          {isInsidePages ? (
            !Boolean(prepoluateData?.pageID && prepoluateData.sectionID) &&
            !isEdit ? (
              <>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={"pageID"}
                      label={"Page"}
                      rules={[
                        {
                          required: true,
                          message: "Please select a page",
                        },
                      ]}
                    >
                      <Select
                        disabled={canAddProjects && projectId === undefined}
                        // reset section id field
                        onChange={() => {
                          form.setFieldsValue({ sectionID: undefined });
                        }}
                        options={pagesOptions}
                        placeholder={"Select page"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={"sectionID"}
                      label={"Section"}
                      rules={[
                        {
                          required: true,
                          message: "Please select a section",
                        },
                      ]}
                    >
                      <Select
                        options={sectionOptions}
                        disabled={
                          (canAddProjects && projectId === undefined) ||
                          form.getFieldValue("pageID") === undefined
                        }
                        placeholder={"Select page"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null
          ) : (
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Form.Item name="assignedTo" label="Assigned To">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    disabled={isUsersLoading}
                    mode="multiple"
                    placeholder="Select users"
                    options={users}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name="targetDate" label="Target Date">
                  <Form.DatePicker
                    format={DATE_FORMAT}
                    placeholder="Select date"
                    disabledDate={(current) =>
                      current && current < dayjs().endOf("day").subtract(1, "d")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {isEdit && (
            <Checkbox
              checked={!dontNotifyAtAll}
              onChange={(e) => {
                setDontNotifyAtAll?.(!e.target.checked);
              }}
            >
              Subscribe to updates
            </Checkbox>
          )}
        </>
      </Form>
    </>
  );
};

export default TaskModal;
