import colors from "@/constants/colors";
import { Flex } from "@aws-amplify/ui-react";
import { textContainer, textVariant2 } from "@utils/motions";
import { Typography, theme } from "antd";
import { MotionProps, motion } from "framer-motion";
import styled from "styled-components";

interface Text {
  title: any;
  textStyles?: string;
  center?: "true" | "false";
  onAnimationComplete?: () => void;
  viewport?: MotionProps["viewport"];
  animate?: MotionProps["animate"];
  whileInView?: MotionProps["whileInView"];
  extra?: React.ReactNode;
  TitleWrapper?: React.ReactNode;
}

const StyledParagraph = styled(motion<Text>(Typography.Paragraph))`
  font-family: var(--font-secondary) !important;
  color: ${colors.primary};
  margin-bottom: 0 !important;
  ${({ center }) =>
    center === "true" &&
    `
  text-align: center;
    
`}
`;
const StyledHeading = styled(motion<Text>(Typography.Title))`
  margin-top: 0 !important;

  // @ts-ignore
  ${({ center }) =>
    center === "true" &&
    `
    text-align: center;
    
  `}
`;

export const TypingText = ({
  title,
  center,
  onAnimationComplete,
  viewport,
  whileInView,
  extra,
}: Text) => {
  const { token } = theme.useToken();

  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"center"}
      display={"flex"}
      marginBottom={"1rem"}
    >
      {/* @ts-ignore */}
      <StyledParagraph
        initial={`hidden`}
        center={center}
        whileInView={whileInView ?? "show"}
        viewport={viewport ?? { once: true, amount: 0.5 }}
        variants={textContainer}
        onAnimationComplete={onAnimationComplete}
        style={{
          fontSize: token.fontSizeHeading3,
        }}
      >
        {Array.from(title as any).map((letter: any, index) => (
          <motion.span variants={textVariant2} key={index}>
            {letter === " " ? "\u00A0" : letter}
          </motion.span>
        ))}
      </StyledParagraph>
      {extra}
    </Flex>
  );
};

const DivWrapper = styled.div``;

export const TitleText = ({
  title,
  center,
  extra,
  viewport,
  whileInView,
}: Text) => {
  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"center"}
      display={"flex"}
      marginBottom={"1rem"}
    >
      {/* @ts-ignore */}
      <StyledHeading
        variants={textVariant2}
        initial={"hidden"}
        level={2}
        center={center}
        viewport={viewport ?? { once: true, amount: 0.55 }}
        whileInView={whileInView ?? "show"}
        // @ts-ignore
        className="heading-no-shadow"
      >
        {title as any}
      </StyledHeading>

      {extra}
    </Flex>
  );
};
