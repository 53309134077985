import { Gallery, GalleryItem, UpdateGalleryInput } from "@/API";
import {
  NO_FILE_ATTACHED,
  VIDEO_BACK,
  VIDEO_BACK_ERROR,
  videoAccept,
} from "@/constants/assets";
import { updateGallery } from "@/graphql/mutations";
import {
  deleteImageFromS3,
  getImageFromS3,
  jsonFileCreator,
  sortBySequence,
  uploadFileToS3,
} from "@/utils";
import { Button, Col, Empty, Flex, Image, Row, Typography } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { VideoAsset } from "../MediaAssets";
import SideLoader from "../common/SideLoader";

const Media = ({ asset }: { asset: GalleryItem }) => {
  const url = getImageFromS3(asset.link, true);
  return videoAccept
    .concat("video/mp4, video/quicktime")
    .includes(asset.mediaType ?? "none") ? (
    <VideoAsset height={"100%"} src={url} />
  ) : (
    <Image
      height={"100%"}
      // preview={false}
      onLoad={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = VIDEO_BACK;
      }}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = VIDEO_BACK_ERROR;
      }}
      loading="lazy"
      width={"100%"}
      style={{
        objectFit: "cover",
      }}
      src={asset.link ? url : NO_FILE_ATTACHED}
      alt={asset.mediaType ?? "asset"}
    />
  );
};

export const uploadGalleryToS3 = async (data: Gallery[], projectId: string) => {
  try {
    const mapped = data.map((galleryItem) => {
      const updatedGallery = galleryItem.gallery
        ?.map((galleryItem) => {
          if (!galleryItem) return null;
          if (galleryItem.link) {
            return {
              ...galleryItem,
              link: getImageFromS3(galleryItem.link, true),
              isVideo: videoAccept
                .concat("video/mp4, video/quicktime")
                .includes(galleryItem.mediaType ?? "none"),
            };
          }
          return galleryItem;
        })
        .filter(Boolean);

      const updatedItem: UpdateGalleryInput = {
        ...galleryItem,
        coverImage: getImageFromS3(galleryItem.coverImage, true),
        gallery: updatedGallery,
      };
      return updatedItem;
    });

    const jsonFile = jsonFileCreator(mapped, `gallery-${projectId}.json`);

    const key = `GALLERY_PUBLIC/${projectId}`;

    await deleteImageFromS3(key);

    const response = await uploadFileToS3(jsonFile, key, "application/json");

    if (response === "") {
      throw new Error("Error while uploading file");
    }

    if (response.key) {
      return response.key;
    }
  } catch (error) {
    //@ts-ignore
    throw new Error(error.message);
  }
};

const GalleryView = ({
  item,
  goBack,
  onEdit,
  publish,
}: {
  item: Gallery;
  publish: (galleryId: string) => void;
  goBack: () => void;
  onEdit: (id: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const sortedImages = useMemo(
    () =>
      (item.gallery && item.sortSeq
        ? sortBySequence(item.gallery as any, item.sortSeq as any)
        : item.gallery) as GalleryItem[],
    [item, item.sortSeq]
  );

  const [gallery, setGallery] = useState<GalleryItem[]>(sortedImages);

  useEffect(() => {
    setGallery(sortedImages);
  }, [item.gallery]);

  const [isPublished, setIsPublished] = useState(false);

  const onNameChange = async (id: string, value: string) => {
    try {
      setIsLoading(true);
      const updatedGallery = item.gallery?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            note: value,
          };
        }
        return item;
      });

      setGallery(updatedGallery as GalleryItem[]);

      const updatedItem: UpdateGalleryInput = {
        id: item.id,
        gallery: updatedGallery,
      };

      const res: any = await API.graphql(
        graphqlOperation(updateGallery, { input: updatedItem })
      );

      if (res.data.updateGallery) {
        console.log("updated successfully");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        overflowX: "hidden",
      }}
    >
      <Flex justify="space-between">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
            },
          }}
        >
          <Typography.Title
            style={{
              marginBottom: 12,
            }}
            level={3}
          >
            {item.title}
          </Typography.Title>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 0.5,
            },
          }}
        >
          <Flex gap={"middle"}>
            <Button
              onClick={() => {
                goBack();
              }}
              type="link"
              icon={<IoIosArrowRoundBack />}
            >
              Gallery List
            </Button>
            <Button
              onClick={() => {
                onEdit(item.id);
              }}
              type="default"
            >
              Edit
            </Button>
            <Button
              disabled={isPublished}
              onClick={() => {
                publish(item.id);
                setIsPublished(true);
              }}
              type="primary"
            >
              Publish
            </Button>
          </Flex>
        </motion.div>
      </Flex>

      {gallery?.length === 0 && (
        <Empty
          description="No images in this gallery"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.5,
          },
        }}
      >
        <Row
          style={{
            marginBottom: 12,
          }}
          gutter={[16, 16]}
        >
          {gallery?.map((image) => {
            if (image === null) return null;
            return (
              <Col key={image.id} className="gallery" span={8}>
                <Media asset={image} />
                <div className="gallery-details">
                  <Typography.Title
                    editable={{
                      onChange(value) {
                        if (value !== image.note) {
                          onNameChange(image.id, value);
                        }
                      },
                    }}
                    level={4}
                  >
                    {image.note}
                  </Typography.Title>
                </div>
              </Col>
            );
          })}
        </Row>
      </motion.div>

      <SideLoader isLoading={isLoading} />
    </div>
  );
};

export default GalleryView;
