import { StyleGuide } from "@/API";
import { TypingText } from "@/components/common/Title";
import { Buffer } from "@/components/sections/StyleGuide";
import { getStyleGuideValue } from "@/utils";
import { Divider, Tooltip, Typography, message } from "antd";
import { useMemo } from "react";

const Colors = ({ styleGuideData }: { styleGuideData: StyleGuide[] }) => {
  
  
  
  const tintColorExists = useMemo(() => getStyleGuideValue(styleGuideData, 'primary.1') 
    && getStyleGuideValue(styleGuideData, 'primary.2')
    && getStyleGuideValue(styleGuideData, 'primary.3')
    && getStyleGuideValue(styleGuideData, 'primary.4')
    , [styleGuideData])

  
  const paletteColors =tintColorExists? [
    {
      name: "Primary",
      value: "primary",
      width: "50%",
    },
    
    {
      name: "Tint 1",
      value: "primary.1",
      width: "25%",
    },
    {
      name: "Tint 2",
      value: "primary.2",
      width: "25%",
    },
    {
      name: "Tint 3" ,
      value: "primary.3",
      width: "25%",
    },
    {
      name: "Tint 4",
      value: "primary.4",
      width: "25%",
    },
    {
      name: "Secondary",
      value: "secondary",
      width: "25%",
    },

  ]: [
    {
      name: "Primary",
      value: "primary",
      width: "70%",
    },
    {
      name: "Secondary",
      value: "secondary",
      width: "30%",
    },
  ];

  const neutralColors = [
    {
      name: "White",
      value: "white",
    },

    {
      name: "Gray",
      value: "gray",
    },
    {
      name: "Dark",
      value: "dark",
    },
  ];

  const [messageApi, contextHolder] = message.useMessage();
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    messageApi.success("Copied to clipboard");
  };

  return (
    <div className="">
      {contextHolder}
      <TypingText title={"Color Pallete"} />
      <Typography.Text type="secondary">
        Hover over the color to copy the hex code
      </Typography.Text>
      <Divider />
      <Buffer />
      <div className="color-bar">
        <div className="color-name-container">
          {paletteColors.map((color) => (
            <div
              className="color-bar"
              style={{
                width: color.width,
              }}
            >
              <p>{color.name}</p>
            </div>
          ))}
        </div>
        <div className="color-bar-container">
          {paletteColors.map((color) => {
            const value = getStyleGuideValue(styleGuideData, color.value);
            return (
              <Tooltip key={value} title={"Click to copy"}>
                <div
                  className="color-bar"
                  onClick={() => {
                    copyToClipboard(value);
                  }}
                  style={{
                    backgroundColor: value,
                    width: color.width,
                  }}
                >
                  <p>{value}</p>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <Buffer />

      <div className="color-bar">
        <div className="color-name-container">
          {neutralColors.map((color) => (
            <div
              className="color-bar"
              style={{
                width: `calc(100% / ${neutralColors.length})`,
              }}
            >
              <p>{color.name}</p>
            </div>
          ))}
        </div>
        <div className="color-bar-container">
          {neutralColors.map((color) => {
            const value = getStyleGuideValue(styleGuideData, color.value);
            return (
              <Tooltip key={value} title="Click to copy">
                <div
                  onClick={() => {
                    copyToClipboard(value);
                  }}
                  className="color-bar"
                  style={{
                    backgroundColor: value,

                    width: `calc(100% / ${neutralColors.length})`,
                  }}
                >
                  <p>{value}</p>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Colors;
