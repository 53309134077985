import { Modal, Typography } from "antd";

const TypographyPreview = ({
  modalPreview,
  setModalPreview,
}: {
  modalPreview: {
    show: boolean;
    data: any;
  };
  setModalPreview: (arg0: { show: boolean; data: any }) => void;
}) => {
  return (
    <Modal
      width={700}
      open={modalPreview.show}
      onCancel={() => setModalPreview({ show: false, data: null })}
      okButtonProps={{ hidden: true }}
      title={`${modalPreview?.data?.fontFamily} - Preview`}
      cancelButtonProps={{ hidden: true }}
    >
      {/* need to show how the font will look */}
      {modalPreview.data !== null && (
        <>
          <Typography.Text>
            {/* a to z */}
            {Array.from(Array(26).keys()).map((letter) => {
              return (
                <span
                  key={letter}
                  style={{
                    fontFamily: modalPreview?.data?.fontFamily,
                    fontSize: `${modalPreview?.data?.fontSize}px`,
                    textTransform: "uppercase",
                  }}
                >
                  {String.fromCharCode(letter + 65)}
                </span>
              );
            })}
          </Typography.Text>
          <br />
          <Typography.Text>
            {/* a to z */}
            {Array.from(Array(26).keys()).map((letter) => {
              return (
                <span
                  key={letter}
                  style={{
                    fontFamily: modalPreview?.data?.fontFamily,
                    fontSize: `${modalPreview?.data?.fontSize}px`,
                    textTransform: "lowercase",
                  }}
                >
                  {String.fromCharCode(letter + 65)}
                </span>
              );
            })}
          </Typography.Text>
          <br />
          <Typography.Text>
            {/* a to z */}
            <span
              style={{
                fontFamily: modalPreview?.data?.fontFamily,
                fontSize: `${modalPreview?.data?.fontSize}px`,
              }}
            >
              0123456789ab*#&$@!?_+=%
            </span>
          </Typography.Text>
        </>
      )}
    </Modal>
  );
};

export default TypographyPreview;
