import { Badge } from "antd";
import React from "react";

const AttachedComponentWrapper = ({
  isAttached,
  isGallery,
  children,
}: {
  isGallery?: boolean;
  isAttached: boolean;
  children: React.ReactNode;
}) => {
  const WrapperComponent = Boolean(isAttached || isGallery)
    ? Badge.Ribbon
    : React.Fragment;

  const badgeProps = Boolean(isGallery && isAttached)
    ? {
        color: "red",
        text: "Gallery & Attached",
      }
    : Boolean(isGallery && !isAttached)
    ? {
        color: "blue",
        text: "Gallery",
      }
    : Boolean(isAttached)
    ? {
        color: "green",
        text: "Attached",
      }
    : {};

  return <WrapperComponent {...badgeProps}>{children}</WrapperComponent>;
};

export default AttachedComponentWrapper;
