import { PageSection, Pages, Project } from "@/API";
import colors from "@/constants/colors";
import useUserData from "@/hooks/useData";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Flex, Menu } from "antd";
import type { MenuProps } from "antd/es/menu";
import React from "react";
import { FiMinus } from "react-icons/fi";
import { GoPlus } from "react-icons/go";
import { RiPagesLine } from "react-icons/ri";
import { styled } from "styled-components";

const Sidebar = styled.div`
  width: 100%;
  min-height: 400px;
  height: 100%;
`;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  onTitleClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
): MenuItem {
  const CustomComponent = ({ children }: { children: React.ReactNode }) => (
    <div onClick={onTitleClick}>{children}</div>
  );

  return {
    key,
    icon,
    children,
    label: <CustomComponent>{label}</CustomComponent>,
    type,
    onClick,
  } as MenuItem;
}

interface SiteMapSidebarProps {
  pages: Pages[];
  project: Project;
  setSelectedSection: (section: Omit<PageSection, "__typename"> | null) => void;
  setSelectedPage: (section: Pages | null) => void;
  setOnlySidebar: React.Dispatch<React.SetStateAction<boolean>>;
  onlySidebar: boolean;
}

const SiteMapSidebar = ({
  pages,
  setSelectedPage,
  setSelectedSection,
  project,
  setOnlySidebar,
  onlySidebar,
}: SiteMapSidebarProps) => {
  const { isAdmin, isZoiq } = useUserData();

  const pagesItems: MenuItem[] = pages.map((page) =>
    getItem(
      page.name,
      page.id,
      <LinkOutlined />,
      page.pageSections.length > 0
        ? page.pageSections.map((section) =>
            getItem(
              section?.section,
              `${section?.section}_${page.id}`,
              <RiPagesLine />,
              undefined,
              undefined,
              () => {
                setSelectedSection(section!);
                setSelectedPage(page);
              }
            )
          )
        : undefined,
      undefined
    )
  );

  pagesItems.unshift(
    getItem("Flyer", "flyer", <RiPagesLine />, undefined, undefined, () => {
      setSelectedPage(null);
      setSelectedSection({
        id: "flyer",
        section: "flyer",
        link: "",
      });
    })
  );

  const pageWrapper = [
    getItem("Pages", "pages", <RiPagesLine />, pagesItems, "group"),
  ];

  const projectUrl = project?.liveUrl;
  const siteWrapper = [
    getItem(
      <Flex gap={"middle"} align="center">
        {projectUrl ?? "Site"}

        {(isAdmin || isZoiq) && (
          <Button
            type="default"
            shape="circle"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOnlySidebar((prev) => !prev);
            }}
            icon={onlySidebar ? <FiMinus /> : <GoPlus />}
          />
        )}
      </Flex>,
      "site",
      <RiPagesLine />,
      pageWrapper,
      undefined,
      undefined,
      () => {
        setSelectedPage(null);
        setSelectedSection({
          id: "site",
          section: "site",
          link: "",
        });
      }
    ),
  ];

  return (
    <Sidebar>
      {/* <ManageSectionPopup/> */}

      <Menu
        style={{
          backgroundColor: colors.lightGray,
          border: "1px solid rgba(253, 253, 253, 0.12)",
          borderRadius: "1rem",
          height: "100%",
        }}
        className="card no-shadow"
        defaultOpenKeys={["site"]}
        mode="inline"
        items={siteWrapper}
      />
    </Sidebar>
  );
};

export default SiteMapSidebar;
