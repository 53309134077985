/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateOrgMeetingInput = {
  id?: string | null,
  organizationID: string,
  name: string,
  description?: string | null,
  startTime: string,
  endTime: string,
  attendees: Array< string | null >,
};

export type ModelOrgMeetingConditionInput = {
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  attendees?: ModelStringInput | null,
  and?: Array< ModelOrgMeetingConditionInput | null > | null,
  or?: Array< ModelOrgMeetingConditionInput | null > | null,
  not?: ModelOrgMeetingConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type OrgMeeting = {
  __typename: "OrgMeeting",
  id: string,
  organizationID: string,
  name: string,
  description?: string | null,
  startTime: string,
  endTime: string,
  attendees: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrgMeetingInput = {
  id: string,
  organizationID?: string | null,
  name?: string | null,
  description?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  attendees?: Array< string | null > | null,
};

export type DeleteOrgMeetingInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  authID?: string | null,
  email?: string | null,
  avatar?: string | null,
  name: string,
  type: OrganizationType,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  timezone?: TimezoneInput | null,
  zipCode?: string | null,
  url?: string | null,
  maxBucketSize?: number | null,
  staffSortSeq?: Array< string | null > | null,
  storeInventorySortSeq?: Array< string | null > | null,
  partnersLogoSortSeq?: Array< string | null > | null,
  mediaFilesSortSeq?: Array< string | null > | null,
  gallerySortSeq?: Array< string | null > | null,
  allowedFileTabs?: Array< string | null > | null,
  orgSize?: OrgSize | null,
  industry?: string | null,
  mission?: string | null,
  software?: Array< string | null > | null,
  paper?: PaperOptions | null,
  printer?: PrinterOptions | null,
  comments?: Array< OrgCommentInput | null > | null,
  socialMedia?: Array< SocialLinksInput | null > | null,
  contact?: OrgContactInput | null,
};

export enum OrganizationType {
  PROFIT = "PROFIT",
  NONPROFIT = "NONPROFIT",
  GOVERNMENT = "GOVERNMENT",
  NGO = "NGO",
}


export type TimezoneInput = {
  country?: string | null,
  timezone?: string | null,
};

export enum OrgSize {
  ONE_TO_FIVE = "ONE_TO_FIVE",
  FIVE_TO_THIRTY = "FIVE_TO_THIRTY",
  THIRTY_TO_HUNDRED = "THIRTY_TO_HUNDRED",
  HUNDRED_TO_THOUSAND = "HUNDRED_TO_THOUSAND",
  THOUSAND_PLUS = "THOUSAND_PLUS",
}


export enum PaperOptions {
  PAPER_DRIVEN = "PAPER_DRIVEN",
  ONLINE = "ONLINE",
  AUTOMATED = "AUTOMATED",
}


export enum PrinterOptions {
  ALL_THE_TIME = "ALL_THE_TIME",
  ONCE_A_DAY = "ONCE_A_DAY",
  ONCE_A_WEEK = "ONCE_A_WEEK",
  NOT_OFTEN = "NOT_OFTEN",
  IF_EVER = "IF_EVER",
}


export type OrgCommentInput = {
  comment?: string | null,
  date?: string | null,
};

export type SocialLinksInput = {
  type?: SocialLinkType | null,
  link?: string | null,
};

export enum SocialLinkType {
  LINKEDIN = "LINKEDIN",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM",
  WEBSITE = "WEBSITE",
  FACEBOOK = "FACEBOOK",
  OTHER = "OTHER",
}


export type OrgContactInput = {
  name?: string | null,
  email?: string | null,
  phone?: string | null,
};

export type ModelOrganizationConditionInput = {
  authID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  url?: ModelStringInput | null,
  maxBucketSize?: ModelIntInput | null,
  staffSortSeq?: ModelIDInput | null,
  storeInventorySortSeq?: ModelIDInput | null,
  partnersLogoSortSeq?: ModelIDInput | null,
  mediaFilesSortSeq?: ModelIDInput | null,
  gallerySortSeq?: ModelIDInput | null,
  allowedFileTabs?: ModelStringInput | null,
  orgSize?: ModelOrgSizeInput | null,
  industry?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  software?: ModelStringInput | null,
  paper?: ModelPaperOptionsInput | null,
  printer?: ModelPrinterOptionsInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelOrganizationTypeInput = {
  eq?: OrganizationType | null,
  ne?: OrganizationType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelOrgSizeInput = {
  eq?: OrgSize | null,
  ne?: OrgSize | null,
};

export type ModelPaperOptionsInput = {
  eq?: PaperOptions | null,
  ne?: PaperOptions | null,
};

export type ModelPrinterOptionsInput = {
  eq?: PrinterOptions | null,
  ne?: PrinterOptions | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  authID?: string | null,
  email?: string | null,
  avatar?: string | null,
  user?: ModelUserConnection | null,
  projects?: ModelProjectConnection | null,
  name: string,
  type: OrganizationType,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  timezone?: Timezone | null,
  zipCode?: string | null,
  url?: string | null,
  maxBucketSize?: number | null,
  staffSortSeq?: Array< string | null > | null,
  storeInventorySortSeq?: Array< string | null > | null,
  partnersLogoSortSeq?: Array< string | null > | null,
  mediaFilesSortSeq?: Array< string | null > | null,
  gallerySortSeq?: Array< string | null > | null,
  allowedFileTabs?: Array< string | null > | null,
  orgSize?: OrgSize | null,
  industry?: string | null,
  mission?: string | null,
  software?: Array< string | null > | null,
  paper?: PaperOptions | null,
  printer?: PrinterOptions | null,
  comments?:  Array<OrgComment | null > | null,
  socialMedia?:  Array<SocialLinks | null > | null,
  contact?: OrgContact | null,
  meetings?: ModelOrgMeetingConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  authID: string,
  organizationID?: string | null,
  organization?: Organization | null,
  name: string,
  timezone?: Timezone | null,
  title?: string | null,
  email: string,
  phone?: string | null,
  isAdmin?: boolean | null,
  initialEmailSentOn?: string | null,
  otherProjects?:  Array<OtherProjectRole | null > | null,
  overrideOrganization?: string | null,
  orgLevel?: number | null,
  lastLogin?: string | null,
  status: Status,
  adminAccessTo?: Array< string | null > | null,
  liveVisiblity?: boolean | null,
  isZoiq?: boolean | null,
  completedTasks?: Array< string | null > | null,
  links?: Array< string | null > | null,
  socialMedia?:  Array<SocialLinks | null > | null,
  canAddProjects?: boolean | null,
  stats?: ModelUserStatConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Timezone = {
  __typename: "Timezone",
  country?: string | null,
  timezone?: string | null,
};

export type OtherProjectRole = {
  __typename: "OtherProjectRole",
  projectID?: string | null,
  projectRole?: ProjectRole | null,
};

export enum ProjectRole {
  BUSINESS_SPONSOR = "BUSINESS_SPONSOR",
  DECISION_MAKER = "DECISION_MAKER",
  SME = "SME",
  TECHNICAL_EXPERT = "TECHNICAL_EXPERT",
  PROJECT_OWNER = "PROJECT_OWNER",
  ADMIN = "ADMIN",
  SALES = "SALES",
}


export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  TRAINING = "TRAINING",
}


export type SocialLinks = {
  __typename: "SocialLinks",
  type?: SocialLinkType | null,
  link?: string | null,
};

export type ModelUserStatConnection = {
  __typename: "ModelUserStatConnection",
  items:  Array<UserStat | null >,
  nextToken?: string | null,
};

export type UserStat = {
  __typename: "UserStat",
  id: string,
  userID: string,
  taskID: string,
  task?: Backlog | null,
  createdAt: string,
  updatedAt: string,
};

export type Backlog = {
  __typename: "Backlog",
  id: string,
  name: string,
  title?: string | null,
  isCompleted?: boolean | null,
  dateCompleted?: string | null,
  createdBy: string,
  createdByEmail: string,
  user?: User | null,
  media?:  Array<BacklogMedia | null > | null,
  status: BacklogStatus,
  priority: BacklogPriority,
  assignedTo?: Array< string | null > | null,
  assignedToUser?: ModelUserConnection | null,
  comments?: ModelCommentsConnection | null,
  targetDate?: string | null,
  sectionID?: string | null,
  pageID?: string | null,
  notifyAssignedMembers?: boolean | null,
  subtasks?: ModelSubTaskModelConnection | null,
  referralLinks?: Array< string | null > | null,
  styles?: BacklogStyles | null,
  note?: string | null,
  tags?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type BacklogMedia = {
  __typename: "BacklogMedia",
  id: string,
  mediaType: string,
  mediaLink: string,
};

export enum BacklogStatus {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  DONE = "DONE",
}


export enum BacklogPriority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  CRITICAL = "CRITICAL",
}


export type ModelCommentsConnection = {
  __typename: "ModelCommentsConnection",
  items:  Array<Comments | null >,
  nextToken?: string | null,
};

export type Comments = {
  __typename: "Comments",
  id: string,
  backlogID: string,
  backlog?: Backlog | null,
  comment: string,
  date: string,
  user?: User | null,
  authID: string,
  authEmail: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelSubTaskModelConnection = {
  __typename: "ModelSubTaskModelConnection",
  items:  Array<SubTaskModel | null >,
  nextToken?: string | null,
};

export type SubTaskModel = {
  __typename: "SubTaskModel",
  id: string,
  isCompleted?: boolean | null,
  task: string,
  completedOn?: string | null,
  mediaId?: string | null,
  sprintType?: SprintType | null,
  backlogID?: string | null,
  backlog?: Backlog | null,
  statusUpdateDate?: string | null,
  priority?: BacklogPriority | null,
  completedByEmail?: string | null,
  organizationID?: string | null,
  organization?: Organization | null,
  projectID?: string | null,
  project?: Project | null,
  projectPlanID?: string | null,
  projectPlan?: ProjectPlan | null,
  status?: SubtaskStatus | null,
  moscow?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum SprintType {
  ACTIVE = "ACTIVE",
  BACKLOG = "BACKLOG",
}


export type Project = {
  __typename: "Project",
  id: string,
  organizationID?: string | null,
  organization?: Organization | null,
  name: string,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  milestone?: string | null,
  ownerID?: string | null,
  ownerAuthID?: string | null,
  ownerEmail?: string | null,
  owner?: User | null,
  status: ProjectStatus,
  otherUsers?:  Array<OtherUser | null > | null,
  risk: Risk,
  githubRepo?: string | null,
  awsAccount?: string | null,
  cognitoUrl?: string | null,
  dynamo?:  Array<Dynamo | null > | null,
  solutionType?: string | null,
  scope?: string | null,
  estUsersInFirstYear?: string | null,
  userAgeGroups?: Array< string | null > | null,
  lambdaFunction?:  Array<LambdaFunction | null > | null,
  liveUrl?: string | null,
  siteData?: SiteData | null,
  isZoiq?: boolean | null,
  discussion?: ModelDiscussionConnection | null,
  sourceFile?: ModelSourceFileConnection | null,
  gallery?: ModelGalleryConnection | null,
  projectPlan?: ModelProjectPlanConnection | null,
  successCriteria?: ModelSuccessCriteriaConnection | null,
  meetings?: ModelMeetingsConnection | null,
  pages?: ModelPagesConnection | null,
  summaries?: ModelSummaryConnection | null,
  assets?: ModelAssetsConnection | null,
  store?: ModelStoreConnection | null,
  userSortSeq?: Array< string | null > | null,
  projectPlanSortSeq?: Array< string | null > | null,
  projectMeetingsSortSeq?: Array< string | null > | null,
  projectCriteriaSortSeq?: Array< string | null > | null,
  counts?: Counts | null,
  closingMatrix?: ClosingMatrix | null,
  createdAt: string,
  updatedAt: string,
};

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
}


export type OtherUser = {
  __typename: "OtherUser",
  userID?: string | null,
  projectRole?: ProjectRole | null,
};

export enum Risk {
  RED = "RED",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  BLUE = "BLUE",
  BLACK = "BLACK",
}


export type Dynamo = {
  __typename: "Dynamo",
  key?: string | null,
  value?: string | null,
};

export type LambdaFunction = {
  __typename: "LambdaFunction",
  name?: string | null,
  url?: string | null,
};

export type SiteData = {
  __typename: "SiteData",
  audience?: Array< AudienceType | null > | null,
  trafficCount?: AudienceSize | null,
  trafficLocation?: Array< string | null > | null,
  tone?: ToneType | null,
  languages?: LanguageType | null,
  websites?:  Array<Website | null > | null,
  pageSortSeq?: Array< string | null > | null,
  sectionSortSeq?:  Array<SectionSort | null > | null,
  organizationSummary?: string | null,
  purpose?: string | null,
  goals?: Array< string | null > | null,
  messaging?: string | null,
  keyDates?: Array< string | null > | null,
  suggestedSolution?: Array< SuggestedSolutions | null > | null,
  projectNotes?: string | null,
  contactPerson?: string | null,
  isLocked?: boolean | null,
  appId?: string | null,
  branchName?: string | null,
};

export enum AudienceType {
  B2B = "B2B",
  COMMUNITY = "COMMUNITY",
  USERS = "USERS",
  NON_PROFIT = "NON_PROFIT",
  DONORS = "DONORS",
  FOUNDATIONS = "FOUNDATIONS",
}


export enum AudienceSize {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}


export enum ToneType {
  INFORMATIVE = "INFORMATIVE",
}


export enum LanguageType {
  SINGLE = "SINGLE",
  MULTILINGUAL = "MULTILINGUAL",
}


export type Website = {
  __typename: "Website",
  link?: string | null,
  reason?: string | null,
};

export type SectionSort = {
  __typename: "SectionSort",
  pageId?: string | null,
  seq?: Array< string | null > | null,
};

export enum SuggestedSolutions {
  NEW_WEBSITE = "NEW_WEBSITE",
  WEBSITE_REDESIGN = "WEBSITE_REDESIGN",
  APP = "APP",
  OTHER = "OTHER",
}


export type ModelDiscussionConnection = {
  __typename: "ModelDiscussionConnection",
  items:  Array<Discussion | null >,
  nextToken?: string | null,
};

export type Discussion = {
  __typename: "Discussion",
  id: string,
  projectID: string,
  project?: Project | null,
  name: string,
  date?: string | null,
  actionItemsSortSeq?: Array< string | null > | null,
  actions?: ModelActionItemsConnection | null,
  emailSent?: boolean | null,
  emailSentDate?: string | null,
  meetingID?: string | null,
  meeting?: Meetings | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelActionItemsConnection = {
  __typename: "ModelActionItemsConnection",
  items:  Array<ActionItems | null >,
  nextToken?: string | null,
};

export type ActionItems = {
  __typename: "ActionItems",
  id: string,
  discussionID: string,
  actionItem: string,
  owners?: Array< string | null > | null,
  isCompleted?: boolean | null,
  targetDate?: string | null,
  dateAssigned?: string | null,
  emailSent?: boolean | null,
  dateCompleted?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Meetings = {
  __typename: "Meetings",
  id: string,
  projectID: string,
  project?: Project | null,
  name: string,
  description: string,
  firstMeetingDate: string,
  lastMeetingDate: string,
  startTime: string,
  endTime: string,
  rolls?: RollsType | null,
  frequency?: FrequencyType | null,
  link?: string | null,
  attendees: Array< string | null >,
  mailSentDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type RollsType = {
  __typename: "RollsType",
  Date?: string | null,
  Day?: Array< string | null > | null,
};

export enum FrequencyType {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
}


export type ModelSourceFileConnection = {
  __typename: "ModelSourceFileConnection",
  items:  Array<SourceFile | null >,
  nextToken?: string | null,
};

export type SourceFile = {
  __typename: "SourceFile",
  id: string,
  userID: string,
  organizationID?: string | null,
  projectId?: string | null,
  email: string,
  size?: number | null,
  mediaType?: string | null,
  link: string,
  note?: string | null,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelGalleryConnection = {
  __typename: "ModelGalleryConnection",
  items:  Array<Gallery | null >,
  nextToken?: string | null,
};

export type Gallery = {
  __typename: "Gallery",
  id: string,
  userID: string,
  organizationID?: string | null,
  projectId?: string | null,
  email: string,
  coverImage: string,
  sortSeq?: Array< string | null > | null,
  gallery?:  Array<GalleryItem | null > | null,
  title?: string | null,
  user?: User | null,
  publishDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type GalleryItem = {
  __typename: "GalleryItem",
  id: string,
  link: string,
  mediaType: string,
  note?: string | null,
};

export type ModelProjectPlanConnection = {
  __typename: "ModelProjectPlanConnection",
  items:  Array<ProjectPlan | null >,
  nextToken?: string | null,
};

export type ProjectPlan = {
  __typename: "ProjectPlan",
  id: string,
  projectID: string,
  milestone: string,
  startDate?: string | null,
  endDate?: string | null,
  status: ProjectStatus,
  risk: Risk,
  fakeId?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelSuccessCriteriaConnection = {
  __typename: "ModelSuccessCriteriaConnection",
  items:  Array<SuccessCriteria | null >,
  nextToken?: string | null,
};

export type SuccessCriteria = {
  __typename: "SuccessCriteria",
  id: string,
  projectID: string,
  project?: Project | null,
  description: string,
  measurableCriteria: string,
  type: SuccessCriteriaType,
  pageId?: string | null,
  sectionId?: string | null,
  page?: Pages | null,
  createdAt: string,
  updatedAt: string,
  pagesCriteriaId?: string | null,
};

export enum SuccessCriteriaType {
  MUST_HAVE = "MUST_HAVE",
  COULD_HAVE = "COULD_HAVE",
  SHOULD_HAVE = "SHOULD_HAVE",
  WONT_HAVE = "WONT_HAVE",
}


export type Pages = {
  __typename: "Pages",
  id: string,
  projectID: string,
  project?: Project | null,
  name: string,
  description: string,
  link?: string | null,
  pageSections:  Array<PageSection | null >,
  criteria?: ModelSuccessCriteriaConnection | null,
  successCriteriaSummary?: SuccessCriteriaSummary | null,
  createdAt: string,
  updatedAt: string,
};

export type PageSection = {
  __typename: "PageSection",
  section: string,
  noMedia?: boolean | null,
  id: string,
  link?: string | null,
  meta?: Array< string | null > | null,
  media?: Array< string | null > | null,
  lastEmailSent?: string | null,
  notes?: string | null,
  illustration?: string | null,
  instructionsLevel?: string | null,
  signOff?: SignOffSection | null,
};

export type SignOffSection = {
  __typename: "SignOffSection",
  title: string,
  description?: string | null,
  originalDesign: string,
  originalDesignDate: string,
  updatedDesign?: string | null,
  updatedDesignDate?: string | null,
};

export type SuccessCriteriaSummary = {
  __typename: "SuccessCriteriaSummary",
  mustHave?: string | null,
  shouldHave?: string | null,
  couldHave?: string | null,
  wontHave?: string | null,
};

export type ModelMeetingsConnection = {
  __typename: "ModelMeetingsConnection",
  items:  Array<Meetings | null >,
  nextToken?: string | null,
};

export type ModelPagesConnection = {
  __typename: "ModelPagesConnection",
  items:  Array<Pages | null >,
  nextToken?: string | null,
};

export type ModelSummaryConnection = {
  __typename: "ModelSummaryConnection",
  items:  Array<Summary | null >,
  nextToken?: string | null,
};

export type Summary = {
  __typename: "Summary",
  id: string,
  projectID: string,
  project?: Project | null,
  summary: string,
  summaryDate: string,
  summaryOwnerID: string,
  summaryOwnerEmail: string,
  summaryOwner?: User | null,
  summaryType: SummaryType,
  discussionDate?: string | null,
  discussedWithID?: string | null,
  discussedWithEmail?: string | null,
  discussedWith?: User | null,
  createdAt: string,
  updatedAt: string,
};

export enum SummaryType {
  INFORMATION = "INFORMATION",
  DECISION = "DECISION",
  ASSUMPTION = "ASSUMPTION",
  AGREEMENT = "AGREEMENT",
}


export type ModelAssetsConnection = {
  __typename: "ModelAssetsConnection",
  items:  Array<Assets | null >,
  nextToken?: string | null,
};

export type Assets = {
  __typename: "Assets",
  id: string,
  userID: string,
  projectID?: string | null,
  organizationID?: string | null,
  email: string,
  size?: number | null,
  profile?: ProfileContent | null,
  mediaType?: string | null,
  onlyAdmin?: boolean | null,
  link: string,
  multipleLinks?: Array< string | null > | null,
  type: AssetType,
  subType?: SubAssetType | null,
  note?: string | null,
  isAssigned?: boolean | null,
  galleryId?: string | null,
  assignedDate?: string | null,
  storeID?: string | null,
  storeImagesOrder?: Array< string | null > | null,
  multipleProjects?: Array< string | null > | null,
  user?: User | null,
  lastUpdatedBy?: string | null,
  lastUpdatedByEmail?: string | null,
  lastUpdatedOn?: string | null,
  isUpdated?: boolean | null,
  lastUpdatedByUser?: User | null,
  isRemoved?: boolean | null,
  assignedToInfo?: AssignedToInfo | null,
  createdAt: string,
  updatedAt: string,
};

export type ProfileContent = {
  __typename: "ProfileContent",
  id: string,
  name: string,
  title?: string | null,
  bio?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: string | null,
  department?: string | null,
  LI?: string | null,
  Twitter?: string | null,
  YouTube?: string | null,
  IG?: string | null,
  status: StaffStatus,
};

export enum StaffStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export enum AssetType {
  ORGANIZATION = "ORGANIZATION",
  TEAM = "TEAM",
  STOCK = "STOCK",
  DOCUMENTS = "DOCUMENTS",
  PARTNERS_STOCK = "PARTNERS_STOCK",
  STORE_INVENTORY = "STORE_INVENTORY",
  VECTORS = "VECTORS",
}


export enum SubAssetType {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
  API = "API",
  MAIN_LOGO = "MAIN_LOGO",
  FAVICON = "FAVICON",
  OTHER = "OTHER",
}


export type AssignedToInfo = {
  __typename: "AssignedToInfo",
  pageID?: string | null,
  sectionID?: string | null,
};

export type ModelStoreConnection = {
  __typename: "ModelStoreConnection",
  items:  Array<Store | null >,
  nextToken?: string | null,
};

export type Store = {
  __typename: "Store",
  id: string,
  projectID?: string | null,
  organizationID?: string | null,
  project?: Project | null,
  title: string,
  description: string,
  amount: number,
  inStock: boolean,
  addedBy: string,
  mediaIds?: Array< string | null > | null,
  colorsAvailable?: Array< string | null > | null,
  sizesAvailable?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Counts = {
  __typename: "Counts",
  ORGANIZATION?: number | null,
  TEAM?: number | null,
  STOCK?: number | null,
  DOCUMENTS?: number | null,
  PARTNERS_STOCK?: number | null,
  STORE_INVENTORY?: number | null,
  VECTORS?: number | null,
  GALLERY?: number | null,
  SOURCE_FILE?: number | null,
};

export type ClosingMatrix = {
  __typename: "ClosingMatrix",
  projectFunds?: string | null,
  relationshipWithLead?: string | null,
  projectRelation?: Array< string | null > | null,
  opportunityRelated?: boolean | null,
  additionalBuildRequirements?: string | null,
  estNewFeatures?: string | null,
  industryExpertOnTheirSide?: string | null,
  technicalExpertOnTheirSide?: string | null,
  perceivedReadiness?: string | null,
  notes?: string | null,
};

export enum SubtaskStatus {
  Pending = "Pending",
  InProgress = "InProgress",
  PartiallyCompleted = "PartiallyCompleted",
  Killed = "Killed",
  Completed = "Completed",
}


export type BacklogStyles = {
  __typename: "BacklogStyles",
  bgStyle?: string | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type OrgComment = {
  __typename: "OrgComment",
  comment?: string | null,
  date?: string | null,
};

export type OrgContact = {
  __typename: "OrgContact",
  name?: string | null,
  email?: string | null,
  phone?: string | null,
};

export type ModelOrgMeetingConnection = {
  __typename: "ModelOrgMeetingConnection",
  items:  Array<OrgMeeting | null >,
  nextToken?: string | null,
};

export type UpdateOrganizationInput = {
  id: string,
  authID?: string | null,
  email?: string | null,
  avatar?: string | null,
  name?: string | null,
  type?: OrganizationType | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  timezone?: TimezoneInput | null,
  zipCode?: string | null,
  url?: string | null,
  maxBucketSize?: number | null,
  staffSortSeq?: Array< string | null > | null,
  storeInventorySortSeq?: Array< string | null > | null,
  partnersLogoSortSeq?: Array< string | null > | null,
  mediaFilesSortSeq?: Array< string | null > | null,
  gallerySortSeq?: Array< string | null > | null,
  allowedFileTabs?: Array< string | null > | null,
  orgSize?: OrgSize | null,
  industry?: string | null,
  mission?: string | null,
  software?: Array< string | null > | null,
  paper?: PaperOptions | null,
  printer?: PrinterOptions | null,
  comments?: Array< OrgCommentInput | null > | null,
  socialMedia?: Array< SocialLinksInput | null > | null,
  contact?: OrgContactInput | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateAssetsInput = {
  id?: string | null,
  userID: string,
  projectID?: string | null,
  organizationID?: string | null,
  email: string,
  size?: number | null,
  profile?: ProfileContentInput | null,
  mediaType?: string | null,
  onlyAdmin?: boolean | null,
  link: string,
  multipleLinks?: Array< string | null > | null,
  type: AssetType,
  subType?: SubAssetType | null,
  note?: string | null,
  isAssigned?: boolean | null,
  galleryId?: string | null,
  assignedDate?: string | null,
  storeID?: string | null,
  storeImagesOrder?: Array< string | null > | null,
  multipleProjects?: Array< string | null > | null,
  lastUpdatedBy?: string | null,
  lastUpdatedByEmail?: string | null,
  lastUpdatedOn?: string | null,
  isUpdated?: boolean | null,
  isRemoved?: boolean | null,
  assignedToInfo?: AssignedToInfoInput | null,
};

export type ProfileContentInput = {
  id: string,
  name: string,
  title?: string | null,
  bio?: string | null,
  email?: string | null,
  phone?: string | null,
  country?: string | null,
  department?: string | null,
  LI?: string | null,
  Twitter?: string | null,
  YouTube?: string | null,
  IG?: string | null,
  status: StaffStatus,
};

export type AssignedToInfoInput = {
  pageID?: string | null,
  sectionID?: string | null,
};

export type ModelAssetsConditionInput = {
  userID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  size?: ModelIntInput | null,
  mediaType?: ModelStringInput | null,
  onlyAdmin?: ModelBooleanInput | null,
  link?: ModelStringInput | null,
  multipleLinks?: ModelStringInput | null,
  type?: ModelAssetTypeInput | null,
  subType?: ModelSubAssetTypeInput | null,
  note?: ModelStringInput | null,
  isAssigned?: ModelBooleanInput | null,
  galleryId?: ModelIDInput | null,
  assignedDate?: ModelStringInput | null,
  storeID?: ModelIDInput | null,
  storeImagesOrder?: ModelIDInput | null,
  multipleProjects?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  lastUpdatedByEmail?: ModelStringInput | null,
  lastUpdatedOn?: ModelStringInput | null,
  isUpdated?: ModelBooleanInput | null,
  isRemoved?: ModelBooleanInput | null,
  and?: Array< ModelAssetsConditionInput | null > | null,
  or?: Array< ModelAssetsConditionInput | null > | null,
  not?: ModelAssetsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAssetTypeInput = {
  eq?: AssetType | null,
  ne?: AssetType | null,
};

export type ModelSubAssetTypeInput = {
  eq?: SubAssetType | null,
  ne?: SubAssetType | null,
};

export type UpdateAssetsInput = {
  id: string,
  userID?: string | null,
  projectID?: string | null,
  organizationID?: string | null,
  email?: string | null,
  size?: number | null,
  profile?: ProfileContentInput | null,
  mediaType?: string | null,
  onlyAdmin?: boolean | null,
  link?: string | null,
  multipleLinks?: Array< string | null > | null,
  type?: AssetType | null,
  subType?: SubAssetType | null,
  note?: string | null,
  isAssigned?: boolean | null,
  galleryId?: string | null,
  assignedDate?: string | null,
  storeID?: string | null,
  storeImagesOrder?: Array< string | null > | null,
  multipleProjects?: Array< string | null > | null,
  lastUpdatedBy?: string | null,
  lastUpdatedByEmail?: string | null,
  lastUpdatedOn?: string | null,
  isUpdated?: boolean | null,
  isRemoved?: boolean | null,
  assignedToInfo?: AssignedToInfoInput | null,
};

export type DeleteAssetsInput = {
  id: string,
};

export type CreateSourceFileInput = {
  id?: string | null,
  userID: string,
  organizationID?: string | null,
  projectId?: string | null,
  email: string,
  size?: number | null,
  mediaType?: string | null,
  link: string,
  note?: string | null,
};

export type ModelSourceFileConditionInput = {
  userID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  size?: ModelIntInput | null,
  mediaType?: ModelStringInput | null,
  link?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array< ModelSourceFileConditionInput | null > | null,
  or?: Array< ModelSourceFileConditionInput | null > | null,
  not?: ModelSourceFileConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateSourceFileInput = {
  id: string,
  userID?: string | null,
  organizationID?: string | null,
  projectId?: string | null,
  email?: string | null,
  size?: number | null,
  mediaType?: string | null,
  link?: string | null,
  note?: string | null,
};

export type DeleteSourceFileInput = {
  id: string,
};

export type CreateGalleryInput = {
  id?: string | null,
  userID: string,
  organizationID?: string | null,
  projectId?: string | null,
  email: string,
  coverImage: string,
  sortSeq?: Array< string | null > | null,
  gallery?: Array< GalleryItemInput | null > | null,
  title?: string | null,
  publishDate?: string | null,
};

export type GalleryItemInput = {
  id: string,
  link: string,
  mediaType: string,
  note?: string | null,
};

export type ModelGalleryConditionInput = {
  userID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  sortSeq?: ModelIDInput | null,
  title?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  and?: Array< ModelGalleryConditionInput | null > | null,
  or?: Array< ModelGalleryConditionInput | null > | null,
  not?: ModelGalleryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateGalleryInput = {
  id: string,
  userID?: string | null,
  organizationID?: string | null,
  projectId?: string | null,
  email?: string | null,
  coverImage?: string | null,
  sortSeq?: Array< string | null > | null,
  gallery?: Array< GalleryItemInput | null > | null,
  title?: string | null,
  publishDate?: string | null,
};

export type DeleteGalleryInput = {
  id: string,
};

export type CreateUserStatInput = {
  id?: string | null,
  userID: string,
  taskID: string,
};

export type ModelUserStatConditionInput = {
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  and?: Array< ModelUserStatConditionInput | null > | null,
  or?: Array< ModelUserStatConditionInput | null > | null,
  not?: ModelUserStatConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserStatInput = {
  id: string,
  userID?: string | null,
  taskID?: string | null,
};

export type DeleteUserStatInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  authID: string,
  organizationID?: string | null,
  name: string,
  timezone?: TimezoneInput | null,
  title?: string | null,
  email: string,
  phone?: string | null,
  isAdmin?: boolean | null,
  initialEmailSentOn?: string | null,
  otherProjects?: Array< OtherProjectRoleInput | null > | null,
  overrideOrganization?: string | null,
  orgLevel?: number | null,
  lastLogin?: string | null,
  status: Status,
  adminAccessTo?: Array< string | null > | null,
  liveVisiblity?: boolean | null,
  isZoiq?: boolean | null,
  completedTasks?: Array< string | null > | null,
  links?: Array< string | null > | null,
  socialMedia?: Array< SocialLinksInput | null > | null,
  canAddProjects?: boolean | null,
};

export type OtherProjectRoleInput = {
  projectID?: string | null,
  projectRole?: ProjectRole | null,
};

export type ModelUserConditionInput = {
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  isAdmin?: ModelBooleanInput | null,
  initialEmailSentOn?: ModelStringInput | null,
  overrideOrganization?: ModelStringInput | null,
  orgLevel?: ModelIntInput | null,
  lastLogin?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  adminAccessTo?: ModelIDInput | null,
  liveVisiblity?: ModelBooleanInput | null,
  isZoiq?: ModelBooleanInput | null,
  completedTasks?: ModelIDInput | null,
  links?: ModelStringInput | null,
  canAddProjects?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type UpdateUserInput = {
  id?: string | null,
  authID: string,
  organizationID?: string | null,
  name?: string | null,
  timezone?: TimezoneInput | null,
  title?: string | null,
  email: string,
  phone?: string | null,
  isAdmin?: boolean | null,
  initialEmailSentOn?: string | null,
  otherProjects?: Array< OtherProjectRoleInput | null > | null,
  overrideOrganization?: string | null,
  orgLevel?: number | null,
  lastLogin?: string | null,
  status?: Status | null,
  adminAccessTo?: Array< string | null > | null,
  liveVisiblity?: boolean | null,
  isZoiq?: boolean | null,
  completedTasks?: Array< string | null > | null,
  links?: Array< string | null > | null,
  socialMedia?: Array< SocialLinksInput | null > | null,
  canAddProjects?: boolean | null,
};

export type DeleteUserInput = {
  authID: string,
  email: string,
};

export type CreateProjectInput = {
  id?: string | null,
  organizationID?: string | null,
  name: string,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  milestone?: string | null,
  ownerID?: string | null,
  ownerAuthID?: string | null,
  ownerEmail?: string | null,
  status: ProjectStatus,
  otherUsers?: Array< OtherUserInput | null > | null,
  risk: Risk,
  githubRepo?: string | null,
  awsAccount?: string | null,
  cognitoUrl?: string | null,
  dynamo?: Array< DynamoInput | null > | null,
  solutionType?: string | null,
  scope?: string | null,
  estUsersInFirstYear?: string | null,
  userAgeGroups?: Array< string | null > | null,
  lambdaFunction?: Array< LambdaFunctionInput | null > | null,
  liveUrl?: string | null,
  siteData?: SiteDataInput | null,
  isZoiq?: boolean | null,
  userSortSeq?: Array< string | null > | null,
  projectPlanSortSeq?: Array< string | null > | null,
  projectMeetingsSortSeq?: Array< string | null > | null,
  projectCriteriaSortSeq?: Array< string | null > | null,
  counts?: CountsInput | null,
  closingMatrix?: ClosingMatrixInput | null,
};

export type OtherUserInput = {
  userID?: string | null,
  projectRole?: ProjectRole | null,
};

export type DynamoInput = {
  key?: string | null,
  value?: string | null,
};

export type LambdaFunctionInput = {
  name?: string | null,
  url?: string | null,
};

export type SiteDataInput = {
  audience?: Array< AudienceType | null > | null,
  trafficCount?: AudienceSize | null,
  trafficLocation?: Array< string | null > | null,
  tone?: ToneType | null,
  languages?: LanguageType | null,
  websites?: Array< WebsiteInput | null > | null,
  pageSortSeq?: Array< string | null > | null,
  sectionSortSeq?: Array< SectionSortInput | null > | null,
  organizationSummary?: string | null,
  purpose?: string | null,
  goals?: Array< string | null > | null,
  messaging?: string | null,
  keyDates?: Array< string | null > | null,
  suggestedSolution?: Array< SuggestedSolutions | null > | null,
  projectNotes?: string | null,
  contactPerson?: string | null,
  isLocked?: boolean | null,
  appId?: string | null,
  branchName?: string | null,
};

export type WebsiteInput = {
  link?: string | null,
  reason?: string | null,
};

export type SectionSortInput = {
  pageId?: string | null,
  seq?: Array< string | null > | null,
};

export type CountsInput = {
  ORGANIZATION?: number | null,
  TEAM?: number | null,
  STOCK?: number | null,
  DOCUMENTS?: number | null,
  PARTNERS_STOCK?: number | null,
  STORE_INVENTORY?: number | null,
  VECTORS?: number | null,
  GALLERY?: number | null,
  SOURCE_FILE?: number | null,
};

export type ClosingMatrixInput = {
  projectFunds?: string | null,
  relationshipWithLead?: string | null,
  projectRelation?: Array< string | null > | null,
  opportunityRelated?: boolean | null,
  additionalBuildRequirements?: string | null,
  estNewFeatures?: string | null,
  industryExpertOnTheirSide?: string | null,
  technicalExpertOnTheirSide?: string | null,
  perceivedReadiness?: string | null,
  notes?: string | null,
};

export type ModelProjectConditionInput = {
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  milestone?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  ownerAuthID?: ModelIDInput | null,
  ownerEmail?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  risk?: ModelRiskInput | null,
  githubRepo?: ModelStringInput | null,
  awsAccount?: ModelStringInput | null,
  cognitoUrl?: ModelStringInput | null,
  solutionType?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  estUsersInFirstYear?: ModelStringInput | null,
  userAgeGroups?: ModelStringInput | null,
  liveUrl?: ModelStringInput | null,
  isZoiq?: ModelBooleanInput | null,
  userSortSeq?: ModelIDInput | null,
  projectPlanSortSeq?: ModelIDInput | null,
  projectMeetingsSortSeq?: ModelIDInput | null,
  projectCriteriaSortSeq?: ModelIDInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelProjectStatusInput = {
  eq?: ProjectStatus | null,
  ne?: ProjectStatus | null,
};

export type ModelRiskInput = {
  eq?: Risk | null,
  ne?: Risk | null,
};

export type UpdateProjectInput = {
  id: string,
  organizationID?: string | null,
  name?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  milestone?: string | null,
  ownerID?: string | null,
  ownerAuthID?: string | null,
  ownerEmail?: string | null,
  status?: ProjectStatus | null,
  otherUsers?: Array< OtherUserInput | null > | null,
  risk?: Risk | null,
  githubRepo?: string | null,
  awsAccount?: string | null,
  cognitoUrl?: string | null,
  dynamo?: Array< DynamoInput | null > | null,
  solutionType?: string | null,
  scope?: string | null,
  estUsersInFirstYear?: string | null,
  userAgeGroups?: Array< string | null > | null,
  lambdaFunction?: Array< LambdaFunctionInput | null > | null,
  liveUrl?: string | null,
  siteData?: SiteDataInput | null,
  isZoiq?: boolean | null,
  userSortSeq?: Array< string | null > | null,
  projectPlanSortSeq?: Array< string | null > | null,
  projectMeetingsSortSeq?: Array< string | null > | null,
  projectCriteriaSortSeq?: Array< string | null > | null,
  counts?: CountsInput | null,
  closingMatrix?: ClosingMatrixInput | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateProjectPlanInput = {
  id?: string | null,
  projectID: string,
  milestone: string,
  startDate?: string | null,
  endDate?: string | null,
  status: ProjectStatus,
  risk: Risk,
  fakeId?: number | null,
};

export type ModelProjectPlanConditionInput = {
  projectID?: ModelIDInput | null,
  milestone?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  risk?: ModelRiskInput | null,
  fakeId?: ModelIntInput | null,
  and?: Array< ModelProjectPlanConditionInput | null > | null,
  or?: Array< ModelProjectPlanConditionInput | null > | null,
  not?: ModelProjectPlanConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateProjectPlanInput = {
  id: string,
  projectID?: string | null,
  milestone?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  status?: ProjectStatus | null,
  risk?: Risk | null,
  fakeId?: number | null,
};

export type DeleteProjectPlanInput = {
  id: string,
};

export type CreateDiscussionInput = {
  id?: string | null,
  projectID: string,
  name: string,
  date?: string | null,
  actionItemsSortSeq?: Array< string | null > | null,
  emailSent?: boolean | null,
  emailSentDate?: string | null,
  meetingID?: string | null,
};

export type ModelDiscussionConditionInput = {
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  actionItemsSortSeq?: ModelIDInput | null,
  emailSent?: ModelBooleanInput | null,
  emailSentDate?: ModelStringInput | null,
  meetingID?: ModelIDInput | null,
  and?: Array< ModelDiscussionConditionInput | null > | null,
  or?: Array< ModelDiscussionConditionInput | null > | null,
  not?: ModelDiscussionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateDiscussionInput = {
  id: string,
  projectID?: string | null,
  name?: string | null,
  date?: string | null,
  actionItemsSortSeq?: Array< string | null > | null,
  emailSent?: boolean | null,
  emailSentDate?: string | null,
  meetingID?: string | null,
};

export type DeleteDiscussionInput = {
  id: string,
};

export type CreateStyleGuideInput = {
  id?: string | null,
  name: string,
  type: StyleGuideType,
  value: string,
  organizationID: string,
  projectID?: string | null,
  extraInfo?: string | null,
};

export enum StyleGuideType {
  COLORS = "COLORS",
  TYPOGRAPHY = "TYPOGRAPHY",
  FORMS = "FORMS",
  VARIABLES = "VARIABLES",
}


export type ModelStyleGuideConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStyleGuideTypeInput | null,
  value?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  extraInfo?: ModelStringInput | null,
  and?: Array< ModelStyleGuideConditionInput | null > | null,
  or?: Array< ModelStyleGuideConditionInput | null > | null,
  not?: ModelStyleGuideConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStyleGuideTypeInput = {
  eq?: StyleGuideType | null,
  ne?: StyleGuideType | null,
};

export type StyleGuide = {
  __typename: "StyleGuide",
  id: string,
  name: string,
  type: StyleGuideType,
  value: string,
  organizationID: string,
  projectID?: string | null,
  extraInfo?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateStyleGuideInput = {
  id: string,
  name?: string | null,
  type?: StyleGuideType | null,
  value?: string | null,
  organizationID?: string | null,
  projectID?: string | null,
  extraInfo?: string | null,
};

export type DeleteStyleGuideInput = {
  id: string,
};

export type CreateActionItemsInput = {
  id?: string | null,
  discussionID: string,
  actionItem: string,
  owners?: Array< string | null > | null,
  isCompleted?: boolean | null,
  targetDate?: string | null,
  dateAssigned?: string | null,
  emailSent?: boolean | null,
  dateCompleted?: string | null,
};

export type ModelActionItemsConditionInput = {
  discussionID?: ModelIDInput | null,
  actionItem?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  targetDate?: ModelStringInput | null,
  dateAssigned?: ModelStringInput | null,
  emailSent?: ModelBooleanInput | null,
  dateCompleted?: ModelStringInput | null,
  and?: Array< ModelActionItemsConditionInput | null > | null,
  or?: Array< ModelActionItemsConditionInput | null > | null,
  not?: ModelActionItemsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateActionItemsInput = {
  id: string,
  discussionID?: string | null,
  actionItem?: string | null,
  owners?: Array< string | null > | null,
  isCompleted?: boolean | null,
  targetDate?: string | null,
  dateAssigned?: string | null,
  emailSent?: boolean | null,
  dateCompleted?: string | null,
};

export type DeleteActionItemsInput = {
  id: string,
};

export type CreateSummaryInput = {
  id?: string | null,
  projectID: string,
  summary: string,
  summaryDate: string,
  summaryOwnerID: string,
  summaryOwnerEmail: string,
  summaryType: SummaryType,
  discussionDate?: string | null,
  discussedWithID?: string | null,
  discussedWithEmail?: string | null,
};

export type ModelSummaryConditionInput = {
  projectID?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  summaryDate?: ModelStringInput | null,
  summaryOwnerID?: ModelIDInput | null,
  summaryOwnerEmail?: ModelStringInput | null,
  summaryType?: ModelSummaryTypeInput | null,
  discussionDate?: ModelStringInput | null,
  discussedWithID?: ModelIDInput | null,
  discussedWithEmail?: ModelStringInput | null,
  and?: Array< ModelSummaryConditionInput | null > | null,
  or?: Array< ModelSummaryConditionInput | null > | null,
  not?: ModelSummaryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSummaryTypeInput = {
  eq?: SummaryType | null,
  ne?: SummaryType | null,
};

export type UpdateSummaryInput = {
  id: string,
  projectID?: string | null,
  summary?: string | null,
  summaryDate?: string | null,
  summaryOwnerID?: string | null,
  summaryOwnerEmail?: string | null,
  summaryType?: SummaryType | null,
  discussionDate?: string | null,
  discussedWithID?: string | null,
  discussedWithEmail?: string | null,
};

export type DeleteSummaryInput = {
  id: string,
};

export type CreateMeetingsInput = {
  id?: string | null,
  projectID: string,
  name: string,
  description: string,
  firstMeetingDate: string,
  lastMeetingDate: string,
  startTime: string,
  endTime: string,
  rolls?: RollsTypeInput | null,
  frequency?: FrequencyType | null,
  link?: string | null,
  attendees: Array< string | null >,
  mailSentDate?: string | null,
};

export type RollsTypeInput = {
  Date?: string | null,
  Day?: Array< string | null > | null,
};

export type ModelMeetingsConditionInput = {
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  firstMeetingDate?: ModelStringInput | null,
  lastMeetingDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  frequency?: ModelFrequencyTypeInput | null,
  link?: ModelStringInput | null,
  attendees?: ModelIDInput | null,
  mailSentDate?: ModelStringInput | null,
  and?: Array< ModelMeetingsConditionInput | null > | null,
  or?: Array< ModelMeetingsConditionInput | null > | null,
  not?: ModelMeetingsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFrequencyTypeInput = {
  eq?: FrequencyType | null,
  ne?: FrequencyType | null,
};

export type UpdateMeetingsInput = {
  id: string,
  projectID?: string | null,
  name?: string | null,
  description?: string | null,
  firstMeetingDate?: string | null,
  lastMeetingDate?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  rolls?: RollsTypeInput | null,
  frequency?: FrequencyType | null,
  link?: string | null,
  attendees?: Array< string | null > | null,
  mailSentDate?: string | null,
};

export type DeleteMeetingsInput = {
  id: string,
};

export type CreateSuccessCriteriaInput = {
  id?: string | null,
  projectID: string,
  description: string,
  measurableCriteria: string,
  type: SuccessCriteriaType,
  pageId?: string | null,
  sectionId?: string | null,
  pagesCriteriaId?: string | null,
};

export type ModelSuccessCriteriaConditionInput = {
  projectID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  measurableCriteria?: ModelStringInput | null,
  type?: ModelSuccessCriteriaTypeInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  and?: Array< ModelSuccessCriteriaConditionInput | null > | null,
  or?: Array< ModelSuccessCriteriaConditionInput | null > | null,
  not?: ModelSuccessCriteriaConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  pagesCriteriaId?: ModelIDInput | null,
};

export type ModelSuccessCriteriaTypeInput = {
  eq?: SuccessCriteriaType | null,
  ne?: SuccessCriteriaType | null,
};

export type UpdateSuccessCriteriaInput = {
  id: string,
  projectID?: string | null,
  description?: string | null,
  measurableCriteria?: string | null,
  type?: SuccessCriteriaType | null,
  pageId?: string | null,
  sectionId?: string | null,
  pagesCriteriaId?: string | null,
};

export type DeleteSuccessCriteriaInput = {
  id: string,
};

export type CreatePagesInput = {
  id?: string | null,
  projectID: string,
  name: string,
  description: string,
  link?: string | null,
  pageSections: Array< PageSectionInput | null >,
  successCriteriaSummary?: SuccessCriteriaSummaryInput | null,
};

export type PageSectionInput = {
  section: string,
  noMedia?: boolean | null,
  id: string,
  link?: string | null,
  meta?: Array< string | null > | null,
  media?: Array< string | null > | null,
  lastEmailSent?: string | null,
  notes?: string | null,
  illustration?: string | null,
  instructionsLevel?: string | null,
  signOff?: SignOffSectionInput | null,
};

export type SignOffSectionInput = {
  title: string,
  description?: string | null,
  originalDesign: string,
  originalDesignDate: string,
  updatedDesign?: string | null,
  updatedDesignDate?: string | null,
};

export type SuccessCriteriaSummaryInput = {
  mustHave?: string | null,
  shouldHave?: string | null,
  couldHave?: string | null,
  wontHave?: string | null,
};

export type ModelPagesConditionInput = {
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  link?: ModelStringInput | null,
  and?: Array< ModelPagesConditionInput | null > | null,
  or?: Array< ModelPagesConditionInput | null > | null,
  not?: ModelPagesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePagesInput = {
  id: string,
  projectID?: string | null,
  name?: string | null,
  description?: string | null,
  link?: string | null,
  pageSections?: Array< PageSectionInput | null > | null,
  successCriteriaSummary?: SuccessCriteriaSummaryInput | null,
};

export type DeletePagesInput = {
  id: string,
};

export type CreateUserJourneyInput = {
  id?: string | null,
  projectID: string,
  userRole: string,
  pageName: string,
  sectionName: string,
  access: boolean,
};

export type ModelUserJourneyConditionInput = {
  projectID?: ModelIDInput | null,
  userRole?: ModelStringInput | null,
  pageName?: ModelStringInput | null,
  sectionName?: ModelStringInput | null,
  access?: ModelBooleanInput | null,
  and?: Array< ModelUserJourneyConditionInput | null > | null,
  or?: Array< ModelUserJourneyConditionInput | null > | null,
  not?: ModelUserJourneyConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserJourney = {
  __typename: "UserJourney",
  id: string,
  projectID: string,
  project?: Project | null,
  userRole: string,
  pageName: string,
  sectionName: string,
  access: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserJourneyInput = {
  id: string,
  projectID?: string | null,
  userRole?: string | null,
  pageName?: string | null,
  sectionName?: string | null,
  access?: boolean | null,
};

export type DeleteUserJourneyInput = {
  id: string,
};

export type CreateBacklogSequenceInput = {
  id?: string | null,
  sequence: Array< string | null >,
};

export type ModelBacklogSequenceConditionInput = {
  sequence?: ModelIDInput | null,
  and?: Array< ModelBacklogSequenceConditionInput | null > | null,
  or?: Array< ModelBacklogSequenceConditionInput | null > | null,
  not?: ModelBacklogSequenceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type BacklogSequence = {
  __typename: "BacklogSequence",
  id: string,
  sequence: Array< string | null >,
  createdAt: string,
  updatedAt: string,
};

export type UpdateBacklogSequenceInput = {
  id: string,
  sequence?: Array< string | null > | null,
};

export type DeleteBacklogSequenceInput = {
  id: string,
};

export type CreateCommentsInput = {
  id?: string | null,
  backlogID: string,
  comment: string,
  date: string,
  authID: string,
  authEmail: string,
};

export type ModelCommentsConditionInput = {
  backlogID?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  date?: ModelStringInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  and?: Array< ModelCommentsConditionInput | null > | null,
  or?: Array< ModelCommentsConditionInput | null > | null,
  not?: ModelCommentsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCommentsInput = {
  id: string,
  backlogID?: string | null,
  comment?: string | null,
  date?: string | null,
  authID?: string | null,
  authEmail?: string | null,
};

export type DeleteCommentsInput = {
  id: string,
};

export type CreateSubTaskModelInput = {
  id?: string | null,
  isCompleted?: boolean | null,
  task: string,
  completedOn?: string | null,
  mediaId?: string | null,
  sprintType?: SprintType | null,
  backlogID?: string | null,
  statusUpdateDate?: string | null,
  priority?: BacklogPriority | null,
  completedByEmail?: string | null,
  organizationID?: string | null,
  projectID?: string | null,
  projectPlanID?: string | null,
  status?: SubtaskStatus | null,
  moscow?: string | null,
};

export type ModelSubTaskModelConditionInput = {
  isCompleted?: ModelBooleanInput | null,
  task?: ModelStringInput | null,
  completedOn?: ModelStringInput | null,
  mediaId?: ModelIDInput | null,
  sprintType?: ModelSprintTypeInput | null,
  backlogID?: ModelIDInput | null,
  statusUpdateDate?: ModelStringInput | null,
  priority?: ModelBacklogPriorityInput | null,
  completedByEmail?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  projectPlanID?: ModelIDInput | null,
  status?: ModelSubtaskStatusInput | null,
  moscow?: ModelStringInput | null,
  and?: Array< ModelSubTaskModelConditionInput | null > | null,
  or?: Array< ModelSubTaskModelConditionInput | null > | null,
  not?: ModelSubTaskModelConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelSprintTypeInput = {
  eq?: SprintType | null,
  ne?: SprintType | null,
};

export type ModelBacklogPriorityInput = {
  eq?: BacklogPriority | null,
  ne?: BacklogPriority | null,
};

export type ModelSubtaskStatusInput = {
  eq?: SubtaskStatus | null,
  ne?: SubtaskStatus | null,
};

export type UpdateSubTaskModelInput = {
  id: string,
  isCompleted?: boolean | null,
  task?: string | null,
  completedOn?: string | null,
  mediaId?: string | null,
  sprintType?: SprintType | null,
  backlogID?: string | null,
  statusUpdateDate?: string | null,
  priority?: BacklogPriority | null,
  completedByEmail?: string | null,
  organizationID?: string | null,
  projectID?: string | null,
  projectPlanID?: string | null,
  status?: SubtaskStatus | null,
  moscow?: string | null,
};

export type DeleteSubTaskModelInput = {
  id: string,
};

export type CreateBacklogInput = {
  id?: string | null,
  name: string,
  title?: string | null,
  isCompleted?: boolean | null,
  dateCompleted?: string | null,
  createdBy: string,
  createdByEmail: string,
  media?: Array< BacklogMediaInput | null > | null,
  status: BacklogStatus,
  priority: BacklogPriority,
  assignedTo?: Array< string | null > | null,
  targetDate?: string | null,
  sectionID?: string | null,
  pageID?: string | null,
  notifyAssignedMembers?: boolean | null,
  referralLinks?: Array< string | null > | null,
  styles?: BacklogStylesInput | null,
  note?: string | null,
  tags?: Array< string | null > | null,
};

export type BacklogMediaInput = {
  id: string,
  mediaType: string,
  mediaLink: string,
};

export type BacklogStylesInput = {
  bgStyle?: string | null,
};

export type ModelBacklogConditionInput = {
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  dateCompleted?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdByEmail?: ModelStringInput | null,
  status?: ModelBacklogStatusInput | null,
  priority?: ModelBacklogPriorityInput | null,
  assignedTo?: ModelIDInput | null,
  targetDate?: ModelStringInput | null,
  sectionID?: ModelIDInput | null,
  pageID?: ModelIDInput | null,
  notifyAssignedMembers?: ModelBooleanInput | null,
  referralLinks?: ModelStringInput | null,
  note?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelBacklogConditionInput | null > | null,
  or?: Array< ModelBacklogConditionInput | null > | null,
  not?: ModelBacklogConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelBacklogStatusInput = {
  eq?: BacklogStatus | null,
  ne?: BacklogStatus | null,
};

export type UpdateBacklogInput = {
  id: string,
  name?: string | null,
  title?: string | null,
  isCompleted?: boolean | null,
  dateCompleted?: string | null,
  createdBy?: string | null,
  createdByEmail?: string | null,
  media?: Array< BacklogMediaInput | null > | null,
  status?: BacklogStatus | null,
  priority?: BacklogPriority | null,
  assignedTo?: Array< string | null > | null,
  targetDate?: string | null,
  sectionID?: string | null,
  pageID?: string | null,
  notifyAssignedMembers?: boolean | null,
  referralLinks?: Array< string | null > | null,
  styles?: BacklogStylesInput | null,
  note?: string | null,
  tags?: Array< string | null > | null,
};

export type DeleteBacklogInput = {
  id: string,
};

export type CreateContentCreationInput = {
  id?: string | null,
  projectID: string,
  content: string,
  pageId?: string | null,
  sectionId?: string | null,
  authID: string,
  authEmail: string,
  pageName?: string | null,
  sent?: boolean | null,
  sentDate?: string | null,
};

export type ModelContentCreationConditionInput = {
  projectID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  pageName?: ModelStringInput | null,
  sent?: ModelBooleanInput | null,
  sentDate?: ModelStringInput | null,
  and?: Array< ModelContentCreationConditionInput | null > | null,
  or?: Array< ModelContentCreationConditionInput | null > | null,
  not?: ModelContentCreationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ContentCreation = {
  __typename: "ContentCreation",
  id: string,
  projectID: string,
  project?: Project | null,
  content: string,
  pageId?: string | null,
  sectionId?: string | null,
  authID: string,
  authEmail: string,
  user?: User | null,
  pageName?: string | null,
  sent?: boolean | null,
  sentDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateContentCreationInput = {
  id: string,
  projectID?: string | null,
  content?: string | null,
  pageId?: string | null,
  sectionId?: string | null,
  authID?: string | null,
  authEmail?: string | null,
  pageName?: string | null,
  sent?: boolean | null,
  sentDate?: string | null,
};

export type DeleteContentCreationInput = {
  id: string,
};

export type CreateConversationInput = {
  id?: string | null,
  projectID: string,
  conversations?: Array< ConversationInputInput | null > | null,
  authID: string,
  pageId?: string | null,
  sectionId?: string | null,
  authEmail: string,
};

export type ConversationInputInput = {
  message: string,
  isBot: boolean,
  date: string,
  id?: string | null,
};

export type ModelConversationConditionInput = {
  projectID?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  and?: Array< ModelConversationConditionInput | null > | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  not?: ModelConversationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Conversation = {
  __typename: "Conversation",
  id: string,
  projectID: string,
  project?: Project | null,
  conversations?:  Array<ConversationInput | null > | null,
  authID: string,
  pageId?: string | null,
  sectionId?: string | null,
  authEmail: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type ConversationInput = {
  __typename: "ConversationInput",
  message: string,
  isBot: boolean,
  date: string,
  id?: string | null,
};

export type UpdateConversationInput = {
  id: string,
  projectID?: string | null,
  conversations?: Array< ConversationInputInput | null > | null,
  authID?: string | null,
  pageId?: string | null,
  sectionId?: string | null,
  authEmail?: string | null,
};

export type DeleteConversationInput = {
  id: string,
};

export type CreateStoreInput = {
  id?: string | null,
  projectID?: string | null,
  organizationID?: string | null,
  title: string,
  description: string,
  amount: number,
  inStock: boolean,
  addedBy: string,
  mediaIds?: Array< string | null > | null,
  colorsAvailable?: Array< string | null > | null,
  sizesAvailable?: Array< string | null > | null,
};

export type ModelStoreConditionInput = {
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  inStock?: ModelBooleanInput | null,
  addedBy?: ModelIDInput | null,
  mediaIds?: ModelIDInput | null,
  colorsAvailable?: ModelStringInput | null,
  sizesAvailable?: ModelStringInput | null,
  and?: Array< ModelStoreConditionInput | null > | null,
  or?: Array< ModelStoreConditionInput | null > | null,
  not?: ModelStoreConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateStoreInput = {
  id: string,
  projectID?: string | null,
  organizationID?: string | null,
  title?: string | null,
  description?: string | null,
  amount?: number | null,
  inStock?: boolean | null,
  addedBy?: string | null,
  mediaIds?: Array< string | null > | null,
  colorsAvailable?: Array< string | null > | null,
  sizesAvailable?: Array< string | null > | null,
};

export type DeleteStoreInput = {
  id: string,
};

export type CreateFontInput = {
  id?: string | null,
  name: string,
  link: string,
  complimentsWithID?: Array< string | null > | null,
};

export type ModelFontConditionInput = {
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  complimentsWithID?: ModelIDInput | null,
  and?: Array< ModelFontConditionInput | null > | null,
  or?: Array< ModelFontConditionInput | null > | null,
  not?: ModelFontConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Font = {
  __typename: "Font",
  id: string,
  name: string,
  link: string,
  complimentsWithID?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateFontInput = {
  id: string,
  name?: string | null,
  link?: string | null,
  complimentsWithID?: Array< string | null > | null,
};

export type DeleteFontInput = {
  id: string,
};

export type CreateActivityInput = {
  id?: string | null,
  authID: string,
  authEmail: string,
  activity: string,
  link?: string | null,
  date: string,
};

export type ModelActivityConditionInput = {
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  activity?: ModelStringInput | null,
  link?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelActivityConditionInput | null > | null,
  or?: Array< ModelActivityConditionInput | null > | null,
  not?: ModelActivityConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Activity = {
  __typename: "Activity",
  id: string,
  authID: string,
  authEmail: string,
  user?: User | null,
  activity: string,
  link?: string | null,
  date: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateActivityInput = {
  id: string,
  authID?: string | null,
  authEmail?: string | null,
  activity?: string | null,
  link?: string | null,
  date?: string | null,
};

export type DeleteActivityInput = {
  id: string,
};

export type CreateSolutionInput = {
  id?: string | null,
  authID: string,
  authEmail: string,
  tags?: Array< string | null > | null,
  title: string,
  description?: string | null,
  projectID?: string | null,
};

export type ModelSolutionConditionInput = {
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projectID?: ModelIDInput | null,
  and?: Array< ModelSolutionConditionInput | null > | null,
  or?: Array< ModelSolutionConditionInput | null > | null,
  not?: ModelSolutionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Solution = {
  __typename: "Solution",
  id: string,
  authID: string,
  authEmail: string,
  user?: User | null,
  tags?: Array< string | null > | null,
  title: string,
  description?: string | null,
  projectID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSolutionInput = {
  id: string,
  authID?: string | null,
  authEmail?: string | null,
  tags?: Array< string | null > | null,
  title?: string | null,
  description?: string | null,
  projectID?: string | null,
};

export type DeleteSolutionInput = {
  id: string,
};

export type CreatePopupsInput = {
  id?: string | null,
  authID: string,
  authEmail: string,
  title: string,
  description?: string | null,
  projectID?: string | null,
  isActive?: boolean | null,
  expiryDate?: string | null,
  content?: Array< PopupContentInput | null > | null,
  image?: string | null,
};

export type PopupContentInput = {
  name: string,
  value: string,
};

export type ModelPopupsConditionInput = {
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projectID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  expiryDate?: ModelStringInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelPopupsConditionInput | null > | null,
  or?: Array< ModelPopupsConditionInput | null > | null,
  not?: ModelPopupsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Popups = {
  __typename: "Popups",
  id: string,
  authID: string,
  authEmail: string,
  user?: User | null,
  title: string,
  description?: string | null,
  projectID?: string | null,
  project?: Project | null,
  isActive?: boolean | null,
  expiryDate?: string | null,
  content?:  Array<PopupContent | null > | null,
  image?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type PopupContent = {
  __typename: "PopupContent",
  name: string,
  value: string,
};

export type UpdatePopupsInput = {
  id: string,
  authID?: string | null,
  authEmail?: string | null,
  title?: string | null,
  description?: string | null,
  projectID?: string | null,
  isActive?: boolean | null,
  expiryDate?: string | null,
  content?: Array< PopupContentInput | null > | null,
  image?: string | null,
};

export type DeletePopupsInput = {
  id: string,
};

export type ModelOrgMeetingFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  attendees?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrgMeetingFilterInput | null > | null,
  or?: Array< ModelOrgMeetingFilterInput | null > | null,
  not?: ModelOrgMeetingFilterInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelOrganizationTypeInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  zipCode?: ModelStringInput | null,
  url?: ModelStringInput | null,
  maxBucketSize?: ModelIntInput | null,
  staffSortSeq?: ModelIDInput | null,
  storeInventorySortSeq?: ModelIDInput | null,
  partnersLogoSortSeq?: ModelIDInput | null,
  mediaFilesSortSeq?: ModelIDInput | null,
  gallerySortSeq?: ModelIDInput | null,
  allowedFileTabs?: ModelStringInput | null,
  orgSize?: ModelOrgSizeInput | null,
  industry?: ModelStringInput | null,
  mission?: ModelStringInput | null,
  software?: ModelStringInput | null,
  paper?: ModelPaperOptionsInput | null,
  printer?: ModelPrinterOptionsInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelAssetsFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  size?: ModelIntInput | null,
  mediaType?: ModelStringInput | null,
  onlyAdmin?: ModelBooleanInput | null,
  link?: ModelStringInput | null,
  multipleLinks?: ModelStringInput | null,
  type?: ModelAssetTypeInput | null,
  subType?: ModelSubAssetTypeInput | null,
  note?: ModelStringInput | null,
  isAssigned?: ModelBooleanInput | null,
  galleryId?: ModelIDInput | null,
  assignedDate?: ModelStringInput | null,
  storeID?: ModelIDInput | null,
  storeImagesOrder?: ModelIDInput | null,
  multipleProjects?: ModelIDInput | null,
  lastUpdatedBy?: ModelIDInput | null,
  lastUpdatedByEmail?: ModelStringInput | null,
  lastUpdatedOn?: ModelStringInput | null,
  isUpdated?: ModelBooleanInput | null,
  isRemoved?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAssetsFilterInput | null > | null,
  or?: Array< ModelAssetsFilterInput | null > | null,
  not?: ModelAssetsFilterInput | null,
};

export type ModelSourceFileFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  size?: ModelIntInput | null,
  mediaType?: ModelStringInput | null,
  link?: ModelStringInput | null,
  note?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSourceFileFilterInput | null > | null,
  or?: Array< ModelSourceFileFilterInput | null > | null,
  not?: ModelSourceFileFilterInput | null,
};

export type ModelGalleryFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  coverImage?: ModelStringInput | null,
  sortSeq?: ModelIDInput | null,
  title?: ModelStringInput | null,
  publishDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGalleryFilterInput | null > | null,
  or?: Array< ModelGalleryFilterInput | null > | null,
  not?: ModelGalleryFilterInput | null,
};

export type ModelUserStatFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserStatFilterInput | null > | null,
  or?: Array< ModelUserStatFilterInput | null > | null,
  not?: ModelUserStatFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  isAdmin?: ModelBooleanInput | null,
  initialEmailSentOn?: ModelStringInput | null,
  overrideOrganization?: ModelStringInput | null,
  orgLevel?: ModelIntInput | null,
  lastLogin?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  adminAccessTo?: ModelIDInput | null,
  liveVisiblity?: ModelBooleanInput | null,
  isZoiq?: ModelBooleanInput | null,
  completedTasks?: ModelIDInput | null,
  links?: ModelStringInput | null,
  canAddProjects?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  milestone?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  ownerAuthID?: ModelIDInput | null,
  ownerEmail?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  risk?: ModelRiskInput | null,
  githubRepo?: ModelStringInput | null,
  awsAccount?: ModelStringInput | null,
  cognitoUrl?: ModelStringInput | null,
  solutionType?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  estUsersInFirstYear?: ModelStringInput | null,
  userAgeGroups?: ModelStringInput | null,
  liveUrl?: ModelStringInput | null,
  isZoiq?: ModelBooleanInput | null,
  userSortSeq?: ModelIDInput | null,
  projectPlanSortSeq?: ModelIDInput | null,
  projectMeetingsSortSeq?: ModelIDInput | null,
  projectCriteriaSortSeq?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
};

export type ModelProjectPlanFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  milestone?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  risk?: ModelRiskInput | null,
  fakeId?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProjectPlanFilterInput | null > | null,
  or?: Array< ModelProjectPlanFilterInput | null > | null,
  not?: ModelProjectPlanFilterInput | null,
};

export type ModelDiscussionFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  date?: ModelStringInput | null,
  actionItemsSortSeq?: ModelIDInput | null,
  emailSent?: ModelBooleanInput | null,
  emailSentDate?: ModelStringInput | null,
  meetingID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDiscussionFilterInput | null > | null,
  or?: Array< ModelDiscussionFilterInput | null > | null,
  not?: ModelDiscussionFilterInput | null,
};

export type ModelStyleGuideFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStyleGuideTypeInput | null,
  value?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  extraInfo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStyleGuideFilterInput | null > | null,
  or?: Array< ModelStyleGuideFilterInput | null > | null,
  not?: ModelStyleGuideFilterInput | null,
};

export type ModelStyleGuideConnection = {
  __typename: "ModelStyleGuideConnection",
  items:  Array<StyleGuide | null >,
  nextToken?: string | null,
};

export type ModelActionItemsFilterInput = {
  id?: ModelIDInput | null,
  discussionID?: ModelIDInput | null,
  actionItem?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  targetDate?: ModelStringInput | null,
  dateAssigned?: ModelStringInput | null,
  emailSent?: ModelBooleanInput | null,
  dateCompleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelActionItemsFilterInput | null > | null,
  or?: Array< ModelActionItemsFilterInput | null > | null,
  not?: ModelActionItemsFilterInput | null,
};

export type ModelSummaryFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  summary?: ModelStringInput | null,
  summaryDate?: ModelStringInput | null,
  summaryOwnerID?: ModelIDInput | null,
  summaryOwnerEmail?: ModelStringInput | null,
  summaryType?: ModelSummaryTypeInput | null,
  discussionDate?: ModelStringInput | null,
  discussedWithID?: ModelIDInput | null,
  discussedWithEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSummaryFilterInput | null > | null,
  or?: Array< ModelSummaryFilterInput | null > | null,
  not?: ModelSummaryFilterInput | null,
};

export type ModelMeetingsFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  firstMeetingDate?: ModelStringInput | null,
  lastMeetingDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  frequency?: ModelFrequencyTypeInput | null,
  link?: ModelStringInput | null,
  attendees?: ModelIDInput | null,
  mailSentDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMeetingsFilterInput | null > | null,
  or?: Array< ModelMeetingsFilterInput | null > | null,
  not?: ModelMeetingsFilterInput | null,
};

export type ModelSuccessCriteriaFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  measurableCriteria?: ModelStringInput | null,
  type?: ModelSuccessCriteriaTypeInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSuccessCriteriaFilterInput | null > | null,
  or?: Array< ModelSuccessCriteriaFilterInput | null > | null,
  not?: ModelSuccessCriteriaFilterInput | null,
  pagesCriteriaId?: ModelIDInput | null,
};

export type ModelPagesFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  link?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPagesFilterInput | null > | null,
  or?: Array< ModelPagesFilterInput | null > | null,
  not?: ModelPagesFilterInput | null,
};

export type ModelUserJourneyFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  userRole?: ModelStringInput | null,
  pageName?: ModelStringInput | null,
  sectionName?: ModelStringInput | null,
  access?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserJourneyFilterInput | null > | null,
  or?: Array< ModelUserJourneyFilterInput | null > | null,
  not?: ModelUserJourneyFilterInput | null,
};

export type ModelUserJourneyConnection = {
  __typename: "ModelUserJourneyConnection",
  items:  Array<UserJourney | null >,
  nextToken?: string | null,
};

export type ModelBacklogSequenceFilterInput = {
  id?: ModelIDInput | null,
  sequence?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBacklogSequenceFilterInput | null > | null,
  or?: Array< ModelBacklogSequenceFilterInput | null > | null,
  not?: ModelBacklogSequenceFilterInput | null,
};

export type ModelBacklogSequenceConnection = {
  __typename: "ModelBacklogSequenceConnection",
  items:  Array<BacklogSequence | null >,
  nextToken?: string | null,
};

export type ModelCommentsFilterInput = {
  id?: ModelIDInput | null,
  backlogID?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  date?: ModelStringInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCommentsFilterInput | null > | null,
  or?: Array< ModelCommentsFilterInput | null > | null,
  not?: ModelCommentsFilterInput | null,
};

export type ModelSubTaskModelFilterInput = {
  id?: ModelIDInput | null,
  isCompleted?: ModelBooleanInput | null,
  task?: ModelStringInput | null,
  completedOn?: ModelStringInput | null,
  mediaId?: ModelIDInput | null,
  sprintType?: ModelSprintTypeInput | null,
  backlogID?: ModelIDInput | null,
  statusUpdateDate?: ModelStringInput | null,
  priority?: ModelBacklogPriorityInput | null,
  completedByEmail?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  projectPlanID?: ModelIDInput | null,
  status?: ModelSubtaskStatusInput | null,
  moscow?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSubTaskModelFilterInput | null > | null,
  or?: Array< ModelSubTaskModelFilterInput | null > | null,
  not?: ModelSubTaskModelFilterInput | null,
};

export type ModelBacklogFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  title?: ModelStringInput | null,
  isCompleted?: ModelBooleanInput | null,
  dateCompleted?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  createdByEmail?: ModelStringInput | null,
  status?: ModelBacklogStatusInput | null,
  priority?: ModelBacklogPriorityInput | null,
  assignedTo?: ModelIDInput | null,
  targetDate?: ModelStringInput | null,
  sectionID?: ModelIDInput | null,
  pageID?: ModelIDInput | null,
  notifyAssignedMembers?: ModelBooleanInput | null,
  referralLinks?: ModelStringInput | null,
  note?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelBacklogFilterInput | null > | null,
  or?: Array< ModelBacklogFilterInput | null > | null,
  not?: ModelBacklogFilterInput | null,
};

export type ModelBacklogConnection = {
  __typename: "ModelBacklogConnection",
  items:  Array<Backlog | null >,
  nextToken?: string | null,
};

export type ModelContentCreationFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  pageName?: ModelStringInput | null,
  sent?: ModelBooleanInput | null,
  sentDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContentCreationFilterInput | null > | null,
  or?: Array< ModelContentCreationFilterInput | null > | null,
  not?: ModelContentCreationFilterInput | null,
};

export type ModelContentCreationConnection = {
  __typename: "ModelContentCreationConnection",
  items:  Array<ContentCreation | null >,
  nextToken?: string | null,
};

export type ModelConversationFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  pageId?: ModelIDInput | null,
  sectionId?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationFilterInput | null > | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  not?: ModelConversationFilterInput | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items:  Array<Conversation | null >,
  nextToken?: string | null,
};

export type ModelStoreFilterInput = {
  id?: ModelIDInput | null,
  projectID?: ModelIDInput | null,
  organizationID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  inStock?: ModelBooleanInput | null,
  addedBy?: ModelIDInput | null,
  mediaIds?: ModelIDInput | null,
  colorsAvailable?: ModelStringInput | null,
  sizesAvailable?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelStoreFilterInput | null > | null,
  or?: Array< ModelStoreFilterInput | null > | null,
  not?: ModelStoreFilterInput | null,
};

export type ModelFontFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  link?: ModelStringInput | null,
  complimentsWithID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFontFilterInput | null > | null,
  or?: Array< ModelFontFilterInput | null > | null,
  not?: ModelFontFilterInput | null,
};

export type ModelFontConnection = {
  __typename: "ModelFontConnection",
  items:  Array<Font | null >,
  nextToken?: string | null,
};

export type ModelActivityFilterInput = {
  id?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  activity?: ModelStringInput | null,
  link?: ModelStringInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelActivityFilterInput | null > | null,
  or?: Array< ModelActivityFilterInput | null > | null,
  not?: ModelActivityFilterInput | null,
};

export type ModelActivityConnection = {
  __typename: "ModelActivityConnection",
  items:  Array<Activity | null >,
  nextToken?: string | null,
};

export type ModelSolutionFilterInput = {
  id?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projectID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSolutionFilterInput | null > | null,
  or?: Array< ModelSolutionFilterInput | null > | null,
  not?: ModelSolutionFilterInput | null,
};

export type ModelSolutionConnection = {
  __typename: "ModelSolutionConnection",
  items:  Array<Solution | null >,
  nextToken?: string | null,
};

export type ModelPopupsFilterInput = {
  id?: ModelIDInput | null,
  authID?: ModelIDInput | null,
  authEmail?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  projectID?: ModelIDInput | null,
  isActive?: ModelBooleanInput | null,
  expiryDate?: ModelStringInput | null,
  image?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPopupsFilterInput | null > | null,
  or?: Array< ModelPopupsFilterInput | null > | null,
  not?: ModelPopupsFilterInput | null,
};

export type ModelPopupsConnection = {
  __typename: "ModelPopupsConnection",
  items:  Array<Popups | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionOrgMeetingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  attendees?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrgMeetingFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrgMeetingFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  avatar?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  address1?: ModelSubscriptionStringInput | null,
  address2?: ModelSubscriptionStringInput | null,
  city?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  zipCode?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  maxBucketSize?: ModelSubscriptionIntInput | null,
  staffSortSeq?: ModelSubscriptionIDInput | null,
  storeInventorySortSeq?: ModelSubscriptionIDInput | null,
  partnersLogoSortSeq?: ModelSubscriptionIDInput | null,
  mediaFilesSortSeq?: ModelSubscriptionIDInput | null,
  gallerySortSeq?: ModelSubscriptionIDInput | null,
  allowedFileTabs?: ModelSubscriptionStringInput | null,
  orgSize?: ModelSubscriptionStringInput | null,
  industry?: ModelSubscriptionStringInput | null,
  mission?: ModelSubscriptionStringInput | null,
  software?: ModelSubscriptionStringInput | null,
  paper?: ModelSubscriptionStringInput | null,
  printer?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAssetsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionIntInput | null,
  mediaType?: ModelSubscriptionStringInput | null,
  onlyAdmin?: ModelSubscriptionBooleanInput | null,
  link?: ModelSubscriptionStringInput | null,
  multipleLinks?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  subType?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  isAssigned?: ModelSubscriptionBooleanInput | null,
  galleryId?: ModelSubscriptionIDInput | null,
  assignedDate?: ModelSubscriptionStringInput | null,
  storeID?: ModelSubscriptionIDInput | null,
  storeImagesOrder?: ModelSubscriptionIDInput | null,
  multipleProjects?: ModelSubscriptionIDInput | null,
  lastUpdatedBy?: ModelSubscriptionIDInput | null,
  lastUpdatedByEmail?: ModelSubscriptionStringInput | null,
  lastUpdatedOn?: ModelSubscriptionStringInput | null,
  isUpdated?: ModelSubscriptionBooleanInput | null,
  isRemoved?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAssetsFilterInput | null > | null,
  or?: Array< ModelSubscriptionAssetsFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionSourceFileFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  size?: ModelSubscriptionIntInput | null,
  mediaType?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSourceFileFilterInput | null > | null,
  or?: Array< ModelSubscriptionSourceFileFilterInput | null > | null,
};

export type ModelSubscriptionGalleryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  email?: ModelSubscriptionStringInput | null,
  coverImage?: ModelSubscriptionStringInput | null,
  sortSeq?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  publishDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGalleryFilterInput | null > | null,
  or?: Array< ModelSubscriptionGalleryFilterInput | null > | null,
};

export type ModelSubscriptionUserStatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  taskID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserStatFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserStatFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  isAdmin?: ModelSubscriptionBooleanInput | null,
  initialEmailSentOn?: ModelSubscriptionStringInput | null,
  overrideOrganization?: ModelSubscriptionStringInput | null,
  orgLevel?: ModelSubscriptionIntInput | null,
  lastLogin?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  adminAccessTo?: ModelSubscriptionIDInput | null,
  liveVisiblity?: ModelSubscriptionBooleanInput | null,
  isZoiq?: ModelSubscriptionBooleanInput | null,
  completedTasks?: ModelSubscriptionIDInput | null,
  links?: ModelSubscriptionStringInput | null,
  canAddProjects?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  milestone?: ModelSubscriptionStringInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  ownerAuthID?: ModelSubscriptionIDInput | null,
  ownerEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  risk?: ModelSubscriptionStringInput | null,
  githubRepo?: ModelSubscriptionStringInput | null,
  awsAccount?: ModelSubscriptionStringInput | null,
  cognitoUrl?: ModelSubscriptionStringInput | null,
  solutionType?: ModelSubscriptionStringInput | null,
  scope?: ModelSubscriptionStringInput | null,
  estUsersInFirstYear?: ModelSubscriptionStringInput | null,
  userAgeGroups?: ModelSubscriptionStringInput | null,
  liveUrl?: ModelSubscriptionStringInput | null,
  isZoiq?: ModelSubscriptionBooleanInput | null,
  userSortSeq?: ModelSubscriptionIDInput | null,
  projectPlanSortSeq?: ModelSubscriptionIDInput | null,
  projectMeetingsSortSeq?: ModelSubscriptionIDInput | null,
  projectCriteriaSortSeq?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionProjectPlanFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  milestone?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  risk?: ModelSubscriptionStringInput | null,
  fakeId?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectPlanFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectPlanFilterInput | null > | null,
};

export type ModelSubscriptionDiscussionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  actionItemsSortSeq?: ModelSubscriptionIDInput | null,
  emailSent?: ModelSubscriptionBooleanInput | null,
  emailSentDate?: ModelSubscriptionStringInput | null,
  meetingID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDiscussionFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiscussionFilterInput | null > | null,
};

export type ModelSubscriptionStyleGuideFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  extraInfo?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStyleGuideFilterInput | null > | null,
  or?: Array< ModelSubscriptionStyleGuideFilterInput | null > | null,
};

export type ModelSubscriptionActionItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  discussionID?: ModelSubscriptionIDInput | null,
  actionItem?: ModelSubscriptionStringInput | null,
  owners?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  targetDate?: ModelSubscriptionStringInput | null,
  dateAssigned?: ModelSubscriptionStringInput | null,
  emailSent?: ModelSubscriptionBooleanInput | null,
  dateCompleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionActionItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionActionItemsFilterInput | null > | null,
};

export type ModelSubscriptionSummaryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  summary?: ModelSubscriptionStringInput | null,
  summaryDate?: ModelSubscriptionStringInput | null,
  summaryOwnerID?: ModelSubscriptionIDInput | null,
  summaryOwnerEmail?: ModelSubscriptionStringInput | null,
  summaryType?: ModelSubscriptionStringInput | null,
  discussionDate?: ModelSubscriptionStringInput | null,
  discussedWithID?: ModelSubscriptionIDInput | null,
  discussedWithEmail?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSummaryFilterInput | null > | null,
  or?: Array< ModelSubscriptionSummaryFilterInput | null > | null,
};

export type ModelSubscriptionMeetingsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  firstMeetingDate?: ModelSubscriptionStringInput | null,
  lastMeetingDate?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  frequency?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  attendees?: ModelSubscriptionIDInput | null,
  mailSentDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMeetingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMeetingsFilterInput | null > | null,
};

export type ModelSubscriptionSuccessCriteriaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  measurableCriteria?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  pageId?: ModelSubscriptionIDInput | null,
  sectionId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSuccessCriteriaFilterInput | null > | null,
  or?: Array< ModelSubscriptionSuccessCriteriaFilterInput | null > | null,
};

export type ModelSubscriptionPagesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPagesFilterInput | null > | null,
  or?: Array< ModelSubscriptionPagesFilterInput | null > | null,
  pagesCriteriaId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionUserJourneyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  userRole?: ModelSubscriptionStringInput | null,
  pageName?: ModelSubscriptionStringInput | null,
  sectionName?: ModelSubscriptionStringInput | null,
  access?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserJourneyFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserJourneyFilterInput | null > | null,
};

export type ModelSubscriptionBacklogSequenceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sequence?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBacklogSequenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionBacklogSequenceFilterInput | null > | null,
};

export type ModelSubscriptionCommentsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  backlogID?: ModelSubscriptionIDInput | null,
  comment?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  authID?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentsFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentsFilterInput | null > | null,
};

export type ModelSubscriptionSubTaskModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  task?: ModelSubscriptionStringInput | null,
  completedOn?: ModelSubscriptionStringInput | null,
  mediaId?: ModelSubscriptionIDInput | null,
  sprintType?: ModelSubscriptionStringInput | null,
  backlogID?: ModelSubscriptionIDInput | null,
  statusUpdateDate?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionStringInput | null,
  completedByEmail?: ModelSubscriptionStringInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  projectPlanID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  moscow?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubTaskModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubTaskModelFilterInput | null > | null,
};

export type ModelSubscriptionBacklogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  isCompleted?: ModelSubscriptionBooleanInput | null,
  dateCompleted?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  createdByEmail?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionStringInput | null,
  assignedTo?: ModelSubscriptionIDInput | null,
  targetDate?: ModelSubscriptionStringInput | null,
  sectionID?: ModelSubscriptionIDInput | null,
  pageID?: ModelSubscriptionIDInput | null,
  notifyAssignedMembers?: ModelSubscriptionBooleanInput | null,
  referralLinks?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBacklogFilterInput | null > | null,
  or?: Array< ModelSubscriptionBacklogFilterInput | null > | null,
};

export type ModelSubscriptionContentCreationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  content?: ModelSubscriptionStringInput | null,
  pageId?: ModelSubscriptionIDInput | null,
  sectionId?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  pageName?: ModelSubscriptionStringInput | null,
  sent?: ModelSubscriptionBooleanInput | null,
  sentDate?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentCreationFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentCreationFilterInput | null > | null,
};

export type ModelSubscriptionConversationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  pageId?: ModelSubscriptionIDInput | null,
  sectionId?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConversationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConversationFilterInput | null > | null,
};

export type ModelSubscriptionStoreFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  organizationID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  inStock?: ModelSubscriptionBooleanInput | null,
  addedBy?: ModelSubscriptionIDInput | null,
  mediaIds?: ModelSubscriptionIDInput | null,
  colorsAvailable?: ModelSubscriptionStringInput | null,
  sizesAvailable?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionStoreFilterInput | null > | null,
  or?: Array< ModelSubscriptionStoreFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFontFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  complimentsWithID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFontFilterInput | null > | null,
  or?: Array< ModelSubscriptionFontFilterInput | null > | null,
};

export type ModelSubscriptionActivityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  activity?: ModelSubscriptionStringInput | null,
  link?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionActivityFilterInput | null > | null,
  or?: Array< ModelSubscriptionActivityFilterInput | null > | null,
};

export type ModelSubscriptionSolutionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSolutionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSolutionFilterInput | null > | null,
};

export type ModelSubscriptionPopupsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  authID?: ModelSubscriptionIDInput | null,
  authEmail?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  projectID?: ModelSubscriptionIDInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  expiryDate?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPopupsFilterInput | null > | null,
  or?: Array< ModelSubscriptionPopupsFilterInput | null > | null,
};

export type CreateOrgMeetingMutationVariables = {
  input: CreateOrgMeetingInput,
  condition?: ModelOrgMeetingConditionInput | null,
};

export type CreateOrgMeetingMutation = {
  createOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrgMeetingMutationVariables = {
  input: UpdateOrgMeetingInput,
  condition?: ModelOrgMeetingConditionInput | null,
};

export type UpdateOrgMeetingMutation = {
  updateOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrgMeetingMutationVariables = {
  input: DeleteOrgMeetingInput,
  condition?: ModelOrgMeetingConditionInput | null,
};

export type DeleteOrgMeetingMutation = {
  deleteOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAssetsMutationVariables = {
  input: CreateAssetsInput,
  condition?: ModelAssetsConditionInput | null,
};

export type CreateAssetsMutation = {
  createAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAssetsMutationVariables = {
  input: UpdateAssetsInput,
  condition?: ModelAssetsConditionInput | null,
};

export type UpdateAssetsMutation = {
  updateAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAssetsMutationVariables = {
  input: DeleteAssetsInput,
  condition?: ModelAssetsConditionInput | null,
};

export type DeleteAssetsMutation = {
  deleteAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSourceFileMutationVariables = {
  input: CreateSourceFileInput,
  condition?: ModelSourceFileConditionInput | null,
};

export type CreateSourceFileMutation = {
  createSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSourceFileMutationVariables = {
  input: UpdateSourceFileInput,
  condition?: ModelSourceFileConditionInput | null,
};

export type UpdateSourceFileMutation = {
  updateSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSourceFileMutationVariables = {
  input: DeleteSourceFileInput,
  condition?: ModelSourceFileConditionInput | null,
};

export type DeleteSourceFileMutation = {
  deleteSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGalleryMutationVariables = {
  input: CreateGalleryInput,
  condition?: ModelGalleryConditionInput | null,
};

export type CreateGalleryMutation = {
  createGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGalleryMutationVariables = {
  input: UpdateGalleryInput,
  condition?: ModelGalleryConditionInput | null,
};

export type UpdateGalleryMutation = {
  updateGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGalleryMutationVariables = {
  input: DeleteGalleryInput,
  condition?: ModelGalleryConditionInput | null,
};

export type DeleteGalleryMutation = {
  deleteGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserStatMutationVariables = {
  input: CreateUserStatInput,
  condition?: ModelUserStatConditionInput | null,
};

export type CreateUserStatMutation = {
  createUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserStatMutationVariables = {
  input: UpdateUserStatInput,
  condition?: ModelUserStatConditionInput | null,
};

export type UpdateUserStatMutation = {
  updateUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserStatMutationVariables = {
  input: DeleteUserStatInput,
  condition?: ModelUserStatConditionInput | null,
};

export type DeleteUserStatMutation = {
  deleteUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProjectPlanMutationVariables = {
  input: CreateProjectPlanInput,
  condition?: ModelProjectPlanConditionInput | null,
};

export type CreateProjectPlanMutation = {
  createProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProjectPlanMutationVariables = {
  input: UpdateProjectPlanInput,
  condition?: ModelProjectPlanConditionInput | null,
};

export type UpdateProjectPlanMutation = {
  updateProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProjectPlanMutationVariables = {
  input: DeleteProjectPlanInput,
  condition?: ModelProjectPlanConditionInput | null,
};

export type DeleteProjectPlanMutation = {
  deleteProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDiscussionMutationVariables = {
  input: CreateDiscussionInput,
  condition?: ModelDiscussionConditionInput | null,
};

export type CreateDiscussionMutation = {
  createDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDiscussionMutationVariables = {
  input: UpdateDiscussionInput,
  condition?: ModelDiscussionConditionInput | null,
};

export type UpdateDiscussionMutation = {
  updateDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDiscussionMutationVariables = {
  input: DeleteDiscussionInput,
  condition?: ModelDiscussionConditionInput | null,
};

export type DeleteDiscussionMutation = {
  deleteDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStyleGuideMutationVariables = {
  input: CreateStyleGuideInput,
  condition?: ModelStyleGuideConditionInput | null,
};

export type CreateStyleGuideMutation = {
  createStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStyleGuideMutationVariables = {
  input: UpdateStyleGuideInput,
  condition?: ModelStyleGuideConditionInput | null,
};

export type UpdateStyleGuideMutation = {
  updateStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStyleGuideMutationVariables = {
  input: DeleteStyleGuideInput,
  condition?: ModelStyleGuideConditionInput | null,
};

export type DeleteStyleGuideMutation = {
  deleteStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateActionItemsMutationVariables = {
  input: CreateActionItemsInput,
  condition?: ModelActionItemsConditionInput | null,
};

export type CreateActionItemsMutation = {
  createActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateActionItemsMutationVariables = {
  input: UpdateActionItemsInput,
  condition?: ModelActionItemsConditionInput | null,
};

export type UpdateActionItemsMutation = {
  updateActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteActionItemsMutationVariables = {
  input: DeleteActionItemsInput,
  condition?: ModelActionItemsConditionInput | null,
};

export type DeleteActionItemsMutation = {
  deleteActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSummaryMutationVariables = {
  input: CreateSummaryInput,
  condition?: ModelSummaryConditionInput | null,
};

export type CreateSummaryMutation = {
  createSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSummaryMutationVariables = {
  input: UpdateSummaryInput,
  condition?: ModelSummaryConditionInput | null,
};

export type UpdateSummaryMutation = {
  updateSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSummaryMutationVariables = {
  input: DeleteSummaryInput,
  condition?: ModelSummaryConditionInput | null,
};

export type DeleteSummaryMutation = {
  deleteSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingsMutationVariables = {
  input: CreateMeetingsInput,
  condition?: ModelMeetingsConditionInput | null,
};

export type CreateMeetingsMutation = {
  createMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMeetingsMutationVariables = {
  input: UpdateMeetingsInput,
  condition?: ModelMeetingsConditionInput | null,
};

export type UpdateMeetingsMutation = {
  updateMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMeetingsMutationVariables = {
  input: DeleteMeetingsInput,
  condition?: ModelMeetingsConditionInput | null,
};

export type DeleteMeetingsMutation = {
  deleteMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSuccessCriteriaMutationVariables = {
  input: CreateSuccessCriteriaInput,
  condition?: ModelSuccessCriteriaConditionInput | null,
};

export type CreateSuccessCriteriaMutation = {
  createSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type UpdateSuccessCriteriaMutationVariables = {
  input: UpdateSuccessCriteriaInput,
  condition?: ModelSuccessCriteriaConditionInput | null,
};

export type UpdateSuccessCriteriaMutation = {
  updateSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type DeleteSuccessCriteriaMutationVariables = {
  input: DeleteSuccessCriteriaInput,
  condition?: ModelSuccessCriteriaConditionInput | null,
};

export type DeleteSuccessCriteriaMutation = {
  deleteSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type CreatePagesMutationVariables = {
  input: CreatePagesInput,
  condition?: ModelPagesConditionInput | null,
};

export type CreatePagesMutation = {
  createPages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePagesMutationVariables = {
  input: UpdatePagesInput,
  condition?: ModelPagesConditionInput | null,
};

export type UpdatePagesMutation = {
  updatePages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePagesMutationVariables = {
  input: DeletePagesInput,
  condition?: ModelPagesConditionInput | null,
};

export type DeletePagesMutation = {
  deletePages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserJourneyMutationVariables = {
  input: CreateUserJourneyInput,
  condition?: ModelUserJourneyConditionInput | null,
};

export type CreateUserJourneyMutation = {
  createUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserJourneyMutationVariables = {
  input: UpdateUserJourneyInput,
  condition?: ModelUserJourneyConditionInput | null,
};

export type UpdateUserJourneyMutation = {
  updateUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserJourneyMutationVariables = {
  input: DeleteUserJourneyInput,
  condition?: ModelUserJourneyConditionInput | null,
};

export type DeleteUserJourneyMutation = {
  deleteUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBacklogSequenceMutationVariables = {
  input: CreateBacklogSequenceInput,
  condition?: ModelBacklogSequenceConditionInput | null,
};

export type CreateBacklogSequenceMutation = {
  createBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBacklogSequenceMutationVariables = {
  input: UpdateBacklogSequenceInput,
  condition?: ModelBacklogSequenceConditionInput | null,
};

export type UpdateBacklogSequenceMutation = {
  updateBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBacklogSequenceMutationVariables = {
  input: DeleteBacklogSequenceInput,
  condition?: ModelBacklogSequenceConditionInput | null,
};

export type DeleteBacklogSequenceMutation = {
  deleteBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCommentsMutationVariables = {
  input: CreateCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type CreateCommentsMutation = {
  createComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCommentsMutationVariables = {
  input: UpdateCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type UpdateCommentsMutation = {
  updateComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCommentsMutationVariables = {
  input: DeleteCommentsInput,
  condition?: ModelCommentsConditionInput | null,
};

export type DeleteCommentsMutation = {
  deleteComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubTaskModelMutationVariables = {
  input: CreateSubTaskModelInput,
  condition?: ModelSubTaskModelConditionInput | null,
};

export type CreateSubTaskModelMutation = {
  createSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubTaskModelMutationVariables = {
  input: UpdateSubTaskModelInput,
  condition?: ModelSubTaskModelConditionInput | null,
};

export type UpdateSubTaskModelMutation = {
  updateSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubTaskModelMutationVariables = {
  input: DeleteSubTaskModelInput,
  condition?: ModelSubTaskModelConditionInput | null,
};

export type DeleteSubTaskModelMutation = {
  deleteSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateBacklogMutationVariables = {
  input: CreateBacklogInput,
  condition?: ModelBacklogConditionInput | null,
};

export type CreateBacklogMutation = {
  createBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBacklogMutationVariables = {
  input: UpdateBacklogInput,
  condition?: ModelBacklogConditionInput | null,
};

export type UpdateBacklogMutation = {
  updateBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBacklogMutationVariables = {
  input: DeleteBacklogInput,
  condition?: ModelBacklogConditionInput | null,
};

export type DeleteBacklogMutation = {
  deleteBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentCreationMutationVariables = {
  input: CreateContentCreationInput,
  condition?: ModelContentCreationConditionInput | null,
};

export type CreateContentCreationMutation = {
  createContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentCreationMutationVariables = {
  input: UpdateContentCreationInput,
  condition?: ModelContentCreationConditionInput | null,
};

export type UpdateContentCreationMutation = {
  updateContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentCreationMutationVariables = {
  input: DeleteContentCreationInput,
  condition?: ModelContentCreationConditionInput | null,
};

export type DeleteContentCreationMutation = {
  deleteContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConversationMutationVariables = {
  input: CreateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type UpdateConversationMutation = {
  updateConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type DeleteConversationMutation = {
  deleteConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStoreMutationVariables = {
  input: CreateStoreInput,
  condition?: ModelStoreConditionInput | null,
};

export type CreateStoreMutation = {
  createStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStoreMutationVariables = {
  input: UpdateStoreInput,
  condition?: ModelStoreConditionInput | null,
};

export type UpdateStoreMutation = {
  updateStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStoreMutationVariables = {
  input: DeleteStoreInput,
  condition?: ModelStoreConditionInput | null,
};

export type DeleteStoreMutation = {
  deleteStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFontMutationVariables = {
  input: CreateFontInput,
  condition?: ModelFontConditionInput | null,
};

export type CreateFontMutation = {
  createFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFontMutationVariables = {
  input: UpdateFontInput,
  condition?: ModelFontConditionInput | null,
};

export type UpdateFontMutation = {
  updateFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFontMutationVariables = {
  input: DeleteFontInput,
  condition?: ModelFontConditionInput | null,
};

export type DeleteFontMutation = {
  deleteFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateActivityMutationVariables = {
  input: CreateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type CreateActivityMutation = {
  createActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateActivityMutationVariables = {
  input: UpdateActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type UpdateActivityMutation = {
  updateActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteActivityMutationVariables = {
  input: DeleteActivityInput,
  condition?: ModelActivityConditionInput | null,
};

export type DeleteActivityMutation = {
  deleteActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSolutionMutationVariables = {
  input: CreateSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type CreateSolutionMutation = {
  createSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSolutionMutationVariables = {
  input: UpdateSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type UpdateSolutionMutation = {
  updateSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSolutionMutationVariables = {
  input: DeleteSolutionInput,
  condition?: ModelSolutionConditionInput | null,
};

export type DeleteSolutionMutation = {
  deleteSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePopupsMutationVariables = {
  input: CreatePopupsInput,
  condition?: ModelPopupsConditionInput | null,
};

export type CreatePopupsMutation = {
  createPopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePopupsMutationVariables = {
  input: UpdatePopupsInput,
  condition?: ModelPopupsConditionInput | null,
};

export type UpdatePopupsMutation = {
  updatePopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePopupsMutationVariables = {
  input: DeletePopupsInput,
  condition?: ModelPopupsConditionInput | null,
};

export type DeletePopupsMutation = {
  deletePopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrgMeetingQueryVariables = {
  id: string,
};

export type GetOrgMeetingQuery = {
  getOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrgMeetingsQueryVariables = {
  filter?: ModelOrgMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrgMeetingsQuery = {
  listOrgMeetings?:  {
    __typename: "ModelOrgMeetingConnection",
    items:  Array< {
      __typename: "OrgMeeting",
      id: string,
      organizationID: string,
      name: string,
      description?: string | null,
      startTime: string,
      endTime: string,
      attendees: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAssetsQueryVariables = {
  id: string,
};

export type GetAssetsQuery = {
  getAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAssetsQueryVariables = {
  filter?: ModelAssetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAssetsQuery = {
  listAssets?:  {
    __typename: "ModelAssetsConnection",
    items:  Array< {
      __typename: "Assets",
      id: string,
      userID: string,
      projectID?: string | null,
      organizationID?: string | null,
      email: string,
      size?: number | null,
      mediaType?: string | null,
      onlyAdmin?: boolean | null,
      link: string,
      multipleLinks?: Array< string | null > | null,
      type: AssetType,
      subType?: SubAssetType | null,
      note?: string | null,
      isAssigned?: boolean | null,
      galleryId?: string | null,
      assignedDate?: string | null,
      storeID?: string | null,
      storeImagesOrder?: Array< string | null > | null,
      multipleProjects?: Array< string | null > | null,
      lastUpdatedBy?: string | null,
      lastUpdatedByEmail?: string | null,
      lastUpdatedOn?: string | null,
      isUpdated?: boolean | null,
      isRemoved?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSourceFileQueryVariables = {
  id: string,
};

export type GetSourceFileQuery = {
  getSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSourceFilesQueryVariables = {
  filter?: ModelSourceFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSourceFilesQuery = {
  listSourceFiles?:  {
    __typename: "ModelSourceFileConnection",
    items:  Array< {
      __typename: "SourceFile",
      id: string,
      userID: string,
      organizationID?: string | null,
      projectId?: string | null,
      email: string,
      size?: number | null,
      mediaType?: string | null,
      link: string,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGalleryQueryVariables = {
  id: string,
};

export type GetGalleryQuery = {
  getGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGalleriesQueryVariables = {
  filter?: ModelGalleryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGalleriesQuery = {
  listGalleries?:  {
    __typename: "ModelGalleryConnection",
    items:  Array< {
      __typename: "Gallery",
      id: string,
      userID: string,
      organizationID?: string | null,
      projectId?: string | null,
      email: string,
      coverImage: string,
      sortSeq?: Array< string | null > | null,
      title?: string | null,
      publishDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserStatQueryVariables = {
  id: string,
};

export type GetUserStatQuery = {
  getUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserStatsQueryVariables = {
  filter?: ModelUserStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserStatsQuery = {
  listUserStats?:  {
    __typename: "ModelUserStatConnection",
    items:  Array< {
      __typename: "UserStat",
      id: string,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  authID: string,
  email: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  authID?: string | null,
  email?: ModelStringKeyConditionInput | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectPlanQueryVariables = {
  id: string,
};

export type GetProjectPlanQuery = {
  getProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProjectPlansQueryVariables = {
  filter?: ModelProjectPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectPlansQuery = {
  listProjectPlans?:  {
    __typename: "ModelProjectPlanConnection",
    items:  Array< {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDiscussionQueryVariables = {
  id: string,
};

export type GetDiscussionQuery = {
  getDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDiscussionsQueryVariables = {
  filter?: ModelDiscussionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiscussionsQuery = {
  listDiscussions?:  {
    __typename: "ModelDiscussionConnection",
    items:  Array< {
      __typename: "Discussion",
      id: string,
      projectID: string,
      name: string,
      date?: string | null,
      actionItemsSortSeq?: Array< string | null > | null,
      emailSent?: boolean | null,
      emailSentDate?: string | null,
      meetingID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStyleGuideQueryVariables = {
  id: string,
};

export type GetStyleGuideQuery = {
  getStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStyleGuidesQueryVariables = {
  filter?: ModelStyleGuideFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStyleGuidesQuery = {
  listStyleGuides?:  {
    __typename: "ModelStyleGuideConnection",
    items:  Array< {
      __typename: "StyleGuide",
      id: string,
      name: string,
      type: StyleGuideType,
      value: string,
      organizationID: string,
      projectID?: string | null,
      extraInfo?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActionItemsQueryVariables = {
  id: string,
};

export type GetActionItemsQuery = {
  getActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListActionItemsQueryVariables = {
  filter?: ModelActionItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActionItemsQuery = {
  listActionItems?:  {
    __typename: "ModelActionItemsConnection",
    items:  Array< {
      __typename: "ActionItems",
      id: string,
      discussionID: string,
      actionItem: string,
      owners?: Array< string | null > | null,
      isCompleted?: boolean | null,
      targetDate?: string | null,
      dateAssigned?: string | null,
      emailSent?: boolean | null,
      dateCompleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSummaryQueryVariables = {
  id: string,
};

export type GetSummaryQuery = {
  getSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSummariesQueryVariables = {
  filter?: ModelSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSummariesQuery = {
  listSummaries?:  {
    __typename: "ModelSummaryConnection",
    items:  Array< {
      __typename: "Summary",
      id: string,
      projectID: string,
      summary: string,
      summaryDate: string,
      summaryOwnerID: string,
      summaryOwnerEmail: string,
      summaryType: SummaryType,
      discussionDate?: string | null,
      discussedWithID?: string | null,
      discussedWithEmail?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingsQueryVariables = {
  id: string,
};

export type GetMeetingsQuery = {
  getMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingsConnection",
    items:  Array< {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSuccessCriteriaQueryVariables = {
  id: string,
};

export type GetSuccessCriteriaQuery = {
  getSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type ListSuccessCriteriaQueryVariables = {
  filter?: ModelSuccessCriteriaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSuccessCriteriaQuery = {
  listSuccessCriteria?:  {
    __typename: "ModelSuccessCriteriaConnection",
    items:  Array< {
      __typename: "SuccessCriteria",
      id: string,
      projectID: string,
      description: string,
      measurableCriteria: string,
      type: SuccessCriteriaType,
      pageId?: string | null,
      sectionId?: string | null,
      createdAt: string,
      updatedAt: string,
      pagesCriteriaId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPagesQueryVariables = {
  id: string,
};

export type GetPagesQuery = {
  getPages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPagesConnection",
    items:  Array< {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserJourneyQueryVariables = {
  id: string,
};

export type GetUserJourneyQuery = {
  getUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserJourneysQueryVariables = {
  filter?: ModelUserJourneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserJourneysQuery = {
  listUserJourneys?:  {
    __typename: "ModelUserJourneyConnection",
    items:  Array< {
      __typename: "UserJourney",
      id: string,
      projectID: string,
      userRole: string,
      pageName: string,
      sectionName: string,
      access: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBacklogSequenceQueryVariables = {
  id: string,
};

export type GetBacklogSequenceQuery = {
  getBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBacklogSequencesQueryVariables = {
  filter?: ModelBacklogSequenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBacklogSequencesQuery = {
  listBacklogSequences?:  {
    __typename: "ModelBacklogSequenceConnection",
    items:  Array< {
      __typename: "BacklogSequence",
      id: string,
      sequence: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentsQueryVariables = {
  id: string,
};

export type GetCommentsQuery = {
  getComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      backlogID: string,
      comment: string,
      date: string,
      authID: string,
      authEmail: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubTaskModelQueryVariables = {
  id: string,
};

export type GetSubTaskModelQuery = {
  getSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubTaskModelsQueryVariables = {
  filter?: ModelSubTaskModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubTaskModelsQuery = {
  listSubTaskModels?:  {
    __typename: "ModelSubTaskModelConnection",
    items:  Array< {
      __typename: "SubTaskModel",
      id: string,
      isCompleted?: boolean | null,
      task: string,
      completedOn?: string | null,
      mediaId?: string | null,
      sprintType?: SprintType | null,
      backlogID?: string | null,
      statusUpdateDate?: string | null,
      priority?: BacklogPriority | null,
      completedByEmail?: string | null,
      organizationID?: string | null,
      projectID?: string | null,
      projectPlanID?: string | null,
      status?: SubtaskStatus | null,
      moscow?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBacklogQueryVariables = {
  id: string,
};

export type GetBacklogQuery = {
  getBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBacklogsQueryVariables = {
  filter?: ModelBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBacklogsQuery = {
  listBacklogs?:  {
    __typename: "ModelBacklogConnection",
    items:  Array< {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentCreationQueryVariables = {
  id: string,
};

export type GetContentCreationQuery = {
  getContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentCreationsQueryVariables = {
  filter?: ModelContentCreationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentCreationsQuery = {
  listContentCreations?:  {
    __typename: "ModelContentCreationConnection",
    items:  Array< {
      __typename: "ContentCreation",
      id: string,
      projectID: string,
      content: string,
      pageId?: string | null,
      sectionId?: string | null,
      authID: string,
      authEmail: string,
      pageName?: string | null,
      sent?: boolean | null,
      sentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationsQuery = {
  listConversations?:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      projectID: string,
      authID: string,
      pageId?: string | null,
      sectionId?: string | null,
      authEmail: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStoreQueryVariables = {
  id: string,
};

export type GetStoreQuery = {
  getStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStoresQueryVariables = {
  filter?: ModelStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoresQuery = {
  listStores?:  {
    __typename: "ModelStoreConnection",
    items:  Array< {
      __typename: "Store",
      id: string,
      projectID?: string | null,
      organizationID?: string | null,
      title: string,
      description: string,
      amount: number,
      inStock: boolean,
      addedBy: string,
      mediaIds?: Array< string | null > | null,
      colorsAvailable?: Array< string | null > | null,
      sizesAvailable?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFontQueryVariables = {
  id: string,
};

export type GetFontQuery = {
  getFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFontsQueryVariables = {
  filter?: ModelFontFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFontsQuery = {
  listFonts?:  {
    __typename: "ModelFontConnection",
    items:  Array< {
      __typename: "Font",
      id: string,
      name: string,
      link: string,
      complimentsWithID?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActivityQueryVariables = {
  id: string,
};

export type GetActivityQuery = {
  getActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListActivitiesQueryVariables = {
  filter?: ModelActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActivitiesQuery = {
  listActivities?:  {
    __typename: "ModelActivityConnection",
    items:  Array< {
      __typename: "Activity",
      id: string,
      authID: string,
      authEmail: string,
      activity: string,
      link?: string | null,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSolutionQueryVariables = {
  id: string,
};

export type GetSolutionQuery = {
  getSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSolutionsQueryVariables = {
  filter?: ModelSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSolutionsQuery = {
  listSolutions?:  {
    __typename: "ModelSolutionConnection",
    items:  Array< {
      __typename: "Solution",
      id: string,
      authID: string,
      authEmail: string,
      tags?: Array< string | null > | null,
      title: string,
      description?: string | null,
      projectID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPopupsQueryVariables = {
  id: string,
};

export type GetPopupsQuery = {
  getPopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPopupsQueryVariables = {
  filter?: ModelPopupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPopupsQuery = {
  listPopups?:  {
    __typename: "ModelPopupsConnection",
    items:  Array< {
      __typename: "Popups",
      id: string,
      authID: string,
      authEmail: string,
      title: string,
      description?: string | null,
      projectID?: string | null,
      isActive?: boolean | null,
      expiryDate?: string | null,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgMeetingsByOrganizationIDQueryVariables = {
  organizationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgMeetingsByOrganizationIDQuery = {
  orgMeetingsByOrganizationID?:  {
    __typename: "ModelOrgMeetingConnection",
    items:  Array< {
      __typename: "OrgMeeting",
      id: string,
      organizationID: string,
      name: string,
      description?: string | null,
      startTime: string,
      endTime: string,
      attendees: Array< string | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssetsByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssetsByProjectIDQuery = {
  assetsByProjectID?:  {
    __typename: "ModelAssetsConnection",
    items:  Array< {
      __typename: "Assets",
      id: string,
      userID: string,
      projectID?: string | null,
      organizationID?: string | null,
      email: string,
      size?: number | null,
      mediaType?: string | null,
      onlyAdmin?: boolean | null,
      link: string,
      multipleLinks?: Array< string | null > | null,
      type: AssetType,
      subType?: SubAssetType | null,
      note?: string | null,
      isAssigned?: boolean | null,
      galleryId?: string | null,
      assignedDate?: string | null,
      storeID?: string | null,
      storeImagesOrder?: Array< string | null > | null,
      multipleProjects?: Array< string | null > | null,
      lastUpdatedBy?: string | null,
      lastUpdatedByEmail?: string | null,
      lastUpdatedOn?: string | null,
      isUpdated?: boolean | null,
      isRemoved?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssetsByStoreIDQueryVariables = {
  storeID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAssetsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssetsByStoreIDQuery = {
  assetsByStoreID?:  {
    __typename: "ModelAssetsConnection",
    items:  Array< {
      __typename: "Assets",
      id: string,
      userID: string,
      projectID?: string | null,
      organizationID?: string | null,
      email: string,
      size?: number | null,
      mediaType?: string | null,
      onlyAdmin?: boolean | null,
      link: string,
      multipleLinks?: Array< string | null > | null,
      type: AssetType,
      subType?: SubAssetType | null,
      note?: string | null,
      isAssigned?: boolean | null,
      galleryId?: string | null,
      assignedDate?: string | null,
      storeID?: string | null,
      storeImagesOrder?: Array< string | null > | null,
      multipleProjects?: Array< string | null > | null,
      lastUpdatedBy?: string | null,
      lastUpdatedByEmail?: string | null,
      lastUpdatedOn?: string | null,
      isUpdated?: boolean | null,
      isRemoved?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SourceFilesByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSourceFileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SourceFilesByProjectIdQuery = {
  sourceFilesByProjectId?:  {
    __typename: "ModelSourceFileConnection",
    items:  Array< {
      __typename: "SourceFile",
      id: string,
      userID: string,
      organizationID?: string | null,
      projectId?: string | null,
      email: string,
      size?: number | null,
      mediaType?: string | null,
      link: string,
      note?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GalleriesByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGalleryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GalleriesByProjectIdQuery = {
  galleriesByProjectId?:  {
    __typename: "ModelGalleryConnection",
    items:  Array< {
      __typename: "Gallery",
      id: string,
      userID: string,
      organizationID?: string | null,
      projectId?: string | null,
      email: string,
      coverImage: string,
      sortSeq?: Array< string | null > | null,
      title?: string | null,
      publishDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserStatsByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserStatsByUserIDQuery = {
  userStatsByUserID?:  {
    __typename: "ModelUserStatConnection",
    items:  Array< {
      __typename: "UserStat",
      id: string,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByAuthIDQueryVariables = {
  authID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByAuthIDQuery = {
  usersByAuthID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByOrganizationIDQueryVariables = {
  organizationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByOrganizationIDQuery = {
  usersByOrganizationID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByOrganizationIDQueryVariables = {
  organizationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByOrganizationIDQuery = {
  projectsByOrganizationID?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectPlansByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectPlansByProjectIDQuery = {
  projectPlansByProjectID?:  {
    __typename: "ModelProjectPlanConnection",
    items:  Array< {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DiscussionsByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDiscussionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DiscussionsByProjectIDQuery = {
  discussionsByProjectID?:  {
    __typename: "ModelDiscussionConnection",
    items:  Array< {
      __typename: "Discussion",
      id: string,
      projectID: string,
      name: string,
      date?: string | null,
      actionItemsSortSeq?: Array< string | null > | null,
      emailSent?: boolean | null,
      emailSentDate?: string | null,
      meetingID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActionItemsByDiscussionIDQueryVariables = {
  discussionID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelActionItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActionItemsByDiscussionIDQuery = {
  actionItemsByDiscussionID?:  {
    __typename: "ModelActionItemsConnection",
    items:  Array< {
      __typename: "ActionItems",
      id: string,
      discussionID: string,
      actionItem: string,
      owners?: Array< string | null > | null,
      isCompleted?: boolean | null,
      targetDate?: string | null,
      dateAssigned?: string | null,
      emailSent?: boolean | null,
      dateCompleted?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SummariesByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SummariesByProjectIDQuery = {
  summariesByProjectID?:  {
    __typename: "ModelSummaryConnection",
    items:  Array< {
      __typename: "Summary",
      id: string,
      projectID: string,
      summary: string,
      summaryDate: string,
      summaryOwnerID: string,
      summaryOwnerEmail: string,
      summaryType: SummaryType,
      discussionDate?: string | null,
      discussedWithID?: string | null,
      discussedWithEmail?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MeetingsByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MeetingsByProjectIDQuery = {
  meetingsByProjectID?:  {
    __typename: "ModelMeetingsConnection",
    items:  Array< {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SuccessCriteriaByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSuccessCriteriaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SuccessCriteriaByProjectIDQuery = {
  successCriteriaByProjectID?:  {
    __typename: "ModelSuccessCriteriaConnection",
    items:  Array< {
      __typename: "SuccessCriteria",
      id: string,
      projectID: string,
      description: string,
      measurableCriteria: string,
      type: SuccessCriteriaType,
      pageId?: string | null,
      sectionId?: string | null,
      createdAt: string,
      updatedAt: string,
      pagesCriteriaId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PagesByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPagesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PagesByProjectIDQuery = {
  pagesByProjectID?:  {
    __typename: "ModelPagesConnection",
    items:  Array< {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByBacklogIDQueryVariables = {
  backlogID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByBacklogIDQuery = {
  commentsByBacklogID?:  {
    __typename: "ModelCommentsConnection",
    items:  Array< {
      __typename: "Comments",
      id: string,
      backlogID: string,
      comment: string,
      date: string,
      authID: string,
      authEmail: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubTaskModelsByBacklogIDQueryVariables = {
  backlogID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubTaskModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubTaskModelsByBacklogIDQuery = {
  subTaskModelsByBacklogID?:  {
    __typename: "ModelSubTaskModelConnection",
    items:  Array< {
      __typename: "SubTaskModel",
      id: string,
      isCompleted?: boolean | null,
      task: string,
      completedOn?: string | null,
      mediaId?: string | null,
      sprintType?: SprintType | null,
      backlogID?: string | null,
      statusUpdateDate?: string | null,
      priority?: BacklogPriority | null,
      completedByEmail?: string | null,
      organizationID?: string | null,
      projectID?: string | null,
      projectPlanID?: string | null,
      status?: SubtaskStatus | null,
      moscow?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StoresByProjectIDQueryVariables = {
  projectID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStoreFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StoresByProjectIDQuery = {
  storesByProjectID?:  {
    __typename: "ModelStoreConnection",
    items:  Array< {
      __typename: "Store",
      id: string,
      projectID?: string | null,
      organizationID?: string | null,
      title: string,
      description: string,
      amount: number,
      inStock: boolean,
      addedBy: string,
      mediaIds?: Array< string | null > | null,
      colorsAvailable?: Array< string | null > | null,
      sizesAvailable?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrgMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMeetingFilterInput | null,
};

export type OnCreateOrgMeetingSubscription = {
  onCreateOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrgMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMeetingFilterInput | null,
};

export type OnUpdateOrgMeetingSubscription = {
  onUpdateOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrgMeetingSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMeetingFilterInput | null,
};

export type OnDeleteOrgMeetingSubscription = {
  onDeleteOrgMeeting?:  {
    __typename: "OrgMeeting",
    id: string,
    organizationID: string,
    name: string,
    description?: string | null,
    startTime: string,
    endTime: string,
    attendees: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    authID?: string | null,
    email?: string | null,
    avatar?: string | null,
    user?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    type: OrganizationType,
    address1?: string | null,
    address2?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    zipCode?: string | null,
    url?: string | null,
    maxBucketSize?: number | null,
    staffSortSeq?: Array< string | null > | null,
    storeInventorySortSeq?: Array< string | null > | null,
    partnersLogoSortSeq?: Array< string | null > | null,
    mediaFilesSortSeq?: Array< string | null > | null,
    gallerySortSeq?: Array< string | null > | null,
    allowedFileTabs?: Array< string | null > | null,
    orgSize?: OrgSize | null,
    industry?: string | null,
    mission?: string | null,
    software?: Array< string | null > | null,
    paper?: PaperOptions | null,
    printer?: PrinterOptions | null,
    comments?:  Array< {
      __typename: "OrgComment",
      comment?: string | null,
      date?: string | null,
    } | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    contact?:  {
      __typename: "OrgContact",
      name?: string | null,
      email?: string | null,
      phone?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelOrgMeetingConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAssetsSubscriptionVariables = {
  filter?: ModelSubscriptionAssetsFilterInput | null,
};

export type OnCreateAssetsSubscription = {
  onCreateAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAssetsSubscriptionVariables = {
  filter?: ModelSubscriptionAssetsFilterInput | null,
};

export type OnUpdateAssetsSubscription = {
  onUpdateAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAssetsSubscriptionVariables = {
  filter?: ModelSubscriptionAssetsFilterInput | null,
};

export type OnDeleteAssetsSubscription = {
  onDeleteAssets?:  {
    __typename: "Assets",
    id: string,
    userID: string,
    projectID?: string | null,
    organizationID?: string | null,
    email: string,
    size?: number | null,
    profile?:  {
      __typename: "ProfileContent",
      id: string,
      name: string,
      title?: string | null,
      bio?: string | null,
      email?: string | null,
      phone?: string | null,
      country?: string | null,
      department?: string | null,
      LI?: string | null,
      Twitter?: string | null,
      YouTube?: string | null,
      IG?: string | null,
      status: StaffStatus,
    } | null,
    mediaType?: string | null,
    onlyAdmin?: boolean | null,
    link: string,
    multipleLinks?: Array< string | null > | null,
    type: AssetType,
    subType?: SubAssetType | null,
    note?: string | null,
    isAssigned?: boolean | null,
    galleryId?: string | null,
    assignedDate?: string | null,
    storeID?: string | null,
    storeImagesOrder?: Array< string | null > | null,
    multipleProjects?: Array< string | null > | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    lastUpdatedBy?: string | null,
    lastUpdatedByEmail?: string | null,
    lastUpdatedOn?: string | null,
    isUpdated?: boolean | null,
    lastUpdatedByUser?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isRemoved?: boolean | null,
    assignedToInfo?:  {
      __typename: "AssignedToInfo",
      pageID?: string | null,
      sectionID?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSourceFileSubscriptionVariables = {
  filter?: ModelSubscriptionSourceFileFilterInput | null,
};

export type OnCreateSourceFileSubscription = {
  onCreateSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSourceFileSubscriptionVariables = {
  filter?: ModelSubscriptionSourceFileFilterInput | null,
};

export type OnUpdateSourceFileSubscription = {
  onUpdateSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSourceFileSubscriptionVariables = {
  filter?: ModelSubscriptionSourceFileFilterInput | null,
};

export type OnDeleteSourceFileSubscription = {
  onDeleteSourceFile?:  {
    __typename: "SourceFile",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    size?: number | null,
    mediaType?: string | null,
    link: string,
    note?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGallerySubscriptionVariables = {
  filter?: ModelSubscriptionGalleryFilterInput | null,
};

export type OnCreateGallerySubscription = {
  onCreateGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGallerySubscriptionVariables = {
  filter?: ModelSubscriptionGalleryFilterInput | null,
};

export type OnUpdateGallerySubscription = {
  onUpdateGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGallerySubscriptionVariables = {
  filter?: ModelSubscriptionGalleryFilterInput | null,
};

export type OnDeleteGallerySubscription = {
  onDeleteGallery?:  {
    __typename: "Gallery",
    id: string,
    userID: string,
    organizationID?: string | null,
    projectId?: string | null,
    email: string,
    coverImage: string,
    sortSeq?: Array< string | null > | null,
    gallery?:  Array< {
      __typename: "GalleryItem",
      id: string,
      link: string,
      mediaType: string,
      note?: string | null,
    } | null > | null,
    title?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    publishDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserStatSubscriptionVariables = {
  filter?: ModelSubscriptionUserStatFilterInput | null,
};

export type OnCreateUserStatSubscription = {
  onCreateUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserStatSubscriptionVariables = {
  filter?: ModelSubscriptionUserStatFilterInput | null,
};

export type OnUpdateUserStatSubscription = {
  onUpdateUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserStatSubscriptionVariables = {
  filter?: ModelSubscriptionUserStatFilterInput | null,
};

export type OnDeleteUserStatSubscription = {
  onDeleteUserStat?:  {
    __typename: "UserStat",
    id: string,
    userID: string,
    taskID: string,
    task?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    authID: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    timezone?:  {
      __typename: "Timezone",
      country?: string | null,
      timezone?: string | null,
    } | null,
    title?: string | null,
    email: string,
    phone?: string | null,
    isAdmin?: boolean | null,
    initialEmailSentOn?: string | null,
    otherProjects?:  Array< {
      __typename: "OtherProjectRole",
      projectID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    overrideOrganization?: string | null,
    orgLevel?: number | null,
    lastLogin?: string | null,
    status: Status,
    adminAccessTo?: Array< string | null > | null,
    liveVisiblity?: boolean | null,
    isZoiq?: boolean | null,
    completedTasks?: Array< string | null > | null,
    links?: Array< string | null > | null,
    socialMedia?:  Array< {
      __typename: "SocialLinks",
      type?: SocialLinkType | null,
      link?: string | null,
    } | null > | null,
    canAddProjects?: boolean | null,
    stats?:  {
      __typename: "ModelUserStatConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    milestone?: string | null,
    ownerID?: string | null,
    ownerAuthID?: string | null,
    ownerEmail?: string | null,
    owner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status: ProjectStatus,
    otherUsers?:  Array< {
      __typename: "OtherUser",
      userID?: string | null,
      projectRole?: ProjectRole | null,
    } | null > | null,
    risk: Risk,
    githubRepo?: string | null,
    awsAccount?: string | null,
    cognitoUrl?: string | null,
    dynamo?:  Array< {
      __typename: "Dynamo",
      key?: string | null,
      value?: string | null,
    } | null > | null,
    solutionType?: string | null,
    scope?: string | null,
    estUsersInFirstYear?: string | null,
    userAgeGroups?: Array< string | null > | null,
    lambdaFunction?:  Array< {
      __typename: "LambdaFunction",
      name?: string | null,
      url?: string | null,
    } | null > | null,
    liveUrl?: string | null,
    siteData?:  {
      __typename: "SiteData",
      audience?: Array< AudienceType | null > | null,
      trafficCount?: AudienceSize | null,
      trafficLocation?: Array< string | null > | null,
      tone?: ToneType | null,
      languages?: LanguageType | null,
      pageSortSeq?: Array< string | null > | null,
      organizationSummary?: string | null,
      purpose?: string | null,
      goals?: Array< string | null > | null,
      messaging?: string | null,
      keyDates?: Array< string | null > | null,
      suggestedSolution?: Array< SuggestedSolutions | null > | null,
      projectNotes?: string | null,
      contactPerson?: string | null,
      isLocked?: boolean | null,
      appId?: string | null,
      branchName?: string | null,
    } | null,
    isZoiq?: boolean | null,
    discussion?:  {
      __typename: "ModelDiscussionConnection",
      nextToken?: string | null,
    } | null,
    sourceFile?:  {
      __typename: "ModelSourceFileConnection",
      nextToken?: string | null,
    } | null,
    gallery?:  {
      __typename: "ModelGalleryConnection",
      nextToken?: string | null,
    } | null,
    projectPlan?:  {
      __typename: "ModelProjectPlanConnection",
      nextToken?: string | null,
    } | null,
    successCriteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    meetings?:  {
      __typename: "ModelMeetingsConnection",
      nextToken?: string | null,
    } | null,
    pages?:  {
      __typename: "ModelPagesConnection",
      nextToken?: string | null,
    } | null,
    summaries?:  {
      __typename: "ModelSummaryConnection",
      nextToken?: string | null,
    } | null,
    assets?:  {
      __typename: "ModelAssetsConnection",
      nextToken?: string | null,
    } | null,
    store?:  {
      __typename: "ModelStoreConnection",
      nextToken?: string | null,
    } | null,
    userSortSeq?: Array< string | null > | null,
    projectPlanSortSeq?: Array< string | null > | null,
    projectMeetingsSortSeq?: Array< string | null > | null,
    projectCriteriaSortSeq?: Array< string | null > | null,
    counts?:  {
      __typename: "Counts",
      ORGANIZATION?: number | null,
      TEAM?: number | null,
      STOCK?: number | null,
      DOCUMENTS?: number | null,
      PARTNERS_STOCK?: number | null,
      STORE_INVENTORY?: number | null,
      VECTORS?: number | null,
      GALLERY?: number | null,
      SOURCE_FILE?: number | null,
    } | null,
    closingMatrix?:  {
      __typename: "ClosingMatrix",
      projectFunds?: string | null,
      relationshipWithLead?: string | null,
      projectRelation?: Array< string | null > | null,
      opportunityRelated?: boolean | null,
      additionalBuildRequirements?: string | null,
      estNewFeatures?: string | null,
      industryExpertOnTheirSide?: string | null,
      technicalExpertOnTheirSide?: string | null,
      perceivedReadiness?: string | null,
      notes?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProjectPlanSubscriptionVariables = {
  filter?: ModelSubscriptionProjectPlanFilterInput | null,
};

export type OnCreateProjectPlanSubscription = {
  onCreateProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProjectPlanSubscriptionVariables = {
  filter?: ModelSubscriptionProjectPlanFilterInput | null,
};

export type OnUpdateProjectPlanSubscription = {
  onUpdateProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProjectPlanSubscriptionVariables = {
  filter?: ModelSubscriptionProjectPlanFilterInput | null,
};

export type OnDeleteProjectPlanSubscription = {
  onDeleteProjectPlan?:  {
    __typename: "ProjectPlan",
    id: string,
    projectID: string,
    milestone: string,
    startDate?: string | null,
    endDate?: string | null,
    status: ProjectStatus,
    risk: Risk,
    fakeId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDiscussionSubscriptionVariables = {
  filter?: ModelSubscriptionDiscussionFilterInput | null,
};

export type OnCreateDiscussionSubscription = {
  onCreateDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDiscussionSubscriptionVariables = {
  filter?: ModelSubscriptionDiscussionFilterInput | null,
};

export type OnUpdateDiscussionSubscription = {
  onUpdateDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDiscussionSubscriptionVariables = {
  filter?: ModelSubscriptionDiscussionFilterInput | null,
};

export type OnDeleteDiscussionSubscription = {
  onDeleteDiscussion?:  {
    __typename: "Discussion",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    date?: string | null,
    actionItemsSortSeq?: Array< string | null > | null,
    actions?:  {
      __typename: "ModelActionItemsConnection",
      nextToken?: string | null,
    } | null,
    emailSent?: boolean | null,
    emailSentDate?: string | null,
    meetingID?: string | null,
    meeting?:  {
      __typename: "Meetings",
      id: string,
      projectID: string,
      name: string,
      description: string,
      firstMeetingDate: string,
      lastMeetingDate: string,
      startTime: string,
      endTime: string,
      frequency?: FrequencyType | null,
      link?: string | null,
      attendees: Array< string | null >,
      mailSentDate?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStyleGuideSubscriptionVariables = {
  filter?: ModelSubscriptionStyleGuideFilterInput | null,
};

export type OnCreateStyleGuideSubscription = {
  onCreateStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStyleGuideSubscriptionVariables = {
  filter?: ModelSubscriptionStyleGuideFilterInput | null,
};

export type OnUpdateStyleGuideSubscription = {
  onUpdateStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStyleGuideSubscriptionVariables = {
  filter?: ModelSubscriptionStyleGuideFilterInput | null,
};

export type OnDeleteStyleGuideSubscription = {
  onDeleteStyleGuide?:  {
    __typename: "StyleGuide",
    id: string,
    name: string,
    type: StyleGuideType,
    value: string,
    organizationID: string,
    projectID?: string | null,
    extraInfo?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateActionItemsSubscriptionVariables = {
  filter?: ModelSubscriptionActionItemsFilterInput | null,
};

export type OnCreateActionItemsSubscription = {
  onCreateActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateActionItemsSubscriptionVariables = {
  filter?: ModelSubscriptionActionItemsFilterInput | null,
};

export type OnUpdateActionItemsSubscription = {
  onUpdateActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteActionItemsSubscriptionVariables = {
  filter?: ModelSubscriptionActionItemsFilterInput | null,
};

export type OnDeleteActionItemsSubscription = {
  onDeleteActionItems?:  {
    __typename: "ActionItems",
    id: string,
    discussionID: string,
    actionItem: string,
    owners?: Array< string | null > | null,
    isCompleted?: boolean | null,
    targetDate?: string | null,
    dateAssigned?: string | null,
    emailSent?: boolean | null,
    dateCompleted?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSummarySubscriptionVariables = {
  filter?: ModelSubscriptionSummaryFilterInput | null,
};

export type OnCreateSummarySubscription = {
  onCreateSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSummarySubscriptionVariables = {
  filter?: ModelSubscriptionSummaryFilterInput | null,
};

export type OnUpdateSummarySubscription = {
  onUpdateSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSummarySubscriptionVariables = {
  filter?: ModelSubscriptionSummaryFilterInput | null,
};

export type OnDeleteSummarySubscription = {
  onDeleteSummary?:  {
    __typename: "Summary",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summary: string,
    summaryDate: string,
    summaryOwnerID: string,
    summaryOwnerEmail: string,
    summaryOwner?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    summaryType: SummaryType,
    discussionDate?: string | null,
    discussedWithID?: string | null,
    discussedWithEmail?: string | null,
    discussedWith?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMeetingsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingsFilterInput | null,
};

export type OnCreateMeetingsSubscription = {
  onCreateMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMeetingsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingsFilterInput | null,
};

export type OnUpdateMeetingsSubscription = {
  onUpdateMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMeetingsSubscriptionVariables = {
  filter?: ModelSubscriptionMeetingsFilterInput | null,
};

export type OnDeleteMeetingsSubscription = {
  onDeleteMeetings?:  {
    __typename: "Meetings",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    firstMeetingDate: string,
    lastMeetingDate: string,
    startTime: string,
    endTime: string,
    rolls?:  {
      __typename: "RollsType",
      Date?: string | null,
      Day?: Array< string | null > | null,
    } | null,
    frequency?: FrequencyType | null,
    link?: string | null,
    attendees: Array< string | null >,
    mailSentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSuccessCriteriaSubscriptionVariables = {
  filter?: ModelSubscriptionSuccessCriteriaFilterInput | null,
};

export type OnCreateSuccessCriteriaSubscription = {
  onCreateSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type OnUpdateSuccessCriteriaSubscriptionVariables = {
  filter?: ModelSubscriptionSuccessCriteriaFilterInput | null,
};

export type OnUpdateSuccessCriteriaSubscription = {
  onUpdateSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type OnDeleteSuccessCriteriaSubscriptionVariables = {
  filter?: ModelSubscriptionSuccessCriteriaFilterInput | null,
};

export type OnDeleteSuccessCriteriaSubscription = {
  onDeleteSuccessCriteria?:  {
    __typename: "SuccessCriteria",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    description: string,
    measurableCriteria: string,
    type: SuccessCriteriaType,
    pageId?: string | null,
    sectionId?: string | null,
    page?:  {
      __typename: "Pages",
      id: string,
      projectID: string,
      name: string,
      description: string,
      link?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    pagesCriteriaId?: string | null,
  } | null,
};

export type OnCreatePagesSubscriptionVariables = {
  filter?: ModelSubscriptionPagesFilterInput | null,
};

export type OnCreatePagesSubscription = {
  onCreatePages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePagesSubscriptionVariables = {
  filter?: ModelSubscriptionPagesFilterInput | null,
};

export type OnUpdatePagesSubscription = {
  onUpdatePages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePagesSubscriptionVariables = {
  filter?: ModelSubscriptionPagesFilterInput | null,
};

export type OnDeletePagesSubscription = {
  onDeletePages?:  {
    __typename: "Pages",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    link?: string | null,
    pageSections:  Array< {
      __typename: "PageSection",
      section: string,
      noMedia?: boolean | null,
      id: string,
      link?: string | null,
      meta?: Array< string | null > | null,
      media?: Array< string | null > | null,
      lastEmailSent?: string | null,
      notes?: string | null,
      illustration?: string | null,
      instructionsLevel?: string | null,
    } | null >,
    criteria?:  {
      __typename: "ModelSuccessCriteriaConnection",
      nextToken?: string | null,
    } | null,
    successCriteriaSummary?:  {
      __typename: "SuccessCriteriaSummary",
      mustHave?: string | null,
      shouldHave?: string | null,
      couldHave?: string | null,
      wontHave?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserJourneySubscriptionVariables = {
  filter?: ModelSubscriptionUserJourneyFilterInput | null,
};

export type OnCreateUserJourneySubscription = {
  onCreateUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserJourneySubscriptionVariables = {
  filter?: ModelSubscriptionUserJourneyFilterInput | null,
};

export type OnUpdateUserJourneySubscription = {
  onUpdateUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserJourneySubscriptionVariables = {
  filter?: ModelSubscriptionUserJourneyFilterInput | null,
};

export type OnDeleteUserJourneySubscription = {
  onDeleteUserJourney?:  {
    __typename: "UserJourney",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    userRole: string,
    pageName: string,
    sectionName: string,
    access: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBacklogSequenceSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogSequenceFilterInput | null,
};

export type OnCreateBacklogSequenceSubscription = {
  onCreateBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBacklogSequenceSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogSequenceFilterInput | null,
};

export type OnUpdateBacklogSequenceSubscription = {
  onUpdateBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBacklogSequenceSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogSequenceFilterInput | null,
};

export type OnDeleteBacklogSequenceSubscription = {
  onDeleteBacklogSequence?:  {
    __typename: "BacklogSequence",
    id: string,
    sequence: Array< string | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
};

export type OnCreateCommentsSubscription = {
  onCreateComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
};

export type OnUpdateCommentsSubscription = {
  onUpdateComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCommentsSubscriptionVariables = {
  filter?: ModelSubscriptionCommentsFilterInput | null,
};

export type OnDeleteCommentsSubscription = {
  onDeleteComments?:  {
    __typename: "Comments",
    id: string,
    backlogID: string,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    comment: string,
    date: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    authID: string,
    authEmail: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSubTaskModelSubscriptionVariables = {
  filter?: ModelSubscriptionSubTaskModelFilterInput | null,
};

export type OnCreateSubTaskModelSubscription = {
  onCreateSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubTaskModelSubscriptionVariables = {
  filter?: ModelSubscriptionSubTaskModelFilterInput | null,
};

export type OnUpdateSubTaskModelSubscription = {
  onUpdateSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubTaskModelSubscriptionVariables = {
  filter?: ModelSubscriptionSubTaskModelFilterInput | null,
};

export type OnDeleteSubTaskModelSubscription = {
  onDeleteSubTaskModel?:  {
    __typename: "SubTaskModel",
    id: string,
    isCompleted?: boolean | null,
    task: string,
    completedOn?: string | null,
    mediaId?: string | null,
    sprintType?: SprintType | null,
    backlogID?: string | null,
    backlog?:  {
      __typename: "Backlog",
      id: string,
      name: string,
      title?: string | null,
      isCompleted?: boolean | null,
      dateCompleted?: string | null,
      createdBy: string,
      createdByEmail: string,
      status: BacklogStatus,
      priority: BacklogPriority,
      assignedTo?: Array< string | null > | null,
      targetDate?: string | null,
      sectionID?: string | null,
      pageID?: string | null,
      notifyAssignedMembers?: boolean | null,
      referralLinks?: Array< string | null > | null,
      note?: string | null,
      tags?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    statusUpdateDate?: string | null,
    priority?: BacklogPriority | null,
    completedByEmail?: string | null,
    organizationID?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      authID?: string | null,
      email?: string | null,
      avatar?: string | null,
      name: string,
      type: OrganizationType,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      zipCode?: string | null,
      url?: string | null,
      maxBucketSize?: number | null,
      staffSortSeq?: Array< string | null > | null,
      storeInventorySortSeq?: Array< string | null > | null,
      partnersLogoSortSeq?: Array< string | null > | null,
      mediaFilesSortSeq?: Array< string | null > | null,
      gallerySortSeq?: Array< string | null > | null,
      allowedFileTabs?: Array< string | null > | null,
      orgSize?: OrgSize | null,
      industry?: string | null,
      mission?: string | null,
      software?: Array< string | null > | null,
      paper?: PaperOptions | null,
      printer?: PrinterOptions | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    projectPlanID?: string | null,
    projectPlan?:  {
      __typename: "ProjectPlan",
      id: string,
      projectID: string,
      milestone: string,
      startDate?: string | null,
      endDate?: string | null,
      status: ProjectStatus,
      risk: Risk,
      fakeId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: SubtaskStatus | null,
    moscow?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateBacklogSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogFilterInput | null,
};

export type OnCreateBacklogSubscription = {
  onCreateBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBacklogSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogFilterInput | null,
};

export type OnUpdateBacklogSubscription = {
  onUpdateBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBacklogSubscriptionVariables = {
  filter?: ModelSubscriptionBacklogFilterInput | null,
};

export type OnDeleteBacklogSubscription = {
  onDeleteBacklog?:  {
    __typename: "Backlog",
    id: string,
    name: string,
    title?: string | null,
    isCompleted?: boolean | null,
    dateCompleted?: string | null,
    createdBy: string,
    createdByEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    media?:  Array< {
      __typename: "BacklogMedia",
      id: string,
      mediaType: string,
      mediaLink: string,
    } | null > | null,
    status: BacklogStatus,
    priority: BacklogPriority,
    assignedTo?: Array< string | null > | null,
    assignedToUser?:  {
      __typename: "ModelUserConnection",
      nextToken?: string | null,
    } | null,
    comments?:  {
      __typename: "ModelCommentsConnection",
      nextToken?: string | null,
    } | null,
    targetDate?: string | null,
    sectionID?: string | null,
    pageID?: string | null,
    notifyAssignedMembers?: boolean | null,
    subtasks?:  {
      __typename: "ModelSubTaskModelConnection",
      nextToken?: string | null,
    } | null,
    referralLinks?: Array< string | null > | null,
    styles?:  {
      __typename: "BacklogStyles",
      bgStyle?: string | null,
    } | null,
    note?: string | null,
    tags?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentCreationSubscriptionVariables = {
  filter?: ModelSubscriptionContentCreationFilterInput | null,
};

export type OnCreateContentCreationSubscription = {
  onCreateContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentCreationSubscriptionVariables = {
  filter?: ModelSubscriptionContentCreationFilterInput | null,
};

export type OnUpdateContentCreationSubscription = {
  onUpdateContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentCreationSubscriptionVariables = {
  filter?: ModelSubscriptionContentCreationFilterInput | null,
};

export type OnDeleteContentCreationSubscription = {
  onDeleteContentCreation?:  {
    __typename: "ContentCreation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    content: string,
    pageId?: string | null,
    sectionId?: string | null,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    pageName?: string | null,
    sent?: boolean | null,
    sentDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnCreateConversationSubscription = {
  onCreateConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnUpdateConversationSubscription = {
  onUpdateConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConversationSubscriptionVariables = {
  filter?: ModelSubscriptionConversationFilterInput | null,
};

export type OnDeleteConversationSubscription = {
  onDeleteConversation?:  {
    __typename: "Conversation",
    id: string,
    projectID: string,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    conversations?:  Array< {
      __typename: "ConversationInput",
      message: string,
      isBot: boolean,
      date: string,
      id?: string | null,
    } | null > | null,
    authID: string,
    pageId?: string | null,
    sectionId?: string | null,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStoreSubscriptionVariables = {
  filter?: ModelSubscriptionStoreFilterInput | null,
};

export type OnCreateStoreSubscription = {
  onCreateStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStoreSubscriptionVariables = {
  filter?: ModelSubscriptionStoreFilterInput | null,
};

export type OnUpdateStoreSubscription = {
  onUpdateStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStoreSubscriptionVariables = {
  filter?: ModelSubscriptionStoreFilterInput | null,
};

export type OnDeleteStoreSubscription = {
  onDeleteStore?:  {
    __typename: "Store",
    id: string,
    projectID?: string | null,
    organizationID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description: string,
    amount: number,
    inStock: boolean,
    addedBy: string,
    mediaIds?: Array< string | null > | null,
    colorsAvailable?: Array< string | null > | null,
    sizesAvailable?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFontSubscriptionVariables = {
  filter?: ModelSubscriptionFontFilterInput | null,
};

export type OnCreateFontSubscription = {
  onCreateFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFontSubscriptionVariables = {
  filter?: ModelSubscriptionFontFilterInput | null,
};

export type OnUpdateFontSubscription = {
  onUpdateFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFontSubscriptionVariables = {
  filter?: ModelSubscriptionFontFilterInput | null,
};

export type OnDeleteFontSubscription = {
  onDeleteFont?:  {
    __typename: "Font",
    id: string,
    name: string,
    link: string,
    complimentsWithID?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnCreateActivitySubscription = {
  onCreateActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnUpdateActivitySubscription = {
  onUpdateActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteActivitySubscriptionVariables = {
  filter?: ModelSubscriptionActivityFilterInput | null,
};

export type OnDeleteActivitySubscription = {
  onDeleteActivity?:  {
    __typename: "Activity",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    activity: string,
    link?: string | null,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnCreateSolutionSubscription = {
  onCreateSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnUpdateSolutionSubscription = {
  onUpdateSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionSolutionFilterInput | null,
};

export type OnDeleteSolutionSubscription = {
  onDeleteSolution?:  {
    __typename: "Solution",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    tags?: Array< string | null > | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePopupsSubscriptionVariables = {
  filter?: ModelSubscriptionPopupsFilterInput | null,
};

export type OnCreatePopupsSubscription = {
  onCreatePopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePopupsSubscriptionVariables = {
  filter?: ModelSubscriptionPopupsFilterInput | null,
};

export type OnUpdatePopupsSubscription = {
  onUpdatePopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePopupsSubscriptionVariables = {
  filter?: ModelSubscriptionPopupsFilterInput | null,
};

export type OnDeletePopupsSubscription = {
  onDeletePopups?:  {
    __typename: "Popups",
    id: string,
    authID: string,
    authEmail: string,
    user?:  {
      __typename: "User",
      id: string,
      authID: string,
      organizationID?: string | null,
      name: string,
      title?: string | null,
      email: string,
      phone?: string | null,
      isAdmin?: boolean | null,
      initialEmailSentOn?: string | null,
      overrideOrganization?: string | null,
      orgLevel?: number | null,
      lastLogin?: string | null,
      status: Status,
      adminAccessTo?: Array< string | null > | null,
      liveVisiblity?: boolean | null,
      isZoiq?: boolean | null,
      completedTasks?: Array< string | null > | null,
      links?: Array< string | null > | null,
      canAddProjects?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    title: string,
    description?: string | null,
    projectID?: string | null,
    project?:  {
      __typename: "Project",
      id: string,
      organizationID?: string | null,
      name: string,
      description?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      milestone?: string | null,
      ownerID?: string | null,
      ownerAuthID?: string | null,
      ownerEmail?: string | null,
      status: ProjectStatus,
      risk: Risk,
      githubRepo?: string | null,
      awsAccount?: string | null,
      cognitoUrl?: string | null,
      solutionType?: string | null,
      scope?: string | null,
      estUsersInFirstYear?: string | null,
      userAgeGroups?: Array< string | null > | null,
      liveUrl?: string | null,
      isZoiq?: boolean | null,
      userSortSeq?: Array< string | null > | null,
      projectPlanSortSeq?: Array< string | null > | null,
      projectMeetingsSortSeq?: Array< string | null > | null,
      projectCriteriaSortSeq?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    isActive?: boolean | null,
    expiryDate?: string | null,
    content?:  Array< {
      __typename: "PopupContent",
      name: string,
      value: string,
    } | null > | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
