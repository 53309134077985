import { Pages, Project } from "@/API";
import { updateSortSeqMutationSiteData } from "@/utils/graphql";
import { UniqueIdentifier } from "@dnd-kit/core";
import { Empty, Select, Tabs, message } from "antd";
import { useState } from "react";
import SortTable from "../common/SortComponents/SortTable";
import { isEmpty } from "lodash";

interface PagesProps {
  project: Project;
  refetch: () => void;
  pages: Pages[];
}

const PageSortTable = ({ project, refetch, pages }: PagesProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <SortTable
        onDragEndSuccess={(updated) => {
          const newSeq: string[] = updated.map(
            (item: { id: UniqueIdentifier }) => item.id
          );

          messageApi.loading("Updating page order");

          updateSortSeqMutationSiteData(
            project.id,
            newSeq,
            project?.siteData?.sectionSortSeq ?? [],
            project.siteData!
          );
          refetch?.();
          messageApi.success("Updated page order");
        }}
        dataSource={pages}
        pagination={false}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
          },
        ]}
      />
    </>
  );
};
const SectionSortTable = ({ project, refetch, pages }: PagesProps) => {
  const [selectedPageId, setSelectedPageId] = useState("");

  const sections =
    pages.find((page) => page.id === selectedPageId)?.pageSections ?? [];

  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <Select
        style={{ width: "100%", marginBottom: 20 }}
        options={pages.map((page) => ({
          label: page.name,
          value: page.id,
        }))}
        placeholder="Select a page"
        showSearch
        allowClear
        onChange={(value) => setSelectedPageId(value as string)}
      />

      {selectedPageId ? (
        <SortTable
          onDragEndSuccess={(updated) => {
            messageApi.loading("Updating section order");
            const newSeq: string[] = updated.map(
              (item: { id: UniqueIdentifier }) => item.id
            );

            const existingSeq = project?.siteData?.sectionSortSeq ?? [];

            const doesItExist = !isEmpty(
              existingSeq.find((seq) => seq?.pageId === selectedPageId)
            );

            const updatedSeq =
              existingSeq.length === 0
                ? [
                    {
                      pageId: selectedPageId,
                      seq: newSeq,
                    },
                  ]
                : doesItExist
                ? existingSeq.map((seq) => {
                    if (seq?.pageId === selectedPageId) {
                      return {
                        pageId: selectedPageId,
                        seq: newSeq,
                      };
                    }
                    return seq;
                  })
                : [
                    ...existingSeq,
                    {
                      pageId: selectedPageId,
                      seq: newSeq,
                    },
                  ];

            updateSortSeqMutationSiteData(
              project.id,
              project?.siteData?.pageSortSeq as string[],
              updatedSeq,
              project.siteData!
            );
            refetch?.();
            messageApi.success("Updated section order");
          }}
          dataSource={sections}
          pagination={false}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              width: "10%",
            },
            {
              title: "Section Name",
              dataIndex: "section",
              key: "section",
              render: (section: any) => section,
            },
          ]}
        />
      ) : (
        <Empty
          description="Select a page to sort sections"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </>
  );
};

const SortPagesAndSection = ({ refetch, project, pages }: PagesProps) => {
  return (
    <div>
      <Tabs
        items={[
          {
            key: "pages",
            label: "Pages",
            children: (
              <PageSortTable
                project={project}
                refetch={refetch}
                pages={pages}
              />
            ),
          },
          {
            key: "sections",
            label: "Sections",
            children: (
              <SectionSortTable
                project={project}
                refetch={refetch}
                pages={pages}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default SortPagesAndSection;
