import {
  AssetType,
  Assets as AssetsInterface,
  Assets as AssetsType,
  Gallery,
  GalleryItem,
  ModelAssetsFilterInput,
  Organization,
  Store,
  UpdateAssetsInput,
  UpdateGalleryInput,
  UpdatePagesInput,
  User,
} from "@/API";
import { videoAccept } from "@/constants/assets";
import { ASSET_TYPE_OPTIONS } from "@/constants/staticData";
import {
  getOrganization,
  getUserNames,
  listAssets,
} from "@/customGraphql/customQueries";
import {
  deleteAssets,
  deleteStore,
  updateAssets,
  updateGallery,
  updatePages,
} from "@/graphql/mutations";

import useBucketSize from "@/hooks/useBucketSize";
import useUserData from "@/hooks/useData";
import useS3Keys from "@/hooks/useS3Keys";
import SectionLayout from "@/layout/SectionLayout";
import { deleteImageFromS3, getImageFromS3, getStorageSize } from "@/utils";
import { download } from "@/utils/graphql";
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Empty,
  Flex,
  List,
  MenuProps,
  Modal,
  Popconfirm,
  Popover,
  Progress,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { cubicBezier, motion } from "framer-motion";
import { isEmpty, orderBy, uniq } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CiViewTable } from "react-icons/ci";
import { RiMailSendLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import BrandingAssets from "../BrandingAsset";
import DocumentAssets from "../DocumentAssets";
import MediaAssets from "../MediaAssets";
import AttachMediaTable from "../ProjectComponents/AttachMediaTable";
import { fetchGallery } from "../ProjectComponents/GallerySection";
import { fetchProjectsPages } from "../ProjectComponents/ProjectPages";
import { TitleWithCount } from "../ProjectComponents/ProjectSC";
import NewAsset from "../RareComponents/NewAsset";
import StaffAssets from "../StaffAssets";
import StoreInventory from "../StoreInventory";
import VectorAssets from "../VectorAssets";
import Loading from "../common/Loading";

type _GalleryItem = Omit<GalleryItem, "__typename">;

export const assetCols = {
  span: 24,
  lg: 12,
  xxl: 8,
};

export const fetchAssets = async (
  organisationId: string,
  projectId?: string,
  activeKey?: string
) => {
  const filter: ModelAssetsFilterInput = projectId
    ? {
        and: [
          { multipleProjects: { contains: projectId } },
          // {
          //   organizationID: {
          //     eq: organisationId,
          //   },
          // },
        ],
      }
    : {
        organizationID: {
          eq: organisationId,
        },
      };

  const _filter = activeKey
    ? {
        ...filter,
        type: {
          eq: activeKey,
        },
      }
    : {
        ...filter,
      };

  const response: any = await API.graphql(
    graphqlOperation(listAssets, {
      limit: 2000,
      filter: _filter,
    })
  );
  const items = response.data.listAssets.items as AssetsType[];
  return orderBy(items, "createdAt", ["desc"]);
};

export const AdminOnlyTag = ({
  onlyAdmin,
}: {
  onlyAdmin: boolean | undefined | null;
}) => {
  return (
    onlyAdmin && (
      <Tag style={{ marginLeft: 6 }} color="gold-inverse">
        Only Admin
      </Tag>
    )
  );
};

export interface AssetWithStore extends AssetsInterface {
  store: Store;
}
// create a new enum with extending AssetType

export const ActionDropDown = ({
  asset,
  other,
  dropdownRender,
  onEditAsset,
  onDeleteAsset,
  store,
  onRemoveAsset,
  onAddToGallery,
  onRemoveGallery,
  onSendEmail,
  options = {
    delete: true,
    edit: true,
    download: true,
    remove: false,
    gallery: false,
    removeGallery: false,
  },
}: {
  asset: AssetsInterface;
  onEditAsset: (id: string, store?: Store) => void;
  onRemoveAsset?: (id: string, pageId: string, sectionId: string) => void;
  onDeleteAsset: (id: string, key: string, storeId: string) => void;
  dropdownRender?: (menu: React.ReactNode) => React.ReactNode;
  onAddToGallery?: (id: string) => void;
  onRemoveGallery?: (id: string) => void;
  onSendEmail?: (id: string) => void;
  store?: Store;
  options?: {
    gallery?: boolean;
    delete?: boolean;
    download?: boolean;
    remove?: boolean;
    edit?: boolean;
    removeGallery?: boolean;
    sendEmail?: boolean;
  };
  other?: {
    pageId?: string;
    sectionId?: string;
  };
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const onDownload = (key: string, name: string) => {
    // download file from s3 using key

    download(key, name, () => {
      messageApi.success("Downloaded successfully");
    });
  };

  const { isAdmin, isZoiq } = useUserData();

  const adminConditions = asset.onlyAdmin || isZoiq || isAdmin;

  const items: MenuProps["items"] = [
    options?.download && {
      label: "Download",
      onClick: () => onDownload(asset.link, asset.note!),
      icon: <DownloadOutlined />,
      key: "download",
    },
    options?.gallery && {
      label: "Add to gallery",
      onClick: () => onAddToGallery?.(asset.id),
      icon: <PlusCircleFilled />,
      key: "gallery",
    },
    options?.removeGallery && {
      label: (
        <Popconfirm
          title="Are you sure to remove this image from gallery?"
          onConfirm={() => onRemoveGallery?.(asset.id)}
        >
          Remove from gallery
        </Popconfirm>
      ),

      icon: <DeleteOutlined />,
      key: "removeGallery",
      danger: true,
    },
    options?.edit && {
      label: "Edit",
      icon: <EditOutlined />,
      key: "edit",
      onClick: () => {
        onEditAsset(asset.id, store);
      },
    },
    options?.delete && {
      label: (
        <Popconfirm
          title="Are you sure to delete this asset?"
          onConfirm={() => onDeleteAsset(asset.id, asset.link, store?.id!)}
        >
          Delete
        </Popconfirm>
      ),
      danger: true,
      icon: <DeleteOutlined />,

      key: "delete",
    },
    options?.remove && {
      label: (
        <Popconfirm
          title={"Are you sure to remove this asset?"}
          onConfirm={() =>
            onRemoveAsset?.(asset.id, other?.pageId!, other?.sectionId!)
          }
        >
          Remove
        </Popconfirm>
      ),
      danger: true,

      icon: <DeleteOutlined />,

      key: "remove",
    },
    adminConditions && {
      label: "Copy URL",
      onClick: () => {
        navigator.clipboard.writeText(getImageFromS3(asset.link));
        messageApi.success("Copied to clipboard");
      },
      icon: <CopyOutlined />,
      key: "copy",
    },
    options?.sendEmail && {
      label: "Notify ZOIQ",
      onClick: () => {
        onSendEmail?.(asset.id);
      },
      icon: <RiMailSendLine />,
      key: "send-email",
    },
  ].filter(Boolean) as MenuProps["items"];
  return (
    <>
      {contextHolder}
      <Dropdown dropdownRender={dropdownRender} menu={{ items }}>
        <Button
          shape="circle"
          type="text"
          icon={<BiDotsVerticalRounded />}
          size="small"
        />
      </Dropdown>
    </>
  );
};
interface AssetsProps {
  organisationId: string;
  projectId?: string;
  activeKey: AssetType;
}

// const updateLink = async (
//   id: string,
//   organisationId: string,
//   projectId: string,
//   link: string
// ) => {
//   if (link.includes("undefined")) {
//     const newLink = link.replace(
//       "undefined",
//       "cfb83e8a-6da5-4289-9583-2be1729d7500"
//     );
//     try {
//       const response: any = await API.graphql(
//         graphqlOperation(updateAssets, {
//           input: {
//             id,
//             link: newLink,
//           },
//         })
//       );
//       return response.data.updateAssets;
//     } catch (error) {
//       console.error(error);
//     }
//   }
// };

const Assets = ({ activeKey, projectId, organisationId }: AssetsProps) => {
  const [newAssetModal, setNewAssetModal] = useState(false);

  const { isAdmin, isZoiq, data: userData } = useUserData();

  const showForAdmins = isAdmin || isZoiq;

  const [showAttachedOnly, setShowAttachedOnly] = useState(false);

  const {
    data: assetsData,
    isLoading,
    isFetched,

    refetch,
    isRefetching,
  } = useQuery(
    `assets-${organisationId}-${activeKey}-${projectId}`,
    () => fetchAssets(organisationId, projectId, activeKey),
    {
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      // async onSuccess(data) {
      //   await Promise.all(
      //     data.map(async (asset) => {
      //       await updateLink(asset.id, organisationId, projectId!, asset.link);
      //     })
      //   );
      // },
    }
  );

  const [editAssetData, setEditAssetData] = useState<AssetWithStore>(
    {} as AssetWithStore
  );

  const { data: projectPages, refetch: refetchProjectPages } = useQuery(
    `project-pages-${projectId}`,
    () => fetchProjectsPages(projectId!),
    {
      enabled: !!projectId,
      refetchOnMount: true,
      refetchOnReconnect: true,
    }
  );

  const { data: organisation } = useQuery(
    `organisation-${organisationId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getOrganization, {
          id: organisationId,
        })
      );
      return response.data.getOrganization as Organization;
    },
    {
      enabled: !!organisationId,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
  const [shouldUpdateStaffApi, setShouldUpdateStaffApi] = useState(false);

  useEffect(() => {
    if (shouldUpdateStaffApi) {
      setTimeout(() => {
        setShouldUpdateStaffApi(false);
      }, 1000);
    }
  }, [shouldUpdateStaffApi]);

  const isThisTabAllowed = useCallback(
    (tab: AssetType) =>
      (organisation?.allowedFileTabs?.includes(tab) || showForAdmins) &&
      activeKey === tab,
    [activeKey, organisation?.allowedFileTabs, showForAdmins]
  );

  const [messageApi, contextHolder] = message.useMessage();

  const data = orderBy(
    assetsData
      ?.filter((asset) => (showForAdmins ? true : Boolean(!asset?.onlyAdmin)))
      .filter(Boolean),
    ["createdAt"],
    ["desc"]
  );

  const [mediaFilter, setMediaFilter] = useState("all"); // all, video, image

  const getFiles = (activeKey: AssetType) => {
    let temp_data = data;
    if (showAttachedOnly) {
      return temp_data.filter((asset) => asset.isAssigned);
    }

    if (mediaFilter === "all") {
      temp_data = data;
    } else if (mediaFilter === "video" || mediaFilter === "image") {
      temp_data = data.filter((asset) => {
        const isVideo = videoAccept
          .concat("video/mp4, video/quicktime")
          .includes(asset.mediaType ?? "none");
        return mediaFilter === "video"
          ? isVideo
          : mediaFilter === "image"
          ? !isVideo
          : false;
      });
    } else if (mediaFilter === "only_admin") {
      temp_data = data.filter((asset) => asset.onlyAdmin);
    } else {
      temp_data = data;
    }

    // move all isAttached to the start of the array
    const attached = temp_data.filter((asset) => asset.isAssigned);
    const notAttached = temp_data.filter((asset) => !asset.isAssigned);

    return [...attached, ...notAttached].filter(
      (asset) => asset.type === activeKey
    );
  };

  const { token } = theme.useToken();

  const onDeleteAsset = async (
    id: string,
    linkKey?: string,
    storeId?: string
  ) => {
    try {
      linkKey && linkKey?.length > 0 && deleteImageFromS3(linkKey);

      const asset = data.find((asset) => asset.id === id) as AssetsInterface;

      if (!asset) {
        messageApi.error("Something went wrong deleting assets");
        return;
      }

      await API.graphql(
        graphqlOperation(deleteAssets, {
          input: {
            id,
          },
        })
      );

      const isAttached =
        !isEmpty(asset.assignedToInfo?.pageID) &&
        !isEmpty(asset.assignedToInfo?.sectionID);

      if (isAttached) {
        const page = projectPages?.find(
          (page) => page.id === asset.assignedToInfo?.pageID
        );

        const updatedSections = page?.pageSections?.map((d) => {
          if (d === null) return null;
          if (d.id === asset.assignedToInfo?.sectionID) {
            const filtered = d?.media?.filter((d) => d !== id) ?? [];
            return {
              ...d,
              media: filtered,
            };
          }
          return d;
        });
        await API.graphql(
          graphqlOperation(updatePages, {
            input: {
              id: page?.id,
              pageSections: updatedSections,
            },
          })
        );
      }

      messageApi.success("Successfully deleted");

      if (storeId) {
        await API.graphql(
          graphqlOperation(deleteStore, {
            input: {
              id: storeId,
            },
          })
        );
      }

      refetch?.();
      refetchProjectPages();
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong deleting assets");
    }
  };

  const onEditAsset = async (id: string, store: Store) => {
    setNewAssetModal(true);
    const asset = data.find((asset) => asset.id === id) as AssetsInterface;
    if (!isEmpty(asset)) {
      setEditAssetData({
        ...asset,
        store: store,
      });
    }
  };

  const onRemoveGallery = async (id: string) => {
    try {
      const asset = data.find((asset) => asset.id === id) as AssetsInterface;

      const galleryId = asset.galleryId;

      if (!galleryId) {
        messageApi.error("Something went wrong. Could not find gallery");
        return;
      }

      const gallery = galleryData?.find((gallery) => gallery.id === galleryId);

      if (!gallery) {
        messageApi.error("Something went wrong. Could not find gallery");
        return;
      }

      const existingGalleryItems = (gallery.gallery ?? []).filter(
        Boolean
      ) as _GalleryItem[];

      const existingSeq = (gallery.sortSeq ?? []).filter(Boolean) as string[];

      const updatedGallery: _GalleryItem[] = existingGalleryItems.filter(
        (item) => item.id !== id
      );
      const updatedSeq = existingSeq.filter((seq) => seq !== id);

      const input: UpdateGalleryInput = {
        id: galleryId,
        gallery: updatedGallery,
        sortSeq: updatedSeq,
      };

      const input2: UpdateAssetsInput = {
        id: id,
        galleryId: null,
      };

      const response: any = await API.graphql(
        graphqlOperation(updateGallery, {
          input,
        })
      );

      const response2: any = await API.graphql(
        graphqlOperation(updateAssets, {
          input: input2,
        })
      );

      if (response?.data?.updateGallery && response2?.data?.updateAssets) {
        messageApi.success("Removed from gallery");
        refetchGalleryData();
        refetch();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong removing from gallery");
    }
  };

  const onRemoveAsset = async (
    id: string,
    pageId: string,
    sectionId: string
  ) => {
    try {
      const page = projectPages?.find((page) => page.id === pageId);

      const section = page?.pageSections?.find(
        (section) => section?.id === sectionId
      );

      const assetIds = section?.media ?? [];

      const updatedSections = page?.pageSections?.map((d) => {
        if (d === null) return null;
        if (d.id === sectionId) {
          return {
            ...d,
            media: uniq(assetIds.filter((d) => d !== id)).filter(Boolean),
          };
        }
        return d;
      });

      const input: UpdatePagesInput = {
        id: pageId,
        pageSections: updatedSections,
      };

      const response: any = await API.graphql(
        graphqlOperation(updatePages, {
          input,
        })
      );

      await API.graphql(
        graphqlOperation(updateAssets, {
          input: {
            id: id,
            isAssigned: false,
            assignedDate: new Date().toISOString(),
          },
        })
      );

      if (response?.data?.updatePages) {
        console.log("Removed media ");

        refetchProjectPages();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadBtn = (
    <Button
      type="primary"
      onClick={() => setNewAssetModal(true)}
      icon={<UploadOutlined />}
    >
      {activeKey === AssetType.TEAM
        ? "Add Staff"
        : activeKey === AssetType.STORE_INVENTORY
        ? "Add store item"
        : "Upload Files"}
    </Button>
  );

  const { data: users } = useQuery(
    `listUsers-${userData?.organizationID}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getUserNames, {
          limit: 1000,
        })
      );
      return response.data.listUsers.items as User[];
    }
  );

  const { data: galleryData, refetch: refetchGalleryData } = useQuery(
    `gallery-${projectId}`,
    () => fetchGallery(projectId!),
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  );

  const [galleryModal, setGalleryModal] = useState(false);

  const [tempAssetIdForGallery, setTempAssetIdForGallery] = useState("");

  const onAddToGallery = async (galleryId: string) => {
    try {
      // find link
      const asset = data.find((asset) => asset.id === tempAssetIdForGallery);
      if (!asset) {
        messageApi.error("Something went wrong. Could not find asset");
        return;
      }
      const link = asset?.link;
      if (!link) {
        messageApi.error("Something went wrong. Could not find link");
        return;
      }

      if (!galleryData) {
        messageApi.error("Something went wrong. Could not find gallery");
        return;
      }

      const gallery = galleryData.find((gallery) => gallery.id === galleryId);
      if (!gallery) {
        messageApi.error("Something went wrong. Could not find gallery");
        return;
      }

      const existingGalleryItems = (gallery.gallery ?? []).filter(
        Boolean
      ) as _GalleryItem[];
      const existingSeq = (gallery.sortSeq ?? []).filter(Boolean) as string[];

      const newId = uuidv4();

      const newGalleryItem: _GalleryItem = {
        id: newId,
        link: link,
        mediaType: asset.mediaType ?? "image/jpeg",
        note: asset.note ?? "",
      };

      const updatedGallery: _GalleryItem[] = [
        ...existingGalleryItems,
        newGalleryItem,
      ];
      const updatedSeq = [...existingSeq, newId];

      const input: UpdateGalleryInput = {
        id: galleryId,
        gallery: updatedGallery,
        sortSeq: updatedSeq,
      };
      const input2: UpdateAssetsInput = {
        id: tempAssetIdForGallery,

        galleryId: galleryId,
      };

      const response: any = await API.graphql(
        graphqlOperation(updateGallery, {
          input,
        })
      );
      const response2: any = await API.graphql(
        graphqlOperation(updateAssets, {
          input: input2,
        })
      );

      if (response?.data?.updateGallery && response2?.data?.updateAssets) {
        messageApi.success("Added to gallery");
        refetchGalleryData();
        refetch();
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong adding to gallery");
    } finally {
      setGalleryModal(false);
      setTempAssetIdForGallery("");
    }
  };

  const openGalleryModal = (assetId: string) => {
    setGalleryModal(true);
    setTempAssetIdForGallery(assetId);
  };

  const { projectFilesKey } = useS3Keys(projectId!, organisationId);

  const {
    sizeRemaingPercentage,
    usedSize,
    bucketSize,
    maxBucketSize,
    remainingSize,
    isBucketSizeLoading,
  } = useBucketSize(projectFilesKey, isRefetching);

  const [isSearching, setIsSearching] = useState(false);

  const showAttachedOnlyBtn = (
    <Checkbox
      checked={showAttachedOnly}
      onChange={(e) => {
        setMediaFilter("all");
        setIsSearching(false);
        setShowAttachedOnly(e.target.checked);
      }}
    >
      Show attached only
    </Checkbox>
  );

  const mediaFilterBtn = (
    <Select
      style={{ width: 120 }}
      defaultValue="all"
      value={mediaFilter}
      onChange={(v) => {
        setMediaFilter(v);
        setIsSearching(false);
        setShowAttachedOnly(false);
      }}
      options={
        [
          { label: "All", value: "all" },
          { label: "Video", value: "video" },
          { label: "Image", value: "image" },
          // @ts-ignore
          (isAdmin || isZoiq) && { label: "Only Admin", value: "only_admin" },
        ].filter(Boolean) as any[]
      }
    />
  );

  const commonProps = {
    onEditAsset,
    onDeleteAsset,
    refetch,
    users,
    onRemoveAsset,
    showAttachedOnlyBtn,
    mediaFilterBtn,
    isSearching,
    setIsSearching,
    onAddToGallery: openGalleryModal,
    onRemoveGallery,
  };

  const onSuccess = async () => {
    onCancel();
    refetch();
    refetchProjectPages();

    if (activeKey === AssetType.TEAM) {
      setShouldUpdateStaffApi(true);
    }
  };

  const onCancel = () => {
    setNewAssetModal(false);
    setEditAssetData({} as AssetWithStore);
  };

  const [showAttachTable, setShowAttachTable] = useState(false);

  const renderItems = () => {
    switch (true) {
      case isThisTabAllowed(AssetType.STOCK):
        return (
          <MediaAssets
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.STOCK)}
            isRefetching={isRefetching}
            isFetched={isFetched}
          />
        );

      case isThisTabAllowed(AssetType.DOCUMENTS):
        return (
          <DocumentAssets
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.DOCUMENTS)}
          />
        );

      case isThisTabAllowed(AssetType.TEAM):
        return (
          <StaffAssets
            staffSortSeq={organisation?.staffSortSeq as string[]}
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.TEAM)}
            projectId={projectId!}
            shouldUpload={shouldUpdateStaffApi}
          />
        );
      case isThisTabAllowed(AssetType.ORGANIZATION):
        return (
          <BrandingAssets
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.ORGANIZATION)}
          />
        );

      case isThisTabAllowed(AssetType.PARTNERS_STOCK):
        return (
          <BrandingAssets
            activeKey={activeKey}
            partnersLogoSortSeq={organisation?.partnersLogoSortSeq as string[]}
            organisationId={organisationId}
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.PARTNERS_STOCK)}
          />
        );
      case isThisTabAllowed(AssetType.VECTORS):
        return (
          <VectorAssets
            {...commonProps}
            // @ts-ignore
            assets={getFiles(AssetType.VECTORS)}
          />
        );

      case isThisTabAllowed(AssetType.STORE_INVENTORY):
        return (
          <StoreInventory
            // @ts-ignore
            assets={getFiles(AssetType.STORE_INVENTORY)}
            isAssetsRefetching={isRefetching}
            storeInventorySortSeq={
              organisation?.storeInventorySortSeq as string[]
            }
            projectId={projectId!}
            {...commonProps}
          />
        );
    }
  };

  if (isAdmin && organisationId.length > 0 && organisationId === "all") {
    return (
      <Empty
        style={{
          height: "100vh",
        }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          "You have selected all organizations. Please select an organization to view style guide. "
        }
      />
    );
  }

  return (
    <SectionLayout
      isLoading={isLoading}
      useContainer={false}
      innerStyle={{
        minHeight: "100vh",
      }}
    >
      {contextHolder}

      {showAttachTable && projectPages && (
        <AttachMediaTable
          projectId={projectId!}
          assets={data}
          pages={projectPages}
          open={showAttachTable}
          onCancel={() => setShowAttachTable(false)}
        />
      )}

      {galleryModal && (
        <Modal
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          title="Gallery"
          open={galleryModal}
          width={500}
          onCancel={() => {
            setGalleryModal(false);
            setTempAssetIdForGallery("");
          }}
        >
          <List
            dataSource={galleryData ?? []}
            renderItem={(item: Gallery) => {
              return (
                <List.Item
                  extra={
                    <Button
                      onClick={() => {
                        onAddToGallery(item.id);
                      }}
                    >
                      Add to this
                    </Button>
                  }
                >
                  <List.Item.Meta
                    title={item.title}
                    description={`${item.gallery?.length} item(s)`}
                    avatar={
                      <Avatar
                        shape="square"
                        src={getImageFromS3(item.coverImage)}
                        size={64}
                      />
                    }
                  />
                </List.Item>
              );
            }}
          />
        </Modal>
      )}

      {newAssetModal && (
        <Modal
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          title={isEmpty(editAssetData) ? "Upload Files" : "Edit file"}
          open={newAssetModal}
          width={activeKey === AssetType.TEAM ? 1000 : 600}
          onCancel={onCancel}
        >
          <NewAsset
            projectId={projectId!}
            onCancel={onCancel}
            remainingSize={remainingSize}
            editAssetData={editAssetData}
            organisationId={organisationId!}
            onSuccess={onSuccess}
            activeKey={activeKey}
          />
        </Modal>
      )}
      <Space className="section-header">
        <TitleWithCount
          level={3}
          title={
            ASSET_TYPE_OPTIONS.find((option) => option.value === activeKey)
              ?.label ?? ""
          }
          count={getFiles(activeKey).length}
        />

        <Flex gap={"middle"} align="center">
          {!isBucketSizeLoading && sizeRemaingPercentage !== null && (
            <Popover
              placement="bottomRight"
              content={
                <>
                  <Progress
                    type="circle"
                    size={200}
                    trailColor={token.colorPrimaryBg}
                    strokeColor={token.colorPrimary}
                    percent={sizeRemaingPercentage}
                    format={(p) => (
                      <>
                        <Typography.Text>{p?.toFixed(2)}%</Typography.Text>
                        <br />
                        <Typography.Text
                          type="secondary"
                          style={{ marginTop: 8 }}
                        >
                          Total: {getStorageSize(bucketSize)}
                        </Typography.Text>
                      </>
                    )}
                  />
                </>
              }
            >
              <Progress
                type="line"
                trailColor={token.colorPrimaryBg}
                style={{
                  minWidth: 300,
                  maxWidth: 300,
                  marginRight: 100,
                }}
                strokeColor={token.colorPrimary}
                percent={sizeRemaingPercentage}
                status={sizeRemaingPercentage > 95 ? "exception" : "normal"}
                format={() =>
                  `${getStorageSize(usedSize!)}/${getStorageSize(
                    maxBucketSize
                  )}`
                }
              />
            </Popover>
          )}
          <Tooltip title={"Show attached media table"}>
            <Button
              icon={<CiViewTable />}
              shape="circle"
              type="dashed"
              onClick={() => {
                setShowAttachTable(!showAttachTable);
              }}
            />
          </Tooltip>

          {uploadBtn}
        </Flex>
      </Space>

      {isLoading || !isFetched ? (
        <Loading useAppContainer={false} />
      ) : (
        <motion.div
          key={activeKey}
          initial={{ opacity: 0, y: 50, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          transition={{
            delay: 0.2,
            duration: 0.5,
            ease: cubicBezier(0.075, 0.82, 0.165, 1),
          }}
        >
          {renderItems()}
        </motion.div>
      )}
    </SectionLayout>
  );
};

export default Assets;
