import { createContext, useContext, useEffect, useState } from "react";

// create a context with loading state
const LoadingContext = createContext<{
  globalLoading: boolean;
  getLoadingClassName: () => string;
  setGlobalLoading: (value: boolean) => void;
}>({
  globalLoading: false,
  setGlobalLoading: () => {},
  getLoadingClassName: () => "",
});

export const useGlobalLoading = () => useContext(LoadingContext);

const GlobalLoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  const getLoadingClassName = () => {
    if (globalLoading) {
      return " gradient-border ";
    }
    return "";
  };

  useEffect(() => {
    const el = document.querySelector(".ant-modal");
    if (el) {
      if (globalLoading) {
        el?.classList.add("gradient-border");
      } else {
        el?.classList.remove("gradient-border");
      }
    }
  }, [globalLoading]);

  return (
    <LoadingContext.Provider
      value={{ getLoadingClassName, globalLoading, setGlobalLoading }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export default GlobalLoadingProvider;
