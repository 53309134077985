import { AssetType, Project } from "@/API";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import NewAsset from "../RareComponents/NewAsset";

const InsideAssetModal = ({
  activeKey,
  project,
  onSuccess,
  newAssetModal,
  setNewAssetModal,
  editAssetData,
  onCancel,
  remainingSize,
}: {
  activeKey: AssetType;
  project: Project;
  remainingSize: number;
  editAssetData?: any;
  onSuccess: () => void;
  onCancel: () => void;
  newAssetModal: boolean;
  setNewAssetModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const orgId = project.organizationID!;

  const { organisationId } = useParams<{ organisationId: string }>();

  return (
    <>
      <Button
        type="primary"
        onClick={() => setNewAssetModal(true)}
        icon={<UploadOutlined />}
      >
        Upload Files
      </Button>

      {newAssetModal && (
        <Modal
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          title={isEmpty(editAssetData) ? "Edit File" : "Upload Files"}
          open={newAssetModal}
          width={activeKey === AssetType.TEAM ? 1000 : 600}
          onCancel={onCancel}
        >
          <NewAsset
            projectId={project.id}
            onCancel={onCancel}
            remainingSize={remainingSize}
            editAssetData={editAssetData}
            organisationId={organisationId!}
            onSuccess={() => {
              onCancel();
              onSuccess?.();
            }}
            activeKey={activeKey}
          />
        </Modal>
      )}
    </>
  );
};

export default InsideAssetModal;
