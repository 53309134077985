import { useEffect, useState } from "react";

const useVideo = (ref: React.ForwardedRef<HTMLVideoElement>) => {
  const [isEnded, setIsEnded] = useState(false);

  const playVideo = () => {
    setIsPlaying(true);
    setIsPaused(false);
    // @ts-ignore

    if (ref?.current) {
      // @ts-ignore
      ref?.current?.play();
    }
  };
  const pauseVideo = () => {
    setIsPlaying(false);
    setIsPaused(true);
    // @ts-ignore

    if (ref?.current) {
      // @ts-ignore
      ref?.current?.pause();
    }
  };
  // @ts-ignore
  const [isPlaying, setIsPlaying] = useState(!ref?.current?.paused);

  const [isPaused, setIsPaused] = useState(true);

  const restartVideo = () => {
    // Use the "current" property to get a reference to the video element
    // and call the "load" method to force it to reload.
    // @ts-ignore
    if (ref.current) {
      // @ts-ignore
      ref.current.load();

      playVideo();
    }
  };
  const fullScreenVideo = () => {
    // @ts-ignore
    const elem = ref.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    // @ts-ignore
    ref.current?.addEventListener("ended", () => {
      setIsEnded(true);
    });
  }, []);

  // get duration
  // @ts-ignore
  const duration = ref.current?.duration;

  // update time state after each second if video is playing
  useEffect(() => {
    // @ts-ignore
    const interval = setInterval(() => {
      // @ts-ignore
      if (ref.current?.paused) {
        return;
      }
      // @ts-ignore
      setCurrentTime(ref.current?.currentTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    playVideo,
    currentTime,
    pauseVideo,
    restartVideo,
    isPaused,
    isEnded,
    isPlaying,
    fullScreenVideo,
    duration,
  };
};

export default useVideo;
