// create a react hook. which fetches data from the api and returns it to the component using react-query

import { API, graphqlOperation } from "aws-amplify";
import { useQuery } from "react-query";

import { ProjectRole, User } from "@/API";

import { getUser } from "@/customGraphql/customQueries";
import useAuth from "@/hooks/useAuth";

interface UseUserDataResult {
  data:
    | Omit<User, "__typename" | "_deleted" | "_version" | "_lastChangedAt">
    | undefined;
  error: any;
  isUserLoading: boolean;
  isLoggedIn: boolean;
  authID: string | null;
  email: string | null;
  isAdmin: boolean;
  refetchUser: () => void;
  isRefetching: boolean;
  adminAccessTo: string[];
  isZoiq: boolean;
  isAuthLoading: boolean;
  isDecisionMaker: (projectID: string) => boolean;
  canAddProjects: boolean;
}

const useUserData = (): UseUserDataResult => {
  const { isLoggedIn, authID, email, isLoading: isAuthLoading } = useAuth();

  const { data, error, isLoading, isFetched, refetch, isRefetching } = useQuery(
    "user",
    async () => {
      if (!isLoggedIn || !authID || !email) {
        return undefined;
      }
      const res: any = await API.graphql(
        graphqlOperation(getUser, { authID, email })
      );
      return res.data.getUser as UseUserDataResult["data"];
    },
    {
      enabled: Boolean(
        isLoggedIn && authID && authID?.length > 0 && email && email?.length > 0
      ),
    }
  );

  const isAdmin =
    isLoggedIn &&
    !isLoading &&
    isFetched &&
    Boolean(data) &&
    Boolean(data?.isAdmin);

  const isZoiq = Boolean(data?.isZoiq);

  const canAddProjects = Boolean(data?.canAddProjects);

  const userOtherProjects = data?.otherProjects || [];
  const adminAccessTo = (data?.adminAccessTo ?? [])?.filter(
    Boolean
  ) as string[];

  const isDecisionMaker = (projectID: string) =>
    userOtherProjects.find(
      (project) => project && project.projectID === projectID
    )?.projectRole === ProjectRole.DECISION_MAKER;

  return {
    data,
    error,
    isUserLoading: isLoading,

    isRefetching,
    adminAccessTo,
    isLoggedIn,
    isDecisionMaker,
    authID,
    email,
    canAddProjects,
    isAdmin,
    refetchUser: refetch,
    isZoiq,
    isAuthLoading: Boolean(isAuthLoading),
  };
};

export default useUserData;
