import colors from "@/constants/colors";
import { dataNodes } from "@/constants/home.constants";
import { zoomIn } from "@/utils/motions";
import G6 from "@antv/g6";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { findDOMNode } from "react-dom";

interface HeroGraphProps {
  node?: {
    stroke?: string;
    fill?: string;
  };
}

const HeroGraph = ({ node }: HeroGraphProps) => {
  const ref = React.useRef<HTMLElement>(null);
  const graph = React.useRef<any>(null);

  const nodes = dataNodes.nodes;
  const [currentLayout, setCurrentLayout] = useState("circular");

  const updateLayout = (layout: string) => {
    if (!graph.current || graph.current.get("destroyed")) return;

    const layoutObject: any = {
      type: layout,
    };

    if (layout === "circular") {
      layoutObject["radius"] = 200;
    } else if (layout === "force") {
      layoutObject["nodeSize"] = 20;
    } else if (layout === "radial") {
      layoutObject["nodeSize"] = 15;
    } else if (layout === "concentric") {
      layoutObject["minNodeSpacing"] = 30;
    } else if (layout === "mds") {
      layoutObject["linkDistance"] = 100;
    }

    // if layout is not circular. prevent overlap
    if (layout !== "circular") {
      layoutObject["preventOverlap"] = true;
    }
    graph.current.updateLayout(layoutObject);
    // get next layout
    const nextLayout =
      layout === "circular"
        ? "force"
        : layout === "force"
        ? "radial"
        : layout === "radial"
        ? "concentric"
        : layout === "concentric"
        ? "mds"
        : "circular";
    setCurrentLayout(nextLayout);
  };

  // update layout on currentLayout change after initial render

  useEffect(() => {
    setTimeout(() => {
      updateLayout(currentLayout);
    }, 2000);
  }, [currentLayout]);

  useEffect(() => {
    const container = findDOMNode(ref.current) as HTMLElement;
    if (!container) return;
    const width = container.scrollWidth;
    const height = (container.scrollHeight || 500) - 20;

    if (!graph.current) {
      graph.current = new G6.Graph({
        container: container,
        width,
        height,

        layout: {
          type: "circular",
          animate: true,
          preventOverlap: true,
          radius: 200,
        },

        modes: {
          default: ["drag-node"],
        },
        defaultEdge: {
          style: {
            stroke: colors.darkVariant,
            lineWidth: 1,
          },
        },
        defaultNode: {
          size: 25,
          style: {
            fill: node?.fill ?? colors.darkVariant,
            stroke: node?.stroke ?? colors.primaryDark,
          },
        },
        animate: true,
      });

      graph.current.data({ ...dataNodes, nodes });
      graph.current.render();

      setTimeout(() => {
        setCurrentLayout("force");
      }, 2000);

      document.addEventListener(
        "resize",
        () => {
          if (!graph.current || graph.current.get("destroyed")) return;
          if (!container || !container.scrollWidth || !container.scrollHeight)
            return;
          graph.current.changeSize(
            container.scrollWidth,
            container.scrollHeight - 20
          );
        },
        { passive: true }
      );
    }
  }, []);

  return <motion.div variants={zoomIn(0.5, 0.7)} ref={ref as any}></motion.div>;
};

export default HeroGraph;
