import { useTaskContext } from "@/contexts/TaskContexts";
import { Modal } from "antd";

const DeleteSubtaskPopup = () => {
  const { subTaskActions, modules } = useTaskContext();
  const handleDelete = () => {
    subTaskActions.handleDelete(modules.deleteSubtaskPopup!);
    modules.setDeleteSubtaskPopup(null);
  };
  return (
    <Modal
      title="Delete Subtask"
      open
      okButtonProps={{ danger: true }}
      onOk={handleDelete}
      destroyOnClose
      onCancel={() => {
        modules.setDeleteSubtaskPopup(null);
      }}
    >
      <p>Are you sure you want to delete this sub-task?</p>
    </Modal>
  );
};

export default DeleteSubtaskPopup;
