import { AssetType, Assets, SubAssetType } from "@/API";
import { AssetsProps } from "@/interface/Settings";
import { getImageFromS3, sortBySequence } from "@/utils";
import { updateSortSeqMutationOrg } from "@/utils/graphql";
import { SortAscendingOutlined } from "@ant-design/icons";
import { Flex } from "@aws-amplify/ui-react";
import { UniqueIdentifier } from "@dnd-kit/core";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Modal,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";

import SortTable from "./common/SortComponents/SortTable";
import { ActionDropDown, AdminOnlyTag, assetCols } from "./sections/Assets";
import AttachedComponentWrapper from "./ProjectFileComponents/AttachedComponentWrapper";

const BrandingAssets = ({
  assets,
  onEditAsset,
  onDeleteAsset,
  selectedOrgId,
  activeKey,
  partnersLogoSortSeq,
  showAttachedOnlyBtn,
}: AssetsProps & {
  selectedOrgId: string;
  activeKey: AssetType;
  partnersLogoSortSeq: string[];
}) => {
  const isPartners = activeKey === AssetType.PARTNERS_STOCK;
  const [localAssets, setLocalAssets] = useState<AssetsProps["assets"]>([]);

  useEffect(() => {
    if (isPartners) {
      const sorted =
        partnersLogoSortSeq?.length > 0
          ? sortBySequence(assets, partnersLogoSortSeq)
          : assets;
      setLocalAssets(sorted);
    } else {
      setLocalAssets(assets);
    }
  }, [assets, isPartners]);

  const [sortModal, setSortModal] = useState(false);
  const { token } = theme.useToken();

  // split the assets into 3 columns
  const columns = localAssets.reduce((acc: any[], asset, index) => {
    const colIndex = index % 3;
    if (!acc[colIndex]) {
      acc[colIndex] = [];
    }
    acc[colIndex].push(asset);
    return acc;
  }, []) as AssetsProps["assets"][];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <>
      {isPartners && (
        <Modal
          open={sortModal}
          width={1000}
          title="Sort Partners Logo"
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          onCancel={() => setSortModal(false)}
        >
          <SortTable
            onDragEndSuccess={(updated) => {
              const newSeq: string[] = updated.map(
                (item: { id: UniqueIdentifier }) => item.id
              );
              setLocalAssets(updated);
              updateSortSeqMutationOrg(
                selectedOrgId,
                newSeq,
                "partnersLogoSortSeq"
              );
            }}
            dataSource={localAssets as any[]}
            columns={[
              {
                title: "Image",
                dataIndex: "asset.link",
                key: "link",
                render: (_: string, data: Assets) => (
                  <Image
                    height={100}
                    width={200}
                    src={getImageFromS3(data.link)}
                    alt={data.note ?? "No Name"}
                    loading="lazy"
                  />
                ),
              },
              {
                title: "Name",
                dataIndex: "asset.name",
                key: "name",
                render: (_: string, data: Assets) => (
                  <span>{data?.note ?? "-"}</span>
                ),
              },
            ]}
          />
        </Modal>
      )}
      <Space className="w-full">
        {isPartners && (
          <Button
            onClick={() => setSortModal(true)}
            type="dashed"
            icon={<SortAscendingOutlined />}
          >
            Sorting
          </Button>
        )}

        {showAttachedOnlyBtn !== null && showAttachedOnlyBtn !== undefined && (
          <>{showAttachedOnlyBtn}</>
        )}
      </Space>
      {localAssets.length === 0 ? (
        <Empty
          description={isPartners ? "No Partners Logo" : "No Branding Assets"}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          style={{
            marginTop: "1rem",
          }}
          gutter={[16, 16]}
        >
          {columns.map((assets, idx) => {
            if (!assets) return null;
            return (
              <Col key={idx} {...assetCols}>
                {assets.map((asset) => {
                  if (!asset.link) return null;

                  const isMobile = asset.subType === SubAssetType.MOBILE;
                  const isDesktop = asset.subType === SubAssetType.DESKTOP;
                  return (
                    <Col style={{ marginBottom: 32 }} key={asset.id} span={24}>
                      <AttachedComponentWrapper isAttached={asset.isAttached}>
                        <Card>
                          <Image
                            src={getImageFromS3(asset.link)}
                            alt={asset.note ?? "No Name"}
                            loading="lazy"
                            key={asset.id}
                          />

                          <Flex
                            display={"flex"}
                            marginTop={"1rem"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography.Title level={5}>
                              {asset.note}
                              <AdminOnlyTag onlyAdmin={asset.onlyAdmin} />
                            </Typography.Title>
                            <Space>
                              {!isPartners && (isDesktop || isMobile) && (
                                <Tooltip
                                  title={
                                    isMobile ? "For Mobile" : "For Desktop"
                                  }
                                >
                                  <Tag
                                    color={isMobile ? "lime" : "purple"}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {isMobile ? "Mobile" : "Desktop"}
                                  </Tag>
                                </Tooltip>
                              )}
                              <ActionDropDown
                                asset={asset}
                                onDeleteAsset={onDeleteAsset}
                                onEditAsset={onEditAsset}
                                dropdownRender={(menu) => (
                                  <div style={contentStyle}>
                                    {React.cloneElement(
                                      menu as React.ReactElement,
                                      {
                                        style: menuStyle,
                                      }
                                    )}
                                    <Divider style={{ margin: 0 }} />

                                    <div className="dropdown-footer">
                                      <Typography.Text>
                                        File type: {asset.mediaType}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                )}
                              />
                            </Space>
                          </Flex>
                        </Card>
                      </AttachedComponentWrapper>
                    </Col>
                  );
                })}
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default BrandingAssets;
