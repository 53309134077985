import Breadcrumbs, {
  BreadcrumbContextType,
} from "@/components/common/Breadcrumbs";
import Loading from "@/components/common/Loading";
import { staggerContainer } from "@/utils/motions";
import { Divider } from "antd";

import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Section = styled(motion.section)<SectionLayoutProps>`
  position: relative;
  z-index: 10;
  ${({ center }) =>
    center &&
    `
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  `}
`;

interface SectionLayoutProps extends HTMLMotionProps<"section"> {
  children: React.ReactNode;
  backgroundElement?: React.ReactNode;
  /* default true */
  useContainer?: boolean;
  divider?: boolean;
  center?: boolean;
  innerStyle?: React.CSSProperties;
  breadcrumbConfig?: {
    breadcrumbs: BreadcrumbContextType["breadcrumbs"];
  };
  emptyConfig?: {
    hideOn: boolean;
    component: React.ReactNode;
  };
  isLoading?: boolean;
}

const SectionLayout = ({
  children,
  backgroundElement,
  divider = true,
  useContainer = true,
  center,
  innerStyle,
  viewport,
  breadcrumbConfig,
  emptyConfig,
  isLoading = false,
  ...rest
}: SectionLayoutProps) => {
  const { breadcrumbs = [] } = breadcrumbConfig ?? {};
  const { hideOn, component } = emptyConfig ?? {};

  if (isLoading) {
    return <Loading />;
  }

  if (hideOn && !isLoading) {
    return component as any;
  }

  return (
    <motion.div
    // viewport={{ once: true, amount: 0.3 }}
    // whileInView={{ scale: 1, opacity: 1 }}
    // initial={{ scale: 0.8, opacity: 0 }} // zoom in with slide
    // transition={{ duration: 0.5 }}
    // className=""
    >
      <Section
        variants={staggerContainer as any}
        initial="hidden"
        viewport={viewport ?? { once: true, amount: 0.5 }}
        whileInView="show"
        center={center}
        {...rest}
      >
        {backgroundElement}
        <div
          style={{ ...innerStyle }}
          className={useContainer ? "container" : ""}
        >
          {breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          )}
          {children}
        </div>
        {divider && <Divider />}
      </Section>
    </motion.div>
  );
};

export default SectionLayout;
