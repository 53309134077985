import { Gallery, UpdateGalleryInput } from "@/API";
import { deleteGallery, updateGallery } from "@/graphql/mutations";

import {
  deleteImageFromS3,
  formatDate,
  getImageFromS3,
  sortBySequence,
} from "@/utils";
import { download, updateSortSeqMutationOrg } from "@/utils/graphql";
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  SortAscendingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Flex,
  Image,
  MenuProps,
  Modal,
  Popconfirm,
  Row,
  Typography,
  message,
  theme,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
// import NewGallery from "../RareComponents/NewGallery";
import { listGalleries } from "@/customGraphql/customQueries";
import useBucketSize from "@/hooks/useBucketSize";
import useUserData from "@/hooks/useData";
import useS3Keys from "@/hooks/useS3Keys";
import { UniqueIdentifier } from "@dnd-kit/core";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdOutlinePublish } from "react-icons/md";
import Container from "../Container/Container";
import GalleryModal from "../RareComponents/TaskComponents/GalleryModal";
import BucketSizeBar from "../common/BucketSizeBar";
import Loading from "../common/Loading";
import SortTable from "../common/SortComponents/SortTable";
import GalleryView, { uploadGalleryToS3 } from "./GalleryView";

const SortModal = ({
  selectedOrgId,
  refetch,
  open,
  setOpen,
  data,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: Gallery[];
  selectedOrgId: string;
  refetch: () => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <Modal
      open={open}
      width={1000}
      title="Sort Gallery Cards"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={() => setOpen(false)}
    >
      {contextHolder}
      <SortTable
        onDragEndSuccess={async (updated) => {
          messageApi.loading("Updating...");
          const newSeq: string[] = updated.map(
            (item: { id: UniqueIdentifier }) => item.id
          );
          await updateSortSeqMutationOrg(
            selectedOrgId,
            newSeq,
            "gallerySortSeq"
          );
          refetch();
          messageApi.success("Updated successfully");
        }}
        dataSource={data as any[]}
        columns={[
          {
            title: "Cover",
            dataIndex: "coverImage",
            key: "coverImage",
            width: 100,
            render: (url: string) => (
              <Avatar
                shape="square"
                size={64}
                src={getImageFromS3(url, true)}
              />
            ),
          },
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (_: string, data: Gallery) => (
              <span>{data?.title ?? "-"}</span>
            ),
          },
          {
            title: "Items",
            dataIndex: "gallery",
            key: "gallery",
            render: (_: string, data: Gallery) => (
              <span>{data?.gallery?.length ?? 0} items</span>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export const fetchGallery = async (projectId: string) => {
  const response: any = await API.graphql(
    graphqlOperation(listGalleries, {
      filter: {
        // organizationID: {
        //   eq: organizationID,
        // },
        projectId: {
          eq: projectId,
        },
      },
    })
  );

  return response.data.listGalleries.items as Gallery[];
};

const ActionBtn = ({
  asset,
  refetch,
  onEditAsset,
  onSuccess = () => {},
  onPublish,
  afterDelete,
}: {
  refetch: () => void;
  afterDelete: (id: string) => void;
  onSuccess?: (galleryID: string) => void;
  onPublish: (galleryID: string) => void;
  asset: Gallery;
  onEditAsset: (id: string) => void;
}) => {
  const onDownloadAsset = (key: string, name: string) => {
    messageApi.loading("Downloading...");
    download(key, name, () => {
      messageApi.success("Downloaded successfully");
    });
  };

  const onDeleteAsset = async (id: string, link: string) => {
    try {
      messageApi.loading("Deleting...");
      link && link?.length > 0 && deleteImageFromS3(link);
      id.length > 0 &&
        (await API.graphql(
          graphqlOperation(deleteGallery, {
            input: {
              id,
            },
          })
        ));

      afterDelete(id);

      // also delete all the gallery items

      asset.gallery?.forEach(async (item) => {
        item && deleteImageFromS3(item.link);
      });

      messageApi.success("Successfully deleted");

      refetch();

      onSuccess(asset.id);
    } catch (error) {
      messageApi.error("Error deleting");
      console.error(error);
    }
  };

  const onCopyAsset = (link: string) => {
    navigator.clipboard.writeText(getImageFromS3(link));
    messageApi.success("Copied to clipboard");
  };

  const { isAdmin, isZoiq } = useUserData();

  const items: MenuProps["items"] = [
    {
      label: "Download Cover",
      onClick: () => onDownloadAsset(asset.coverImage, asset.title!),
      icon: <DownloadOutlined />,
      key: "download",
    },
    {
      label: "Publish",
      onClick: () => onPublish(asset.id),
      icon: <MdOutlinePublish />,
      key: "publish",
    },

    {
      label: "Edit",
      icon: <EditOutlined />,
      key: "edit",
      onClick: () => {
        onEditAsset(asset.id);
      },
    },
    {
      label: (
        <Popconfirm
          title="Are you sure to delete this asset?"
          onConfirm={() => onDeleteAsset(asset.id, asset.coverImage)}
        >
          Delete
        </Popconfirm>
      ),
      danger: true,
      icon: <DeleteOutlined />,

      key: "delete",
    },

    (isAdmin || isZoiq) && {
      label: "Copy URL",
      onClick: () => {
        onCopyAsset(asset.coverImage);
      },
      icon: <CopyOutlined />,
      key: "copy",
    },
  ].filter(Boolean) as MenuProps["items"];

  const [messageApi, contextHolder] = message.useMessage();
  const { token } = theme.useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <>
      {contextHolder}

      <Dropdown
        dropdownRender={(menu) => (
          <div style={contentStyle}>
            {React.cloneElement(menu as React.ReactElement, {
              style: menuStyle,
            })}
            <Divider style={{ margin: 0 }} />

            {asset.publishDate && (
              <div className="dropdown-footer">
                <Typography.Text>
                  Publish Date: {formatDate(asset.publishDate)}
                </Typography.Text>
              </div>
            )}
          </div>
        )}
        menu={{ items }}
      >
        <Button
          shape="circle"
          type="text"
          icon={<BiDotsVerticalRounded />}
          size="small"
        />
      </Dropdown>
    </>
  );
};

const GallerySection = ({
  selectedOrgId,
  sequence = [],
  refetchOrg,
  projectId,
}: {
  sequence: string[];
  selectedOrgId: string;
  refetchOrg: () => void;
  projectId: string;
}) => {
  const {
    data = [],
    isFetched,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(`gallery-${projectId}`, () => fetchGallery(projectId), {
    refetchOnWindowFocus: false,
    enabled: !!projectId,
    async onSuccess(data) {
      const sorted = sortBySequence(data, sequence);
      setLocalGallery(sorted);
    },
  });

  const [localGallery, setLocalGallery] = useState<Gallery[]>([]);
  const [showingInnerItems, setShowingInnerItems] = useState<Gallery>(
    {} as Gallery
  );

  const [newGalleryModal, setNewGalleryModal] = useState(false);

  const [editSourceData, setEditSourceData] = useState({} as Gallery);
  const [messageApi, contextHolder] = message.useMessage();

  const onEditGallery = (id: string) => {
    setNewGalleryModal(true);
    const __data = data.find((asset) => asset.id === id);
    if (__data) {
      setEditSourceData(__data);
    } else {
      messageApi.error("Error fetching data");
    }
  };

  const onViewGallery = (id: string) => {
    const __data = data.find((asset) => asset.id === id);
    if (__data) {
      setShowingInnerItems(__data);
      setEditSourceData(__data);
    } else {
      messageApi.error("Error fetching data");
    }
  };

  useEffect(() => {
    if (!isLoading && isFetched && data.length > 0) {
      if (sequence.length === 0) return;
      const sorted = sortBySequence(data, sequence);
      setLocalGallery(sorted);
    }
  }, [sequence, isLoading, isFetched]);

  const [sortModal, setSortModal] = useState(false);

  const { galleryFilesKey } = useS3Keys(projectId, selectedOrgId);

  const { sizeRemaingPercentage, remainingSize, usedSize, maxBucketSize } =
    useBucketSize(galleryFilesKey, isRefetching);

  const publish = async (galleryId: string) => {
    refetch().then(async ({ data }) => {
      const publishDate = new Date().toISOString();

      const noLinksFiles: Gallery["gallery"] = [];

      const updateLocalGallery = data
        ?.map((item) => {
          if (item.id === galleryId) {
            if (isEmpty(item.coverImage)) return null;

            const updatedGallery = item.gallery
              ?.filter((item) => {
                if (item && !isEmpty(item.link)) {
                  return true;
                }
                noLinksFiles.push(item);
              })
              .filter(Boolean);

            return {
              ...item,
              gallery: updatedGallery,
              publishDate: publishDate,
            };
          }

          const updatedGallery = item.gallery
            ?.filter((item) => {
              if (item && !isEmpty(item.link)) {
                return true;
              }
              noLinksFiles.push(item);
            })
            .filter(Boolean);
          return {
            ...item,
            gallery: updatedGallery,
          };
        })
        .filter(Boolean) as Gallery[];

      setLocalGallery(updateLocalGallery);
      const input: UpdateGalleryInput = {
        id: galleryId,
        publishDate: publishDate,
      };
      await API.graphql(
        graphqlOperation(updateGallery, {
          input,
        })
      );

      if (noLinksFiles.length > 0) {
        console.warn("Some files are not uploaded to S3, please try again");
        noLinksFiles.forEach((item) => {
          console.log(item?.note);
        });
      }

      const galleryItems = updateLocalGallery?.filter((item) =>
        dayjs(item.publishDate).isValid()
      );

      uploadGalleryToS3(galleryItems, projectId).then(async () => {
        messageApi.success("Gallery uploaded to S3");
      });
    });
  };

  const afterDelete = (id: string) => {
    try {
      const updateLocalGallery = localGallery.filter((item) => item.id !== id);
      const galleryItems = updateLocalGallery?.filter((item) =>
        dayjs(item.publishDate).isValid()
      );

      uploadGalleryToS3(galleryItems, projectId).then(async () => {
        messageApi.success("Gallery uploaded to S3");
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="">
      {contextHolder}

      {newGalleryModal && (
        <GalleryModal
          remainingSize={remainingSize}
          projectId={projectId}
          selectedOrdId={selectedOrgId}
          editGalleryData={editSourceData}
          refetch={() => {
            refetch().then(({ data }) => {
              if (!data) return;

              const updatedItem = data.find(
                (item) => item.id === editSourceData.id
              ) as Gallery;

              setShowingInnerItems(updatedItem);
              setEditSourceData(updatedItem);
            });
          }}
          setOpen={setNewGalleryModal}
          open={newGalleryModal}
          onSuccess={publish}
        />
      )}

      {sortModal && (
        <SortModal
          selectedOrgId={selectedOrgId}
          refetch={() => {
            refetchOrg();
            refetch();
          }}
          open={sortModal}
          setOpen={setSortModal}
          data={localGallery}
        />
      )}

      <Flex
        justify="space-between"
        style={{
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <Button
          onClick={() => setSortModal(true)}
          type="dashed"
          icon={<SortAscendingOutlined />}
        >
          Sorting
        </Button>

        <Flex gap={"middle"}>
          <BucketSizeBar
            usedSize={usedSize}
            maxBucketSize={maxBucketSize}
            sizeRemaingPercentage={sizeRemaingPercentage}
          />

          <Button
            type="primary"
            onClick={() => setNewGalleryModal(true)}
            icon={<UploadOutlined />}
          >
            Add new gallery
          </Button>
        </Flex>
      </Flex>

      <Container size="xl">
        {isLoading || !isFetched ? (
          <Loading useAppContainer={false} />
        ) : data.length === 0 ? (
          <Empty
            description="No gallery items"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : isEmpty(showingInnerItems) ? (
          <Row
            style={{
              marginTop: "1rem",
            }}
            gutter={[16, 16]}
          >
            {localGallery.map((asset) => {
              return (
                <Col key={asset.id} span={8} className="gallery-image">
                  <Image
                    preview={false}
                    onClick={() => onViewGallery(asset.id)}
                    rootClassName=""
                    src={getImageFromS3(asset.coverImage, true)}
                  />

                  <div className="gallery-item-details">
                    <Flex justify="space-between">
                      <Typography.Title level={4}>
                        {asset.title}
                      </Typography.Title>

                      <Flex>
                        <Typography.Text type="secondary">
                          {asset.gallery?.length} items
                        </Typography.Text>
                        <ActionBtn
                          afterDelete={afterDelete}
                          refetch={refetch}
                          onSuccess={publish}
                          onPublish={publish}
                          asset={asset}
                          onEditAsset={onEditGallery}
                        />
                      </Flex>
                    </Flex>
                  </div>
                </Col>
              );
            })}
          </Row>
        ) : (
          <>
            <Divider />
            {/* update this view whenever showingInnerItems change */}
            {showingInnerItems && (
              <GalleryView
                goBack={() => {
                  setShowingInnerItems({} as Gallery);
                  setEditSourceData({} as Gallery);
                }}
                onEdit={onEditGallery}
                publish={publish}
                item={showingInnerItems}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default GallerySection;
