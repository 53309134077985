import { Assets, Pages, Project } from "@/API";
import { videoAccept } from "@/constants/assets";
import { getProjectInfo } from "@/customGraphql/customQueries";
import { getImageFromS3, sortBySequence } from "@/utils";
import { Avatar, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { GrDocumentMissing } from "react-icons/gr";

interface MediaType {
  media: string;
  name: string;
  section: string;
}

const AttachMediaTable = ({
  pages,
  open,
  onCancel,
  assets,
  projectId,
}: {
  pages: Pages[];
  projectId: string;
  assets: Assets[];
  open: boolean;
  onCancel: () => void;
}) => {
  const {
    data: projectData,
    isLoading: isProjectLoading,
    isFetched,
  } = useQuery(
    `project-view-attach-media-${projectId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getProjectInfo, {
          id: projectId,
        })
      );
      return response.data.getProject as Project;
    },
    {
      enabled: !!projectId,
    }
  );

  const mapImages = () => {
    if (!assets) return [];
    if (assets.length === 0) return [];
    // map images in object with page name and their section media -> {name:"Test page", images: [...]}
    const images: any = [];

    const pagesSortSeq = projectData?.siteData?.pageSortSeq;
    const sectionSortSeq = projectData?.siteData?.sectionSortSeq;

    const sortedSeq = pages.map((p) => {
      const seq = (
        sectionSortSeq?.find((s) => s?.pageId === p.id)?.seq || []
      ).filter(Boolean);

      return {
        ...p,
        pageSections: sortBySequence(p.pageSections as any[], seq as any[]),
      };
    });
    const sortedPages = sortBySequence(sortedSeq, pagesSortSeq as any[]);

    sortedPages.forEach((page) => {
      page.pageSections.forEach((section) => {
        if (section.noMedia) return null;

        if (isEmpty(section?.media) || !section?.media?.length) {
          images.push({
            media: "",

            name: page.name,
            section: section.section,
          });
        }

        section?.media?.forEach((media: any) => {
          const asset = assets?.find((d: any) => d?.id === media);
          if (!asset) return null;
          if (!asset.link) return null;
          if (
            videoAccept
              .concat("video/mp4, video/quicktime")
              .includes(asset.mediaType ?? "none")
          )
            return null;

          images.push({
            media: getImageFromS3(asset.link),
            name: page.name,
            section: section.section,
          });
        });
      });
    });
    return images;
  };

  const media: MediaType[] = mapImages();

  const columns: ColumnsType<MediaType> = [
    // media, page name, section name
    {
      title: "Media",
      dataIndex: "media",
      key: "media",
      render(value, record) {
        return (
          <Avatar
            shape="square"
            size={"large"}
            icon={<GrDocumentMissing />}
            src={value}
            style={{
              borderRadius: 0,
            }}
            alt={record.name + record.section}
          />
        );
      },
    },
    {
      title: "Page Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Section Name",
      dataIndex: "section",
      key: "section",
    },
  ];

  return (
    <Modal title="Attached Media" open={open} onCancel={onCancel} footer={null}>
      <Table
        loading={isProjectLoading || !isFetched}
        dataSource={media}
        columns={columns}
      />
    </Modal>
  );
};

export default AttachMediaTable;
