import { Button, Result } from "antd";
import { Link } from "react-router-dom";

interface NotFoundInsideProps {
  subTitle?: string;
  extra?: React.ReactNode;
}

const NotFoundInside = ({ subTitle, extra }: NotFoundInsideProps) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle={subTitle ?? "Sorry, the page you visited does not exist."}
      extra={
        extra ?? (
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        )
      }
    />
  );
};

export default NotFoundInside;
