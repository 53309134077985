import { getBase64 } from "@/utils";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import React, { useState } from "react";
import PreviewImage from "../RareComponents/PreviewImage";

const ImageUpload = ({
  disabled,
  maxCount = 1,
  fileList,
  setFileList,
  enablePreview = true,
}: {
  disabled?: boolean;
  maxCount?: number;
  images?: string[];
  enablePreview?: boolean;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        disabled={disabled}
        onChange={handleChange}
        maxCount={20}
        multiple
        beforeUpload={() => false}
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>

      {enablePreview && (
        <PreviewImage
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          previewImage={previewImage}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ImageUpload;
