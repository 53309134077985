import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        authID
        email
        user {
          items {
            authID
          }
          nextToken
        }
        projects {
          items {
            id
            name
            organizationID
          }
          nextToken
          __typename
        }
        name
        type
        address1
        address2
        city
        state
        country
        timezone {
          country
          timezone
          __typename
        }
        zipCode
        url
        maxBucketSize
        staffSortSeq
        storeInventorySortSeq
        partnersLogoSortSeq
        mediaFilesSortSeq
        gallerySortSeq
        allowedFileTabs
        orgSize
        industry
        mission
        software
        paper
        printer
        comments {
          comment
          date
          __typename
        }
        socialMedia {
          type
          link
          __typename
        }
        contact {
          name
          email
          phone
          __typename
        }
        meetings {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customQuerieslistOrganizationsForUsers = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        authID
        user {
          items {
            authID
          }
        }
        projects {
          items {
            id
            name
            status
            otherUsers {
              userID
              projectRole
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listOrgNames = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const listOrgNamesWithProjects = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        projects {
          items {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const listGalleries = /* GraphQL */ `
  query ListGalleries(
    $filter: ModelGalleryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGalleries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        organizationID
        email
        coverImage
        sortSeq
        gallery {
          id
          link
          mediaType
          note
        }
        publishDate
        title

        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      maxBucketSize
      staffSortSeq
      gallerySortSeq
      partnersLogoSortSeq
      storeInventorySortSeq
      allowedFileTabs
      timezone {
        timezone
        country
      }
    }
  }
`;

export const getOrganizationFull = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      avatar
      user {
        items {
          id
          authID
          organizationID
          name
          title
          email
          phone
          isAdmin
          initialEmailSentOn
          overrideOrganization
          orgLevel
          lastLogin
          status
          adminAccessTo
          liveVisiblity
          isZoiq
          completedTasks
          links
          createdAt
          updatedAt
        }
        nextToken
      }
      projects {
        items {
          id
          organizationID
          name
          description
          startDate
          endDate
          milestone
          status
          risk
          githubRepo
          awsAccount
          liveUrl
          isZoiq
          userSortSeq
          projectPlanSortSeq
          projectMeetingsSortSeq
          projectCriteriaSortSeq
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      type
      address1
      address2
      city
      state
      country
      timezone {
        country
        timezone
      }
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      comments {
        comment
        date
      }
      socialMedia {
        type
        link
      }
      contact {
        name
        email
        phone
      }
      meetings {
        items {
          id
          organizationID
          name
          description
          startTime
          endTime
          attendees
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $authID: ID
    $email: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      authID: $authID
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        authID

        organizationID
        overrideOrganization
        name
        title
        adminAccessTo
        email
        otherProjects {
          projectID
          projectRole
        }

        organization {
          name
          timezone {
            timezone
            country
          }
        }
        phone
        isAdmin
        timezone {
          timezone
          country
        }
        orgLevel
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      startDate
      endDate
      milestone
      status
      organizationID
      isZoiq
      risk
      userSortSeq
      githubRepo
      awsAccount
      liveUrl
      projectPlanSortSeq
      projectMeetingsSortSeq
      ownerID
      ownerAuthID
      ownerEmail
      organization {
        allowedFileTabs
      }
      otherUsers {
        userID
        projectRole
      }
      projectCriteriaSortSeq
      siteData {
        audience
        trafficCount
        trafficLocation
        tone
        languages
        websites {
          link
          reason
        }
      }

      sourceFile {
        items {
          id
          userID
          organizationID
          projectId
          email
          size
          mediaType
          link
          note
        }
      }

      gallery {
        items {
          id
          userID
          organizationID
          projectId
          email
          coverImage
          sortSeq
          gallery {
            id
            link
            mediaType
            note
          }
          title
        }
      }

      projectPlan {
        items {
          id
          projectID
          milestone
          createdAt
        }
      }

      meetings {
        items {
          id
          projectID
          name
          description
          startTime
          endTime
          attendees
          createdAt
          updatedAt
        }
      }

      pages {
        items {
          id
          projectID
          name
          description
          link
          pageSections {
            section
            link
            id
            media
            meta
            illustration
            lastEmailSent
            instructionsLevel
          }
        }
      }

      successCriteria {
        items {
          id
          projectID
          description
          measurableCriteria
          type
          createdAt
          updatedAt
        }
      }

      summaries {
        items {
          id
          projectID
          summary
          summaryDate
          summaryOwnerID
          summaryOwnerEmail
          summaryOwner {
            id
            authID
            name
          }
          summaryType
          discussionDate
          discussedWithID
          discussedWithEmail
          discussedWith {
            id
            authID
            name
          }
        }
      }

      assets {
        items {
          id
          projectID
          note
          link
          createdAt
          updatedAt
        }
      }

      store {
        items {
          id
          projectID
          organizationID
          title
          description
          amount
          inStock
          addedBy
          mediaIds
          colorsAvailable
          sizesAvailable
          createdAt
          updatedAt
        }
      }

      discussion {
        items {
          id
          projectID
          name
          date
          actionItemsSortSeq
          createdAt
          updatedAt
          emailSent
          emailSentDate
          actions {
            items {
              id
              discussionID
              actionItem
              dateCompleted
              dateAssigned
              targetDate
              owners
              isCompleted
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const getProjectInfo = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      startDate
      endDate
      milestone
      status
      liveUrl
      risk
      organizationID
      awsAccount
      githubRepo
      isZoiq

      pages {
        items {
          id
          projectID
          name
          description
          link
          pageSections {
            section
            link
            id
            media
            meta
            illustration
            lastEmailSent
            instructionsLevel
          }
        }
      }

      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups

      siteData {
        audience
        trafficCount
        trafficLocation
        tone
        languages
        websites {
          link
          reason
        }
        pageSortSeq
        sectionSortSeq {
          pageId
          seq
        }
        organizationSummary
        purpose
        goals
        messaging
        keyDates
        suggestedSolution
        projectNotes
        contactPerson
        isLocked
        __typename
      }

      closingMatrix {
        projectFunds
        relationshipWithLead
        projectRelation
        opportunityRelated
        additionalBuildRequirements
        estNewFeatures
        industryExpertOnTheirSide
        technicalExpertOnTheirSide
        perceivedReadiness
        notes
      }
    }
  }
`;

export const getProjectName = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      organizationID
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($authID: ID!, $email: String!) {
    getUser(authID: $authID, email: $email) {
      id
      authID
      organization {
        id
        name
      }
      isZoiq

      overrideOrganization
      organizationID

      otherProjects {
        projectID
        projectRole
      }
      completedTasks
      adminAccessTo
      canAddProjects
      name
      title
      email
      phone
      isAdmin
      lastLogin
      orgLevel
      status
      timezone {
        timezone
        country
      }

      createdAt
      updatedAt
    }
  }
`;

export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        name
        description
        link
        pageSections {
          section
          link
          id

          media
          signOff {
            title
            description
            originalDesign
            originalDesignDate
            updatedDesign
            updatedDesignDate
          }
          meta
          illustration
          noMedia
          lastEmailSent
          instructionsLevel
        }
      }
      nextToken
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        name
        description
        startDate
        endDate
        milestone
        status
        isZoiq
        organizationID
        ownerID
        ownerAuthID
        ownerEmail
        otherUsers {
          userID
          projectRole
        }

        siteData {
          pageSortSeq
          sectionSortSeq {
            pageId
            seq
          }
        }

        pages {
          items {
            id
            projectID
            name
            description
            link
            pageSections {
              section
              link
              id
              media
              meta
              illustration
              lastEmailSent
              instructionsLevel
            }
          }
        }

        projectPlan {
          items {
            id
            projectID
            milestone
            createdAt
          }
        }
        discussion {
          items {
            id
            projectID
            name
            date
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserNames = /* GraphQL */ `
  query ListUsers(
    $authID: ID
    $email: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      authID: $authID
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        authID
        title
        email
        phone
        isZoiq
      }
    }
  }
`;

export const listSummaries = /* GraphQL */ `
  query ListSummaries(
    $filter: ModelSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        summary
        summaryDate
        summaryOwnerID
        summaryOwnerEmail
        summaryOwner {
          id
          authID
          name
        }
        summaryType
        discussionDate
        discussedWithID
        discussedWithEmail
        discussedWith {
          id
          authID
          name
        }
      }
      nextToken
    }
  }
`;
export const getBacklogSequence = /* GraphQL */ `
  query GetBacklogSequence($id: ID!) {
    getBacklogSequence(id: $id) {
      id
      sequence
    }
  }
`;

export const listBacklogs = /* GraphQL */ `
  query ListBacklogs(
    $filter: ModelBacklogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBacklogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isCompleted
        dateCompleted
        assignedTo
        createdBy
        createdByEmail
        title
        pageID
        targetDate

        media {
          id
          mediaType
          mediaLink
        }
        subtasks {
          items {
            id
            isCompleted
            task
            moscow
            completedOn
            mediaId
            backlogID
          }
        }
        status
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listSuccessCriteria = /* GraphQL */ `
  query ListSuccessCriteria(
    $filter: ModelSuccessCriteriaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuccessCriteria(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        description
        measurableCriteria
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getSiteData = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      organizationID
      liveUrl
      lambdaFunction {
        name
        url
      }

      dynamo {
        key
        value
      }
      cognitoUrl
      awsAccount
      githubRepo
      organization {
        allowedFileTabs
        maxBucketSize
      }
      status
      siteData {
        audience
        trafficCount
        trafficLocation
        tone
        languages
        appId
        branchName
        sectionSortSeq {
          pageId
          seq
        }
        pageSortSeq
        websites {
          link
          reason
        }
        organizationSummary
        purpose
        goals
        messaging
        keyDates
        suggestedSolution
        projectNotes
        contactPerson
      }

      pages {
        items {
          id
          projectID
          name
          description
          link
          pageSections {
            section
            link
            id
            media
            meta
            illustration
            lastEmailSent
            instructionsLevel
          }
        }
      }
    }
  }
`;

export const getAwsInfo = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      siteData {
        appId
        branchName
      }
    }
  }
`;

export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        organizationID
        title
        description
        amount
        inStock
        addedBy
        mediaIds
        colorsAvailable
        sizesAvailable
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMeetingAttendees = /* GraphQL */ `
  query GetMeetings($id: ID!) {
    getMeetings(id: $id) {
      id
      name
      attendees
    }
  }
`;

export const listMeetingAttendees = /* GraphQL */ `
  query ListUsers(
    $authID: ID
    $email: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      authID: $authID
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        authID
        name
        email
      }
      nextToken
    }
  }
`;

export const listProjectNames = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationID
        name
        status
        ownerID
        ownerAuthID
        ownerEmail
        isZoiq
        projectPlan {
          items {
            id
            projectID
            milestone
          }
        }
        organizationID
      }
      nextToken
    }
  }
`;

export const getTask = /* GraphQL */ `
  query GetBacklog($id: ID!) {
    getBacklog(id: $id) {
      id
      name
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      title
      referralLinks
      media {
        id
        mediaType
        mediaLink
      }
      status
      priority
      assignedTo
      comments {
        items {
          id
          backlogID
          comment
          date
          authID
          authEmail
          createdAt
          updatedAt
        }
        nextToken
      }
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      subtasks {
        items {
          id
          isCompleted
          task
          moscow
          completedOn
          mediaId
          backlogID
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getPopups = /* GraphQL */ `
  query GetPopups($id: ID!) {
    getPopups(id: $id) {
      id
      authID
      authEmail
      title
      description
      projectID
      isActive
      expiryDate
      content {
        name
        value
      }
      image
      createdAt
      updatedAt
    }
  }
`;

export const listSubTaskModels = /* GraphQL */ `query ListSubTaskModels(
  $filter: ModelSubTaskModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubTaskModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      isCompleted
      task
      completedOn
      mediaId
      sprintType
      backlogID
      priority
      organizationID
      organization {
        name
      }
      projectID
      project {
      name
      }
      projectPlanID
      projectPlan {
        milestone
      }
      status
      moscow
      createdAt
      updatedAt

    }
    nextToken

  }
}
` as GeneratedQuery<
  APITypes.ListSubTaskModelsQueryVariables,
  APITypes.ListSubTaskModelsQuery
>;

export const listAssets = /* GraphQL */ `query ListAssets(
  $filter: ModelAssetsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      projectID
      organizationID
      email
      size
      mediaType
      onlyAdmin
      link
      multipleLinks
      type
      subType
      note
      isAssigned
      galleryId
      profile {
       id
  name
  title

  bio
  email
  phone
  country
  department
  LI
  Twitter
  YouTube
  IG
  status
  }
      assignedDate
      storeID
      storeImagesOrder
      multipleProjects
      lastUpdatedBy
      lastUpdatedByEmail
      lastUpdatedOn
      isUpdated
      isRemoved
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssetsQueryVariables,
  APITypes.ListAssetsQuery
>;
