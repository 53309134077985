import { Assets } from "@/API";
import { AssetsProps } from "@/interface/Settings";
import { getImageFromS3 } from "@/utils";
import { Flex } from "@aws-amplify/ui-react";
import {
  Card,
  Col,
  Divider,
  Empty,
  Image,
  Row,
  Space,
  Typography,
  theme,
} from "antd";
import React from "react";

import { ActionDropDown, AdminOnlyTag, assetCols } from "./sections/Assets";

const VectorAssets = ({ assets, onEditAsset, onDeleteAsset }: AssetsProps) => {
  const { token } = theme.useToken();

  if (!assets.length)
    return (
      <Empty
        description="No Vector Assets"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      ></Empty>
    );

  // split the assets into 3 columns
  const columns = assets.reduce((acc: any[], asset, index) => {
    const colIndex = index % 3;
    if (!acc[colIndex]) {
      acc[colIndex] = [];
    }
    acc[colIndex].push(asset);
    return acc;
  }, []) as Assets[][];

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <>
      <Row
        style={{
          marginTop: "1rem",
        }}
        gutter={[16, 16]}
      >
        {columns.map((assets, idx) => {
          if (!assets) return null;
          return (
            <Col key={idx} {...assetCols}>
              {assets.map((asset) => {
                if (!asset.link) return null;

                return (
                  <Col style={{ marginBottom: 32 }} key={asset.id} span={24}>
                    <Card>
                      <Image
                        src={getImageFromS3(asset.link)}
                        alt={asset.note ?? "No Name"}
                        loading="lazy"
                      />

                      <Flex
                        display={"flex"}
                        marginTop={"1rem"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography.Title level={5}></Typography.Title>
                        {asset.note}
                        <AdminOnlyTag onlyAdmin={asset.onlyAdmin} />
                        <Space>
                          <ActionDropDown
                            asset={asset}
                            onDeleteAsset={onDeleteAsset}
                            onEditAsset={onEditAsset}
                            dropdownRender={(menu) => (
                              <div style={contentStyle}>
                                {React.cloneElement(
                                  menu as React.ReactElement,
                                  {
                                    style: menuStyle,
                                  }
                                )}
                                <Divider style={{ margin: 0 }} />

                                <div className="dropdown-footer">
                                  <Typography.Text>
                                    File type: {asset.mediaType}
                                  </Typography.Text>
                                </div>
                              </div>
                            )}
                          />
                        </Space>
                      </Flex>
                    </Card>
                  </Col>
                );
              })}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default VectorAssets;
