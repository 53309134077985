import { SubAssetType } from "@/API";
import { AssetsProps } from "@/interface/Settings";

import useUserData from "@/hooks/useData";
import { Card, Col, Empty, Flex, Row, Select, Space, Tag } from "antd";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import FileIcon from "./FileIcon";
import AttachedComponentWrapper from "./ProjectFileComponents/AttachedComponentWrapper";
import Author from "./common/Author";
import { ActionDropDown, AdminOnlyTag, assetCols } from "./sections/Assets";

const DocumentAssets = ({
  assets,
  users,
  onEditAsset,
  onDeleteAsset,
  showAttachedOnlyBtn,
}: AssetsProps) => {
  const { isAdmin, isZoiq } = useUserData();

  const filteredAssets =
    isZoiq || isAdmin
      ? assets
      : assets.filter((asset) => asset.subType !== SubAssetType.API);

  useEffect(() => {
    setLocalCopyOfAssets(filteredAssets);
  }, [filteredAssets]);

  const [localCopyOfAssets, setLocalCopyOfAssets] = useState(filteredAssets);

  return (
    <>
      {showAttachedOnlyBtn !== null && showAttachedOnlyBtn !== undefined && (
        <>{showAttachedOnlyBtn}</>
      )}

      <Flex
        style={{
          marginTop: 12,
        }}
        gap={"middle"}
      >
        <Select
          placeholder="Sort by name"
          onChange={(v) => {
            const sorted = orderBy(
              localCopyOfAssets,
              ["note"],
              [v === "a-z" ? "asc" : "desc"]
            );
            setLocalCopyOfAssets(sorted);
          }}
          options={[
            {
              // a-z sort
              label: "A-Z",
              value: "a-z",
            },
            {
              // z-a sort
              label: "Z-A",
              value: "z-a",
            },
          ]}
        />
        <Select
          style={{ width: 200 }}
          placeholder="Sort by created date"
          onChange={(v) => {
            const sorted = orderBy(
              localCopyOfAssets,
              ["createdAt"],
              [v === "newest" ? "desc" : "asc"]
            );
            setLocalCopyOfAssets(sorted);
          }}
          options={[
            {
              // newest to oldest
              label: "Newest to oldest",
              value: "newest",
            },
            {
              // oldest to newest
              label: "Oldest to newest",
              value: "oldest",
            },
          ]}
        />
      </Flex>

      {assets.length === 0 ? (
        <Empty
          description="No Documents or Api"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row
          style={{
            marginTop: "1rem",
          }}
          gutter={[16, 16]}
        >
          {localCopyOfAssets.map((asset) => {
            const isApi = asset.subType === SubAssetType.API;
            const user = users?.find((d) => d.authID === asset.userID);
            return (
              <Col key={asset.id} {...assetCols}>
                <AttachedComponentWrapper isAttached={asset.isAttached}>
                  <Card>
                    <Card.Meta
                      avatar={<FileIcon mediaType={asset.mediaType!} />}
                      title={asset.note ?? "No Name"}
                    />

                    <Flex
                      style={{
                        marginTop: 16,
                      }}
                      align="center"
                      justify="space-between"
                    >
                      <Author
                        user={
                          asset.isUpdated ? asset.lastUpdatedByUser! : user!
                        }
                        isUpdated={Boolean(asset.isUpdated)}
                        updatedAt={asset.lastUpdatedOn!}
                        createdAt={asset.createdAt}
                      />
                      <Space>
                        <AdminOnlyTag onlyAdmin={asset.onlyAdmin} />

                        {isApi && <Tag color="gold">API</Tag>}
                        <ActionDropDown
                          onEditAsset={onEditAsset}
                          onDeleteAsset={onDeleteAsset}
                          asset={asset}
                          options={{
                            delete: true,
                            download: true,
                            edit: true,
                          }}
                        />
                      </Space>
                    </Flex>
                  </Card>
                </AttachedComponentWrapper>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default DocumentAssets;
