import { Modal } from "antd";

const PreviewImage = ({
  previewOpen,
  previewTitle,
  previewImage,
  handleCancel,
}: {
  previewOpen: boolean;
  previewTitle: string;
  previewImage: string;
  handleCancel: () => void;
}) => {
  return (
    <Modal
      open={previewOpen}
      title={previewTitle}
      footer={null}
      onCancel={handleCancel}
    >
      <img alt="example" style={{ width: "100%" }} src={previewImage} />
    </Modal>
  );
};

export default PreviewImage;
