import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useTabs<T>(uniqTabKey = "", defaultActiveKey: string = "") {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<T>(defaultActiveKey as T);

  // Get initial activeKey from URL on mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keyFromUrl = searchParams.get(uniqTabKey) || "";

    setActiveKey((keyFromUrl || defaultActiveKey) as T);
  }, []);

  // Update URL query parameter on tab change
  const handleTabChange = (key: string) => {
    if (key !== activeKey) {
      setActiveKey(key as T);

      const searchParams = new URLSearchParams(location.search);
      searchParams.set(uniqTabKey, key);
      // if there are already more than one query parameter, use & instead of ?
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  return {
    activeKey,
    handleTabChange,
  };
}

export default useTabs;
