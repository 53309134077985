import {
  CreateUserInput,
  Organization,
  Project,
  ProjectRole,
  Status,
  UpdateUserInput,
  User,
} from "@/API";
import colors from "@/constants/colors";
import { projectRoleList, statusList } from "@/constants/staticData";
import {
  customQuerieslistOrganizationsForUsers,
  getUser,
  getUserNames,
  listProjects,
} from "@/customGraphql/customQueries";
import { CustomError } from "@/interface/Settings";
import { createUser, sendEmail } from "@/utils/graphql";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Input,
  Popconfirm,
  Row,
  Select,
  message,
  notification,
} from "antd";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  AnimatedCard,
  Box,
  StyledAuthWrapper,
  StyledCardPattern,
} from "./common/StyledComponents";

import { allTimezones, useTimezoneSelect } from "react-timezone-select";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

import { BASE_URL } from "@/constants/home.constants";
import {
  customMutationCreateUser,
  updateProject,
} from "@/customGraphql/customMutations";
import { updateUser } from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import { emailTemplate } from "@/templates/email-template";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Flex } from "@aws-amplify/ui-react";
import { isEmpty, uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";
import Form from "./common/Form";
interface RegisterUserFormValues extends Omit<CreateUserInput, "authID"> {
  organization: string;
  project: string;
  projectRole: ProjectRole;
}

const initialValues: RegisterUserFormValues = {
  name: "",
  title: "",
  email: "",
  phone: "",

  projectRole: ProjectRole.TECHNICAL_EXPERT,
  orgLevel: 1,
  status: Status.ACTIVE,
  organization: "",
  isAdmin: false,
  project: "",
};

export const fetchProjects = async (orgID: string) => {
  // if (!orgID) {
  //   messageApi.error("Organization is required");
  //   return [];
  // }
  // setLoadingProjects(true);
  try {
    const response: any = await API.graphql(
      graphqlOperation(listProjects, {
        filter: {
          organizationID: { eq: orgID },
        },
      })
    );
    return response.data.listProjects.items as Project[];
  } catch (error) {
    console.error(error);
    // messageApi.error("Error fetching projects");
  } finally {
    // setLoadingProjects(false);
  }
};

const RegisterUserForm: React.FC = () => {
  const [form] = Form.useForm();
  Form.useWatch("isAdmin", form);
  Form.useWatch("adminAccessTo", form);

  const urlParams = new URLSearchParams(window.location.search);

  const idFromUrl = urlParams.get("id");
  const emailFromUrl = urlParams.get("email");

  const isParamsCorrect = Boolean(
    idFromUrl && idFromUrl.length > 0 && emailFromUrl && idFromUrl.length > 0
  );

  const [isLoading, setIsLoading] = useState(false);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  const { data: organizationList, isLoading: loadingOrg } = useQuery(
    `organizations-users`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(customQuerieslistOrganizationsForUsers)
      );
      return response.data.listOrganizations.items as Organization[];
    }
  );

  const { data: fetchedUserData, isLoading: isFetchedUserLoading } = useQuery(
    `profile-${idFromUrl}-${emailFromUrl}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getUser, {
          authID: idFromUrl,
          email: emailFromUrl,
        })
      );
      return response.data.getUser as User;
    },
    {
      enabled: isParamsCorrect,
    }
  );

  const { data: currentUser, isAdmin } = useUserData();
  const userData = isParamsCorrect ? fetchedUserData : currentUser;

  const isEdit = useMemo(
    () =>
      location.pathname.includes("/profile/edit") &&
      userData !== undefined &&
      userData.authID,
    [location.pathname, userData]
  );

  const [loadingProjects, setLoadingProjects] = useState(false);
  const [projects, setProjects] = useState<any[]>([] as any[]);

  useEffect(() => {
    if (
      projects?.length > 0 &&
      userData?.otherProjects &&
      userData?.otherProjects?.length > 0
    ) {
      const projectId = projects?.find((project) =>
        userData?.otherProjects?.find(
          (otherProject) => otherProject?.projectID === project?.value
        )
      )?.value;

      form.setFieldValue("project", projectId);
    }
  }, [projects, userData?.otherProjects]);

  useEffect(() => {
    if (isEdit && userData) {
      userData.organizationID && onOrgChange(userData.organizationID);

      form.setFieldsValue({
        name: userData.name,
        title: userData.title,
        email: userData.email,
        overrideOrganization: userData.overrideOrganization,
        phone: userData.phone,
        orgLevel: userData.orgLevel,
        isAdmin: userData.isAdmin,
        timezone: userData.timezone?.country,
        // primaryRole: userData.primaryRole,
        adminAccessTo: userData.adminAccessTo ?? [],
        organization: userData?.organizationID ?? "",
        status: userData.status,
        canAddProjects: userData.canAddProjects,
        // projectRole: userData.primaryRole,
        isZoiq: userData.isZoiq,
      });
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [isEdit, isFetchedUserLoading]);

  const createCognitoUser = async (email: string) => {
    setIsLoading(true);
    try {
      const response = await createUser(email);
      if (!response) return;

      if (typeof response === "object" && typeof response.data === "object") {
        if (response.status === 200) {
          // @ts-ignore
          const user = response?.data?.User;
          return user;
        } else {
          // @ts-ignore
          throw new Error(response?.data?.message || "Error creating user");
        }
      }
    } catch (error) {
      const customError = error as CustomError;

      notification.error({
        message: "Error",
        description: customError.message,
      });

      console.error("cognito error -> ", error);
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const checkIfUserNameExists = async (name: string) => {
    try {
      const response: any = await API.graphql(
        graphqlOperation(getUserNames, {
          filter: { name: { eq: name } },
        })
      );
      const items = response.data.listUsers.items ?? [];
      return items.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const onFinish = async (values: RegisterUserFormValues) => {
    const parsedTimezone = values?.timezone
      ? // @ts-ignore
        parseTimezone(values?.timezone)
      : {
          label: "",
          value: "",
        };

    try {
      const nameAlreadyExists = await checkIfUserNameExists(values.name);

      if (nameAlreadyExists) {
        return messageApi.error(
          "User name already exists. please try different name"
        );
      }

      const authUser = await createCognitoUser(values.email);

      const _isadmin =
        values.isAdmin ?? (values?.adminAccessTo?.length ?? 0) > 0;

      if (authUser?.Username) {
        try {
          const input: CreateUserInput = {
            authID: authUser.Username,
            organizationID: _isadmin
              ? "admin"
              : values?.organization?.toString(),
            name: values.name,
            title: values.title,
            email: values.email,
            phone: values.phone,
            // primaryRole: values.projectRole,
            isZoiq: values.isZoiq,
            orgLevel: values.orgLevel,
            lastLogin: new Date().toISOString(),
            initialEmailSentOn: new Date().toISOString(),
            canAddProjects: values.canAddProjects,
            otherProjects: _isadmin
              ? []
              : [
                  {
                    projectID: values?.project?.toString() ?? "",
                    projectRole: values?.projectRole,
                  },
                ],
            status: values.status,
            isAdmin: values.isAdmin,
            overrideOrganization: values.overrideOrganization,
            timezone: {
              // @ts-ignore
              country: parsedTimezone?.value,
              // @ts-ignore
              timezone: parsedTimezone?.label,
            },
            adminAccessTo: !values.isAdmin ? values?.adminAccessTo ?? [] : [],
          };

          const projects = organizationList?.find(
            (o) => o?.id === values.organization
          )?.projects?.items as Project[];

          const projectData = projects?.find((p) => p?.id === values.project);

          const createUserResponse: any = await API.graphql(
            graphqlOperation(customMutationCreateUser, { input })
          );
          const otherUsers = [
            ...(projectData?.otherUsers ?? []),
            {
              userID: createUserResponse.data.createUser.id,
              projectRole: values?.projectRole,
            },
          ];

          const updateProjectResponse =
            _isadmin || !Boolean(values?.project)
              ? Promise.resolve({ data: { createUser: { id: "admin" } } })
              : API.graphql(
                  graphqlOperation(updateProject, {
                    input: {
                      id: values?.project?.toString(),
                      otherUsers: otherUsers,
                    },
                  })
                );

          if (createUserResponse && updateProjectResponse) {
            const projectName = projects?.find(
              (p) => p?.id === values?.project?.toString()
            )?.name;

            resetStateValues();
            messageApi.success("User created successfully");
            await sendEmail(
              values.email,
              "Account created successfully 🎉🎉",
              emailTemplate(
                `${BASE_URL}/login/?email=` + values.email,
                values.name,
                projectName ?? "",
                values.projectRole
              )
            );
            messageApi.success("Email sent successfully");
            navigate("/client-portal");
          } else {
            console.error(
              "error creating user ->> ",
              createUserResponse,
              updateProjectResponse
            );

            messageApi.error("Error creating user");
          }
        } catch (error) {
          messageApi.error("Error creating person");
          console.error("error creating person ->>", error);
        }
      } else {
        messageApi.error("Could not create user");
        console.log("Could not create user");
      }
    } catch (error) {
      messageApi.error("Could not create user");
      console.error("error on onFinish function ->> ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  const onFinishUpdate = async (values: any) => {
    setIsLoading(true);
    try {
      const parsedTimezone = values?.timezone
        ? parseTimezone(values?.timezone!)
        : { label: "", value: "" };
      // if user name is changed, check if it already exists
      if (userData?.name !== values.name) {
        const nameAlreadyExists = await checkIfUserNameExists(values.name);

        if (nameAlreadyExists) {
          return messageApi.error(
            "User name already exists. please try different name"
          );
        }
      }

      const projects = organizationList?.find(
        (o) => o?.id === values?.organization
      )?.projects?.items as Project[];

      const projectData = projects?.find((p) => p?.id === values.project);

      try {
        const input: UpdateUserInput = {
          id: userData?.id!,
          authID: userData?.authID!,
          email: userData?.email!,
          name: values.name,
          organizationID: values.isAdmin
            ? "admin"
            : values?.organization?.toString(),
          title: values.title,
          phone: values.phone,
          // primaryRole: values.projectRole,
          orgLevel: values.orgLevel,
          status: values.status,
          isZoiq: values.isZoiq,
          overrideOrganization: values.overrideOrganization,
          isAdmin: values.isAdmin,
          canAddProjects: values.canAddProjects,
          adminAccessTo: !values.isAdmin ? values?.adminAccessTo ?? [] : [],
          otherProjects: !values.isAdmin
            ? uniqBy(
                [
                  ...(userData?.otherProjects ?? []),
                  {
                    projectID: values?.project?.toString() ?? "",
                    projectRole: values?.projectRole,
                  },
                ],
                "projectID"
              )
            : values?.otherProjects ?? [],
          timezone: {
            country: parsedTimezone?.value,

            timezone: parsedTimezone?.label,
          },
        };

        const updateUserResponse: any = await API.graphql(
          graphqlOperation(updateUser, { input })
        );

        console.log(updateUserResponse);

        const otherUsers = uniqBy(
          [
            ...(projectData?.otherUsers ?? []),
            {
              userID: updateUserResponse?.data?.updateUser?.id,
              projectRole: values?.projectRole,
            },
          ],
          "userID"
        );

        const updateProjectResponse = values.isAdmin
          ? Promise.resolve({ data: { updateUser: { id: "admin" } } })
          : API.graphql(
              graphqlOperation(updateProject, {
                input: {
                  id: values?.project?.toString(),
                  otherUsers: otherUsers,
                },
              })
            );

        if (updateUserResponse && updateProjectResponse) {
          resetStateValues();
          messageApi.success("Profile updated successfully");

          navigate(isParamsCorrect ? `/user-list` : "/profile");
        } else {
          messageApi.error("Error updated user");
        }
      } catch (error) {
        console.error("error creating person ->>", error);
      }
    } catch (error) {
      console.error("error on onFinishUpdate function ->> ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    resetStateValues();
    form.resetFields();
  };

  const resetStateValues = () => {
    form.setFieldsValue(initialValues);
  };

  const mappedOrgs = useMemo(() => {
    if (organizationList && organizationList.length > 0) {
      return organizationList.map((org) => ({
        label: org?.name,
        value: org?.id,
      }));
    }
    return [];
  }, [organizationList]);

  const onOrgChange = async (value: string) => {
    setLoadingProjects(true);
    const fetchedProjects = await fetchProjects(value);
    setLoadingProjects(false);
    if (fetchedProjects && fetchedProjects.length > 0) {
      const updated = fetchedProjects.map((project) => ({
        label: project?.name,
        value: project?.id,
      }));

      setProjects(updated);
    }
  };

  const onCancel = () => {
    handleReset();

    navigate(!isEdit ? "/client-portal" : "/user-list");
  };
  const onConfirm = () => {
    form.submit();
  };

  // not an actual admin.. the new user is an admin of the project or not
  const isFormAdmin = form.getFieldValue("isAdmin");

  return (
    <StyledAuthWrapper>
      {contextHolder}
      <StyledCardPattern color={colors.primary} className="pattern-dots-md">
        <AnimatedCard
          className="card"
          // title={isEdit ? "Update Profile" : "Register User"}
          title={
            <Flex
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {isEdit ? "Update Profile" : "Register User"}

              <Popconfirm
                title="Do you want to save changes?"
                onCancel={onCancel}
                okText="Yes"
                cancelText="No"
                onConfirm={onConfirm}
              >
                <Button icon={<ArrowLeftOutlined />}>Back</Button>
              </Popconfirm>
            </Flex>
          }
        >
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={isEdit ? onFinishUpdate : onFinish}
            onReset={handleReset}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the user name." },
              ]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>

            <Form.Item label="Title" name="title">
              <Input
                // @ts-ignore

                placeholder="Enter your title"
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please input the user email." },
              ]}
            >
              <Input
                disabled={Boolean(isEdit)}
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item label="Phone" name="phone">
              <Input
                // @ts-ignore

                placeholder="Enter your phone number"
              />
            </Form.Item>

            <Form.Item label="Org Level" name="orgLevel">
              <Input
                // @ts-ignore

                placeholder="Enter your org level"
                type="number"
                min={0}
                max={3}
              />
            </Form.Item>

            <Form.Item
              label="Status"
              name="status"
              rules={[
                { required: true, message: "Please select the user status." },
              ]}
            >
              <Select
                placeholder="Select state for user"
                options={statusList}
              />
            </Form.Item>
            <Form.Item label="Timezone" name="timezone">
              <Select placeholder="Select timezone" options={options} />
            </Form.Item>

            {isAdmin && (
              <Box
                style={{
                  marginBottom: 24,
                }}
              >
                <Form.Item label="Role" name="isAdmin" valuePropName="checked">
                  <Checkbox>Admin</Checkbox>
                </Form.Item>

                <Divider>Or</Divider>

                <Form.Item
                  label="Access to specific organizations"
                  name="adminAccessTo"
                >
                  <Select
                    mode="multiple"
                    placeholder="Select organizations"
                    loading={loadingOrg}
                    disabled={form.getFieldValue("isAdmin") || loadingOrg}
                    options={mappedOrgs}
                  />
                </Form.Item>
              </Box>
            )}

            {!isFormAdmin && isEmpty(form.getFieldValue("adminAccessTo")) && (
              <Form.Item
                label="Organization"
                name="organization"
                rules={[
                  {
                    required: true,
                    message: "Please select the organization",
                  },
                ]}
              >
                <Select
                  onChange={onOrgChange}
                  placeholder="Select organization for user"
                  loading={loadingOrg}
                  disabled={isFormAdmin || loadingOrg}
                  options={mappedOrgs.filter(
                    (d) =>
                      !form.getFieldValue("adminAccessTo")?.includes(d?.value)
                  )}
                />
              </Form.Item>
            )}

            <Form.Item
              name="overrideOrganization"
              label="Override Organization"
            >
              <Input placeholder="Enter override organization" />
            </Form.Item>

            {!isFormAdmin && isEmpty(form.getFieldValue("adminAccessTo")) && (
              <>
                <Form.Item
                  label="Project"
                  name="project"
                  rules={[
                    {
                      required: true,
                      message: "Please assign the project to user",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select project for user"
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          !isEmpty(form.getFieldValue("organization")) ||
                          !isEmpty(form.getFieldValue("adminAccessTo"))
                            ? "No project found for this organization"
                            : "Please select organization first"
                        }
                      />
                    }
                    disabled={isFormAdmin || loadingOrg || loadingProjects}
                    options={projects}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Project role"
              name="projectRole"
              rules={
                isFormAdmin
                  ? undefined
                  : [
                      {
                        required: true,
                        message: "Please select project role.",
                      },
                    ]
              }
            >
              <Select
                placeholder="Select project role for user"
                disabled={isFormAdmin}
                options={projectRoleList}
              />
            </Form.Item>
            {isAdmin && (
              <Row>
                <Col xs={24} lg={12}>
                  <Form.Item label="Zoiq" name="isZoiq" valuePropName="checked">
                    <Checkbox checked={form.getFieldValue("isZoiq")}>
                      Yes
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Can Add Projects"
                    name="canAddProjects"
                    valuePropName="checked"
                  >
                    <Checkbox checked={form.getFieldValue("canAddProjects")}>
                      Yes
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Form.Item>
              {/* align this element to right */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={isLoading}
                >
                  {isEdit ? "Update" : "Register User"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </AnimatedCard>
      </StyledCardPattern>
    </StyledAuthWrapper>
  );
};

export default RegisterUserForm;
