import "@/App.less";

import colors from "@/constants/colors";
import GlobalLoadingProvider from "@/contexts/LoadingContexts";
import MainRouter from "@/router/MainRouter";
import { ConfigProvider, ThemeConfig, notification, theme } from "antd";
import enUS from "antd/lib/locale/en_US";
import Amplify from "@aws-amplify/core";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useZoiqTheme } from "zoiq-module";
import awsconfig from "../aws-exports";
import Loading from "./common/Loading";
import { HelmetProvider } from "react-helmet-async";
const helmetContext = { helmet: {} };
// force push

Amplify.configure(awsconfig);

function App() {
  const { token, isFetchingTheme } = useZoiqTheme();

  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  useEffect(() => {
    window.addEventListener("online", () => {
      notificationApi.success({
        message: "You're back!!",
        duration: 3000,
      });
    });
    window.addEventListener("offline", () => {
      notificationApi.info({
        message: "You're offline",
        duration: 3000,
      });
    });
  }, []);

  const customTheme: ThemeConfig = {
    algorithm: theme.darkAlgorithm,
    token: token,

    components: {
      Card: {
        colorBgContainer: colors.lightGray,
      },

      Button: {
        colorLink: colors.primary,
        colorLinkHover: colors.primaryDark,
        borderRadius: 9999,
        borderRadiusLG: 9999,
        borderRadiusXS: 9999,
        borderRadiusSM: 9999,
      },

      Input: {
        colorBgContainer: colors.dark,
      },
      Segmented: {
        itemColor: colors.secondaryWhite,
        colorText: colors.primary,
      },
    },
    // test
  };

  return (
    <HelmetProvider context={helmetContext}>
      <ConfigProvider
        input={{ autoComplete: "off" }}
        theme={customTheme}
        locale={enUS}
      >
        <GlobalLoadingProvider>
          {notificationContextHolder}
          <Router>
            <div className="App">
              {isFetchingTheme ? <Loading /> : <MainRouter />}
            </div>
          </Router>
        </GlobalLoadingProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
