import { PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  Modal,
  Progress,
  Row,
  Select,
  Upload,
  UploadFile,
  UploadProps,
  message,
  theme,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AssetType,
  CreateAssetsInput,
  CreateStoreInput,
  Organization,
  Pages,
  Project,
  StaffStatus,
  UpdateAssetsInput,
  UpdatePagesInput,
  UpdateStoreInput,
} from "@/API";
import {
  docAccept,
  imgAccept,
  imgVideoAudioAccept,
  vidExtended,
} from "@/constants/assets";
import { invalid_file_error } from "@/constants/portal.constants";
import {
  adminData,
  availableColors,
  brandingSubTypes,
  statusOptions,
} from "@/constants/staticData";
import { useGlobalLoading } from "@/contexts/LoadingContexts";
import { getOrganization } from "@/customGraphql/customQueries";
import {
  createAssets,
  createStore,
  updateAssets,
  updatePages,
  updateStore,
} from "@/graphql/mutations";
import useUserData from "@/hooks/useData";
import useS3Keys from "@/hooks/useS3Keys";
import {
  documentAddTemplate,
  imageAddTemplate,
} from "@/templates/email-template";
import {
  convertToMB,
  deleteImageFromS3,
  getBase64,
  getImageFromS3,
  getStyleGuideEnvValue,
  sortBySequence,
  uploadFileToS3,
  uploadFileToS3Optimised,
} from "@/utils";
import { sendEmail } from "@/utils/graphql";
import { Flex } from "@aws-amplify/ui-react";
import { UniqueIdentifier } from "@dnd-kit/core";
import { RcFile } from "antd/es/upload";
import { API, graphqlOperation } from "aws-amplify";
import { isArray, isEmpty, uniq } from "lodash";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import CustomUploadItem from "../CustomUploadItem";
import { fetchProjects } from "../RegisterUserForm";
import Form from "../common/Form";
import SortableUploadList from "../common/SortComponents/SortableUploadList";
import { AssetWithStore } from "../sections/Assets";
import { fetchStyleGuide } from "../sections/StyleGuide";

const INVALID_PHONE_FORMAT_MESSAGE =
  "Please enter a valid phone number (xxx) xxx-xxxx";

async function isValidExtension(url: RequestInfo | URL) {
  try {
    const response = await fetch(url, { method: "HEAD" });
    const status = response.status;
    if (status === 404)
      return {
        isValid: false,
        contentType: "",
      };
    const contentType = response.headers.get("Content-Type");

    // Add or remove required MIME types as needed
    const validContentType = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "audio/mpeg",
      "audio/wav",
      "video/mp4",
      "video/x-msvideo",
    ];

    if (!contentType) return false;
    return {
      isValid: validContentType.includes(contentType),
      contentType,
    };
  } catch (error) {
    console.error(error);
    return {
      isValid: false,
      contentType: "",
    };
  }
}

interface NewAssetProps {
  activeKey: AssetType;
  onCancel: () => void;
  onSuccess: (
    asset: CreateAssetsInput | UpdateAssetsInput | AssetWithStore
  ) => void;

  organisationId: string;
  editAssetData: AssetWithStore;
  remainingSize: number | null;
  projectId: string;
}
interface ExistingUploadFile extends UploadFile {
  key: string;
  isExisting: boolean;
}

export const colorsWithEl = availableColors.map((color) => ({
  ...color,
  label: (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 8,
          backgroundColor: color.value,
          marginRight: 8,
        }}
      ></div>
      {color.label}
    </div>
  ),
  colorCode: color.value,
}));

const NewAsset = ({
  activeKey,
  onSuccess,
  onCancel,
  editAssetData,

  organisationId,
  projectId,
  remainingSize,
}: NewAssetProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [isLink, setIsLink] = useState(false);

  const { authID, email, isAdmin, data: userData, isZoiq } = useUserData();

  const [progress, setProgress] = useState<null | number>(null);

  const [shouldCompress, setShouldCompress] = useState(
    AssetType.STOCK === activeKey
  );

  const { data: styleGuideData } = useQuery(
    `style-guide-${organisationId}`,
    () => fetchStyleGuide(organisationId!),
    {
      enabled: Boolean((isZoiq || isAdmin) && organisationId),
    }
  );

  const getVariable = useCallback(
    (name: string) =>
      styleGuideData && getStyleGuideEnvValue(styleGuideData, name),
    [styleGuideData]
  );

  const requiredFields = {
    STAFF_PROFILE_NAME: getVariable("STAFF_PROFILE_NAME") === "TRUE",
    STAFF_PROFILE_IMAGE: getVariable("STAFF_PROFILE_IMAGE") === "TRUE",
    STAFF_PROFILE_TITLE: getVariable("STAFF_PROFILE_TITLE") === "TRUE",
    STAFF_PROFILE_EMAIL: getVariable("STAFF_PROFILE_EMAIL") === "TRUE",
    STAFF_PROFILE_PHONE: getVariable("STAFF_PROFILE_PHONE") === "TRUE",
    STAFF_PROFILE_BIO: getVariable("STAFF_PROFILE_BIO") === "TRUE",
    STAFF_PROFILE_PROJECT: getVariable("STAFF_PROFILE_PROJECT") === "TRUE",
    STAFF_PROFILE_STATUS: getVariable("STAFF_PROFILE_STATUS") === "TRUE",
  };

  const phone_format = getVariable("STAFF_PROFILE_PHONE_FORMAT");

  const { projectFilesKey } = useS3Keys(projectId!, organisationId);
  const onUpload = async (
    file: any,
    existingLink?: string
  ): Promise<string> => {
    // upload files to s3
    if (!fileList.length) return "";
    const id = uuidv4();

    const isVideo = vidExtended.includes(file?.type);

    const uploadFunction =
      isVideo || !shouldCompress ? uploadFileToS3 : uploadFileToS3Optimised;

    const fileLink = await uploadFunction(
      file,
      existingLink ??
        `${projectFilesKey}/${activeKey}/${projectData?.id}/${id}`,
      file?.type,
      {
        progressCallback({ progress }) {
          setProgress(Number(progress.toFixed(0)));
        },
      }
    );

    if (!fileLink) return "";
    return fileLink.key;
  };

  const isEdit = useMemo(() => !isEmpty(editAssetData), [editAssetData]);

  const { data: fetchedOrgData } = useQuery(
    `organization-${organisationId}`,
    async () => {
      const response: any = await API.graphql(
        graphqlOperation(getOrganization, {
          id: organisationId,
        })
      );
      return response.data.getOrganization as Organization;
    },
    {
      enabled: Boolean(organisationId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isEdit) {
      onReset();

      projectId && form.setFieldValue("multipleProjects", projectId);

      return;
    }

    const isCustomUrl = !editAssetData.link.includes("company-assets");
    if (isEdit) {
      let fileList = [] as UploadFile[];
      if (isStoreInventory) {
        const assets = editAssetData?.store?.mediaIds?.filter(Boolean) ?? [];
        fileList = assets?.map((asset) => {
          if (!asset) return null;
          return {
            uid: asset,
            name: editAssetData?.note,
            status: "done",
            url: getImageFromS3(asset),

            originFileObj: new File(
              [],
              "".concat(editAssetData.mediaType!)
            ) as unknown,
            key: asset,
            isExisting: true,
          };
        }) as UploadFile[];
        if (editAssetData.storeImagesOrder) {
          const order = editAssetData?.storeImagesOrder?.filter(Boolean);
          setImageSeq(order as string[]);
          fileList = fileList.sort((a, b) => {
            const aIndex = order?.indexOf(a.uid);
            const bIndex = order?.indexOf(b.uid);
            if (aIndex === -1 || bIndex === -1) return 0;
            return aIndex - bIndex;
          });
        }
        setFileList(fileList);
      } else {
        if (!isCustomUrl) {
          fileList = [
            {
              uid: editAssetData?.id,
              name: editAssetData?.note,
              status: "done",
              url: getImageFromS3(editAssetData?.link),
              originFileObj: new File([], "".concat(editAssetData.mediaType!)),
            },
          ] as UploadFile[];
          setFileList(fileList);
        } else {
          setIsLink(true);
          form.setFieldsValue({
            directLink: editAssetData?.link,
          });
        }
      }

      if (editAssetData?.store && isStoreInventory) {
        form.setFieldsValue({
          title: editAssetData.store.title,
          description: editAssetData.store.description,
          amount: editAssetData.store.amount,
          inStock: Boolean(editAssetData.store.inStock),
          onlyAdmin: editAssetData.onlyAdmin,
          colorsAvailable: editAssetData.store.colorsAvailable,
          sizesAvailable: editAssetData.store.sizesAvailable,
        });
      }
      if (isTeam) {
        if (editAssetData.profile) {
          form.setFieldsValue({
            name: editAssetData.profile.name,
            email: editAssetData.profile.email,
            phone: editAssetData.profile.phone,
            title: editAssetData.profile.title,
            assetName: editAssetData.note,
            onlyAdmin: editAssetData.onlyAdmin,
            bio: editAssetData.profile.bio,
            Twitter: editAssetData.profile.LI,
            LI: editAssetData.profile.Twitter,
            YouTube: editAssetData.profile.YouTube,
            IG: editAssetData.profile.IG,
            department: editAssetData.profile.department,
            country: editAssetData.profile.country,
            status: editAssetData.profile.status ?? StaffStatus.ACTIVE,
          });
        }
      } else {
        form.setFieldsValue({
          assetName: editAssetData.note,
          subType: editAssetData.subType,
        });
      }

      form.setFieldsValue({
        onlyAdmin: editAssetData.onlyAdmin,
        multipleProjects: Array.isArray(editAssetData?.multipleProjects)
          ? editAssetData?.multipleProjects[0]
          : editAssetData?.multipleProjects ?? projectId
          ? projectId
          : [],

        pageID: editAssetData?.assignedToInfo?.pageID,
        sectionID: editAssetData?.assignedToInfo?.sectionID,
      });
    }
  }, [isEdit]);

  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { setGlobalLoading } = useGlobalLoading();

  const onReset = () => {
    setFileList([]);
    setIsLoading(false);
    setGlobalLoading(false);
    form.resetFields();
    setIsLink(false);

    setIsReplacingImage(false);
  };

  const getCommonProfileContentValues = (values: any, extra: any) => {
    return {
      bio: values.bio,
      Twitter: values.linkedin,
      LI: values.twitter,
      YouTube: values.youtube,
      IG: values.IG,
      department: values.department,
      country: values.country,
      status: values.status ?? StaffStatus.ACTIVE,
      ...extra,
    };
  };

  const [error, setError] = useState<string | null>(null);
  const [urlError, setUrlError] = useState("");
  const [projects, setProjects] = useState<Project[]>([]);

  const getFileKey = async (file: ExistingUploadFile, link?: string) => {
    const linkKey =
      isReplacingImage && link
        ? await onUpload(file.originFileObj, link)
        : (link as string);
    if (isEmpty(linkKey)) {
      messageApi.error("Could not upload file, please try again!");
      return "";
    }
    return linkKey as string;
  };

  useEffect(() => {
    if (organisationId) {
      fetchProjects(organisationId).then((res) => {
        if (res) {
          setProjects(res);
        }
      });
    }
  }, [organisationId]);

  const projectsMapped = useMemo(
    () =>
      projects.map((project) => ({
        label: project?.name,
        value: project?.id,
      })),

    [projects]
  );

  const addMediaToPages = async (
    pageID: string,
    sectionID: string,
    mediaId: string | string[],
    imgUrl?: string,
    remove?: boolean
  ) => {
    const page = pagesData?.find((item: any) => item.id === pageID);
    if (!page) return;
    const updatedSections = page.pageSections?.map((d) => {
      if (d === null) return null;
      if (d.id === sectionID) {
        const existingMedia = d.media ?? [];
        return {
          ...d,
          media: uniq(
            remove
              ? existingMedia?.filter((item) => item !== mediaId)
              : isArray(mediaId)
              ? [...(existingMedia || []), ...mediaId]
              : [...(existingMedia || []), mediaId]
          ).filter(Boolean),
          lastEmailSent: new Date().toISOString(),
        };
      }
      return d;
    });

    const pageInput: UpdatePagesInput = {
      id: pageID,
      pageSections: updatedSections,
    };

    await API.graphql(
      graphqlOperation(updatePages, {
        input: pageInput,
      })
    );

    if (!isEmpty(projectData)) {
      const page = projectData.pages?.items?.find(
        (item: any) => item.id === pageID
      );

      if (!page) return;

      const section = page.pageSections?.find(
        (item: any) => item.id === sectionID
      );
      if (!section) return;

      if (imgUrl) {
        await sendEmail(
          adminData.email,
          "New image attached",
          imageAddTemplate(
            projectData.name,
            section.section,
            imgUrl,
            email!,
            `${location.origin}/project-view/${projectData.organizationID}/${projectData.id}?breadcrumbs=pages`,
            remove
          ),
          false,
          "projects@zoiq.io"
        );
      }
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    setGlobalLoading(true);

    if (isTeam) {
      if (phone_format === "WITH_BRACKETS") {
        // test regex for phone number with brackets
        const regex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
        if (!regex.test(values.phone)) {
          setError(INVALID_PHONE_FORMAT_MESSAGE);
          return;
        }
      }
    }

    if (!isLink && fileList.length === 0) {
      setError("Please upload a file or add a link");
      return;
    }

    let fileExtension = "";

    if (values.directLink && values.directLink.length > 0) {
      const fileDetails = await isValidExtension(values.directLink);
      if (fileDetails) {
        if (!fileDetails.isValid) {
          setUrlError(invalid_file_error);
          setIsLoading(false);
          setGlobalLoading(false);
          return;
        } else {
          fileExtension = fileDetails.contentType;
        }
      }
    }

    if (fileList.length > 0 && !values.directLink) {
      const totalFileSize = fileList.reduce(
        (acc, file) => acc + (file.size ?? 0),
        0
      );
      const fileSize = convertToMB(totalFileSize);

      if (remainingSize !== null && fileSize > remainingSize) {
        setError(
          `File size is greater than bucket remaining size. File size: ${fileSize.toFixed(
            2
          )} mb, Remaining size: ${remainingSize} mb. ${
            fileList.length === 1 ? "Remove some files and try again." : ""
          }`
        );
        return;
      }
    }

    const linkKey = isLink
      ? undefined
      : await Promise.all(
          fileList?.map(async (file) => {
            const linkKey = await onUpload(file.originFileObj, undefined);
            return linkKey;
          })
        );

    try {
      const profileContent: CreateAssetsInput["profile"] =
        getCommonProfileContentValues(values, {
          id: uuidv4() ?? "",
          name: values.name ?? "",
          email: values?.email,
          phone: values?.phone,
          title: values?.title,
        });

      const note = !isTeam ? values.assetName : values?.name ?? "";

      let storeResponse: any = null;

      const newMediaIds =
        fileList.length > 0 && isStoreInventory
          ? await Promise.all(
              fileList?.map(async (file) => {
                const linkKey = await onUpload(file.originFileObj, undefined);
                return linkKey;
              })
            )
          : [];

      if (isStoreInventory) {
        const storeInventoryInput: CreateStoreInput = {
          projectID: values.multipleProjects,
          organizationID: organisationId,
          title: values.title,
          description: values.description,
          amount: values.amount ?? 50,
          inStock: Boolean(values.inStock),
          addedBy: authID!,
          colorsAvailable: values.colorsAvailable,
          sizesAvailable: values.sizesAvailable,
          mediaIds: newMediaIds,
        };
        storeResponse = await API.graphql(
          graphqlOperation(createStore, { input: storeInventoryInput })
        );
      }

      const input: any = {
        id: uuidv4(),
        userID: authID!,
        email: email!,
        organizationID: organisationId,
        multipleProjects: [values.multipleProjects],

        note: note,
        onlyAdmin: values.onlyAdmin,
        type: activeKey,
        profile: isTeam ? profileContent : undefined,
        storeID: storeResponse?.data?.createStore?.id,
        storeImagesOrder: imageSeq,
        multipleLinks: newMediaIds,
        subType: values.subType,
      };

      let response: any = null;

      const { pageID, sectionID } = values;

      const isAttached = !isEmpty(pageID) && !isEmpty(sectionID);

      if (isLink) {
        response = await API.graphql(
          graphqlOperation(createAssets, {
            input: {
              ...input,
              link: linkKey ?? values.directLink,
              mediaType: isLink ? fileExtension : fileList[0].type,
              isAssigned: isAttached,
              assignedDate: isAttached ? new Date().toISOString() : undefined,
              assignedToInfo: {
                pageID: pageID,
                sectionID: sectionID,
              },
            },
          })
        );
        if (isAttached) {
          await addMediaToPages(
            pageID,
            sectionID,
            response.data.createAssets.id,
            linkKey ?? values.directLink
          );
        }
      } else {
        const multiplePromise =
          linkKey?.map(async (link, idx) => {
            return API.graphql(
              graphqlOperation(createAssets, {
                input: {
                  ...input,
                  id: uuidv4(),
                  link: link,
                  mediaType: isLink ? fileExtension : fileList[idx].type,
                  isAssigned: isAttached,
                  assignedDate: isAttached
                    ? new Date().toISOString()
                    : undefined,
                  assignedToInfo: {
                    pageID: pageID,
                    sectionID: sectionID,
                  },
                },
              })
            );
          }) ?? [];

        let responses = await Promise.all(multiplePromise);

        if (isAttached) {
          await addMediaToPages(
            pageID,
            sectionID,
            // @ts-ignore
            responses.map((res) => res?.data?.createAssets.id),
            undefined
          );
        }

        response = responses[0];
      }

      if (response !== null) {
        if (!response.data.createAssets) {
          onCancel();
          onReset();
          messageApi.error("Something went wrong, please try again!");

          await deleteImageFromS3(response.data.createAssets.link);

          return;
        }

        if (
          response.data.createAssets.type === AssetType.DOCUMENTS &&
          authID !== adminData.authID
        ) {
          if (userData && fetchedOrgData) {
            const url = await getImageFromS3(response.data.createAssets.link);

            const portalUrl = `${window.location.origin}/client-portal?orgId=${
              isZoiq ? "all" : organisationId
            }&portal=assets&asset-type=DOCUMENTS`;
            await sendEmail(
              adminData.email,
              "New Document Uploaded",
              documentAddTemplate(
                note,
                userData?.name,
                fetchedOrgData?.name,
                url,
                portalUrl
              ),
              [],
              "projects@zoiq.io"
            );
          }
        }

        onSuccess(response.data.createAssets);
        onReset();
        messageApi.success("Asset created successfully!");
      }
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong, please try again!");
    } finally {
      setIsLoading(false);
      setGlobalLoading(false);
    }
  };

  const isStoreInventory = activeKey === AssetType.STORE_INVENTORY;
  const isTeam = activeKey === AssetType.TEAM;
  const isDocuments = activeKey === AssetType.DOCUMENTS;
  const isBranding = activeKey === AssetType.ORGANIZATION;

  const onFinishUpdate = async (values: any) => {
    if (fileList.length === 0 && !values.directLink) {
      setError("Please upload a file or add a link");
      return;
    }

    if (isTeam) {
      // if (phone_format === "WITH_BRACKETS") {
      //   // test regex for phone number with brackets
      //   const regex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
      //   if (!regex.test(values.phone)) {
      //     setError(INVALID_PHONE_FORMAT_MESSAGE);
      //     return;
      //   }
      // }
    }

    let fileExtension = "";

    if (values.directLink && values.directLink.length > 0) {
      const fileDetails = await isValidExtension(values.directLink);
      if (fileDetails) {
        if (!fileDetails.isValid) {
          setUrlError(invalid_file_error);
          return;
        }

        fileExtension = fileDetails.contentType;
      }
    }

    let mediaIds: UpdateStoreInput["mediaIds"] = [];

    try {
      setIsLoading(true);
      setGlobalLoading(true);

      if (isReplacingImage && !isStoreInventory) {
        console.log("deleting image", editAssetData.link);

        await deleteImageFromS3(editAssetData.link);
      }

      let storeResponse: any = null;
      if (isStoreInventory) {
        const fileListWithExisting = fileList as ExistingUploadFile[];
        const existingImages = fileListWithExisting.filter(
          (file) => file.isExisting
        );
        const newImages = fileListWithExisting.filter(
          (file) => !file.isExisting
        );

        const newMediaIds = await Promise.all(
          newImages.map(async (file) => {
            const linkKey = await onUpload(file.originFileObj, undefined);
            return linkKey;
          })
        );

        mediaIds = [...existingImages.map((file) => file.key), ...newMediaIds];

        const storeInventoryInput: UpdateStoreInput = {
          id: editAssetData.store.id,
          projectID: values.multipleProjects,
          title: values.title,
          description: values.description,
          amount: values.amount ?? 50,
          inStock: Boolean(values.inStock),
          organizationID: organisationId,
          colorsAvailable: values.colorsAvailable ?? [],
          sizesAvailable: values.sizesAvailable ?? [],
          mediaIds,
        };
        storeResponse = await API.graphql(
          graphqlOperation(updateStore, { input: storeInventoryInput })
        );
      }

      const profileContent: UpdateAssetsInput["profile"] =
        getCommonProfileContentValues(values, {
          id: uuidv4(),
          name: values.name ?? "",
          email: values?.email,
          phone: values?.phone,
          title: values?.title,
        });

      const file = isLink
        ? {
            type: fileExtension,
          }
        : (fileList[0] as ExistingUploadFile);
      const note = !isTeam ? values.assetName : values?.name ?? "";

      const linkKey = isLink
        ? values.directLink
        : // @ts-ignore
          await getFileKey(file, editAssetData.link);

      const isAssigned = !isEmpty(values.pageID) && !isEmpty(values.sectionID);

      const input: UpdateAssetsInput = {
        id: editAssetData.id,
        // userID: authID!,
        // email: email!,
        organizationID: organisationId,
        multipleProjects: [values.multipleProjects],
        link: linkKey,
        mediaType: file.type,
        note: note,
        onlyAdmin: values.onlyAdmin,
        type: activeKey,
        multipleLinks: mediaIds,
        profile: isTeam ? profileContent : undefined,
        storeID: storeResponse?.data?.updateStore?.id,
        storeImagesOrder: imageSeq,
        subType: values.subType,
        lastUpdatedBy: authID!,
        lastUpdatedByEmail: email!,
        lastUpdatedOn: new Date().toISOString(),
        isUpdated: true,
        isAssigned,
        assignedDate: isAssigned ? new Date().toISOString() : undefined,
        assignedToInfo: {
          pageID: values.pageID,
          sectionID: values.sectionID,
        },
      };

      const response: any = await API.graphql(
        graphqlOperation(updateAssets, {
          input,
        })
      );

      if (!response.data.updateAssets) {
        onCancel();
        onReset();
        messageApi.error("Something went wrong, please try again!");
        return;
      }

      const isAssignedToSamePage =
        values.pageID === editAssetData.assignedToInfo?.pageID;
      const isAssignedToSameSection =
        values.sectionID === editAssetData.assignedToInfo?.sectionID;

      if (!isAssignedToSamePage || !isAssignedToSameSection) {
        await addMediaToPages(
          values.pageID,
          values.sectionID,
          response.data.updateAssets.id,
          linkKey
        );
      }

      if (!isAssigned) {
        if (
          !isEmpty(editAssetData.assignedToInfo?.pageID) &&
          !isEmpty(editAssetData.assignedToInfo?.sectionID)
        ) {
          const pageID = editAssetData?.assignedToInfo?.pageID;
          const sectionID = editAssetData?.assignedToInfo?.sectionID;
          if (typeof pageID === "string" && typeof sectionID === "string") {
            await addMediaToPages(
              pageID,
              sectionID,
              response.data.updateAssets.id,
              linkKey,
              true
            );
          }
        }
      }

      onSuccess(response.data.updateAssets);
      onReset();
      messageApi.success("Asset updated successfully!");
    } catch (error) {
      console.error(error);
      messageApi.error("Something went wrong, please try again!");
    } finally {
      setIsLoading(false);
      setGlobalLoading(false);
    }
  };

  const [form] = Form.useForm();
  Form.useWatch("pageID", form);
  Form.useWatch("sectionID", form);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [previewTitle, setPreviewTitle] = useState("");

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setIsLink(false);
    form.setFieldValue("directLink", "");
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const [isReplacingImage, setIsReplacingImage] = useState(false);

  const uploadButton = (
    <div
      id="upload-btn"
      onClick={() => {
        setIsReplacingImage(true);
      }}
    >
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);

  const projectData = projects.find((item) => item.id === projectId);

  const pagesData = projectData?.pages?.items as Pages[];

  const pagesOptions = useMemo(() => {
    let final = [];

    if (projectData?.siteData?.pageSortSeq) {
      const sorted = sortBySequence(
        pagesData ?? [],
        projectData?.siteData?.pageSortSeq! as string[]
      );
      final = sorted;
    } else {
      final = pagesData ?? [];
    }

    return final?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    }));
  }, [pagesData, projectData?.siteData]);

  const sectionOptions = useMemo(() => {
    const pageID = form.getFieldValue("pageID");
    const page = pagesData?.find((item: any) => item.id === pageID);
    if (!page) return [];

    let sections = page?.pageSections;

    const seq = projectData?.siteData?.sectionSortSeq?.find(
      (d) => d?.pageId === pageID
    )?.seq;
    if (!isEmpty(seq)) {
      sections = sortBySequence(
        page?.pageSections as any,
        seq as string[]
      ) as any;
    }

    return sections?.map((item: any) => ({
      label: item?.section,
      value: item?.id,
    }));
  }, [pagesData, form.getFieldValue("pageID")]);

  const propsObject: UploadProps = {
    listType: "picture-card",
    accept: isStoreInventory
      ? imgAccept
      : isDocuments
      ? docAccept
      : imgVideoAudioAccept,
    fileList: fileList,
    onPreview: handlePreview,
    maxCount: isEdit ? 1 : isStoreInventory ? 20 : isDocuments ? 1 : 10,
    onChange: handleChange,
    beforeUpload: () => false,
    multiple: !isDocuments && !isEdit,
  };

  const [imageSeq, setImageSeq] = useState([] as string[]);

  const { token } = theme.useToken();

  const pageID = form.getFieldValue("pageID");
  const sectionID = form.getFieldValue("sectionID");

  const isPageIDEmpty = isEmpty(pageID);
  const isSectionIDEmpty = isEmpty(sectionID);

  return (
    <div className="">
      {contextHolder}

      <Form form={form} onFinish={isEdit ? onFinishUpdate : onFinish}>
        {isLink === null ? (
          <></>
        ) : isStoreInventory ? (
          <SortableUploadList
            fileList={fileList}
            setFileList={setFileList}
            onDragEndSuccess={(updated) => {
              setFileList(updated);
              const newSeq: string[] = updated.map(
                (item: { uid: UniqueIdentifier }) => item.uid
              );
              setImageSeq(newSeq);
            }}
            {...propsObject}
          >
            {fileList.length >= (propsObject?.maxCount ?? 1)
              ? null
              : uploadButton}
          </SortableUploadList>
        ) : (
          <Form.Item>
            <Upload
              {...propsObject}
              itemRender={(originNode, file) => (
                <CustomUploadItem originNode={originNode} file={file} />
              )}
            >
              {fileList.length >= (propsObject?.maxCount ?? 1)
                ? null
                : uploadButton}
            </Upload>

            {!isDocuments && (
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%", marginBottom: 12 }}
                  src={previewImage}
                />
              </Modal>
            )}
          </Form.Item>
        )}

        {fileList.length === 0 && (
          <>
            <Divider>OR</Divider>
            <Form.Item
              required
              rules={[
                {
                  required: requiredFields.STAFF_PROFILE_IMAGE,
                  message: "Please input the link of the asset!",
                },
                {
                  type: "url",
                  message: "Please enter a valid url",
                },
              ]}
              name={"directLink"}
            >
              <Input
                style={{
                  borderColor:
                    urlError && urlError.length > 0 ? token.colorError : "",
                }}
                onChange={(e) => {
                  setUrlError("");
                  setIsLink(!isEmpty(e.target.value));
                }}
                placeholder="Enter file url"
              />
            </Form.Item>
            {urlError && urlError.length > 0 && (
              <p style={{ color: token.colorError }}>{urlError}</p>
            )}
          </>
        )}

        {activeKey === AssetType.STOCK && progress !== null && (
          <Progress percent={progress} />
        )}

        {!isTeam && !isStoreInventory && (
          <>
            <Form.Item
              required
              rules={[
                {
                  required: true,
                  message: "Please input the name of the asset!",
                },
              ]}
              label="1.  Provide asset name"
              name="assetName"
            >
              <Input placeholder="Asset Name" />
            </Form.Item>
          </>
        )}

        {isBranding && (
          <Form.Item name={"subType"} label={"Sub Type"}>
            <Select
              placeholder="Select Sub Type of Branding"
              options={brandingSubTypes}
            />
          </Form.Item>
        )}

        {isStoreInventory && (
          <>
            <Form.Item label="Title" name="title">
              <Input placeholder="Enter title of an item" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea
                rows={4}
                placeholder="Enter descrition of an item"
              />
            </Form.Item>
            <Form.Item label="Amount" name="amount">
              <InputNumber
                style={{ width: "100%" }}
                addonAfter={"$"}
                defaultValue={50}
              />
            </Form.Item>

            <Form.Item label="Available Colors" name="colorsAvailable">
              <Select
                mode="multiple"
                optionLabelProp="label"
                options={colorsWithEl}
                placeholder="Select Colors"
              />
            </Form.Item>

            <Form.Item label="In Stock" name="inStock" valuePropName="checked">
              <Checkbox
                onChange={(e) =>
                  form.setFieldsValue({ inStock: e.target.checked })
                }
                checked={form.getFieldValue("inStock")}
              ></Checkbox>
            </Form.Item>
          </>
        )}
        {isTeam && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  required
                  rules={[
                    {
                      required: requiredFields.STAFF_PROFILE_NAME,
                      message: "Please input the name of the member!",
                    },
                  ]}
                  label="Name"
                  name={"name"}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: requiredFields.STAFF_PROFILE_TITLE,
                      message: "Please input the title of the member!",
                    },
                  ]}
                  label="Title"
                  name="title"
                >
                  <Input placeholder="Enter title" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: requiredFields.STAFF_PROFILE_EMAIL,
                      message: "Please input the email of the member!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                  label="Email"
                  name="email"
                >
                  <Input placeholder="Enter email" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: requiredFields.STAFF_PROFILE_PHONE,
                      message: "Please input the phone of the member!",
                    },
                    {
                      type: "regexp",
                      pattern:
                        phone_format === "WITH_BRACKETS"
                          ? /^\(\d{3}\)\s\d{3}-\d{4}$/
                          : /^\d{10}$/,
                    },
                  ]}
                  label="Phone"
                  name="phone"
                >
                  <Input placeholder="Enter phone" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: requiredFields.STAFF_PROFILE_BIO,
                      message: "Please input the bio of the member!",
                    },
                  ]}
                  label="Bio"
                  name="bio"
                >
                  <Input.TextArea rows={4} placeholder="Enter bio" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Country" name="country">
                  <Input placeholder="Enter country" />
                </Form.Item>
                <Form.Item label="Department" name="department">
                  <Input placeholder="Enter department" />
                </Form.Item>
                <Form.Item label="Linkedin" name="LI">
                  <Input placeholder="Enter linkeding profile url" />
                </Form.Item>
                <Form.Item label="Twitter" name="Twitter">
                  <Input placeholder="Enter twitter profile url" />
                </Form.Item>
                <Form.Item label="Youtube" name="YouTube">
                  <Input placeholder="Enter youtube profile url" />
                </Form.Item>
                <Form.Item label="Instagram" name="IG">
                  <Input placeholder="Enter instagram profile url" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {!isEdit && AssetType.STOCK === activeKey && (
          <Form.Item>
            <Checkbox
              onChange={(e) => {
                setShouldCompress(e.target.checked);
              }}
              checked={shouldCompress}
            >
              Compress Image
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item
          rules={[
            {
              required: requiredFields.STAFF_PROFILE_PROJECT,
              message: "Please select project(s)",
            },
          ]}
          label="2. Select project"
          name="multipleProjects"
        >
          <Select options={projectsMapped ?? []} placeholder="Select Project" />
        </Form.Item>
        {isTeam && (
          <Form.Item
            rules={[
              {
                required: requiredFields.STAFF_PROFILE_STATUS,
                message: "Please select status",
              },
            ]}
            label="Select status"
            name="status"
          >
            <Select options={statusOptions} placeholder="Select status" />
          </Form.Item>
        )}

        {isEdit && (
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item name={"pageID"} label={"Page"}>
                <Select
                  allowClear
                  showSearch
                  onClear={() => {
                    form.setFieldsValue({ sectionID: undefined });
                  }}
                  disabled={projectId === undefined}
                  // reset section id field
                  optionFilterProp="label"
                  onChange={() => {
                    form.setFieldsValue({ sectionID: undefined });
                  }}
                  options={pagesOptions}
                  placeholder={"Select page"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"sectionID"}
                label={"4. Select section"}
                rules={
                  !isPageIDEmpty
                    ? [
                        {
                          required: true,
                          message: "Please select a page section.",
                        },
                      ]
                    : []
                }
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="label"
                  options={sectionOptions}
                  disabled={projectId === undefined || isPageIDEmpty}
                  placeholder={"Select section"}
                />
              </Form.Item>
            </Col>
            {!isPageIDEmpty && !isSectionIDEmpty && (
              <Col
                span={12}
                // style={{
                //   marginTop: "2rem",
                // }}
              >
                <Form.Item>
                  <Button
                    type="link"
                    onClick={() => {
                      form.setFieldsValue({
                        pageID: undefined,
                        sectionID: undefined,
                      });
                    }}
                  >
                    Detach media file
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}

        {error && (
          <Alert style={{ marginBottom: 16 }} type="error" message={error} />
        )}

        {(isAdmin || isZoiq) && (
          <Form.Item name="onlyAdmin" valuePropName="checked">
            <Checkbox
              onChange={(e) =>
                form.setFieldsValue({ onlyAdmin: e.target.checked })
              }
              checked={form.getFieldValue("onlyAdmin")}
            >
              Admin Only
            </Checkbox>
          </Form.Item>
        )}

        <Form.Item>
          <Flex gap={12} display={"flex"} justifyContent={"flex-end"}>
            <Button
              size="middle"
              onClick={() => {
                onReset();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              size="middle"
              htmlType="submit"
              type="primary"
            >
              {isEdit ? "Update" : "Upload"}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewAsset;
