import { Button, Tooltip } from "antd";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import styled from "styled-components";

interface ScrollButtonProps {
  scrolly: number;
}

const ScrollButton = styled(motion(Button))<ScrollButtonProps>`
  position: fixed;
  bottom: 20px;
  z-index: 10;
  right: 20px;
  display: ${(props) => (props.scrolly < 500 ? "none" : "block")};
`;

function ScrollToTop() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const buttonVariant = {
    hidden: {
      opacity: 0,
      x: 50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "easeInOut",
        duration: 0.5,
      },
    },
  };

  return (
    <Tooltip title="Scroll to top">
      <ScrollButton
        type="primary"
        shape="circle"
        size="large"
        onClick={scrollToTop}
        title="Scroll to top"
        scrolly={scrollY}
        variants={buttonVariant}
        initial="hidden"
        animate={scrollY > 500 ? "visible" : "hidden"}
      >
        ↑
      </ScrollButton>
    </Tooltip>
  );
}

export default ScrollToTop;
