// full screen loading with antd components and styled components
import { ZOIQ_ISOLATED_LOGO } from "@/constants/assets";
import AppLayout from "@/layout/AppLayout";
import { Spin } from "antd";
import { SpinProps } from "antd/lib";
import { cubicBezier, motion } from "framer-motion";
import styled from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const spinProps: SpinProps = {
  indicator: (
    <>
      <motion.img
        initial={{
          rotate: 0,
          scale: 0.75,
        }}
        animate={{
          rotate: 360,
          scale: 1,
          transition: {
            duration: 1,
            repeat: Infinity,
            ease: cubicBezier(0.4, 0, 0.2, 1),
          },
        }}
        style={{
          width: 70,
          height: 70,
        }}
        src={ZOIQ_ISOLATED_LOGO}
      />
    </>
  ),
};

interface LoadingProps {
  useAppContainer?: boolean;
}

const Loading = ({ useAppContainer = true }: LoadingProps) => {
  const content = (
    <LoadingContainer>
      <Spin size="large" />
    </LoadingContainer>
  );

  if (useAppContainer) {
    return (
      <AppLayout
        seo={{
          title: "Loading...",
        }}
        config={{
          cursor: false,
          navbar: false,
          footer: false,
          scroller: false,
        }}
      >
        {content}
      </AppLayout>
    );
  }

  return <>{content}</>;
};

export default Loading;
