import { CreatePopupsInput, Popups, UpdatePopupsInput } from "@/API";
import { createPopups, updatePopups } from "@/graphql/mutations";
import useAuth from "@/hooks/useAuth";
import { getBase64, getImageFromS3, uploadFileToS3Optimised } from "@/utils";
import { PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  notification,
  Switch,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { RcFile } from "antd/es/upload";
import { API, graphqlOperation } from "aws-amplify";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PreviewImage from "../RareComponents/PreviewImage";
import { uploadJsonFileToS3 } from "../RareComponents/UploadJsonToS3";

const ManageSectionPopup = ({
  isOpen,
  setIsOpen,
  data,
  // setSelectedSection,
  projectID,
}: // page,
// section,
// refetch,
{
  isOpen: boolean;
  data?: Popups;
  // page: Pages;
  // refetch: () => void;
  projectID: string;
  // section: PageSection;
  setIsOpen: (value: boolean) => void;
  // setSelectedSection: (section: PageSection) => void;
}) => {
  const [form] = Form.useForm();
  const { authID, email } = useAuth();
  // const { isAdmin, isZoiq } = useUserData();
  const [file, setFile] = useState<UploadFile>();

  const [isImageUpdated, setIsImageUpdated] = useState(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  const handleSave = async () => {
    const values = form.getFieldsValue();

    const isEdit = !isEmpty(data);
    try {
      setIsOpen(false);

      let imageLink = data?.image;

      if (isImageUpdated) {
        imageLink = await onUpload(file, data?.image!);
      }

      const popupID = projectID;

      const commonInput = {
        title: values.title,
        description: values.description,
        expiryDate: new Date(values.expiryDate).toISOString(),
        isActive: values.isActive,
        projectID,
        authID: authID!,
        authEmail: email!,
        image: imageLink,
        content: values.content,
      };

      if (isEdit) {
        const input: UpdatePopupsInput = {
          ...commonInput,
          id: popupID,
        };

        // Update the popup
        await API.graphql(
          graphqlOperation(updatePopups, {
            input,
          })
        );
      } else {
        const input: CreatePopupsInput = {
          ...commonInput,
          id: popupID,
        };
        // Create a new popup
        await API.graphql(
          graphqlOperation(createPopups, {
            input,
          })
        );
      }
      // const sections = page.pageSections?.map((item) => {
      //   if (item?.id === section.id) {
      //     return {
      //       ...item,
      //       popupID: popupID,
      //     };
      //   }
      //   return item;
      // }) as PageSection[];

      // setSelectedSection({
      //   ...section,
      //   popupID: popupID,
      // });
      // const input: UpdatePagesInput = {
      //   id: page.id,
      //   pageSections: sections,
      // };

      // await API.graphql(
      //   graphqlOperation(updatePages, {
      //     input,
      //   })
      // );

      const key = `PROJECT_DATA/POPUP/${projectID}.json`;

      await uploadJsonFileToS3(
        key,
        {
          ...data,
          content: values.content,
          title: values.title,
          description: values.description,
          expiryDate: values.expiryDate,
          image: values.image ? getImageFromS3(values.image) : null,
        },
        `popup-${popupID}`,
        {
          authID: authID!,
          email: email!,
          projectId: projectID!,
        }
      );
      notificationApi.success({
        message: "Popup Saved",
        description: "Popup has been saved successfully",
        placement: "bottomRight",
      });
      // refetch();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data?.image) {
      setFile({
        uid: "-1",
        name: "image.png",
        status: "done",
        url: getImageFromS3(data.image),
      });
    }
  }, []);

  const onUpload = async (
    file: any,
    existingLink?: string
  ): Promise<string> => {
    // upload files to s3
    if (!file) return "";
    const id = uuidv4();
    const fileLink = await uploadFileToS3Optimised(
      file,
      existingLink ?? `popup/${id}`,
      file?.type
    );

    if (!fileLink) return "";
    return fileLink.key;
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFile(newFileList[0].originFileObj);
    setIsImageUpdated(true);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url ?? (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const formInitialValues = {
    ...data,
    expiryDate: data?.expiryDate ? dayjs(data.expiryDate) : undefined,
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Attach Flyer"
        open={isOpen}
        onOk={handleSave}
        okText="Save"
        onCancel={() => setIsOpen(false)}
      >
        <Form layout="vertical" form={form} initialValues={formInitialValues}>
          <Form.Item name="image">
            <Upload
              listType="picture-card"
              fileList={file ? [file] : []}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={() => setFile(undefined)}
              maxCount={1}
              multiple={false}
              beforeUpload={() => false}
            >
              {file ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>

            <PreviewImage
              previewOpen={previewOpen}
              previewTitle={previewTitle}
              previewImage={previewImage}
              handleCancel={handleCancel}
            />
          </Form.Item>

          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>

          <Form.Item label="Description" name={"description"}>
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Expire Date" name="expiryDate">
            <DatePicker
              disabledDate={(current) => {
                return current && current < dayjs().endOf("day");
              }}
            />
          </Form.Item>

          <Form.Item label="Active" name={"isActive"}>
            <Switch
              defaultChecked={Boolean(data?.isActive)}
              onChange={(value) => {
                form.setFieldsValue({ isActive: value });
              }}
            />
          </Form.Item>

          <Divider />

          <Form.List
            name="content"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error("At least 1 item"));
                  } else if (names.length > 100) {
                    return Promise.reject(new Error("No more than 100 items"));
                  }
                },
              },
            ]}
          >
            {(fields, {}, { errors }) => (
              <>
                {fields.map((field) => {
                  return (
                    <Flex
                      key={field.name}
                      vertical
                      style={{ marginBottom: "1rem" }}
                    >
                      <Form.Item
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please add name or delete this field.",
                          },
                        ]}
                      >
                        <Input
                          // disabled={!isAdmin && !isZoiq}
                          placeholder="Enter name"
                        />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        label={data?.content?.[field.name]?.name}
                        name={[field.name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter value",
                          },
                        ]}
                      >
                        <Input placeholder="Enter value" />
                      </Form.Item>

                      {/* <Button
                        block
                        icon={<MinusCircleOutlined />}
                        type="text"
                        danger
                        onClick={() => remove(field.name)}
                      >
                        Remove
                      </Button> */}
                    </Flex>
                  );
                })}

                {/* <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                </Form.Item> */}
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ManageSectionPopup;
