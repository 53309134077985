/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createOrgMeeting = /* GraphQL */ `mutation CreateOrgMeeting(
  $input: CreateOrgMeetingInput!
  $condition: ModelOrgMeetingConditionInput
) {
  createOrgMeeting(input: $input, condition: $condition) {
    id
    organizationID
    name
    description
    startTime
    endTime
    attendees
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrgMeetingMutationVariables,
  APITypes.CreateOrgMeetingMutation
>;
export const updateOrgMeeting = /* GraphQL */ `mutation UpdateOrgMeeting(
  $input: UpdateOrgMeetingInput!
  $condition: ModelOrgMeetingConditionInput
) {
  updateOrgMeeting(input: $input, condition: $condition) {
    id
    organizationID
    name
    description
    startTime
    endTime
    attendees
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrgMeetingMutationVariables,
  APITypes.UpdateOrgMeetingMutation
>;
export const deleteOrgMeeting = /* GraphQL */ `mutation DeleteOrgMeeting(
  $input: DeleteOrgMeetingInput!
  $condition: ModelOrgMeetingConditionInput
) {
  deleteOrgMeeting(input: $input, condition: $condition) {
    id
    organizationID
    name
    description
    startTime
    endTime
    attendees
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrgMeetingMutationVariables,
  APITypes.DeleteOrgMeetingMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    authID
    email
    avatar
    user {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    name
    type
    address1
    address2
    city
    state
    country
    timezone {
      country
      timezone
      __typename
    }
    zipCode
    url
    maxBucketSize
    staffSortSeq
    storeInventorySortSeq
    partnersLogoSortSeq
    mediaFilesSortSeq
    gallerySortSeq
    allowedFileTabs
    orgSize
    industry
    mission
    software
    paper
    printer
    comments {
      comment
      date
      __typename
    }
    socialMedia {
      type
      link
      __typename
    }
    contact {
      name
      email
      phone
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    authID
    email
    avatar
    user {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    name
    type
    address1
    address2
    city
    state
    country
    timezone {
      country
      timezone
      __typename
    }
    zipCode
    url
    maxBucketSize
    staffSortSeq
    storeInventorySortSeq
    partnersLogoSortSeq
    mediaFilesSortSeq
    gallerySortSeq
    allowedFileTabs
    orgSize
    industry
    mission
    software
    paper
    printer
    comments {
      comment
      date
      __typename
    }
    socialMedia {
      type
      link
      __typename
    }
    contact {
      name
      email
      phone
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    authID
    email
    avatar
    user {
      nextToken
      __typename
    }
    projects {
      nextToken
      __typename
    }
    name
    type
    address1
    address2
    city
    state
    country
    timezone {
      country
      timezone
      __typename
    }
    zipCode
    url
    maxBucketSize
    staffSortSeq
    storeInventorySortSeq
    partnersLogoSortSeq
    mediaFilesSortSeq
    gallerySortSeq
    allowedFileTabs
    orgSize
    industry
    mission
    software
    paper
    printer
    comments {
      comment
      date
      __typename
    }
    socialMedia {
      type
      link
      __typename
    }
    contact {
      name
      email
      phone
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createAssets = /* GraphQL */ `mutation CreateAssets(
  $input: CreateAssetsInput!
  $condition: ModelAssetsConditionInput
) {
  createAssets(input: $input, condition: $condition) {
    id
    userID
    projectID
    organizationID
    email
    size
    profile {
      id
      name
      title
      bio
      email
      phone
      country
      department
      LI
      Twitter
      YouTube
      IG
      status
      __typename
    }
    mediaType
    onlyAdmin
    link
    multipleLinks
    type
    subType
    note
    isAssigned
    galleryId
    assignedDate
    storeID
    storeImagesOrder
    multipleProjects
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    lastUpdatedBy
    lastUpdatedByEmail
    lastUpdatedOn
    isUpdated
    lastUpdatedByUser {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    isRemoved
    assignedToInfo {
      pageID
      sectionID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetsMutationVariables,
  APITypes.CreateAssetsMutation
>;
export const updateAssets = /* GraphQL */ `mutation UpdateAssets(
  $input: UpdateAssetsInput!
  $condition: ModelAssetsConditionInput
) {
  updateAssets(input: $input, condition: $condition) {
    id
    userID
    projectID
    organizationID
    email
    size
    profile {
      id
      name
      title
      bio
      email
      phone
      country
      department
      LI
      Twitter
      YouTube
      IG
      status
      __typename
    }
    mediaType
    onlyAdmin
    link
    multipleLinks
    type
    subType
    note
    isAssigned
    galleryId
    assignedDate
    storeID
    storeImagesOrder
    multipleProjects
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    lastUpdatedBy
    lastUpdatedByEmail
    lastUpdatedOn
    isUpdated
    lastUpdatedByUser {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    isRemoved
    assignedToInfo {
      pageID
      sectionID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetsMutationVariables,
  APITypes.UpdateAssetsMutation
>;
export const deleteAssets = /* GraphQL */ `mutation DeleteAssets(
  $input: DeleteAssetsInput!
  $condition: ModelAssetsConditionInput
) {
  deleteAssets(input: $input, condition: $condition) {
    id
    userID
    projectID
    organizationID
    email
    size
    profile {
      id
      name
      title
      bio
      email
      phone
      country
      department
      LI
      Twitter
      YouTube
      IG
      status
      __typename
    }
    mediaType
    onlyAdmin
    link
    multipleLinks
    type
    subType
    note
    isAssigned
    galleryId
    assignedDate
    storeID
    storeImagesOrder
    multipleProjects
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    lastUpdatedBy
    lastUpdatedByEmail
    lastUpdatedOn
    isUpdated
    lastUpdatedByUser {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    isRemoved
    assignedToInfo {
      pageID
      sectionID
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetsMutationVariables,
  APITypes.DeleteAssetsMutation
>;
export const createSourceFile = /* GraphQL */ `mutation CreateSourceFile(
  $input: CreateSourceFileInput!
  $condition: ModelSourceFileConditionInput
) {
  createSourceFile(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    size
    mediaType
    link
    note
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSourceFileMutationVariables,
  APITypes.CreateSourceFileMutation
>;
export const updateSourceFile = /* GraphQL */ `mutation UpdateSourceFile(
  $input: UpdateSourceFileInput!
  $condition: ModelSourceFileConditionInput
) {
  updateSourceFile(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    size
    mediaType
    link
    note
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSourceFileMutationVariables,
  APITypes.UpdateSourceFileMutation
>;
export const deleteSourceFile = /* GraphQL */ `mutation DeleteSourceFile(
  $input: DeleteSourceFileInput!
  $condition: ModelSourceFileConditionInput
) {
  deleteSourceFile(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    size
    mediaType
    link
    note
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSourceFileMutationVariables,
  APITypes.DeleteSourceFileMutation
>;
export const createGallery = /* GraphQL */ `mutation CreateGallery(
  $input: CreateGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  createGallery(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    coverImage
    sortSeq
    gallery {
      id
      link
      mediaType
      note
      __typename
    }
    title
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    publishDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGalleryMutationVariables,
  APITypes.CreateGalleryMutation
>;
export const updateGallery = /* GraphQL */ `mutation UpdateGallery(
  $input: UpdateGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  updateGallery(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    coverImage
    sortSeq
    gallery {
      id
      link
      mediaType
      note
      __typename
    }
    title
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    publishDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGalleryMutationVariables,
  APITypes.UpdateGalleryMutation
>;
export const deleteGallery = /* GraphQL */ `mutation DeleteGallery(
  $input: DeleteGalleryInput!
  $condition: ModelGalleryConditionInput
) {
  deleteGallery(input: $input, condition: $condition) {
    id
    userID
    organizationID
    projectId
    email
    coverImage
    sortSeq
    gallery {
      id
      link
      mediaType
      note
      __typename
    }
    title
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    publishDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGalleryMutationVariables,
  APITypes.DeleteGalleryMutation
>;
export const createUserStat = /* GraphQL */ `mutation CreateUserStat(
  $input: CreateUserStatInput!
  $condition: ModelUserStatConditionInput
) {
  createUserStat(input: $input, condition: $condition) {
    id
    userID
    taskID
    task {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserStatMutationVariables,
  APITypes.CreateUserStatMutation
>;
export const updateUserStat = /* GraphQL */ `mutation UpdateUserStat(
  $input: UpdateUserStatInput!
  $condition: ModelUserStatConditionInput
) {
  updateUserStat(input: $input, condition: $condition) {
    id
    userID
    taskID
    task {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserStatMutationVariables,
  APITypes.UpdateUserStatMutation
>;
export const deleteUserStat = /* GraphQL */ `mutation DeleteUserStat(
  $input: DeleteUserStatInput!
  $condition: ModelUserStatConditionInput
) {
  deleteUserStat(input: $input, condition: $condition) {
    id
    userID
    taskID
    task {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserStatMutationVariables,
  APITypes.DeleteUserStatMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    authID
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    timezone {
      country
      timezone
      __typename
    }
    title
    email
    phone
    isAdmin
    initialEmailSentOn
    otherProjects {
      projectID
      projectRole
      __typename
    }
    overrideOrganization
    orgLevel
    lastLogin
    status
    adminAccessTo
    liveVisiblity
    isZoiq
    completedTasks
    links
    socialMedia {
      type
      link
      __typename
    }
    canAddProjects
    stats {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    authID
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    timezone {
      country
      timezone
      __typename
    }
    title
    email
    phone
    isAdmin
    initialEmailSentOn
    otherProjects {
      projectID
      projectRole
      __typename
    }
    overrideOrganization
    orgLevel
    lastLogin
    status
    adminAccessTo
    liveVisiblity
    isZoiq
    completedTasks
    links
    socialMedia {
      type
      link
      __typename
    }
    canAddProjects
    stats {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    authID
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    timezone {
      country
      timezone
      __typename
    }
    title
    email
    phone
    isAdmin
    initialEmailSentOn
    otherProjects {
      projectID
      projectRole
      __typename
    }
    overrideOrganization
    orgLevel
    lastLogin
    status
    adminAccessTo
    liveVisiblity
    isZoiq
    completedTasks
    links
    socialMedia {
      type
      link
      __typename
    }
    canAddProjects
    stats {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    description
    startDate
    endDate
    milestone
    ownerID
    ownerAuthID
    ownerEmail
    owner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    status
    otherUsers {
      userID
      projectRole
      __typename
    }
    risk
    githubRepo
    awsAccount
    cognitoUrl
    dynamo {
      key
      value
      __typename
    }
    solutionType
    scope
    estUsersInFirstYear
    userAgeGroups
    lambdaFunction {
      name
      url
      __typename
    }
    liveUrl
    siteData {
      audience
      trafficCount
      trafficLocation
      tone
      languages
      pageSortSeq
      organizationSummary
      purpose
      goals
      messaging
      keyDates
      suggestedSolution
      projectNotes
      contactPerson
      isLocked
      appId
      branchName
      __typename
    }
    isZoiq
    discussion {
      nextToken
      __typename
    }
    sourceFile {
      nextToken
      __typename
    }
    gallery {
      nextToken
      __typename
    }
    projectPlan {
      nextToken
      __typename
    }
    successCriteria {
      nextToken
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    summaries {
      nextToken
      __typename
    }
    assets {
      nextToken
      __typename
    }
    store {
      nextToken
      __typename
    }
    userSortSeq
    projectPlanSortSeq
    projectMeetingsSortSeq
    projectCriteriaSortSeq
    counts {
      ORGANIZATION
      TEAM
      STOCK
      DOCUMENTS
      PARTNERS_STOCK
      STORE_INVENTORY
      VECTORS
      GALLERY
      SOURCE_FILE
      __typename
    }
    closingMatrix {
      projectFunds
      relationshipWithLead
      projectRelation
      opportunityRelated
      additionalBuildRequirements
      estNewFeatures
      industryExpertOnTheirSide
      technicalExpertOnTheirSide
      perceivedReadiness
      notes
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    description
    startDate
    endDate
    milestone
    ownerID
    ownerAuthID
    ownerEmail
    owner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    status
    otherUsers {
      userID
      projectRole
      __typename
    }
    risk
    githubRepo
    awsAccount
    cognitoUrl
    dynamo {
      key
      value
      __typename
    }
    solutionType
    scope
    estUsersInFirstYear
    userAgeGroups
    lambdaFunction {
      name
      url
      __typename
    }
    liveUrl
    siteData {
      audience
      trafficCount
      trafficLocation
      tone
      languages
      pageSortSeq
      organizationSummary
      purpose
      goals
      messaging
      keyDates
      suggestedSolution
      projectNotes
      contactPerson
      isLocked
      appId
      branchName
      __typename
    }
    isZoiq
    discussion {
      nextToken
      __typename
    }
    sourceFile {
      nextToken
      __typename
    }
    gallery {
      nextToken
      __typename
    }
    projectPlan {
      nextToken
      __typename
    }
    successCriteria {
      nextToken
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    summaries {
      nextToken
      __typename
    }
    assets {
      nextToken
      __typename
    }
    store {
      nextToken
      __typename
    }
    userSortSeq
    projectPlanSortSeq
    projectMeetingsSortSeq
    projectCriteriaSortSeq
    counts {
      ORGANIZATION
      TEAM
      STOCK
      DOCUMENTS
      PARTNERS_STOCK
      STORE_INVENTORY
      VECTORS
      GALLERY
      SOURCE_FILE
      __typename
    }
    closingMatrix {
      projectFunds
      relationshipWithLead
      projectRelation
      opportunityRelated
      additionalBuildRequirements
      estNewFeatures
      industryExpertOnTheirSide
      technicalExpertOnTheirSide
      perceivedReadiness
      notes
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    name
    description
    startDate
    endDate
    milestone
    ownerID
    ownerAuthID
    ownerEmail
    owner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    status
    otherUsers {
      userID
      projectRole
      __typename
    }
    risk
    githubRepo
    awsAccount
    cognitoUrl
    dynamo {
      key
      value
      __typename
    }
    solutionType
    scope
    estUsersInFirstYear
    userAgeGroups
    lambdaFunction {
      name
      url
      __typename
    }
    liveUrl
    siteData {
      audience
      trafficCount
      trafficLocation
      tone
      languages
      pageSortSeq
      organizationSummary
      purpose
      goals
      messaging
      keyDates
      suggestedSolution
      projectNotes
      contactPerson
      isLocked
      appId
      branchName
      __typename
    }
    isZoiq
    discussion {
      nextToken
      __typename
    }
    sourceFile {
      nextToken
      __typename
    }
    gallery {
      nextToken
      __typename
    }
    projectPlan {
      nextToken
      __typename
    }
    successCriteria {
      nextToken
      __typename
    }
    meetings {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    summaries {
      nextToken
      __typename
    }
    assets {
      nextToken
      __typename
    }
    store {
      nextToken
      __typename
    }
    userSortSeq
    projectPlanSortSeq
    projectMeetingsSortSeq
    projectCriteriaSortSeq
    counts {
      ORGANIZATION
      TEAM
      STOCK
      DOCUMENTS
      PARTNERS_STOCK
      STORE_INVENTORY
      VECTORS
      GALLERY
      SOURCE_FILE
      __typename
    }
    closingMatrix {
      projectFunds
      relationshipWithLead
      projectRelation
      opportunityRelated
      additionalBuildRequirements
      estNewFeatures
      industryExpertOnTheirSide
      technicalExpertOnTheirSide
      perceivedReadiness
      notes
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createProjectPlan = /* GraphQL */ `mutation CreateProjectPlan(
  $input: CreateProjectPlanInput!
  $condition: ModelProjectPlanConditionInput
) {
  createProjectPlan(input: $input, condition: $condition) {
    id
    projectID
    milestone
    startDate
    endDate
    status
    risk
    fakeId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectPlanMutationVariables,
  APITypes.CreateProjectPlanMutation
>;
export const updateProjectPlan = /* GraphQL */ `mutation UpdateProjectPlan(
  $input: UpdateProjectPlanInput!
  $condition: ModelProjectPlanConditionInput
) {
  updateProjectPlan(input: $input, condition: $condition) {
    id
    projectID
    milestone
    startDate
    endDate
    status
    risk
    fakeId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectPlanMutationVariables,
  APITypes.UpdateProjectPlanMutation
>;
export const deleteProjectPlan = /* GraphQL */ `mutation DeleteProjectPlan(
  $input: DeleteProjectPlanInput!
  $condition: ModelProjectPlanConditionInput
) {
  deleteProjectPlan(input: $input, condition: $condition) {
    id
    projectID
    milestone
    startDate
    endDate
    status
    risk
    fakeId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectPlanMutationVariables,
  APITypes.DeleteProjectPlanMutation
>;
export const createDiscussion = /* GraphQL */ `mutation CreateDiscussion(
  $input: CreateDiscussionInput!
  $condition: ModelDiscussionConditionInput
) {
  createDiscussion(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    date
    actionItemsSortSeq
    actions {
      nextToken
      __typename
    }
    emailSent
    emailSentDate
    meetingID
    meeting {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscussionMutationVariables,
  APITypes.CreateDiscussionMutation
>;
export const updateDiscussion = /* GraphQL */ `mutation UpdateDiscussion(
  $input: UpdateDiscussionInput!
  $condition: ModelDiscussionConditionInput
) {
  updateDiscussion(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    date
    actionItemsSortSeq
    actions {
      nextToken
      __typename
    }
    emailSent
    emailSentDate
    meetingID
    meeting {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscussionMutationVariables,
  APITypes.UpdateDiscussionMutation
>;
export const deleteDiscussion = /* GraphQL */ `mutation DeleteDiscussion(
  $input: DeleteDiscussionInput!
  $condition: ModelDiscussionConditionInput
) {
  deleteDiscussion(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    date
    actionItemsSortSeq
    actions {
      nextToken
      __typename
    }
    emailSent
    emailSentDate
    meetingID
    meeting {
      id
      projectID
      name
      description
      firstMeetingDate
      lastMeetingDate
      startTime
      endTime
      frequency
      link
      attendees
      mailSentDate
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscussionMutationVariables,
  APITypes.DeleteDiscussionMutation
>;
export const createStyleGuide = /* GraphQL */ `mutation CreateStyleGuide(
  $input: CreateStyleGuideInput!
  $condition: ModelStyleGuideConditionInput
) {
  createStyleGuide(input: $input, condition: $condition) {
    id
    name
    type
    value
    organizationID
    projectID
    extraInfo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStyleGuideMutationVariables,
  APITypes.CreateStyleGuideMutation
>;
export const updateStyleGuide = /* GraphQL */ `mutation UpdateStyleGuide(
  $input: UpdateStyleGuideInput!
  $condition: ModelStyleGuideConditionInput
) {
  updateStyleGuide(input: $input, condition: $condition) {
    id
    name
    type
    value
    organizationID
    projectID
    extraInfo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStyleGuideMutationVariables,
  APITypes.UpdateStyleGuideMutation
>;
export const deleteStyleGuide = /* GraphQL */ `mutation DeleteStyleGuide(
  $input: DeleteStyleGuideInput!
  $condition: ModelStyleGuideConditionInput
) {
  deleteStyleGuide(input: $input, condition: $condition) {
    id
    name
    type
    value
    organizationID
    projectID
    extraInfo
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStyleGuideMutationVariables,
  APITypes.DeleteStyleGuideMutation
>;
export const createActionItems = /* GraphQL */ `mutation CreateActionItems(
  $input: CreateActionItemsInput!
  $condition: ModelActionItemsConditionInput
) {
  createActionItems(input: $input, condition: $condition) {
    id
    discussionID
    actionItem
    owners
    isCompleted
    targetDate
    dateAssigned
    emailSent
    dateCompleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActionItemsMutationVariables,
  APITypes.CreateActionItemsMutation
>;
export const updateActionItems = /* GraphQL */ `mutation UpdateActionItems(
  $input: UpdateActionItemsInput!
  $condition: ModelActionItemsConditionInput
) {
  updateActionItems(input: $input, condition: $condition) {
    id
    discussionID
    actionItem
    owners
    isCompleted
    targetDate
    dateAssigned
    emailSent
    dateCompleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActionItemsMutationVariables,
  APITypes.UpdateActionItemsMutation
>;
export const deleteActionItems = /* GraphQL */ `mutation DeleteActionItems(
  $input: DeleteActionItemsInput!
  $condition: ModelActionItemsConditionInput
) {
  deleteActionItems(input: $input, condition: $condition) {
    id
    discussionID
    actionItem
    owners
    isCompleted
    targetDate
    dateAssigned
    emailSent
    dateCompleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActionItemsMutationVariables,
  APITypes.DeleteActionItemsMutation
>;
export const createSummary = /* GraphQL */ `mutation CreateSummary(
  $input: CreateSummaryInput!
  $condition: ModelSummaryConditionInput
) {
  createSummary(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    summary
    summaryDate
    summaryOwnerID
    summaryOwnerEmail
    summaryOwner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    summaryType
    discussionDate
    discussedWithID
    discussedWithEmail
    discussedWith {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSummaryMutationVariables,
  APITypes.CreateSummaryMutation
>;
export const updateSummary = /* GraphQL */ `mutation UpdateSummary(
  $input: UpdateSummaryInput!
  $condition: ModelSummaryConditionInput
) {
  updateSummary(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    summary
    summaryDate
    summaryOwnerID
    summaryOwnerEmail
    summaryOwner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    summaryType
    discussionDate
    discussedWithID
    discussedWithEmail
    discussedWith {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSummaryMutationVariables,
  APITypes.UpdateSummaryMutation
>;
export const deleteSummary = /* GraphQL */ `mutation DeleteSummary(
  $input: DeleteSummaryInput!
  $condition: ModelSummaryConditionInput
) {
  deleteSummary(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    summary
    summaryDate
    summaryOwnerID
    summaryOwnerEmail
    summaryOwner {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    summaryType
    discussionDate
    discussedWithID
    discussedWithEmail
    discussedWith {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSummaryMutationVariables,
  APITypes.DeleteSummaryMutation
>;
export const createMeetings = /* GraphQL */ `mutation CreateMeetings(
  $input: CreateMeetingsInput!
  $condition: ModelMeetingsConditionInput
) {
  createMeetings(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    firstMeetingDate
    lastMeetingDate
    startTime
    endTime
    rolls {
      Date
      Day
      __typename
    }
    frequency
    link
    attendees
    mailSentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingsMutationVariables,
  APITypes.CreateMeetingsMutation
>;
export const updateMeetings = /* GraphQL */ `mutation UpdateMeetings(
  $input: UpdateMeetingsInput!
  $condition: ModelMeetingsConditionInput
) {
  updateMeetings(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    firstMeetingDate
    lastMeetingDate
    startTime
    endTime
    rolls {
      Date
      Day
      __typename
    }
    frequency
    link
    attendees
    mailSentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingsMutationVariables,
  APITypes.UpdateMeetingsMutation
>;
export const deleteMeetings = /* GraphQL */ `mutation DeleteMeetings(
  $input: DeleteMeetingsInput!
  $condition: ModelMeetingsConditionInput
) {
  deleteMeetings(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    firstMeetingDate
    lastMeetingDate
    startTime
    endTime
    rolls {
      Date
      Day
      __typename
    }
    frequency
    link
    attendees
    mailSentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingsMutationVariables,
  APITypes.DeleteMeetingsMutation
>;
export const createSuccessCriteria = /* GraphQL */ `mutation CreateSuccessCriteria(
  $input: CreateSuccessCriteriaInput!
  $condition: ModelSuccessCriteriaConditionInput
) {
  createSuccessCriteria(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    description
    measurableCriteria
    type
    pageId
    sectionId
    page {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    pagesCriteriaId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSuccessCriteriaMutationVariables,
  APITypes.CreateSuccessCriteriaMutation
>;
export const updateSuccessCriteria = /* GraphQL */ `mutation UpdateSuccessCriteria(
  $input: UpdateSuccessCriteriaInput!
  $condition: ModelSuccessCriteriaConditionInput
) {
  updateSuccessCriteria(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    description
    measurableCriteria
    type
    pageId
    sectionId
    page {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    pagesCriteriaId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSuccessCriteriaMutationVariables,
  APITypes.UpdateSuccessCriteriaMutation
>;
export const deleteSuccessCriteria = /* GraphQL */ `mutation DeleteSuccessCriteria(
  $input: DeleteSuccessCriteriaInput!
  $condition: ModelSuccessCriteriaConditionInput
) {
  deleteSuccessCriteria(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    description
    measurableCriteria
    type
    pageId
    sectionId
    page {
      id
      projectID
      name
      description
      link
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    pagesCriteriaId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSuccessCriteriaMutationVariables,
  APITypes.DeleteSuccessCriteriaMutation
>;
export const createPages = /* GraphQL */ `mutation CreatePages(
  $input: CreatePagesInput!
  $condition: ModelPagesConditionInput
) {
  createPages(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    link
    pageSections {
      section
      noMedia
      id
      link
      meta
      media
      lastEmailSent
      notes
      illustration
      instructionsLevel
      __typename
    }
    criteria {
      nextToken
      __typename
    }
    successCriteriaSummary {
      mustHave
      shouldHave
      couldHave
      wontHave
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePagesMutationVariables,
  APITypes.CreatePagesMutation
>;
export const updatePages = /* GraphQL */ `mutation UpdatePages(
  $input: UpdatePagesInput!
  $condition: ModelPagesConditionInput
) {
  updatePages(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    link
    pageSections {
      section
      noMedia
      id
      link
      meta
      media
      lastEmailSent
      notes
      illustration
      instructionsLevel
      __typename
    }
    criteria {
      nextToken
      __typename
    }
    successCriteriaSummary {
      mustHave
      shouldHave
      couldHave
      wontHave
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePagesMutationVariables,
  APITypes.UpdatePagesMutation
>;
export const deletePages = /* GraphQL */ `mutation DeletePages(
  $input: DeletePagesInput!
  $condition: ModelPagesConditionInput
) {
  deletePages(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    name
    description
    link
    pageSections {
      section
      noMedia
      id
      link
      meta
      media
      lastEmailSent
      notes
      illustration
      instructionsLevel
      __typename
    }
    criteria {
      nextToken
      __typename
    }
    successCriteriaSummary {
      mustHave
      shouldHave
      couldHave
      wontHave
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePagesMutationVariables,
  APITypes.DeletePagesMutation
>;
export const createUserJourney = /* GraphQL */ `mutation CreateUserJourney(
  $input: CreateUserJourneyInput!
  $condition: ModelUserJourneyConditionInput
) {
  createUserJourney(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    userRole
    pageName
    sectionName
    access
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserJourneyMutationVariables,
  APITypes.CreateUserJourneyMutation
>;
export const updateUserJourney = /* GraphQL */ `mutation UpdateUserJourney(
  $input: UpdateUserJourneyInput!
  $condition: ModelUserJourneyConditionInput
) {
  updateUserJourney(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    userRole
    pageName
    sectionName
    access
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserJourneyMutationVariables,
  APITypes.UpdateUserJourneyMutation
>;
export const deleteUserJourney = /* GraphQL */ `mutation DeleteUserJourney(
  $input: DeleteUserJourneyInput!
  $condition: ModelUserJourneyConditionInput
) {
  deleteUserJourney(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    userRole
    pageName
    sectionName
    access
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserJourneyMutationVariables,
  APITypes.DeleteUserJourneyMutation
>;
export const createBacklogSequence = /* GraphQL */ `mutation CreateBacklogSequence(
  $input: CreateBacklogSequenceInput!
  $condition: ModelBacklogSequenceConditionInput
) {
  createBacklogSequence(input: $input, condition: $condition) {
    id
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBacklogSequenceMutationVariables,
  APITypes.CreateBacklogSequenceMutation
>;
export const updateBacklogSequence = /* GraphQL */ `mutation UpdateBacklogSequence(
  $input: UpdateBacklogSequenceInput!
  $condition: ModelBacklogSequenceConditionInput
) {
  updateBacklogSequence(input: $input, condition: $condition) {
    id
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBacklogSequenceMutationVariables,
  APITypes.UpdateBacklogSequenceMutation
>;
export const deleteBacklogSequence = /* GraphQL */ `mutation DeleteBacklogSequence(
  $input: DeleteBacklogSequenceInput!
  $condition: ModelBacklogSequenceConditionInput
) {
  deleteBacklogSequence(input: $input, condition: $condition) {
    id
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBacklogSequenceMutationVariables,
  APITypes.DeleteBacklogSequenceMutation
>;
export const createComments = /* GraphQL */ `mutation CreateComments(
  $input: CreateCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  createComments(input: $input, condition: $condition) {
    id
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    comment
    date
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    authID
    authEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentsMutationVariables,
  APITypes.CreateCommentsMutation
>;
export const updateComments = /* GraphQL */ `mutation UpdateComments(
  $input: UpdateCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  updateComments(input: $input, condition: $condition) {
    id
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    comment
    date
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    authID
    authEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentsMutationVariables,
  APITypes.UpdateCommentsMutation
>;
export const deleteComments = /* GraphQL */ `mutation DeleteComments(
  $input: DeleteCommentsInput!
  $condition: ModelCommentsConditionInput
) {
  deleteComments(input: $input, condition: $condition) {
    id
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    comment
    date
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    authID
    authEmail
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentsMutationVariables,
  APITypes.DeleteCommentsMutation
>;
export const createSubTaskModel = /* GraphQL */ `mutation CreateSubTaskModel(
  $input: CreateSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  createSubTaskModel(input: $input, condition: $condition) {
    id
    isCompleted
    task
    completedOn
    mediaId
    sprintType
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    statusUpdateDate
    priority
    completedByEmail
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    projectPlanID
    projectPlan {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    status
    moscow
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubTaskModelMutationVariables,
  APITypes.CreateSubTaskModelMutation
>;
export const updateSubTaskModel = /* GraphQL */ `mutation UpdateSubTaskModel(
  $input: UpdateSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  updateSubTaskModel(input: $input, condition: $condition) {
    id
    isCompleted
    task
    completedOn
    mediaId
    sprintType
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    statusUpdateDate
    priority
    completedByEmail
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    projectPlanID
    projectPlan {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    status
    moscow
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubTaskModelMutationVariables,
  APITypes.UpdateSubTaskModelMutation
>;
export const deleteSubTaskModel = /* GraphQL */ `mutation DeleteSubTaskModel(
  $input: DeleteSubTaskModelInput!
  $condition: ModelSubTaskModelConditionInput
) {
  deleteSubTaskModel(input: $input, condition: $condition) {
    id
    isCompleted
    task
    completedOn
    mediaId
    sprintType
    backlogID
    backlog {
      id
      name
      title
      isCompleted
      dateCompleted
      createdBy
      createdByEmail
      status
      priority
      assignedTo
      targetDate
      sectionID
      pageID
      notifyAssignedMembers
      referralLinks
      note
      tags
      createdAt
      updatedAt
      __typename
    }
    statusUpdateDate
    priority
    completedByEmail
    organizationID
    organization {
      id
      authID
      email
      avatar
      name
      type
      address1
      address2
      city
      state
      country
      zipCode
      url
      maxBucketSize
      staffSortSeq
      storeInventorySortSeq
      partnersLogoSortSeq
      mediaFilesSortSeq
      gallerySortSeq
      allowedFileTabs
      orgSize
      industry
      mission
      software
      paper
      printer
      createdAt
      updatedAt
      __typename
    }
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    projectPlanID
    projectPlan {
      id
      projectID
      milestone
      startDate
      endDate
      status
      risk
      fakeId
      createdAt
      updatedAt
      __typename
    }
    status
    moscow
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubTaskModelMutationVariables,
  APITypes.DeleteSubTaskModelMutation
>;
export const createBacklog = /* GraphQL */ `mutation CreateBacklog(
  $input: CreateBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  createBacklog(input: $input, condition: $condition) {
    id
    name
    title
    isCompleted
    dateCompleted
    createdBy
    createdByEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    media {
      id
      mediaType
      mediaLink
      __typename
    }
    status
    priority
    assignedTo
    assignedToUser {
      nextToken
      __typename
    }
    comments {
      nextToken
      __typename
    }
    targetDate
    sectionID
    pageID
    notifyAssignedMembers
    subtasks {
      nextToken
      __typename
    }
    referralLinks
    styles {
      bgStyle
      __typename
    }
    note
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBacklogMutationVariables,
  APITypes.CreateBacklogMutation
>;
export const updateBacklog = /* GraphQL */ `mutation UpdateBacklog(
  $input: UpdateBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  updateBacklog(input: $input, condition: $condition) {
    id
    name
    title
    isCompleted
    dateCompleted
    createdBy
    createdByEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    media {
      id
      mediaType
      mediaLink
      __typename
    }
    status
    priority
    assignedTo
    assignedToUser {
      nextToken
      __typename
    }
    comments {
      nextToken
      __typename
    }
    targetDate
    sectionID
    pageID
    notifyAssignedMembers
    subtasks {
      nextToken
      __typename
    }
    referralLinks
    styles {
      bgStyle
      __typename
    }
    note
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBacklogMutationVariables,
  APITypes.UpdateBacklogMutation
>;
export const deleteBacklog = /* GraphQL */ `mutation DeleteBacklog(
  $input: DeleteBacklogInput!
  $condition: ModelBacklogConditionInput
) {
  deleteBacklog(input: $input, condition: $condition) {
    id
    name
    title
    isCompleted
    dateCompleted
    createdBy
    createdByEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    media {
      id
      mediaType
      mediaLink
      __typename
    }
    status
    priority
    assignedTo
    assignedToUser {
      nextToken
      __typename
    }
    comments {
      nextToken
      __typename
    }
    targetDate
    sectionID
    pageID
    notifyAssignedMembers
    subtasks {
      nextToken
      __typename
    }
    referralLinks
    styles {
      bgStyle
      __typename
    }
    note
    tags
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBacklogMutationVariables,
  APITypes.DeleteBacklogMutation
>;
export const createContentCreation = /* GraphQL */ `mutation CreateContentCreation(
  $input: CreateContentCreationInput!
  $condition: ModelContentCreationConditionInput
) {
  createContentCreation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    content
    pageId
    sectionId
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    pageName
    sent
    sentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContentCreationMutationVariables,
  APITypes.CreateContentCreationMutation
>;
export const updateContentCreation = /* GraphQL */ `mutation UpdateContentCreation(
  $input: UpdateContentCreationInput!
  $condition: ModelContentCreationConditionInput
) {
  updateContentCreation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    content
    pageId
    sectionId
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    pageName
    sent
    sentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContentCreationMutationVariables,
  APITypes.UpdateContentCreationMutation
>;
export const deleteContentCreation = /* GraphQL */ `mutation DeleteContentCreation(
  $input: DeleteContentCreationInput!
  $condition: ModelContentCreationConditionInput
) {
  deleteContentCreation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    content
    pageId
    sectionId
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    pageName
    sent
    sentDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContentCreationMutationVariables,
  APITypes.DeleteContentCreationMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation(
  $input: CreateConversationInput!
  $condition: ModelConversationConditionInput
) {
  createConversation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    conversations {
      message
      isBot
      date
      id
      __typename
    }
    authID
    pageId
    sectionId
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation(
  $input: UpdateConversationInput!
  $condition: ModelConversationConditionInput
) {
  updateConversation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    conversations {
      message
      isBot
      date
      id
      __typename
    }
    authID
    pageId
    sectionId
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation(
  $input: DeleteConversationInput!
  $condition: ModelConversationConditionInput
) {
  deleteConversation(input: $input, condition: $condition) {
    id
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    conversations {
      message
      isBot
      date
      id
      __typename
    }
    authID
    pageId
    sectionId
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createStore = /* GraphQL */ `mutation CreateStore(
  $input: CreateStoreInput!
  $condition: ModelStoreConditionInput
) {
  createStore(input: $input, condition: $condition) {
    id
    projectID
    organizationID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    title
    description
    amount
    inStock
    addedBy
    mediaIds
    colorsAvailable
    sizesAvailable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStoreMutationVariables,
  APITypes.CreateStoreMutation
>;
export const updateStore = /* GraphQL */ `mutation UpdateStore(
  $input: UpdateStoreInput!
  $condition: ModelStoreConditionInput
) {
  updateStore(input: $input, condition: $condition) {
    id
    projectID
    organizationID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    title
    description
    amount
    inStock
    addedBy
    mediaIds
    colorsAvailable
    sizesAvailable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStoreMutationVariables,
  APITypes.UpdateStoreMutation
>;
export const deleteStore = /* GraphQL */ `mutation DeleteStore(
  $input: DeleteStoreInput!
  $condition: ModelStoreConditionInput
) {
  deleteStore(input: $input, condition: $condition) {
    id
    projectID
    organizationID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    title
    description
    amount
    inStock
    addedBy
    mediaIds
    colorsAvailable
    sizesAvailable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoreMutationVariables,
  APITypes.DeleteStoreMutation
>;
export const createFont = /* GraphQL */ `mutation CreateFont(
  $input: CreateFontInput!
  $condition: ModelFontConditionInput
) {
  createFont(input: $input, condition: $condition) {
    id
    name
    link
    complimentsWithID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFontMutationVariables,
  APITypes.CreateFontMutation
>;
export const updateFont = /* GraphQL */ `mutation UpdateFont(
  $input: UpdateFontInput!
  $condition: ModelFontConditionInput
) {
  updateFont(input: $input, condition: $condition) {
    id
    name
    link
    complimentsWithID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFontMutationVariables,
  APITypes.UpdateFontMutation
>;
export const deleteFont = /* GraphQL */ `mutation DeleteFont(
  $input: DeleteFontInput!
  $condition: ModelFontConditionInput
) {
  deleteFont(input: $input, condition: $condition) {
    id
    name
    link
    complimentsWithID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFontMutationVariables,
  APITypes.DeleteFontMutation
>;
export const createActivity = /* GraphQL */ `mutation CreateActivity(
  $input: CreateActivityInput!
  $condition: ModelActivityConditionInput
) {
  createActivity(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    activity
    link
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActivityMutationVariables,
  APITypes.CreateActivityMutation
>;
export const updateActivity = /* GraphQL */ `mutation UpdateActivity(
  $input: UpdateActivityInput!
  $condition: ModelActivityConditionInput
) {
  updateActivity(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    activity
    link
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActivityMutationVariables,
  APITypes.UpdateActivityMutation
>;
export const deleteActivity = /* GraphQL */ `mutation DeleteActivity(
  $input: DeleteActivityInput!
  $condition: ModelActivityConditionInput
) {
  deleteActivity(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    activity
    link
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActivityMutationVariables,
  APITypes.DeleteActivityMutation
>;
export const createSolution = /* GraphQL */ `mutation CreateSolution(
  $input: CreateSolutionInput!
  $condition: ModelSolutionConditionInput
) {
  createSolution(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    tags
    title
    description
    projectID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSolutionMutationVariables,
  APITypes.CreateSolutionMutation
>;
export const updateSolution = /* GraphQL */ `mutation UpdateSolution(
  $input: UpdateSolutionInput!
  $condition: ModelSolutionConditionInput
) {
  updateSolution(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    tags
    title
    description
    projectID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSolutionMutationVariables,
  APITypes.UpdateSolutionMutation
>;
export const deleteSolution = /* GraphQL */ `mutation DeleteSolution(
  $input: DeleteSolutionInput!
  $condition: ModelSolutionConditionInput
) {
  deleteSolution(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    tags
    title
    description
    projectID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSolutionMutationVariables,
  APITypes.DeleteSolutionMutation
>;
export const createPopups = /* GraphQL */ `mutation CreatePopups(
  $input: CreatePopupsInput!
  $condition: ModelPopupsConditionInput
) {
  createPopups(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    title
    description
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    isActive
    expiryDate
    content {
      name
      value
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePopupsMutationVariables,
  APITypes.CreatePopupsMutation
>;
export const updatePopups = /* GraphQL */ `mutation UpdatePopups(
  $input: UpdatePopupsInput!
  $condition: ModelPopupsConditionInput
) {
  updatePopups(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    title
    description
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    isActive
    expiryDate
    content {
      name
      value
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePopupsMutationVariables,
  APITypes.UpdatePopupsMutation
>;
export const deletePopups = /* GraphQL */ `mutation DeletePopups(
  $input: DeletePopupsInput!
  $condition: ModelPopupsConditionInput
) {
  deletePopups(input: $input, condition: $condition) {
    id
    authID
    authEmail
    user {
      id
      authID
      organizationID
      name
      title
      email
      phone
      isAdmin
      initialEmailSentOn
      overrideOrganization
      orgLevel
      lastLogin
      status
      adminAccessTo
      liveVisiblity
      isZoiq
      completedTasks
      links
      canAddProjects
      createdAt
      updatedAt
      __typename
    }
    title
    description
    projectID
    project {
      id
      organizationID
      name
      description
      startDate
      endDate
      milestone
      ownerID
      ownerAuthID
      ownerEmail
      status
      risk
      githubRepo
      awsAccount
      cognitoUrl
      solutionType
      scope
      estUsersInFirstYear
      userAgeGroups
      liveUrl
      isZoiq
      userSortSeq
      projectPlanSortSeq
      projectMeetingsSortSeq
      projectCriteriaSortSeq
      createdAt
      updatedAt
      __typename
    }
    isActive
    expiryDate
    content {
      name
      value
      __typename
    }
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePopupsMutationVariables,
  APITypes.DeletePopupsMutation
>;
