import {
  Backlog,
  BacklogStatus,
  CreateActivityInput,
  SiteData,
  UpdateBacklogSequenceInput,
  UpdateOrganizationInput,
  UpdateProjectInput,
} from "@/API";
import { v4 as uuidv4 } from "uuid";

import { TASK_URL } from "@/constants/home.constants";
import {
  updateBacklogSequence,
  updateProjectSeq,
} from "@/customGraphql/customMutations";
import { getOrganization } from "@/customGraphql/customQueries";
import { createActivity, updateOrganization } from "@/graphql/mutations";
import {
  newActivityTemplate,
  taskUpdateTemplate,
} from "@/templates/email-template";
import * as Amplify from "@aws-sdk/client-amplify";
import { notification } from "antd";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
import {
  S3UploadOptions,
  convertToMB,
  parseTaskStatus,
  uploadFileToS3,
} from ".";
import awsconfig from "../aws-exports";

// const appId = "d2nae30u2qz451";

export const getBuildInfo = (
  appId: string,
  branchName: string
): Promise<Amplify.Job> => {
  return new Promise((resolve, reject) => {
    try {
      const client = new Amplify.Amplify({
        apiVersion: "2016-04-18",
        region: awsconfig.aws_cognito_region,
        credentials: {
          accessKeyId: import.meta.env.VITE_AWS_ACCESS_ID,
          secretAccessKey: import.meta.env.VITE_AWS_SECRET_KEY,
        },
      });

      client.listJobs(
        {
          appId: appId,
          branchName: branchName,
          maxResults: 10,
        },
        (err, data) => {
          if (err) {
            console.log(err, err.stack);
            reject(err);
          }
          const jobs = data?.jobSummaries;
          if (jobs && jobs.length > 0) {
            const latestJob = jobs[0];
            client.getJob(
              {
                appId: appId,
                branchName: branchName,
                jobId: latestJob.jobId,
              },
              (err, data) => {
                if (err) {
                  console.log(err, err.stack);
                  reject(err);
                } else {
                  if (data?.job) {
                    resolve(data.job);
                  } else {
                    reject(new Error("No job found"));
                  }
                }
              }
            );
          } else {
            reject(new Error("No jobs found"));
          }
        }
      );
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const sendCode = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
    notification.success({
      message: "Success",
      description: "A verification code has been sent to your email.",
    });
    return true;
  } catch (error) {
    console.error(error);

    //   @ts-ignore
    return { error: error.message };
  }
};

export const createUser = async (email: string) => {
  try {
    const response = await axios.post(
      "https://tusee9uvai.execute-api.us-east-1.amazonaws.com/create-user",
      {
        email,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export interface EmailData {
  subject: string;
  body: string;
  source: string;
  toAddresses: string[];
}

export const sendEmail = async (
  email: string,
  subject: string,
  body: string,
  cc?: false | string[],
  source?: string
) => {
  const convertedEmail: EmailData = {
    subject,
    body,
    source: source ?? "welcome@zoiq.io",
    toAddresses: cc ? [email, ...cc] : [email],
  };

  try {
    const response = await axios.post(
      "https://mcyn9pkwre.execute-api.us-east-1.amazonaws.com/send-email",

      JSON.stringify(convertedEmail),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;

    // Process events on the client side
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};
export const updateSortSeqMutation = async (
  id: string,
  newSeq: string[],
  sortKey:
    | "projectPlanSortSeq"
    | "userSortSeq"
    | "projectMeetingsSortSeq"
    | "projectCriteriaSortSeq"
) => {
  const input: UpdateProjectInput = {
    id: id,
    [sortKey]: newSeq,
  };
  await API.graphql(
    graphqlOperation(updateProjectSeq, {
      input,
    })
  );
};
export const updateSortSeqMutationOrg = async (
  id: string,
  newSeq: string[],
  sortKey:
    | "staffSortSeq"
    | "storeInventorySortSeq"
    | "partnersLogoSortSeq"
    | "mediaFilesSortSeq"
    | "gallerySortSeq"
) => {
  const input: UpdateOrganizationInput = {
    id: id,
    [sortKey]: newSeq,
  };
  await API.graphql(
    graphqlOperation(updateOrganization, {
      input,
    })
  );
};
export const updateSortSeqMutationSiteData = async (
  id: string,
  newSeq1: string[],
  newSeq2: any[],
  siteData: SiteData
) => {
  if (!newSeq1 && !newSeq2) return;

  const input: UpdateProjectInput = {
    id: id,
    siteData: {
      ...siteData,
      pageSortSeq: newSeq1 ?? [],
      sectionSortSeq: newSeq2 ?? [],
    },
  };
  await API.graphql(
    graphqlOperation(updateProjectSeq, {
      input,
    })
  );
};

export const updateBacklogSortSeqMutation = async (
  id: string,
  newSeq: string[]
) => {
  const input: UpdateBacklogSequenceInput = {
    id: id,
    sequence: newSeq,
  };
  await API.graphql(
    graphqlOperation(updateBacklogSequence, {
      input,
    })
  );
};

export function downloadBlob(blob: any, filename: string, cb: any) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    if (cb) {
      cb();
    }
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);

  a.click();
  return a;
}

export const download = async (fileKey: string, filename: string, cb: any) => {
  const result = await Storage.get(fileKey, {
    download: true,
  });
  // @ts-ignore
  downloadBlob(result.Body, filename, cb);
};

export const fetchFolderSize = async (folderPath: string) => {
  try {
    const objects = await Storage.list(folderPath);

    // using reduce to sum the size of all objects
    const totalSize = objects.results.reduce(
      (acc, cur) => acc + (cur?.size ?? 0),
      0
    );

    // convert size from bytes to mb using binary conversion
    const totalSizeInMb = convertToMB(totalSize);

    const totalSizeInMbRounded = Math.round(totalSizeInMb * 100) / 100;

    return totalSizeInMbRounded;
  } catch (error) {
    console.error("Error fetching folder size:", error);
  }
};

const configuration = new Configuration({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  organization: import.meta.env.VITE_OPENAI_ORG_KEY,
});

const openai = new OpenAIApi(configuration);

export const generateText = async (prompt: string) => {
  const completion = await openai.createChatCompletion({
    model: "gpt-4",
    messages: [
      { role: "system", content: "You are a helpful assistant." },
      { role: "user", content: prompt },
    ],
  });

  return completion;
};

export const generateImage = async (prompt: string) => {
  const response = await openai.createImage({
    prompt,
    n: 4,
    size: "1024x1024",
  });

  return response;
};

export const onUpload = async (
  file: any,
  key: string,
  options?: S3UploadOptions
): Promise<string> => {
  // upload files to s3
  if (!file) return "";

  const fileLink = await uploadFileToS3(file, key, file?.type, options);

  if (!fileLink) return "";

  return fileLink.key;
};

export const addActivity = async (
  activity: string,
  link?: string,
  shouldSentEmail?: boolean
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const authEmail = user.attributes.email;
    const authID = user.attributes.sub;

    const input: CreateActivityInput = {
      id: uuidv4(),
      authID: authID,
      authEmail: authEmail,
      activity,
      link,
      date: new Date().toISOString(),
    };

    const response: any = await API.graphql(
      graphqlOperation(createActivity, { input })
    );

    if (response.data.createActivity) {
      console.log("activity created");

      if (shouldSentEmail) {
        const fullUrl = link?.includes("http")
          ? link
          : `https://zoiq.io${link}`;
        await sendEmail(
          import.meta.env.VITE_MIKE_EMAIL,
          "New activity in Zoiq",
          newActivityTemplate(authEmail, activity, fullUrl ?? ""),
          [],
          "activity@zoiq.io"
        );
      }

      return response.data.createActivity;
    }

    throw new Error("activity not created");
  } catch (error) {
    console.error(error);
  }
};

export const sendEmailAfterTaskUpdates = async (
  value: BacklogStatus,
  task: Backlog
) => {
  const header = `Task status updated from ${parseTaskStatus(
    task.status
  )} -> ${parseTaskStatus(value)}`;
  if (value === BacklogStatus.IN_REVIEW || value === BacklogStatus.DONE) {
    await sendEmail(
      import.meta.env.VITE_MIKE_EMAIL,
      header,
      taskUpdateTemplate(task?.name!, `${TASK_URL}/${task.id}`),
      [],
      "projects@zoiq.io"
    );
  }

  await addActivity(header, `${TASK_URL}/${task.id}`);
};

export const getAdminAccessToOrgs = async (adminAccessTo: string[]) => {
  if (!adminAccessTo) return [];
  if (adminAccessTo.length === 0) return [];

  const response: any = await API.graphql(
    graphqlOperation(getOrganization, {
      id: adminAccessTo[0],
    })
  );
  return response.data.getOrganization.name;
};
