import { Flex, FlexProps } from "@aws-amplify/ui-react";

interface RowFlexProps extends FlexProps {
  children: React.ReactNode;
}

const RowFlex = ({
  justifyContent = "center",
  alignItems = "center",
  children,
  ...rest
}: RowFlexProps) => {
  return (
    <Flex
      direction="row"
      justifyContent={justifyContent}
      alignItems={alignItems}
      display="flex"
      gap={12}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default RowFlex;
