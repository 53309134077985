import { HERO } from "./home.constants";

const LINKS = [
  {
    id: 1,
    heading: "Quick Links",
    links: [
      {
        id: "r1hs",
        name: "Login",
        href: "/login",
      },
      {
        id: "hthw",
        name: "Our Company",
        href: "/our-company",
      },
      {
        id: "fbfer",
        name: "our services",
        href: "#our-services",
      },
      // {
      //     id: 'fgfer3',
      //     name: 'Quote',
      //     href: '/freequote',
      // },
      {
        id: "fgfg2",
        name: "careers",
        href: "#",
      },
      {
        id: "1rgf",
        name: "contact us",
        href: "#contact-us",
      },
    ],
  },
  {
    id: 2,
    heading: "Support",
    links: [
      {
        id: "2a",
        name: "help",
        href: "#",
      },
      {
        id: "2b",
        name: "terms of service",
        href: "#",
      },
      {
        id: "2c",
        name: "Privacy Policy",
        href: "#",
      },
      {
        id: "2d",
        name: "Legal",
        href: "#",
      },
      {
        id: "2e",
        name: "Feedback",
        href: "#",
      },
    ],
  },
];

const FOOTER = {
  links: LINKS,
  heading: "About ZOIQ",
  subtitle: HERO.subtitle2,
  subtitle2: HERO.subtitle,
  contactUs: {
    heading: "Newsletter",
    subtitle: "Subscribe to our newsletter to stay connected with us.",
  },
  copyrights: `ZOIQ © ${new Date().getFullYear()}`,
};

export default FOOTER;
