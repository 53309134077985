import {
  CreateSuccessCriteriaInput,
  Project,
  SuccessCriteria,
  SuccessCriteriaType,
  UpdateSuccessCriteriaInput,
} from "@/API";
import { successCriteriaTypes } from "@/constants/staticData";

import { updateProject } from "@/customGraphql/customMutations";
import {
  createSuccessCriteria,
  updateSuccessCriteria,
} from "@/graphql/mutations";
import useAuth from "@/hooks/useAuth";
import { getLineBrParsed, reverseLineBrParsed } from "@/utils";
import { addActivity } from "@/utils/graphql";
import { Button, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { API, graphqlOperation } from "aws-amplify";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Form from "../common/Form";

interface ValueProps {
  description: string;
  measurableCriteria: string;
  type: SuccessCriteriaType | null;
}

interface NewCriteriaProps {
  editCriteriaData: SuccessCriteria;

  onSuccessMutation: () => void;
  onModalCancel: () => void;
  project: Project;
  pageId?: string;
  sectionId?: string;
}

const initialValues: ValueProps = {
  description: "",
  measurableCriteria: "",
  type: null,
};

const NewCriteria = ({
  editCriteriaData = {} as SuccessCriteria,
  onModalCancel,
  pageId,
  sectionId,
  onSuccessMutation,
  project,
}: NewCriteriaProps) => {
  const [form] = Form.useForm();

  const isEdit = !isEmpty(editCriteriaData);

  useEffect(() => {
    if (form) {
      if (!isEdit) {
        form.resetFields();
        return;
      }

      form.setFieldsValue({
        description: editCriteriaData.description,
        measurableCriteria: reverseLineBrParsed(
          editCriteriaData.measurableCriteria
        ),
        type: editCriteriaData.type,
      });
    }
  }, [editCriteriaData, form, isEdit]);

  const onFinishUpdate = async (values: ValueProps) => {
    setIsLoading(true);
    try {
      if (email === undefined || authID === undefined) {
        throw new Error("Email or authID is undefined");
      }

      const input: UpdateSuccessCriteriaInput = {
        id: editCriteriaData.id,
        description: values.description,
        measurableCriteria: getLineBrParsed(values.measurableCriteria),
        type: values.type,
      };

      const response: any = await API.graphql(
        graphqlOperation(updateSuccessCriteria, { input })
      );

      if (!response.data.updateSuccessCriteria) {
        messageApi.error("Error updating criteria");
        throw new Error("Error updating criteria");
      }

      messageApi.success("Criteria updated successfully");

      form.resetFields();
      onSuccessMutation();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { email, authID } = useAuth();

  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values: ValueProps) => {
    setIsLoading(true);
    try {
      if (email === undefined || authID === undefined) {
        throw new Error("Email or authID is undefined");
      }
      const input: CreateSuccessCriteriaInput = {
        id: uuidv4().substring(0, 6),
        description: values.description,
        measurableCriteria: getLineBrParsed(values.measurableCriteria),
        type: values.type ?? SuccessCriteriaType.SHOULD_HAVE,
        projectID: project.id,
        pageId,
        sectionId,
      };

      const response: any = await API.graphql(
        graphqlOperation(createSuccessCriteria, { input })
      );
      await API.graphql(
        graphqlOperation(updateProject, {
          input: {
            id: project.id,
            projectCriteriaSortSeq: [
              ...(project.projectCriteriaSortSeq ?? []),
              input.id,
            ],
          },
        })
      );

      if (!response.data.createSuccessCriteria) {
        messageApi.error("Error creating criteria");
        throw new Error("Error creating criteria");
      }

      await addActivity(
        "New criteria added",
        `/project-view/${project.organizationID}/${project.id}`
      );

      messageApi.success("Criteria created successfully");
      form.resetFields();
      onSuccessMutation();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    onModalCancel();
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={!isEmpty(editCriteriaData) ? onFinishUpdate : onFinish}
        onReset={onCancel}
        style={{ marginTop: "1rem" }}
      >
        <Form.Item
          rules={[{ required: true, message: "Please enter description" }]}
          label="Description"
          name="description"
          required
        >
          <TextArea rows={4} placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Please enter measurable criteria" },
          ]}
          label="Measurable Criteria"
          name="measurableCriteria"
          required
        >
          <TextArea rows={4} placeholder="Enter measurable criteria" />
        </Form.Item>

        <Form.Item
          required
          rules={[
            {
              required: true,
              message: "Please select criteria type",
            },
          ]}
          label="Criteria Type"
          tooltip={"#1433"}
          name="type"
        >
          <Select
            placeholder="Select criteria type"
            options={successCriteriaTypes}
          />
        </Form.Item>

        <Form.Item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button onClick={onCancel} size="middle">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: "1rem" }}
            type="primary"
            disabled={isLoading}
            size="middle"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NewCriteria;
