import { VIDEO_PATTERN_URL } from "@/constants/assets";
import { OUR_MISSION } from "@/constants/home.constants";
import useBreakpoint from "@/hooks/useBreakpoint";
import useLocalStorage from "@/hooks/useLocalstorage";
import useVideo from "@/hooks/useVideo";
import SectionLayout from "@/layout/SectionLayout";
import { scrollTo } from "@/utils";
import { textVariant2 } from "@/utils/motions";
import { Button, Tooltip, message, notification, theme } from "antd";
import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { AiOutlinePause } from "react-icons/ai";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FiPlay } from "react-icons/fi";
import { VscDebugRestart, VscMute, VscUnmute } from "react-icons/vsc";
import styled from "styled-components";
import HideOn from "./common/HideOn";
import StyledProgress from "./common/StyledComponents/Progress";
import { TypingText } from "./common/Title";

const StyledVideo = styled(motion.video)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme }) => theme.token.borderRadius}px;
  //  if screen size is more than lg, then make width 860px
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 860px;
  }
`;

const PopupWrapper = styled(motion.div)`
  // absolute from right center of div
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  background: ${({ theme }) => theme.token.colorBgLayout};
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.token.borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 1;
  // only show on desktop
  @media (max-width: 768px) {
    display: none;
  }
`;

interface VideoBackProps {
  bottom?: boolean;
}

export const VideoBack = styled.div<VideoBackProps>`
  position: absolute;
  ${({ bottom }) =>
    bottom
      ? `
  bottom: 0;
  left: 0;
  margin-bottom: -4rem;
  margin-left: -4rem;
  `
      : `
  top: 0;
  right: 0;
  margin-top: -4rem;
  margin-right: -4rem;
  // rotate 180deg
  transform: rotate(240deg);
  `}
  z-index: -1;
  user-select: none;
  @media (max-width: 768px) {
    display: none;
  }
  display: block;
`;

const ZoiqVideo = () => {
  const onSkipVideo = () => {
    // scroll to next section
    scrollTo("our-services");
    restartVideo();
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const videoControls = useVideo(videoRef);
  const {
    pauseVideo,
    currentTime,
    duration,
    restartVideo,
    isPaused,
    playVideo,
    isPlaying,
    isEnded,
    fullScreenVideo,
  } = videoControls;

  const { token } = theme.useToken();
  const breakpoints = useBreakpoint();

  const [muted, setMuted] = useLocalStorage<boolean>("zoiq-video-sound", true);
  const [showMuteMessage, setShowMuteMessage] = useLocalStorage<boolean>(
    "zoiq-sound-popup",
    true
  );

  const [messageApi, messageContextHolder] = message.useMessage();
  const enableSound = () => {
    if (muted) {
      messageApi.success("Sound enabled!");
      setMuted(false);
    } else {
      messageApi.info("Sound disabled!");
      setMuted(true);
    }
  };

  // get percentage of video played
  const percentage = (currentTime / duration) * 100;

  const [notificationApi, contextHolder] = notification.useNotification();

  const inView = useInView(videoRef, { amount: 0.8 });

  useEffect(() => {
    if (muted && inView && showMuteMessage) {
      notificationApi.info({
        message: "Sound is disabled",
        description: "Click on the sound icon to enable sound",
        placement: "bottomRight",
        duration: 5,
        onClose: () => setShowMuteMessage(false),
        btn: (
          <Button
            onClick={() => {
              setMuted(false);
              notificationApi.destroy();
              setShowMuteMessage(false);
            }}
            type="dashed"
          >
            Enable Sound
          </Button>
        ),
      });
    } else {
      notificationApi.destroy();
    }
  }, [notificationApi, showMuteMessage, inView]);

  const [triggerText, setTriggerText] = useState(false);

  return (
    <HideOn hide={Boolean(breakpoints.xs)}>
      {contextHolder}
      {messageContextHolder}
      <SectionLayout
        onViewportEnter={() => {
          setTriggerText(true);
        }}
        viewport={{ once: true, amount: 0.9 }}
        id="zoiq-video"
      >
        <TypingText
          whileInView={triggerText ? "show" : "hidden"}
          title={OUR_MISSION.video.heading}
        />

        <VideoBack>
          <img src={VIDEO_PATTERN_URL} alt="video-back-top" />
        </VideoBack>
        <VideoBack bottom>
          <img src={VIDEO_PATTERN_URL} alt="video-back-bottom" />
        </VideoBack>
        <div style={{ position: "relative" }}>
          <PopupWrapper theme={{ token }}>
            <Tooltip title={isPlaying ? "Pause" : "Play"}>
              <Button
                onClick={isPlaying ? pauseVideo : playVideo}
                type="dashed"
                shape="circle"
              >
                {isPlaying ? <FiPlay /> : <AiOutlinePause />}
              </Button>
            </Tooltip>
            <Tooltip title="We have sound">
              <Button onClick={enableSound} type="dashed" shape="circle">
                {!muted ? <VscUnmute /> : <VscMute />}
              </Button>
            </Tooltip>
            <Tooltip title="Show fullscreen">
              <Button onClick={fullScreenVideo} type="dashed" shape="circle">
                <BsArrowsFullscreen />
              </Button>
            </Tooltip>
            <Tooltip title="Restart video">
              <Button onClick={restartVideo} type="dashed" shape="circle">
                <VscDebugRestart />
              </Button>
            </Tooltip>
          </PopupWrapper>
          <StyledVideo
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.8 }}
            theme={{ token: token, breakpoints: breakpoints }}
            variants={textVariant2}
            onViewportLeave={pauseVideo}
            onViewportEnter={() => {
              playVideo();
            }}
            ref={videoRef}
            // poster={VIDEO_COVER_URL}
            onEnded={onSkipVideo}
            preload="none"
            controls={false}
            muted={muted}
          >
            <source src={OUR_MISSION.video.url} />
          </StyledVideo>

          <motion.div
            animate={{
              opacity: isPaused || isEnded ? 0 : 1,
            }}
          >
            <StyledProgress
              width={percentage.toString()}
              style={{
                position: "absolute",
                bottom: "1rem",
                transition: `width ${duration / 60}s ease-in-out`,
                left: "1rem",
                right: "1rem",
              }}
            />
          </motion.div>
        </div>
        {/* button says "we have sound" */}
      </SectionLayout>
    </HideOn>
  );
};

export default ZoiqVideo;
