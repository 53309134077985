import { PropsWithChildren } from "react";
import styled from "styled-components";

interface ContainerProps {
  size?: "sm" | "md" | "lg" | "xl";
}

const getMaxWidth = (size: string) => {
  switch (size) {
    case "sm":
      return "540px";
    case "md":
      return "720px";
    case "lg":
      return "960px";
    case "xl":
      return "1140px";
    default:
      return "1140px";
  }
};

const StyledContainer = styled.div<ContainerProps>`
  max-width: ${(props) => getMaxWidth(props.size || "xl")};
  margin: 0 auto;
`;

const Container = ({
  children,
  ...rest
}: PropsWithChildren<ContainerProps>) => {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
};

export default Container;
